import { WeekBlockSettingsType } from "blocks/WeekBlock/Settings/WeekBlockSettings.index";
import { JFdictionary } from "../dictionary";

const { types } = JFdictionary

const properties: {
  [key in keyof WeekBlockSettingsType]: any
} = {
  selectedFolder: {
    type: types.string
  },
  selectedItem: {
    type: types.string
  }
}

export default {
  type: types.object,
  properties
}
