import React, { ComponentProps, FC } from 'react';
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { Box, Paper, styled } from 'muicomponents/src';

const WrapBox = styled(Box)({}) as typeof Box;
const WrapPaper = styled(Paper)({
    width: '100%',
    overflow: 'auto',
    maxHeight: '100%',
    flex: '1 1',
}) as typeof Paper;
const TableBox = styled(Box)({
    minWidth: '100%',
    width: 'fit-content',
}) as typeof Box;

export const TableListBody: FC<ComponentProps<typeof ItemsListBody>> = ({ children, ...props }) => {
    return (
        <ItemsListBody {...props} BodyComponent={WrapBox}>
            <WrapPaper>
                <TableBox>{children}</TableBox>
            </WrapPaper>
        </ItemsListBody>
    );
};
