import React, { useState, useRef } from 'react';
import { className, ICardProps } from './Card.index';
import { UserList } from 'uielements/src/UserList/UserList';
import LinkIcon from '@material-ui/icons/Link';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { ViewComponent } from 'uielements/src/FieldRender';
import './Card.scss';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import BaseRequests from 'utils/src/requests/requests.base';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Avatar } from 'muicomponents/src/Avatar';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { Link } from 'muicomponents/src/Link';

const maxVisibleResponsible = 8;

export const Card = (props: ICardProps) => {

  const { id, header, statusFieldsLoading, statusFields, additionalFields, user } = props;

  const responsibleForStatus = additionalFields?.responsibleForStatus || [];

  const newsLinkRef: any = useRef(null);

  // Пустая категоря с полями для карточки
  let fields: any[] = [];
  additionalFields?.additionalFieldsValues.forEach((category) => fields = [...fields, ...category.fields.filter(field => (field.showOnCardView && field.data))]);
  const commonCategory = [{ id: GUID_EMPTY, description: '', isCollapsed: 'false', title: '', fields }];


  const onCopyLink = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    newsLinkRef.current.select();
    document.execCommand('copy');
  }



  return <div {...props} className={className()}>
    <div className={className('Header')}>
      <div className={className('Header-Left')}>
        {additionalFields?.currentStatus.isAllowChangeResponsibleUser && <OpenWithIcon />}

        <span>{header}</span>
      </div>

      <div className={className('Header-Right')}>
        <UserMention id={user.id}>
          <Link className={`UserList-Button`} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>
            <Avatar src={user.imgId} size={26}>
              {user.displayName.substring(0,1)}
            </Avatar>
          </Link>
        </UserMention>
        <LinkIcon onClick={onCopyLink} />
      </div>

      

      <textarea
        ref={newsLinkRef}
        value={BaseRequests.getUrl(`/news/${id}`).replace('prerelease', 'app')}
      />
    </div>

    {!!fields.length && <div className={className('Additional')}>
      <ViewComponent
        fields={commonCategory as any}
      />
    </div>}



    {!!responsibleForStatus.length && <div className={className('Users')}>
      <UserList
        cut={false}
        action={responsibleForStatus.length > maxVisibleResponsible}
        data={responsibleForStatus}
        size={26}
        className={className('Responsible')}
        actionBtnContent={`+ ${responsibleForStatus.length - maxVisibleResponsible}`}
        maxVisible={maxVisibleResponsible}
      />
    </div>}
  </div>
}