import React, { FC, useEffect, useState } from 'react'
import { cnAnswer, mapDispatchToProps, mapStateToProps } from './Answer.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Answer.scss'
import { Comments } from '../../LMS/Comments/Comments'
import { Actions } from 'blocks/NewsView/Actions/Actions'
import { Approve } from './Actions/Approve/Approve'
import { Disapprove } from './Actions/Disapprove/Disapprove'
import UserAnswer from '../../components/UserAnswer'
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import { Button, Icon, HelpTooltip } from 'uielements/src'
import { formatDateSTD } from 'utils/src/utils.dates'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

const Statuses: { [key: string]: React.ReactNode } = {
    'Approved': <Tt i18nKey='answer.status.Approved.1' />,//'Верный',
    'Disapproved': <Tt i18nKey='answer.status.Disapproved.1' />,//'Неверный',
    'WaitModeration': <Tt i18nKey='answer.status.WaitModeration.1' />,//'Ожидает проверки'
}

export const AnswerPresenter: FC<any> = ({ className, answer, sendApproveModeration, user, hideModeration }) => {
    return <UserAnswer
        files={answer.files}
        className={cnAnswer({}, [className])}
        imgUrl={user.imgUrl}
        mainContent={<>
            <UserMention id={user.id}>
                <Link className={cnAnswer(`User`, [classNamesDesign.primaryColor3.text])} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>
                    {user.displayName}
                </Link>
            </UserMention>
            {tT('answer.user.answered.1')}
        </>}
        additionalContent={formatDateSTD(answer.timstamp, true)}
        text={answer.text}
        actionContent={!answer.quesionLastVersion && <HelpTooltip
            minHelpWidth={'200px'}
            help={tT('answer.to_version.1', { version: formatDateSTD(answer.quesionVersionDate + 'Z', true)})} ><Icon icon={'exclamation'} /></HelpTooltip>}
        footer={
            answer.moderationStatus !== "NotModerated" && <ButtonBox orientation={'horizontal'} className={cnAnswer('Actions')}>
                {hideModeration ?
                    <div className={cnAnswer('Status', { status: answer.moderationStatus }, ['Button'])}>{Statuses[answer.moderationStatus]}</div> :
                    <>
                        <Approve aid={answer.answerId} />
                        <Disapprove aid={answer.answerId} />
                    </>}
            </ButtonBox>}
        comments={<Comments nid={answer.newsUid} className={cnAnswer('Comments')} />}
    />
}

export const Answer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AnswerPresenter));
