import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
import { IButtonProps } from 'uielements/src/Button/Button.index'
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import { classNamesDesign } from 'utils/src/constants.classes';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { Avatar, AvatarSize } from 'muicomponents/src/Avatar/Avatar';

const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

type IPresenterProps = LMSTO.TOTracksListItem.IPresenterProps
type IOwnProps = LMSTO.TOTracksListItem.IOwnProps
type IDispatchProps = LMSTO.TOTracksListItem.IDispatchProps
type IStateProps = LMSTO.TOTracksListItem.IStateProps
type IState = LMSTO.TOTracksListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    data
}) => {
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('ImgBox')}>
            <UserMention id={data.userModel.id}>
                <Link className={cnClassName('UserMention')} href={appGeneratePath(mainUrls.user.id, { id: data.userModel.id })}>
                    <Avatar className={cnClassName('UserAvatar')} src={data.userModel.imgUrl} size={AvatarSize.l}>
                        {data.userModel.displayName.substring(0,1)}
                    </Avatar>
                </Link>
            </UserMention>
        </div>
        <div className={cnClassName('Info')}>
            <div className={cnClassName('Title')}>
                <div>
                    <UserMention id={data.userModel.id}>
                        <Link className={cnClassName('Mention', [ classNamesDesign.primaryColor3.text ])} href={appGeneratePath(mainUrls.user.id, { id: data.userModel.id })}>
                            {data.userModel.displayName}
                        </Link>
                    </UserMention>
                </div>
                <div>{data.userModel.position}</div>
                <div>{data.userModel.division}</div>
            </div>

            <div className={cnClassName('StatOne')}>
                
            </div>
        </div>

        <div className={cnClassName('ActionBox')}>
            <div className={cnClassName('Progress')}>{tTr('item.user.progress')} {data.completedSuccess}({data.completedPercentage})</div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
