import { Box, styled } from 'muicomponents/src';
import { itemsListHeaderClasses } from 'muicomponents/src/ItemsListDialog/ItemsListHeader/ItemsListHeader';
import { Select } from 'muicomponents/src/Select';
import { tasksListClasses } from './TableList.index';
import { ListItemButton } from 'muicomponents/src/ListItem';

export const SearchActionsBox = styled(Box)({
    display: 'flex',
    gap: 24,
    gridGap: 24,
    rowGap: 8,
    flexWrap: 'wrap',
    [`& .${tasksListClasses.headerButton}`]: {
        marginLeft: 'auto',
    },
}) as typeof Box;

export const SearchActionsSelect = styled(Select)({
    minWidth: 200,
    flex: 1,
}) as typeof Select;

export const HeaderRootBox = styled(Box)({
    [`& .${itemsListHeaderClasses.searchBox}`]: {
        gap: 8,
        gridGap: 8,
        [`& > .MuiBox-root:nth-of-type(1)`]: {
            minWidth: 200,
            flexGrow: 99,
        },
        [`& > .MuiBox-root:nth-of-type(2)`]: {
            flexGrow: 1,
            flexBasis: 400,
        },
    },
}) as typeof Box;

export const ItemBox = styled(ListItemButton)({
    display: 'flex',
    height: 72,
    padding: '12px 16px',
    gap: 16,
    gridGap: 16,
}) as typeof ListItemButton;

export const ItemGridCell = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    flexBasis: 124,
    minWidth: 124,
    flexGrow: 0,
    [`&.displayName, &.users`]: {
        flex: 1,
        hyphens: 'auto',
        '-ms-hyphens': 'auto',
        '-moz-hyphens': 'auto',
        '-webkit-hyphens': 'auto',
    },
}) as typeof Box;

export const ItemActionCell = styled(ItemGridCell)({
    flexBasis: 40,
    minWidth: 40,
    flexGrow: 0,
}) as typeof ItemGridCell;

export const ItemGridContent = styled('div')({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ['-webkit-line-clamp']: '2',
    ['-webkit-box-orient']: 'vertical',
});
