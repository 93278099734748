import React, { FC, useState, useEffect } from "react";
import {
  IUsersCardWidgetProps,
  cnUsersCardWidget,
  cnUsersCardWidgetItem
} from "./UsersCardWidget.index";
import {
  initWidgetAddition,
  initWidgetSettings,
} from "./UsersCardWidget/UsersCardWidget";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { IconButton } from "muicomponents/src/IconButton/IconButton";
import { Box } from "muicomponents/src/Box/Box";
import { Link } from "muicomponents/src/Link/Link";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { isObject } from "lodash";
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { UsersListItem } from "muicomponents/src/ItemsListDialog/UserListItem/UserListItem";
import { ItemsListBody } from "muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody";
import { CardItemLayout } from "muicomponents/src/ItemsListDialog/CardItem/CardItem";
import { IListItemProps } from "muicomponents/src/ItemsListDialog/ItemsListDialog.index";
import { Button, Typography } from "muicomponents/src";
import { ISettingsItem } from "./UsersCardWidget/UsersCardWidget.index";
import { AlertMessage } from "muicomponents/src/AlertMessage/AlertMessage";
import { Translate } from 'localization';

initWidgetAddition();
initWidgetSettings();

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    boxShadow: "0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    padding: "24px",
    paddingTop: "20px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const StyledImg = styled("img")({
  width: "180px",
  height: "180px",
  "@media(min-width: 1280px)": {
    width: "180px",
    height: "180px",
  },
  "@media(min-width: 1360px)": {
    width: "200px",
    height: "200px",
  },
  "@media(min-width: 1440px)": {
    width: "220px",
    height: "220px",
  },
  "@media(min-width: 1600px)": {
    width: "240px",
    height: "240px",
  },
  "@media(min-width: 1900px)": {
    width: "260px",
    height: "260px",
  },
});
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  letterSpacing: "0.15px",
}));

// const Item = ({ settings, onClose }: any) => {
//   return (
//     <HtmlTooltip
//       title={
//         <Stack direction={"row"} gap={2}>
//           <Box sx={{ display: "flex" }}>
//             <StyledLink
//               style={{ color: "rgba(0, 0, 0, 0.87)" }}
//               href={settings?.url}
//             >
//               {settings?.text}
//             </StyledLink>
//           </Box>
//           <Box sx={{ position: "absolute", right: 0, top: 0 }}>
//             <IconButton size={"small"} onClick={onClose}>
//               <CloseIcon fontSize="small" />
//             </IconButton>
//           </Box>
//         </Stack>
//       }
//       placement="left-start"
//       arrow
//       PopperProps={{
//         disablePortal: true,
//       }}
//       open
//       disableFocusListener
//       disableHoverListener
//       disableTouchListener
//     >
//       <StyledImg src={settings?.file?.originalUrl} />
//     </HtmlTooltip>
//   );
// };
const UserCardItem = ({
  item,
}: React.PropsWithChildren<IListItemProps<ISettingsItem>>) => {
  const { user } = item;
  if (!user) return null;
  // console.log("item", item);
  return (
    <CardItemLayout
      className={cnUsersCardWidgetItem()}
      imgAspectRatio={'1 / 1'}
      cardSx={{ minWidth: "262px", maxWidth: "262px" }}
      alt={user.displayName}
      image={user.imgUrl}
      header={
        <Link className={cnUsersCardWidgetItem('Link')} sx={{ textDecoration: "none" }} href={`/user/${user.id}`}>
          {user.displayName}
        </Link>
      }
      children={
        <>
          <Tooltip title={user.division}>
            <Typography className={cnUsersCardWidgetItem('Division')}
              sx={{
                textWrapStyle: "balance",
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '2',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}>{user.division}</Typography>
          </Tooltip>
          <Tooltip title={user.position}>
            <Typography className={cnUsersCardWidgetItem('Position')}
              sx={{
                textWrapStyle: "balance",
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '2',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}>{user.position}</Typography>
          </Tooltip>
        </>
      }
      //   secondaryContent={"formatDateSTD(item.tag.timestamp)"}
      actions={
        <Stack gap="8px" sx={{ overflow: 'hidden' }} className={cnUsersCardWidgetItem('Stack')}>
          <Box
            className={cnUsersCardWidgetItem('Actions')}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
              flex: "1"
            }}
          >
            {/* <Button variant="outlined" size="small">
                  Добавить себе
              </Button> */}
            <Button className={cnUsersCardWidgetItem('Open')} variant="outlined" size="small" href={`/user/${user.id}`}>
              {/* Профиль сотрудника */}
              <Translate
                i18nKey={'pryaniky.UsersCardWidget.item.open.user'}
              />
            </Button>
          </Box>

          {item.info && <Tooltip title={item.info}>
            <Typography className={cnUsersCardWidgetItem('Info')}
              sx={{
                whiteSpace: 'nowrap',
                textWrapStyle: "balance",
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }} >{item.info}</Typography>
          </Tooltip>}

          {item.title && (
            <AlertMessage className={cnUsersCardWidgetItem('Alert')} severity={"success"}>
              <Tooltip title={item.title}>
                <Typography className={cnUsersCardWidgetItem('Title')}
                  sx={{
                    textWrapStyle: "balance",
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    '-webkit-line-clamp': '2',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>{item.title}</Typography>
              </Tooltip>
            </AlertMessage>
          )}
        </Stack>
      }
    />
  );
};

const CardListLayout = ({ children }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "24px",
      }}
    >
      {children}
    </Box>
  );
};
const BodyListComponent = ({ children }: any) => {
  return <Box sx={{ marginBottom: "24px" }}>{children}</Box>;
};

export const UsersCardWidget: FC<IUsersCardWidgetProps | any> = ({
  contexts,
  className,
  isEditable,
  HTMLWidgetContentEdit,
  ...props
}) => {
  //   const [show, setShow] = useState(true);
  let items = props.widget.settings?.items;
  let title = props.widget.settings?.title;
  if (!items) return null;
  if (isObject(items)) {
    items = Object.values(items);
  }
  //   if (!show) return null;

  // return <Box>
  //     {items.filter((v: any) => v.enabled).map((item: any) => <Item onClose={() => setShow(false)} key={item.id} settings={item} />)}
  // </Box>

  return (
    <Box className={cnUsersCardWidget()}>
      <Typography
        variant="h6"
        sx={{ margin: "24px 0px 16px" }}
        className={cnUsersCardWidget("Title")}
      >
        {title}
      </Typography>

      <ItemsList<{} & any>
        disableQR
        BodyComponent={BodyListComponent}
        ItemComponent={UserCardItem}
        ListComponent={CardListLayout}
        disableItemsAction
        method={async function (skipCount, { search, count }) {
          return items;
        }}
      />
    </Box>
  );
};
