import { getMenuService } from 'blocks/LeftMenu/redux/selectors';
import { bindActionCreators } from 'redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import actions from 'utils/src/CommonRedux/base/actionsTypes';

export interface IRenderEditSwitcherProps extends TEditStateProps, TEditDispathProps, IRenderEditSwitcherOwnProps {}

export interface IRenderEditSwitcherOwnProps {
  isMobile: boolean;
}

export const mapStateToProps = (state: any) => ({
  edit: state.store.edit as boolean,
});

export type TEditStateProps = ReturnType<typeof mapStateToProps>

export const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);

export type TEditDispathProps = ReturnType<typeof mapDispatchToProps>


