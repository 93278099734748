import { BaseSuggester } from "muicomponents/src";
import { DialogContent } from "muicomponents/src/DialogParts";
import React, { ComponentProps, FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getCompanyPostTypes } from "redux/sagas/AppSettings/selectors";
import { ComponentInjector } from "utils/src/ComponentInjector";
import { WidgetsTypes } from "utils/src/widgets/types";
import { SelectedContextSettingsProps, SelectedContextSettingsType } from "./SelectedContextSettings.index";

const defaultSettings: SelectedContextSettingsType = {
    postType: null
};

export const SelectedContextSettings: FC<SelectedContextSettingsProps> = ({
    // widgetData: data,
    settings,
    onChange,
    isValid,
}) => {

    const companyPostTypes = useSelector(getCompanyPostTypes);

    const postTypesOptions = useMemo<NonNullable<ComponentProps<typeof BaseSuggester>['options']>>(() => {
        return companyPostTypes.map((el: any) => {
            return {
                ...el,
                type: 'posttype'
            }
        });
    }, [companyPostTypes]);

    const [state, setState] = useState(() => {
        return settings || defaultSettings
    });

    useEffect(() => {
        onChange({
            settings: state,
        });
    }, [state]);

    return (
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px !important' }}>
            <BaseSuggester
                value={state.postType}
                options={postTypesOptions}
                onChange={(e, value) => {
                    if(!Array.isArray(value)) {
                        setState((p) => ({ ...p, postType: value}));
                    }
                }}
            />
        </DialogContent>
    )
};

export const initSelectedContextSettings = () => {
    ComponentInjector.getInstance().addComponent('WAddition', SelectedContextSettings, WidgetsTypes.selectedContext);
    ComponentInjector.getInstance().addComponent('WSettings', SelectedContextSettings, WidgetsTypes.selectedContext);
};