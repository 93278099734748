import React, { FC, useMemo } from 'react';
import { IShopItem } from 'utils/src/requests/models/api.shop';
import { i18n } from 'localization';
import { useDispatch, useSelector } from 'react-redux';
import * as utilsProj from 'utils.project/utils.project';
import { openShopMakeOrderWithMultiOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import { Link } from 'muicomponents/src/Link/Link';
import { IStateType } from 'redux/store';
import { Divider, GiftChip, Typography } from 'muicomponents/src';
import { AvailableBox, ChipsWrapBox, ContentBox, OrderBlockWrapBox, ShopInfoBox, StarBox } from './Shop-Item.styled';
import OrderBlock from '../OrderBlock/OrderBlock';
import StarIcon from '@mui/icons-material/Star';
import { PriceElem } from './PriceElem';
import { CardItemLayout } from 'muicomponents/src/ItemsListDialog/CardItem/CardItem';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import placeholderImg from '../placeholder.svg';
import { cn } from '@bem-react/classname';
import { useShopListContext } from '../ShopList/ShopListMui.context';
import { generatePath } from 'react-router';
import { mainUrls } from 'utils/src';
import { MultiOrderChip } from 'muicomponents/src/Chip/type/MultiOrderChip';

import { CheckMarkPreorderMade } from '../CommonElements/CheckMarkPreorderMade';

import { usePreorder } from '../hooks/usePreorder';

const createMarksComponent = (isGift: boolean, isMultiOrder: boolean) => {
    return () => {
        return (
            <ChipsWrapBox className={cnClassName('Marks')}>
                {isGift && <GiftChip className={cnClassName('CanBeGiftedMark')} />}
                {isMultiOrder && <MultiOrderChip className={cnClassName('CanBeMultiOrderMark')} />}
            </ChipsWrapBox>
        );
    };
};
export interface IShopItemProps {
    data: IShopItem;
}

export const cnClassName = cn('MUIShopItem');

export const ShopItem: FC<IShopItemProps> = (props: IShopItemProps) => {
    const listContext = useShopListContext();

    const { data } = props;
    const dispatch = useDispatch();
    const currencyFormats = useSelector(getCurrencyNames);
    const userId = useSelector(getAuthUser).extData.userId;
    const myThanksCount = useSelector(getAuthUser).extData.mythanksCount;
    const maxOrdersCount = useSelector((s: IStateType) => s.shop.categoryProducts.maxOrdersCount);
    const maxOrdersPeriod = useSelector((s: IStateType) => s.shop.categoryProducts.maxOrdersPeriod);
    const ordersCount = useSelector((s: IStateType) => s.shop.categoryProducts.ordersCount) || 0;
    const additionalInformationBeforeOrder = useSelector(
        (s: IStateType) => s.shop.categoryProducts.additionalInformationBeforeOrder
    );
    const isHidePricesAndButtonBuy = useSelector((s: IStateType) => s.shop.categoryProducts.isHidePricesAndButtonBuy);
    let isHideButtonBuy = useSelector((s: IStateType) => s.shop.categoryProducts.isHideButtonBuy);
    isHideButtonBuy = isHideButtonBuy
        ? isHideButtonBuy
        : ordersCount >= maxOrdersCount && maxOrdersPeriod !== 'unlimited';

    const clearShopProduct = () => {
        dispatch(clearShopProduct());
    };
    const clearShopCatProducts = () => {
        dispatch(clearShopCatProducts());
    };
    const { characteristics = [], thanksPrice, minVirtCurrencyPayment, availableCount, isUnlimitedProduct } = data;

    const contentRating = data?.news?.contentRating;

    // если цена зависит от характеристик: сравниваем userThanksCount с минимальной ценой на товар, а не с общей ценой thanksPrice и выводим "От"
    const priceDependsOnChars = characteristics.some((char) => char.usedForPrice) && minVirtCurrencyPayment > -1;
    const fromPriceText = priceDependsOnChars ? i18n.t('from.price') : null;
    const price = priceDependsOnChars ? minVirtCurrencyPayment : thanksPrice;
    const priceCurrencyText = utilsProj.getFormattedCurrencyName(
        currencyFormats,
        price,
        !characteristics.length ? 'nominative' : 'genitive'
    );

    // для товаров которых нет в наличии: при поиске по товарам их все равно видно, и для отображения корректной надписи о наличии проверям это
    const productIsAvailable = isUnlimitedProduct ? true : !!availableCount;

    const useCountSelector = useMemo(() => {
        return data.externalServiceType !== 'Giftery' && data.maxItemsInOrder > 1;
    }, [data.externalServiceType]);

    const maxForCountSelector = useMemo(() => {
        return Math.min(data.maxItemsInOrder, !isUnlimitedProduct ? availableCount : 9999);
    }, [isUnlimitedProduct, availableCount]);

    const productLink = useMemo(() => {
        if (data) return generatePath(mainUrls.shopProduct.id, { id: data.id });
    }, [data?.id]);

    const { isActivePreorder } = usePreorder((userId || '') as string, data);
    async function openMakeOrder() {
        await openShopMakeOrderWithMultiOrder(dispatch, {
            data,
            preselectedUsersId: listContext.userId,
            additionalInformationBeforeOrder,
            withCount: useCountSelector,
            maxCount: maxForCountSelector,
            multiOrderItem: data.multiOrderItem,
        });
    }
    if (ordersCount === undefined) return null;

    const mainImg = data.mainImgId === '00000000-0000-0000-0000-000000000000' ? placeholderImg : data.mainImgUrl;

    return (
        <CardItemLayout
            alt={data.name}
            image={mainImg}
            header={
                <Link sx={{ textDecoration: 'none' }} className={cnClassName('LinkToProduct')} to={productLink}>
                    {data.name}
                </Link>
            }
            CardActionAreaProps={{
                LinkComponent: Link,
                to: productLink,
            }}
            MarksComponent={createMarksComponent(data.isGift, data.multiOrderItem)}
            actions={
                !isHideButtonBuy && (
                    <OrderBlockWrapBox>
                        <OrderBlock
                            {...{
                                characteristics,
                                currencyFormats,
                                id: data.id,
                                maxOrdersCount,
                                maxOrdersPeriod,
                                myThanksCount,
                                ordersCount,
                                multiOrderItem: data.multiOrderItem,
                                showDialog: () => {
                                    !data.isPreorder && openMakeOrder();
                                },
                                thanksPrice: data.thanksPrice,
                                disableOrder: characteristics.length === 0,
                                productIsAvailable,
                                isGift: data.isGift,
                                isPreorder: data.isPreorder,
                                isHasAlreadyPreorder: isActivePreorder,
                            }}
                        />
                    </OrderBlockWrapBox>
                )
            }
        >
            <ContentBox>
                <ShopInfoBox>
                    {data.allowContentRating && contentRating.rating > 0 && (
                        <StarBox className={'ShopItem-Rating'}>
                            <StarIcon htmlColor="#FFB400" sx={{ height: '25px', width: '25px' }} />
                            <Typography variant="subtitle2" color="gray">
                                {+contentRating.rating.toFixed(1)}
                            </Typography>
                        </StarBox>
                    )}
                    <AvailableBox className={'ShopItem-AvaliableBox'}>
                        <Typography className={'ShopItem-Avaliable'} variant="subtitle2" fontWeight="600">
                            {i18n.t('pryaniky.uielements.materialelements.TransferList.availableTitle')}: &nbsp;{' '}
                        </Typography>
                        <Typography className={'ShopItem-AvaliableValue'} variant="subtitle2" color="gray">
                            {data.isUnlimitedProduct
                                ? i18n.t('pryaniky.shop.unlimited')
                                : availableCount + i18n.t('pryaniky.shop.pc')}
                        </Typography>
                    </AvailableBox>
                </ShopInfoBox>
                <Divider />
                {!isHidePricesAndButtonBuy && (
                    <PriceElem fromPriceText={fromPriceText} price={price} priceCurrencyText={priceCurrencyText} />
                )}
                {data.isPreorder && isActivePreorder && (
                    <CheckMarkPreorderMade
                        typographyProps={{
                            sx: {
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                            },
                        }}
                    />
                )}
            </ContentBox>
        </CardItemLayout>
    );
};
