import React, { useState, FC } from 'react';
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout';
import CakeIcon from '@mui/icons-material/Cake';
import { styled } from '@mui/material/styles';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils';
import { Box } from 'muicomponents/src';

const Icon: any = styled(CakeIcon)({
    color: '#C344BE',
});
export const BirthdayEvent: FC<any> = ({ event }) => {
    return (
        <CalendarEventLayout
            headerText={
                <UserMention id={`${event.user.id}`}>
                    <Box>{event.title}</Box>
                </UserMention>
            }
            link={appGeneratePath(mainUrls.user.id, { id: event.user.id })}
            users={[event.user]}
            Icon={Icon}
        />
    );
};
