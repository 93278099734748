import { CellComponentProps, CellMeasurerCache, TableVirtualized, TableVirtualizedItemProps } from "muicomponents/src/TableVirtualized";
import React, { cloneElement, ComponentProps, FC, forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { UsersEventsTableContextProvider, useUsersEventsTableContext } from "./UsersEventsTable.context";
import { MonthCell } from "./MonthCell";
import { MonthHeader } from "./MonthHeader";
import { LoaderBox, WrapperBox } from "./UsersEventsTable.styled";
import { TableContentHeaderBox } from "./Headers/Header.styled";
import { CellBox, TableContentBox } from "./Cells/Cell.styled";
import { TableHeaderDaysBox, TableHeaderDivisionBox, TableHeaderNamesHeaderBox } from "./Headers/Header";
import { TableCellDaysBox, TableCellDivisionBox, TableCellNamesHeaderBox, TableCellNamesSubLoaderBox, MoreCellComponent } from "./Cells/Cell";
import { tableConstantProps } from "../UsersEvents.constants";
import { Tooltip } from "muicomponents/src/Tooltip";
import { i18n, Translate } from 'localization';
import { list } from "utils/src/requests/requests.users";
import { checkResponseStatus, HierarchyUserStatus, mainUrls, ResponseError, SUserModel } from "utils/src";
import { getDivisionName, UserMention } from "muicomponents/src/UserMention/UserMention";
import { Link } from "muicomponents/src/Link";
import { appGeneratePath } from "utils/src/utils.path";
import { getCalendarEventsRequestV4, getCalendarEventsRequestV4POST } from "utils/src/requests/requests.calendar";
import moment from "moment";
import { useUsersEventsContext } from "../UsersEvents.context";
import { CalendarEvents } from "./UsersEventsTable.index";
import { keyDateFormat } from "./UsersEventsTable.utils";
import { floatingDotRound } from "utils/src/utils.numbers";
import { useDidUpdateEffect, useQueuedState, useReffedState } from "utils/src/hooks";
import { Button, CircularProgress, IconButton, LoadingButton } from "muicomponents/src";
import { ExpandMore } from "muicomponents/src/Icons";
import { Dictionary } from "lodash";

type UserEventsTableType = {
};

const TableItem: FC<TableVirtualizedItemProps & {
    Component: FC<ComponentProps<typeof MonthHeader | typeof MonthCell>>,
    month: string
}> = ({
    Component,
    measure,
    registerChild,
    month,
    ...props
}) => {
    const context = useUsersEventsTableContext();

    const dates = useMemo(() => {
        return context.monthesDictionary[month];
    }, [context.monthesDictionary[month]]);

    useEffect(() => {
        measure();
    }, [context.monthesDictionary[month]]);

    return (
        <Component
            {...props as any}
            {...dates}
            measure={measure}
        />
    );
};

type UserCustomData = {
    remaining_vacations: number [];
}

const TableLoadMoreButton = forwardRef<HTMLButtonElement, ComponentProps<typeof LoadingButton>>((props, ref) => {
    return (
        <LoadingButton
            {...props}
            ref={ref}
            variant='text'
            size={'small'}
            fullWidth
        >
            <Translate i18nKey={!props.loading ? 'more' : 'pryaniky.data.loading'} />
        </LoadingButton>
    );
});

const UserEventsTablePr: FC<UserEventsTableType> = ({
}) => {

    const blockContext = useUsersEventsContext();
    const blockContextRef = useRef(blockContext);
    blockContextRef.current = blockContext;

    const context = useUsersEventsTableContext();
    const contextRef = useRef(context);
    contextRef.current = context;

    const [users, setUsers] = useState<((SUserModel<UserCustomData> & {
        level?: number,
        subUsersLoaded?: boolean;
    }) | {
        type: 'loader',
        headUserId: string,
        level?: number,
        skipCount?: number
    })[]>([
        {
            type: 'loader',
            headUserId: '',
            level: 0
        }
    ]);
    const usersRef = useRef(users);
    usersRef.current = users;
    const loadingRowsRef = useRef<number[]>([]);

    const [events, setEvents] = useState<{[s: string]: CalendarEvents}>({});
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);

    const [subsState, setSubsState, subsStateRef] = useQueuedState<Dictionary<{
        isLoading: boolean,
        isFinished: boolean,
        loaderIndex?: number
    }>>({});
    const [isLoadingEvents, setIsLoadingEvents] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const globalIsLoading = useMemo(() => {
        if(isLoadingEvents) return isLoadingEvents;
        return isLoadingUsers && isLoadingEvents;
    }, [isLoadingUsers, isLoadingEvents]);

    const hideLoadedSubUsers = useCallback(async function (headUserId: string, hideAllAfterIndex: number) {
        let bottomLevelToClose: number | undefined;
        setUsers(usersRef.current.map((user, idx) => {
            if(user.type !== 'loader' && hideAllAfterIndex === idx) {
                return {
                    ...user,
                    subUsersLoaded: false
                };
            }
            return user;
        }).filter((user, idx) => {
            if(user.type !== 'loader' && hideAllAfterIndex === idx) {
                bottomLevelToClose = user.level;
                return true;
            }
            if(typeof bottomLevelToClose === 'number') {
                return bottomLevelToClose >= (user.level || 0);
            }
            if(user.level === bottomLevelToClose) {
                bottomLevelToClose = undefined;
            }
            return true;
        }));
    }, []);

    const getSubUsers = useCallback(async function(headUserId: string, insertAfterIndex: number, skipCount?: number) {
        setSubsState({
            [headUserId]: {
                ...subsStateRef.current[headUserId],
                isLoading: true
            }
        });
        // setSubUsersLoading({
        //     [headUserId]: true
        // });
        try {
            const response = await list({
                skipCount,
                count: 10,
                extended: true,
                // usersFromMyUnits: true,
                catFilter: "subusers",
                /**
                 * это только если по ветке подчиненных идем
                 * используем при рисовании иерархии
                 * usersFromMyUnits - всегда false
                 * userId - undefined
                 */
                selectedHeadUser: headUserId,
                /**
                 * пока хардкод, если нужен будет селектор, доработаем
                 */
                hierarchyName: 'Оргструктура',
                /**
                 * какие дополнительные ключи вытаскивать
                 */
                userCustomDataKeys: [
                    {
                        key: "remaining_vacations",
                        expression: "$..[?(@.VacType=='Основной')].VacRest"
                    }
                ]
            }).r;
            if(checkResponseStatus(response)) {
                const newUsers = usersRef.current.filter((user, idx) => {
                    if(user.type === 'loader' && idx === insertAfterIndex + 1 && user.headUserId === headUserId) {
                        return false;
                    }
                    return true;
                }).map((user, idx) => {
                    if(user.type !== 'loader' && insertAfterIndex === idx) {
                        const prepairedData: typeof users = [
                            {
                                ...user,
                                ...(user.id === headUserId ? { subUsersLoaded: true } : {})
                            },
                            ...response.data.map(subUser => ({ ...subUser, level: user.id === headUserId ? (user.level || 0) + 1 : user.level }))
                        ];
                        if(!response.isFinished) {
                            prepairedData.push({
                                type: 'loader',
                                headUserId: headUserId,
                                level: user.id === headUserId ? (user.level || 0) + 1 : user.level,
                                skipCount: (skipCount || 0) + response.data.length
                            })
                        }
                        return prepairedData;
                    }
                    return user;
                }).flat();
                setUsers(newUsers);
                setSubsState({
                    [headUserId]: {
                        ...subsStateRef.current[headUserId],
                        isFinished: response.isFinished
                    }
                });
            } else {
                throw new ResponseError('user list response error')
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                console.error(error);
            }
        } finally {
            setSubsState({
                [headUserId]: {
                    ...subsStateRef.current[headUserId],
                    isLoading: false
                }
            });
        };
    }, []);

    const getUsers = useCallback(async function(clear?: boolean) {
        setIsError(false);
        setIsLoadingUsers(true);
        try {
            const response = await list({
                skipCount: clear ? 0 : usersRef.current.filter(el => el.type !== 'loader' && !el.level).length,
                count: 10,
                /**
                 * только если под 
                 * usersFromMyUnits - всегда true
                 */
                userId: blockContextRef.current.userId,
                extended: true,
                /**
                 * несуший параметр для этого списка
                 * отключаю, если передаем selectedHeadUser
                 */
                usersFromMyUnits: true,
                /**
                 * это только если по ветке подчиненных идем
                 * используем при рисовании иерархии
                 * usersFromMyUnits - всегда false
                 * userId - undefined
                 */
                // selectedHeadUser: blockContextRef.current.userId,
                /**
                 * пока хардкод, если нужен будет селектор, доработаем
                 */
                hierarchyName: 'Оргструктура',
                /**
                 * какие дополнительные ключи вытаскивать
                 */
                userCustomDataKeys: [
                    {
                        key: "remaining_vacations",
                        expression: "$..[?(@.VacType=='Основной')].VacRest"
                    }
                ]
            }).r;
    
            if(checkResponseStatus(response)) {
                const prepairedData = response.data.map(user => ({ ...user, level: 0 }));
                let newUsers = usersRef.current.map(el => el);
                if(newUsers[newUsers.length - 1].type === 'loader') {
                    newUsers.splice(newUsers.length - 1, 1);
                }
                newUsers = clear ? prepairedData : [ ...newUsers, ...response.data.map(user => ({ ...user, level: 0 })) ];
                if(!response.isFinished && newUsers[newUsers.length - 1].type !== 'loader') {
                    newUsers.push({
                        type: 'loader',
                        headUserId: '',
                        level: 0
                    })
                };
                setUsers(newUsers);
                setIsFinished(response.isFinished);
            } else {
                throw new ResponseError('user list response error')
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                console.error(error);
            }
            setIsError(true);
        } finally {
            setIsLoadingUsers(false);
        };
    }, []);

    useLayoutEffect(() => {
        getUsers(true);
    }, [blockContext.userId]);

    const getEvents = useCallback(async function() {
        if(!usersRef.current.filter(el => el.type !== 'loader').length) return;
        setIsError(false);
        setIsLoadingEvents(true);
        try {
            const start = moment(contextRef.current.dateStart);
            const end = moment(contextRef.current.dateEnd);
            if(!start.isValid() || !end.isValid()) {
                throw new Error('dates is invalid')
            }
            let preparedCIds = blockContextRef.current.cIds;
            if(
                !!blockContextRef.current.settings.generatedCalerdarSettings.calendarId
                && !preparedCIds.includes(blockContextRef.current.settings.generatedCalerdarSettings.calendarId)
            ) {
                preparedCIds = [
                    ...preparedCIds,
                    blockContextRef.current.settings.generatedCalerdarSettings.calendarId
                ];
            }
            const uids: string[] = [];
            for(let item of usersRef.current) {
                if(item.type !== 'loader') {
                    uids.push(item.id);
                }
            }
            const response = await getCalendarEventsRequestV4POST({
                start: keyDateFormat(start),
                end: keyDateFormat(end),
                cIds: preparedCIds,
                uids
            });
    
            if(checkResponseStatus(response)) {
                const eventsDisc: typeof events = {};
                for(const event of response.data.events) {
                    if(!eventsDisc[event.calendarId]) eventsDisc[event.calendarId] = [];
                    eventsDisc[event.calendarId].push(event);
                }
                setEvents(eventsDisc);
            } else {
                throw new ResponseError('user list response error')
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                console.error(error);
            }
            setIsError(true);
        } finally {
            setIsLoadingEvents(false);
        };
    }, []);

    useLayoutEffect(() => {
        getEvents();
    }, [users, context.dateStart, context.dateEnd, blockContext.cIds, blockContext.settings.generatedCalerdarSettings.calendarId]);

    const cache = useMemo(() => new CellMeasurerCache({
        minHeight: tableConstantProps.estimatedRowSize
    }), []);

    const table = useMemo(() => {
        cache.clearAll();
        const header = [
            <TableHeaderNamesHeaderBox
                {...{} as any}
            >
                <Tooltip title={Translate.t({ i18nKey: 'pryaniky.usersevents.header.colleagues' })} overflowOnly>
                    <TableContentHeaderBox variant="body1">
                        <Translate i18nKey={'pryaniky.usersevents.header.colleagues'} />
                    </TableContentHeaderBox>
                </Tooltip>
            </TableHeaderNamesHeaderBox>,
            <TableHeaderDaysBox
                {...{} as any}
            >
                <Tooltip title={Translate.t({ i18nKey: 'pryaniky.usersevents.header.vacationRec' })} overflowOnly>
                    <TableContentHeaderBox variant="body1">
                        <Translate i18nKey={'pryaniky.usersevents.header.vacationRec'} />
                    </TableContentHeaderBox>
                </Tooltip>
            </TableHeaderDaysBox>,
            <TableHeaderDivisionBox
                {...{} as any}
            >
                <Tooltip title={Translate.t({ i18nKey: 'pryaniky.usersevents.header.division' })} overflowOnly>
                    <TableContentHeaderBox variant="body1">
                        <Translate i18nKey={'pryaniky.usersevents.header.division'} />
                    </TableContentHeaderBox>
                </Tooltip>
            </TableHeaderDivisionBox>,
            ...context.monthesArray.map(month => {
                return <TableItem
                    Component={MonthHeader}
                    month={month}
                    events={events}
                    {...{} as any}
                />
            })
        ];
        const body: JSX.Element[][] = [];
        loadingRowsRef.current = [];
        let prepairedUsers = blockContext.showOthers
        ? users
        : users.filter(item => item.type !== 'loader' && item.id === blockContext.userId)
        prepairedUsers.forEach((user, idx) => {
            switch(user.type) {
                case 'loader':
                    loadingRowsRef.current.push(idx + (tableConstantProps.fixedRowCount || 0));
                    const isLoadingValue = user.headUserId ? subsState[user.headUserId].isLoading : isLoadingUsers;
                    const textKey = !isLoadingValue ? 'pryaniky.usersevents.more' : 'loading';
                    body.push([
                        <TableItem
                            Component={MoreCellComponent}
                            {...{} as any}
                            level={user.level}
                        >
                            <Link
                                onClick={() => {
                                    if(isLoadingValue) return;
                                    if(!user.headUserId) {
                                        getUsers();
                                    } else {
                                        getSubUsers(user.headUserId, idx - 1, user.skipCount)
                                    }
                                }}
                            >
                                <Translate i18nKey={textKey} />
                            </Link>
                        </TableItem>,
                        <TableItem
                            Component={MoreCellComponent}
                            {...{} as any}
                            level={user.level}
                        />,
                        <TableItem
                            Component={MoreCellComponent}
                            {...{} as any}
                            level={user.level}
                        />,
                        ...context.monthesArray.map(el => <TableItem
                            Component={MoreCellComponent}
                            {...{} as any}
                            level={user.level}
                        />)
                    ]);
                    break;
                default:
                    let userVacationNumber = user.customUserData?.remaining_vacations.reduce((a, c) => a + c, 0) || 0;
                    userVacationNumber = floatingDotRound(userVacationNumber, 10);
                    const isHeadUser = blockContextRef.current.userId === user.id;
                    let sx: ComponentProps<typeof CellBox>['sx'] = {};
                    const row = [
                        <TableCellNamesHeaderBox
                            isHeadUser={isHeadUser}
                            level={user.level}
                            sx={sx}
                            {...{} as any}
                        >
                            <UserMention id={user.id}>
                                <TableContentBox
                                    variant='body2'
                                    component={Link}
                                    href={appGeneratePath(mainUrls.user.id, { id: user.id })}
                                >
                                    {user.displayName}
                                </TableContentBox>
                            </UserMention>
                            {
                                user.hstatus === HierarchyUserStatus.Head && blockContext.showOthers &&
                                <TableCellNamesSubLoaderBox>
                                    <IconButton onClick={() => {
                                        if(user.subUsersLoaded) {
                                            hideLoadedSubUsers(user.id, idx)
                                        } else {
                                            getSubUsers(user.id, idx)
                                        }
                                    }} size='small'>
                                        {
                                            !!subsState[user.id]?.isLoading
                                            ? <CircularProgress
                                                size={'24px'}
                                            />
                                            : <ExpandMore
                                                sx={{
                                                    transform: !user.subUsersLoaded ? 'rotate(90deg)' : undefined
                                                }}
                                            />
                                        }
                                    </IconButton>
                                </TableCellNamesSubLoaderBox>
                            }
                        </TableCellNamesHeaderBox>,
                        <TableCellDaysBox
                            isHeadUser={isHeadUser}
                            sx={sx}
                            level={user.level}
                            {...{} as any}
                        >
                            <Tooltip title={'2'} overflowOnly>
                                <TableContentBox variant='body2'>
                                    {userVacationNumber}
                                </TableContentBox>
                            </Tooltip>
                        </TableCellDaysBox>,
                        <TableCellDivisionBox
                            isHeadUser={isHeadUser}
                            sx={sx}
                            level={user.level}
                            {...{} as any}
                        >
                            <Tooltip title={getDivisionName(user.division, user.userOrgChart)} overflowOnly>
                                <TableContentBox variant='body2'>
                                    {getDivisionName(user.division, user.userOrgChart)}
                                </TableContentBox>
                            </Tooltip>
                        </TableCellDivisionBox>,
                        ...context.monthesArray.map(month => {
                            return <TableItem
                                isHeadUser={isHeadUser}
                                Component={MonthCell}
                                month={month}
                                events={events}
                                userId={user.id}
                                sx={sx}
                                level={user.level}
                                {...{} as any}
                            />
                        })
                    ];
                    body.push(row);
                    break;
            }
        });
        return [
            header,
            ...body
        ]
    }, [users, isFinished, isLoadingUsers, subsState, events, blockContext.userId, blockContext.showOthers, context.monthesArray, context.monthesDictionary]);

    return (
        <WrapperBox>
            {
                globalIsLoading &&
                <LoaderBox>
                    <CircularProgress />
                </LoaderBox>
            }
            {
                <TableVirtualized
                    cache={cache}
                    {...tableConstantProps}
                    // estimatedRowSize={30}
                    rowHeight={(...props) => {
                        const [{ index }] = props;
                        if(loadingRowsRef.current.includes(index)) {
                            return 31;
                        }
                        return cache.rowHeight(...props);
                    }}
                >
                    {table}
                </TableVirtualized>
            }
        </WrapperBox>
    );
};

type UserEventsTableProps = Pick<ComponentProps<typeof UsersEventsTableContextProvider>, 'dateEnd' | 'dateStart'> & {
    userId?: string;
};

export const UserEventsTable: FC<UserEventsTableProps> = ({
    dateEnd,
    dateStart
}) => {

    return (
        <UsersEventsTableContextProvider dateStart={dateStart} dateEnd={dateEnd}>
            <UserEventsTablePr />
        </UsersEventsTableContextProvider>
    );
}