import { DialogWikiEdit } from 'blocks/Dialogs/WikiCreateNew/WikiEdit/WikiEditNew';
import { getPageBaseType } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { Translate } from 'localization';
import { FavoriteIcon, Typography } from 'muicomponents/src';
import { Avatar, AvatarSize } from 'muicomponents/src/Avatar/Avatar';
import { Box } from 'muicomponents/src/Box/Box';
import { Chip } from 'muicomponents/src/Chip/Chip';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import React, { FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomSettings, PageTypes } from 'utils/src';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import * as utils from 'utils/src/utils';
import { formatDateSTD } from 'utils/src/utils.dates';
import { ContextMenu } from '../../ContextMenu/ContextMenu';
import {
    useWikilistItemBaseImageByType,
    useWikilistItemFileTypeIcon,
    useWikilistItemIconByType,
} from '../Items..hooks';
import {
    WikiDescriptionTypography,
    WikiImageListButton,
    WikilItemDescription,
    WikiNameBox,
    WikiNameButton,
} from '../style';
import { cnItem, IWikiItemListProps, mapDispatchToProps, mapStateToProps } from './ItemList.index';
import './ItemList.scss';

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

export const WikiItemListPresenter: FC<IWikiItemListProps> = ({
    tag: TagName = 'Box',
    className = '',
    data,
    changeItem,
    showControls,
    context,
    updateItemList,
    removeItems,
    parentFolderModerators,
    parentFolderViewers,
    isEditable,
    onItemLinkClick,
    ...props
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [hover, setHover] = useState(false);
    const outLink =
        (data.url.indexOf('http') === 0 || data.url.indexOf('//') === 0) &&
        !data.url.includes(window.location.hostname);
    let href = outLink || data.url[0] === '/' ? data.url : '/' + data.url;

    if (!outLink && data.url.includes(window.location.hostname))
        href = href.replace(
            href.substr(0, href.indexOf(window.location.hostname) + window.location.hostname.length),
            ''
        );

    const noImg = data.titleImageId === GUID_EMPTY || data.titleImageId === null;

    const pageBaseType = getPageBaseType(data.type);

    const nameComponent = useMemo(() => {
        return (
            <Tooltip title={data.title} overflowOnly disableInteractive>
                <WikiNameBox
                    className={cnItem(`WikiName ${data.type === 'url' ? 'CustomStyle primaryColor3-text ' : ''}`)}
                    href={href}
                    variant="body1"
                    onClick={onItemLinkClick}
                    component={WikiNameButton}
                    sx={{
                        marginBottom: '0px',
                    }}
                >
                    {!data.title ? (
                        data.type === PageTypes.url ? (
                            href
                        ) : (
                            <Translate i18nKey={'pryaniky.wikilist.item.emptyName'} />
                        )
                    ) : (
                        data.title
                    )}
                </WikiNameBox>
            </Tooltip>
        );
    }, [href, data.title, data.type]);

    const descComponent = useMemo(() => {
        return (
            <Tooltip title={data.description} overflowOnly disableInteractive>
                <WikiDescriptionTypography
                    className={cnItem('CardArticleItem-Content-Description')}
                    variant="body2"
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                >
                    <WikilItemDescription source={data.description || ''} type={'inline'} />
                </WikiDescriptionTypography>
            </Tooltip>
        );
    }, [data.description]);

    const favoriteWikiPage = (data: any) => {
        utils.API.pages.favoritePage(data.id, !data.isFavorites).r.then((response) => {
            if (!response) toast.error(<Translate i18nKey={'pryaniky.toast.error.server'} />);
            else if (response.error_code === 0) {
                toast.success(
                    <Translate
                        i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorited' : 'toFavorited'}`}
                    />
                );
                updateItemList(data, { ...data, isFavorites: !data.isFavorites });
            }
        });
    };

    const FileTypeIcon = useWikilistItemFileTypeIcon(data.type, data.title, data.icon);

    const pageBaseImage = useWikilistItemBaseImageByType(data.type);

    const itemIcon = useWikilistItemIconByType(data.type);

    return (
        <Box
            className={cnItem({}, [className])}
            onMouseEnter={() => {
                if (!(showControls || isEditable)) return;
                setHover(true);
            }}
            onMouseLeave={() => {
                if (!(showControls || isEditable)) return;
                setHover(false);
            }}
        >
            <Box className={cnItem('Content')}>
                <WikiImageListButton
                    sx={{
                        minWidth: `${AvatarSize.l}px`,
                    }}
                    className={cnItem(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                    href={href}
                    onClick={onItemLinkClick}
                >
                    {pageBaseType !== PageTypes.file ? (
                        <Avatar size={AvatarSize.l} src={noImg ? pageBaseImage : data.titleImageUrl} />
                    ) : (
                        <Avatar
                            size={AvatarSize.l}
                            src={noImg ? FileTypeIcon || '' : data.titleImageUrl}
                            sx={{
                                backgroundColor: noImg ? '#f5f5f5' : '#fff',
                                ['img']: {
                                    transform: noImg ? 'scale(0.54)' : undefined,
                                },
                            }}
                        />
                    )}
                </WikiImageListButton>
                <Box className={cnItem('Content-InfoBox')}>
                    {nameComponent}

                    {descComponent}

                    <Box className={cnItem('CreationDate')} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {itemIcon}
                        <Typography variant={'body2'} color="rgba(0, 0, 0, 0.38)">
                            <Translate
                                i18nKey="pryaniky.wikilist.item.created.new"
                                values={{
                                    creationDate: formatDateSTD(data.creationDate, false),
                                    changeDate: formatDateSTD(data.changeDate, false),
                                }}
                            />
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                    label={
                        data.isDraft ? (
                            <Translate i18nKey={`pryaniky.wikilist.item.isDraft`} />
                        ) : (
                            <Translate i18nKey={`pryaniky.wikilist.item.isArchived`} />
                        )
                    }
                    className={cnItem('IsDraft')}
                    variant="filled"
                    sx={{
                        // position: "absolute",
                        opacity: data.isDraft || data.isArchived ? 1 : 0,
                        color: '#fff',
                        background: data.isDraft ? 'rgba(0, 102, 204, 0.5)' : '#757575',
                        // bottom: "12px",
                        // left: "8px",
                        padding: '3px 10px',
                        marginRight: '16px',
                        height: '24px',
                        '.MuiChip-label': {
                            textTransform: 'initial',
                            padding: 0,
                        },
                    }}
                />
                {useFavoriteWikiItems && (
                    <Box
                        sx={{
                            height: '36px',
                            width: '36px',
                            marginRight: '16px',
                            opacity: data.isFavorites ? 1 : 0,
                        }}
                        className={cnItem('Actions_Hedaer')}
                    >
                        <Tooltip
                            title={
                                <Translate
                                    i18nKey={`pryaniky.modal.wikipage.${
                                        data.isFavorites ? 'unFavorites' : 'toFavorites'
                                    }`}
                                />
                            }
                        >
                            <IconButton
                                color={'primary'}
                                sx={{
                                    background: '#fff',
                                    borderRadius: '50%',
                                    padding: '5px',
                                    '&:hover': {
                                        background: '#fff',
                                    },
                                }}
                                onClick={() => favoriteWikiPage(data)}
                            >
                                <FavoriteIcon on={data.isFavorites} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                <Box
                    sx={{
                        height: '36px',
                        width: '36px',
                        marginRight: '16px',
                        opacity: 0,
                        visibility: hover ? 'visible' : 'hidden',
                    }}
                    className={cnItem('Actions_Hedaer')}
                >
                    {showControls || (isEditable && data.editable) ? (
                        <>
                            <ContextMenu
                                {...props}
                                data={data}
                                removeItems={removeItems}
                                updateItemList={updateItemList}
                                context={context}
                                isAdmin={showControls || isEditable}
                                onEdit={() =>
                                    DialogWikiEdit({
                                        onConfirm: changeItem,
                                        updateItemList,
                                        context: context,
                                        isShown: showDialog,
                                        isAdmin: showControls,
                                        onClose: () => setShowDialog(false),
                                        parentFolderModerators: parentFolderModerators,
                                        parentFolderViewers: parentFolderViewers,
                                        data: data,
                                        id: data.id,
                                    })
                                        .then(() => {})
                                        .catch(() => {})
                                }
                            />
                        </>
                    ) : undefined}
                </Box>
            </Box>
        </Box>
    );
};
export const ItemList = connect(mapStateToProps, mapDispatchToProps)(WikiItemListPresenter);
