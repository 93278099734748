import { cn } from '@bem-react/classname';

export type TasksListProps = {
    context: { [key: string]: any };
};

const cnTasksList = cn('Tasks');

export const tasksListClasses = {
    root: cnTasksList(),
    searchActions: cnTasksList('SearchActions'),
    headerButton: cnTasksList('CreateAction'),
};
