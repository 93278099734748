import React, { FC, useState, useMemo } from 'react';
import { IFloatMessageWidgetProps } from './FloatMessageWidget.index';
import { initWidgetAddition, initWidgetSettings } from './WidgetSettingsDialog/WidgetSettingsDialog';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Box } from 'muicomponents/src/Box/Box';
import { Link } from 'muicomponents/src/Link/Link';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { isObject } from 'lodash';
initWidgetAddition();
initWidgetSettings();

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        boxShadow: '0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        padding: '24px',
        paddingTop: '20px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));

const StyledImg = styled('img')({
    width: '180px',
    height: '180px',
    '@media(min-width: 1280px)': {
        width: '180px',
        height: '180px',
    },
    '@media(min-width: 1360px)': {
        width: '200px',
        height: '200px',
    },
    '@media(min-width: 1440px)': {
        width: '220px',
        height: '220px',
    },
    '@media(min-width: 1600px)': {
        width: '240px',
        height: '240px',
    },
    '@media(min-width: 1900px)': {
        width: '260px',
        height: '260px',
    },
});
const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '0.15px',
}));

const Item = ({ settings: { isShowTimer, durationShowTimer, measurementInSeconds, ...settings }, onClose }: any) => {
    const getLocalStorageData = () => {
        const localStorageData = localStorage.getItem('pryaniky/FloatMessageWidget-' + settings.id);
        return localStorageData ? JSON.parse(localStorageData) : {};
    };

    const { showData } = getLocalStorageData();
    const isShow = useMemo(() => {
        if (isShowTimer && showData) {
            const nowDate = new Date();
            const lastShowTime = new Date(showData);
            lastShowTime.setSeconds(
                lastShowTime.getSeconds() + (durationShowTimer || 0) * (+measurementInSeconds || 60)
            );
            return nowDate > lastShowTime;
        } else {
            return true;
        }
    }, [showData, isShowTimer]);

    const closeHendler = () => {
        onClose();
        localStorage.setItem(`pryaniky/FloatMessageWidget-${settings.id}`, JSON.stringify({ showData: new Date() }));
    };
    return isShow ? (
        <HtmlTooltip
            title={
                <Stack direction={'row'} gap={2}>
                    <Box sx={{ display: 'flex' }}>
                        <StyledLink style={{ color: 'rgba(0, 0, 0, 0.87)' }} href={settings?.url}>
                            {settings?.text}
                        </StyledLink>
                    </Box>
                    <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                        <IconButton size={'small'} onClick={closeHendler}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Stack>
            }
            placement="left-start"
            arrow
            PopperProps={{
                disablePortal: true,
            }}
            open
            disableFocusListener
            disableHoverListener
            disableTouchListener
        >
            <StyledImg src={settings?.file?.originalUrl} />
        </HtmlTooltip>
    ) : null;
};

export const FloatMessageWidget: FC<IFloatMessageWidgetProps | any> = ({
    contexts,
    className,
    isEditable,
    HTMLWidgetContentEdit,
    ...props
}) => {
    const [show, setShow] = useState(true);
    let items = props.widget.settings?.items;
    if (!items) return null;
    if (isObject(items)) {
        items = Object.values(items);
    }
    if (!show) return null;

    return (
        <Box sx={{ position: 'fixed', right: 0, bottom: 0, zIndex: 999 }}>
            {items
                .filter((v: any) => v.enabled)
                .map((item: any) => (
                    <Item onClose={() => setShow(false)} key={item.id} settings={item} />
                ))}
        </Box>
    );
};
