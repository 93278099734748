import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import * as utils from 'utils/src/utils';
import { IRatingsData } from 'utils/src/requests/models/api.ratings';
import { CacheHelper } from 'utils/src/CacheHelper'
import { i18n, Translate } from 'localization';
import { Box, Typography } from 'muicomponents/src';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link/Link';
import { IRatingsTabWidgetProps } from './RatingsWidgetMui.index';
import { BorderLinearProgress, CountTypography, FlexBox, HeaderFlexBox, MarkerAvatar, StyledPaper } from './styled';
import noData from 'assets/svg/NoData.svg'
import { ProgressBox } from 'blocks/UserRatings/styled';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';
import { useSelector } from 'react-redux';
import { getCurrentUserId } from 'utils/src/CommonRedux/base/selectors';
import { generatePath } from 'react-router';
import { mainUrls } from 'utils/src';
import { getLocation } from 'connected-react-router';
import { IStateType } from 'redux/store';
import { getUrlTemplateByPathName } from 'utils.project/utils.project';

const RatingsHeader = () => {
  return <Typography fontWeight='700' >
    <Translate i18nKey={'pryaniky.ratings.widget.noratings'} />
  </Typography>
}

export const RatingsWidgetMui: FC<IRatingsTabWidgetProps> = (props: IRatingsTabWidgetProps) => {

  const Tag = props.tag || 'div'

  const [ratings, setRatings] = useState<IRatingsData[]>()
  const [loading, setLoading] = useState(false)
  const serverData = useRef(false)

  const currentUserId = useSelector(getCurrentUserId);
  const userId = props.context.uId || currentUserId;

  const getData = () => {
    const context = props.context;
    const cacheKey = `ratings.byUser_${userId || 0}_${context.rIds || 0}`;
    let ignoreCache = false;
    setLoading(true)
    CacheHelper.get('userRating', cacheKey)
      .then((value) => {
        if (serverData.current || ignoreCache) return
        value && setRatings(value.data)
        setLoading(false)
      },
      )
      .catch(e => console.warn('CacheHelper, userRating:', e))

    utils.API.ratings.byUser(userId, context.rIds).r.then((response) => {
      if (utils.checkResponseStatus(response)) {
        ignoreCache = true;
        CacheHelper.set('userRating', cacheKey, utils.clone(response))
          .then(() => {
            serverData.current = true
            setRatings(response.data)
            setLoading(false)
          })
          .catch(e => console.warn('CacheHelper, userRating:', e))
      }
    });
  };

  useEffect(() => {
    getData()
  }, [])

  const amount = ratings?.reduce((a, b) => a + b.ratingValues.length, 0) || 0;

  const location = useSelector((state: IStateType) => getLocation(state));
  const coutnForShow = useMemo(() => {
    const { urlModuleName } = getUrlTemplateByPathName(location.pathname)
    const isUserUrl = urlModuleName === 'user';
    return isUserUrl ? 2 : undefined;
  }, [location.pathname]);

  // проверяем сколько пришло рейтингов, если рейтин один, то это повлияет на стили https://tracker.yandex.ru/COMMON-10931
  const ratingsLength = amount > 1;

  if (loading) return <WidgetTypeSkeletonMUI />

  if (!props.settings?.hideIfNoData && !ratings?.length) return (
    <Tag {...props} >
      <StyledPaper>
        <RatingsHeader />
        <CardMedia
          component="svg"
          className={"EmptyList-Avatar"}
          sx={{
            width: '100%',
            height: "107px",
            marginBottom: "12px"
          }}
          image={EmptyWidget}
        />
        <Typography>
          <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
        </Typography>
      </StyledPaper>
    </Tag>
  )

  if (props.settings?.hideIfNoData && !ratings?.length) return null;
  if (!ratings?.length) return null;

  return (
    <Tag {...props} >
      <StyledPaper sx={{
        height: !!coutnForShow ? undefined : 'auto'
      }}>
        <HeaderFlexBox>
          <FlexBox>
            <Link href={generatePath(mainUrls.user.idTab, { id: userId, tab: 'ratings' })}>
              <RatingsHeader />
            </Link>
          </FlexBox>
          {coutnForShow && amount > 2 && <FlexBox>
            <CountTypography variant='body1'>{(amount && amount > 2) ? '2' : amount}</CountTypography >
            <CountTypography variant='body1' color='gray'>{"/" + amount}</CountTypography >
          </FlexBox>}
        </HeaderFlexBox>
        {
          ratings?.map((rating, i) => {
            return <Box key={i}>
              {rating.ratingValues.map((elem, idx) => {
                if (coutnForShow && idx >= coutnForShow) return null;
                const percent: number = parseInt(elem.percantage, 10)
                return <Box key={elem.id} sx={{ mt: 1, border: '1px solid transparent' }}>
                  <FlexBox>
                    <Link href={elem.url || ''} sx={{ textOverflow: 'ellipsis' }}>
                      <Avatar src={elem.imgUrl} sx={{ mr: 2, width: ratingsLength ? "40px" : "64px", height: ratingsLength ? "40px" : "64px" }} />
                    </Link>
                    <Box sx={{ overflow: 'hidden' }}>
                      <Link href={elem.url || ''} sx={{ textOverflow: 'ellipsis' }}>
                        <Typography variant='body2' color='text.primary' noWrap >
                          {elem.name}
                        </Typography>
                      </Link>
                      {ratingsLength && <Typography>
                        <Typography display='inline' variant='body2' >{elem.value}</Typography>
                      </Typography>}
                    </Box>
                  </FlexBox>
                  <ProgressBox>
                    {!ratingsLength && <Typography sx={{ marginTop: "12px" }}>
                      <Typography display='inline' variant='body2' >{elem.value}</Typography>
                    </Typography>}
                    <BorderLinearProgress variant="determinate" value={percent} />
                    {elem.markerImgUrl &&
                      <MarkerAvatar src={elem.markerImgUrl} sx={{ left: `${percent - 2}%` }} />}
                  </ProgressBox>
                </Box>
              })}
            </Box>
          })
        }
      </StyledPaper>
    </Tag>
  );
}