import { Actions } from 'blocks/NewsView/Actions/Actions';
import { Like } from 'blocks/NewsView/Actions/Like/Like';
import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { cnClassName, mapDispatchToProps, mapStateToProps, News } from './interfaces';
import './style.scss';
// import { NewsLikers } from 'blocks/Dialogs/News/Likers/NewsLikers';
import { Reply as ReplyAction } from 'blocks/NewsView/Actions/Reply/Reply';
import { addMultiMention } from 'blocks/PryanikyEditor/MentionPlugin/modifiers/addMention';
import { withComponentEnjector } from 'utils/src/ComponentInjector';
import { withReplyContext } from '../../contexts/reply';
import { NewsCommentsContext } from '../NewsComments/context';

type IPresenterProps = News.ReplyActions.IPresenterProps;
type IOwnProps = News.ReplyActions.IOwnProps;
type IDispatchProps = News.ReplyActions.IDispatchProps;
type IStateProps = News.ReplyActions.IStateProps;
type IState = News.ReplyActions.IState;

const noopObj: any = {};

export const Presenter: FC<IPresenterProps> = ({
    className,
    replyId,
    newsId,
    allowReply,
    reply,
    insertMention,
    sendLikeReply,
    injectionNodes,
}) => {
    const context = useContext(NewsCommentsContext);
    const { user, likeCount, liked } = reply;

    const replyAnswer = useCallback(() => {
        const { displayName, id, userPhotoUrl, imgUrl } = user;

        const mention = {
            name: displayName,
            link: '/user/' + id,
            avatar: imgUrl || userPhotoUrl,
        };

        const withMention = addMultiMention(context.text, mention, '', '', 'SEGMENTED');
        context.onChangeText(withMention);
    }, [user, context.text]);

    const actionLike = useCallback(
        (likeCount: number, liked: boolean) => {
            sendLikeReply({
                likeCount,
                liked,
                newsId,
                replyId,
            });
        },
        [newsId, replyId]
    );

    const buttonProps = useMemo(() => ({ 'data-liked': liked }), [liked]);

    return (
        <Actions className={cnClassName({}, [className])}>
            {allowReply && <ReplyAction onClick={replyAnswer} />}

            <Like
                newsId={newsId}
                replyId={replyId}
                buttonProps={buttonProps}
                likeCount={likeCount}
                liked={liked}
                onClick={actionLike}
            />

            {injectionNodes}
        </Actions>
    );
};

export default withReplyContext(
    connect<IStateProps, IDispatchProps, IOwnProps, IState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        withComponentEnjector(memo(Presenter), [], (props) => [
            'news_additional_reply_action_' + props.newsId,
            'news_additional_reply_action',
        ])
    )
);
