import moment from 'moment';
import { Box, Divider } from 'muicomponents/src';
import { IListItemProps } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';
import { Tooltip } from 'muicomponents/src/Tooltip';
import React, { ComponentProps, FC, ReactNode, useMemo } from 'react';
import {
    expireColumnName,
    mainUrls,
    TaskModel,
    TaskModelColumn,
    TaskModelColumnType,
    TaskModelStatus,
} from 'utils/src';
import { formatDateSTD } from 'utils/src/utils.dates';
import { useTableListContext } from '../TableList.context';
import { ItemActionCell, ItemGridCell, ItemGridContent } from '../TableList.styled';
import { TableListItemProps, cnTableListItem, cnTableListItemCell } from './TableListItem.index';
import {
    AutorLink,
    DispalyNameBox,
    ExpiredBox,
    FlexBox,
    PlusNButton,
    StyledItemBox,
    UsersBox,
} from './TableListItem.styled';
import { HistoryToggleOff, CheckCircle, Info, Pending } from 'muicomponents/src/Icons';
import { Translate } from 'localization';

import { Link } from 'muicomponents/src/Link/Link';
import { Avatar } from 'muicomponents/src/Avatar';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { appGeneratePath } from 'utils/src/utils.path';
import { openUsersList } from 'blocks/TableList/Dialog/OpenUsersList/OpenUsersList';

const daysCountToTaksRedBackground = 3;

export const TableListItem: FC<IListItemProps<TaskModel> & TableListItemProps> = ({
    isWithActionButton = true,
    strForLocalization = 'tasks',
    createStatusIcon,
    wrapProps,
    itemActionsButton,
    ...props
}) => {
    const context = useTableListContext();
    const { item } = props;

    const diffDays = useMemo(() => {
        if (!item.dueDate.value || item.status === TaskModelStatus.completed) return null;
        const a = moment().startOf('day');
        const b = moment(item.dueDate.value).startOf('day');
        return b.diff(a, 'days');
    }, [item.dueDate.value]);

    const colorByDiffDays = useMemo(() => {
        return typeof diffDays === 'number' && diffDays < daysCountToTaksRedBackground;
    }, [diffDays]);

    return (
        <>
            <Divider />
            <StyledItemBox
                disableRipple
                className={cnTableListItem()}
                component={item.url ? Link : Box}
                sx={{
                    cursor: item.url ? undefined : 'auto',
                    background: colorByDiffDays ? '#F4433614' : undefined,
                }}
                href={item.url}
                {...wrapProps}
            >
                {context.columns
                    .filter((el: TaskModelColumn) => el.isVisible)
                    .map((col: TaskModelColumn, idx) => {
                        const field = item[col.name as keyof typeof item];
                        let value: ReactNode = field;
                        if (field && !Array.isArray(field) && typeof field === 'object') {
                            if (!field.isVisible) {
                                value = null;
                            } else {
                                value = field.value;
                            }
                        }
                        switch (col.type) {
                            case TaskModelColumnType.dateTime:
                                value = formatDateSTD(value as string, true, true);
                                break;
                        }

                        let TooltipTitle: ComponentProps<typeof Tooltip>['title'] = false;
                        const statusVal =
                            typeof item.status === 'string' ? item.status : ((item.status as any).value as string);
                        let statusIcon = createStatusIcon ? (
                            createStatusIcon(statusVal)
                        ) : statusVal === TaskModelStatus.completed ? (
                            <CheckCircle color={'success'} />
                        ) : statusVal === TaskModelStatus.inProgress ? (
                            <Pending color={'info'} />
                        ) : statusVal === TaskModelStatus.notStarted || statusVal === TaskModelStatus.deferred ? (
                            <HistoryToggleOff color={'warning'} />
                        ) : null;
                        if (typeof diffDays === 'number' && diffDays <= 0 && statusVal !== TaskModelStatus.completed) {
                            statusIcon = <Info color="error" />;
                        }

                        switch (col.name) {
                            case 'status':
                                value = (
                                    <FlexBox>
                                        {statusIcon}
                                        <ItemGridContent>
                                            <Translate
                                                i18nKey={`pryaniky.${strForLocalization}.statusTitle.${statusVal}`}
                                            />
                                        </ItemGridContent>
                                    </FlexBox>
                                );
                                break;
                            case 'displayName':
                                const isHasStatusCol = context.columns.map((c) => c.name).includes('status');
                                value = (
                                    <DispalyNameBox>
                                        {!isHasStatusCol && <span>{statusIcon}</span>}
                                        <Tooltip disableInteractive overflowOnly title={<>{value}</>}>
                                            <ItemGridContent>{value || <>&mdash;</>}</ItemGridContent>
                                        </Tooltip>
                                    </DispalyNameBox>
                                );
                                break;
                            case 'startDate':
                            case 'type':
                            case 'dueDate':
                            case 'systemName':
                            case 'planningDate':
                            case 'completionDate':
                                value = (
                                    <Tooltip disableInteractive overflowOnly title={<>{value}</>}>
                                        <ItemGridContent>{value || <>&mdash;</>}</ItemGridContent>
                                    </Tooltip>
                                );
                                break;
                            case expireColumnName:
                                value = (
                                    <ExpiredBox
                                        color={colorByDiffDays ? 'error' : undefined}
                                        component={colorByDiffDays ? 'b' : 'span'}
                                    >
                                        {diffDays}
                                    </ExpiredBox>
                                );
                                break;
                            case 'creatorUser':
                                value = (
                                    <Box>
                                        <UserMention id={(value as any)?.id}>
                                            <FlexBox>
                                                <Avatar src={(value as any)?.imgUrl} />
                                                <AutorLink
                                                    to={appGeneratePath(mainUrls.user.id, { id: (value as any)?.id })}
                                                >
                                                    {(value as any)?.displayName}
                                                </AutorLink>
                                            </FlexBox>
                                        </UserMention>
                                    </Box>
                                );
                                break;
                            case 'users':
                                const usersArr = value
                                    ? Array.isArray(value)
                                        ? (value as any[])
                                        : ([value] as any[])
                                    : [];
                                value =
                                    usersArr.length === 0 ? (
                                        <>&mdash;</>
                                    ) : (
                                        <UsersBox>
                                            {usersArr.length > 5 && (
                                                <PlusNButton
                                                    onClick={() => {
                                                        openUsersList({ items: usersArr });
                                                    }}
                                                >
                                                    {`+${usersArr.length - 4}`}
                                                </PlusNButton>
                                            )}
                                            {usersArr
                                                .slice(0, usersArr.length <= 5 ? usersArr.length : 4)
                                                .map((user) => {
                                                    return (
                                                        <UserMention id={user?.id}>
                                                            <AutorLink
                                                                to={appGeneratePath(mainUrls.user.id, {
                                                                    id: user?.id,
                                                                })}
                                                            >
                                                                <Avatar src={user?.imgUrl} />
                                                            </AutorLink>
                                                        </UserMention>
                                                    );
                                                })}
                                        </UsersBox>
                                    );
                                break;
                            default:
                                value = (
                                    <Tooltip disableInteractive overflowOnly title={<>{value}</>}>
                                        <ItemGridContent>{value || <>&mdash;</>}</ItemGridContent>
                                    </Tooltip>
                                );
                                break;
                        }
                        return (
                            <ItemGridCell key={idx} className={cnTableListItemCell({}, [col.name])}>
                                <ItemGridContent className={'ItemGridContent'}>{value || <>&mdash;</>}</ItemGridContent>
                            </ItemGridCell>
                        );
                    })}
                {isWithActionButton && (
                    <ItemActionCell className={cnTableListItemCell('Actions')} component="span">
                        {itemActionsButton}
                    </ItemActionCell>
                )}
            </StyledItemBox>
        </>
    );
};
