import React, { FC, useState, useEffect } from "react";
import { ComponentInjector } from "utils/src/ComponentInjector";
import { WidgetDialogBox } from "./ContentCardWidget.styled";
import {
  IWidgetSettingsProps,
  ISettingsItem,
  cnContentCardWidgetSetting,
} from "./ContentCardWidget.index";
import { TextField } from "muicomponents/src/TextField/TextField";
import { UploadButton } from "muicomponents/src/UploadButton/UploadButton";
import { useFileUploader } from "utils/src/hooks";
import { Button } from "muicomponents/src/Button/Button";
import Switch from "@mui/material/Switch";
import uuid from "uuid";
import FormControlLabel from "@mui/material/FormControlLabel";
import { isObject } from "lodash";
import { IconButton } from "muicomponents/src/IconButton/IconButton";
import Delete from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { i18n, Translate } from "localization";
import { DraftInput, useDraftEdit, UsersSuggester } from "muicomponents/src";
import { useDraftState } from "muicomponents/src/DraftComponents/utils/useDraftState";

const StyledImg = styled("img")({
  width: "36.5px",
  height: "36.5px",
});

const Item = ({
  settings,
  onChange,
  onEnebled,
  onDelete,
}: {
  settings: ISettingsItem;
  onChange: (settnigs: ISettingsItem) => void;
  onEnebled: (enabled: boolean) => void;
  onDelete: () => void;
}) => {
  const [state, setState] = useState<typeof settings>(settings || {});
  const [title, setTitle] = useState(settings?.title || '');
  const [url, setUrl] = useState(settings?.url || '');
  // const [editorState, setSditorState] = useDraftState("text test");
  const {
    draftState,
    setDraftState,
    state: { text },
  } = useDraftEdit(settings.desctiption || "");

  const { isLoading, onFileChange } = useFileUploader({
    allowTypes: ["image/jpeg", "image/png", "image/gif"],
    uploadOnAdd: true,
    maxFilesCount: 1,
    callbacks: {
      loadFinish(item, response) {
        if (response?.error_code === 0) {
          setState((s) => ({ ...s, file: response.data[0] }));
        }
      },
    },
  });

  useEffect(() => {
    onChange({ ...state, title, url, desctiption: text });
  }, [state, text, title, url]);

  return (
    <Stack
      direction={"row"}
      gap={2}
      alignItems="flex-start"
      className={cnContentCardWidgetSetting()}
    >
      <Stack gap={2} flex="1" className={cnContentCardWidgetSetting("Stack")}>
        <TextField
          autoComplete="off"
          className={cnContentCardWidgetSetting("title")}
          required
          label={i18n.t("pryaniky.ContentCardWidget.settings.title")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          autoComplete="off"
          className={cnContentCardWidgetSetting("link")}
          label={i18n.t("pryaniky.ContentCardWidget.settings.link")}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />

        <DraftInput
          className={cnContentCardWidgetSetting("description")}
          label={i18n.t("pryaniky.ContentCardWidget.settings.description")}
          size={"small"}
          value={draftState}
          onChange={setDraftState}
        />

        <Stack gap={2} direction="row">
          <StyledImg
            src={state?.file?.previewUrl}
            className={cnContentCardWidgetSetting("Img")}
          />

          <UploadButton
            className={cnContentCardWidgetSetting("Upload")}
            inputId={`file-${state.id}`}
            loading={isLoading}
            onChangeInput={onFileChange}
          >
            {i18n.t("pryaniky.ContentCardWidget.settings.upload_image")}
          </UploadButton>
        </Stack>

        {/* <FormControlLabel
          control={
            <Switch
              checked={settings.enabled}
              onChange={(e) => onEnebled(e.target.checked)}
            />
          }
          label={i18n.t("pryaniky.ContentCardWidget.settings.enabled")}
        /> */}
      </Stack>
      <Tooltip title={i18n.t("pryaniky.ContentCardWidget.settings.delete")}>
        <IconButton onClick={onDelete}>
          <Delete color="error" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export const WidgetSettingsDialog: FC<IWidgetSettingsProps> = ({
  onChange,
  data,
  settings,
  setValid,
}) => {
  const [state, setState] = useState<ISettingsItem[]>(() => {
    let items = settings?.items || [];
    return items;
  });
  const [title, setTitle] = useState(settings?.title || '');
  useEffect(() => {
    onChange({
      settings: { ...settings, title, items: state },
    });
    setValid(true);
  }, [state, title]);
  // console.log('WidgetSettingsDialog, state', state, settings)
  return (
    <WidgetDialogBox>
      <Stack divider={<Divider />} gap={2}>
        <TextField
          required
          autoComplete="off"
          label={i18n.t("pryaniky.ContentCardWidget.settings.widgetTitle")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {state.map((item) => (
          <Item
            key={item.id}
            onDelete={() => setState((p) => p.filter((v) => v.id !== item.id))}
            onEnebled={(enabled) =>
              setState((p) =>
                p.map((v) =>
                  v.id === item.id
                    ? { ...v, enabled }
                    : { ...v, enabled: false }
                )
              )
            }
            onChange={(s) =>
              setState((p) => p.map((v) => (v.id === item.id ? s : v)))
            }
            settings={item}
          />
        ))}

        <Button
          className={cnContentCardWidgetSetting("Add")}
          onClick={() =>
            setState((s) => [...s, { id: uuid(), enabled: false }])
          }
        >
          {i18n.t("pryaniky.ContentCardWidget.settings.add_item")}
        </Button>
      </Stack>
    </WidgetDialogBox>
  );
};

export const initWidgetAddition = () =>
  ComponentInjector.getInstance().addComponent(
    "WAddition",
    WidgetSettingsDialog,
    "pryaniky/ContentCardWidget"
  );
export const initWidgetSettings = () =>
  ComponentInjector.getInstance().addComponent(
    "WSettings",
    WidgetSettingsDialog,
    "pryaniky/ContentCardWidget"
  );
