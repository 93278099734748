import { cn } from '@bem-react/classname';
import { GetTableListItems } from 'utils/src/requests/requests.tasks';

import { CurrentApplicationsFilterStatus, TaskFilterStatus, TaskModel } from 'utils/src';
import { useScrollListState } from 'utils/src/hooks';
import { TableListItemProps } from './Item/TableListItem.index';
import { IListItemProps } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';

export type TableListProps = {
    context: { [key: string]: any };
};

const cnTableListList = cn('TableList');

export const tasksListClasses = {
    root: cnTableListList(),
    searchActions: cnTableListList('SearchActions'),
    headerButton: cnTableListList('CreateAction'),
};

export type FilterStatus = TaskFilterStatus | CurrentApplicationsFilterStatus;
export type TableListDataModel = TaskModel;
export type ITableListProps<F = FilterStatus, M = TableListDataModel> = {
    context: { [key: string]: any };
    defaultValue: any;
    defaultFilter: F;
    isExpireDays?: boolean;
    isWithActionButton?: boolean;
    strForLocalization?: string;
    bottomContent?: React.ReactNode;
    topContent?: React.ReactNode;
    filterOptions: {
        title: React.ReactNode;
        value: string;
    }[];
    selectedFilter?: F;
    onFilterChange?: (newFilter: F) => void;
    getData: (p: GetTableListItems.Params<F>) => GetTableListItems.Response<M>;
    createActionsEl?: (
        actions: ReturnType<typeof useScrollListState>['actions'],
        context: {
            [key: string]: any;
        }
    ) => React.ReactNode;
    createItemProps?: (
        props: IListItemProps<TaskModel>,
        context: {
            [key: string]: any;
        }
    ) => TableListItemProps;
};
export type TableListSearchActionProps<F = FilterStatus> = {
    filterOptions: ITableListProps<F>['filterOptions'];
    selectedFilter: F;
    onFilterChange: (newFilter: F) => void;
    actionsEl?: React.ReactNode;
};
