import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'
import { Avatar } from 'uielements/src/Avatar/Avatar'
import { i18n, Translate } from 'localization';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

type IPresenterProps = News.ActionHeader.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    actionHeader
}) => {
    const { text, type, user, textKey } = actionHeader

    return <div className={cnClassName({}, [className])}>
        <Avatar className={cnClassName('Avatar')} size={40} name={user.displayName} imgUrl={user.imgUrl} imgId={user.imgId} />
        <div className={cnClassName('Description')}>
            <UserMention id={user.id}>
                <Link className={cnClassName('Mention', [classNamesDesign.primaryColor3.text])} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>
                    {user.displayName}
                </Link>
            </UserMention>
            <span className={cnClassName('Text')}>{text || (textKey && i18n.t('pryaniky.' + textKey + '_' + user.sex))}</span>
        </div>
    </div >
}

export default memo(Presenter);
