import { useSelector } from 'react-redux';
import React, { ComponentProps, FC, useMemo } from 'react';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';

import { cnTabs, NTabs } from "./Tabs.index";
import { prepareWidget } from 'i.widgets';
import { useLocation, useParams, withRouter } from 'react-router';
import { IStateType as IState, IStateType } from 'redux/store';
import './Tabs.scss';
import { i18n, Translate, useTranslateContext } from 'localization';
import { Tabs as TabsComponent } from 'muicomponents/src/Tabs';
import { Button } from 'muicomponents/src/Button/Button';
import { typeOfPath, useUserGroupTagPath } from './Tabs.hooks';
import { StyledTabs, TabPropsSX, TabsBlock, TabsWrapper, TabWrapper } from './Tabs.styled';
import { TabButton } from './TabButton';
import { setBrightnessToHSL } from 'utils/src/utils.colors';
import { getAppDesignColors, getGlobalEditState } from 'utils/src/CommonRedux/base/selectors';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getTranslatedTabName } from './Tabs.utils';

export const Tabs: FC<NTabs.Props> = ({
  tabs: tabsProps,
  context,
  children,
  updateContext,
  disableNavigate,
  ...props
}) => {

  const translateContext = useTranslateContext();

  const location = useLocation();

  const pathType = typeOfPath();
  const { id: pageId } = useParams<{id?: string}>();

  const userGroupTagPath = useUserGroupTagPath();

  const widgets = useSelector((state: IStateType) => state.widgets.widgets);

  const authUserId = useSelector(getAuthUser).baseData.id;
  const isAuthUserPortalAdmin = useSelector(getAuthUser).baseData.isAdmin

  const edit = useSelector(getGlobalEditState);

  const tabProps = useMemo(() => {
    return tabsProps.filter(tab => {
      if (pathType !== 'group') return true;
      if (!context.isContentDisabled) return true;
      return tab.name !== 'feed';
    })
  }, [tabsProps, pathType, context]);

  const tabs = useMemo<ComponentProps<typeof TabsComponent>['tabs']>(() => {
    // const canAuthUserSeeHiddenTab = pathType === 'group' ? context.isGroupAdmin : isAuthUserPortalAdmin;
    // const userOnHisUserPage = pathType === 'user' && pageId === authUserId;
    return tabProps.filter((tab) => 
      edit ? true : !tab.isHidden
    )
      .map(tab => {
        let title = tab.name || tab.id;
        if (tab.name === 'about') {
          switch (pathType) {
            case 'user': {
              title = 'pryaniky.tabs.aboutMe';
              break;
            }
            case 'group': {
              title = 'pryaniky.tabs.aboutGroup';
              break;
            }
          }
        }
        // пробуем прогнать черзе локализацию тут, если каких то данных нет, то делаем фолбек для 
        title = getTranslatedTabName(tab);
        return {
          id: tab.id,
          label: title,
          className: cnTabs('Button'),
          component: TabButton,
          to: userGroupTagPath ? `/${userGroupTagPath}/${tab.name || tab.id}` : undefined,
          disableNavigate,
          iconFile: tab.iconFile,
          icon: tab.icon,
          name: tab.name
        };
      });
  }, [authUserId, pageId, tabProps, pathType, userGroupTagPath, disableNavigate, edit, translateContext.rerender]);

  const activeId = useMemo(() => {
    let st = context.tab;
    if (pathType) {
      /**
       * @todo need switch to useParams
       */
      st = location.pathname.split('/')[3]
    }
    return tabs.find(el => st === el.id || st === el.name)?.id || tabs[0]?.id || '';
  }, [location, pathType, context, tabs]);

  const prepairedWidgets = useMemo(() => {
    const activeTab = tabProps.find(tab => tab.id === activeId);
    if (!activeTab) return null;
    return activeTab.items.map(item => prepareWidget(widgets[item]))
  }, [activeId, tabProps, widgets]);

  const onChangeTab: ComponentProps<typeof StyledTabs>['onChange'] = (e, id) => {
    const activeTab = tabProps.find(el => el.id === id);
    updateContext?.({
      tab: activeTab?.name || activeTab?.id
    });
  };

  const colors = useSelector(getAppDesignColors);

  const wrapperColor = useMemo(() => {
    if (!colors?.primaryColor3) return '';
    return setBrightnessToHSL(colors.primaryColor3, 90);
  }, [colors?.primaryColor3]);

  return (
    <>
      <TabsBlock
        {...props}
        className={cnTabs({}, [props.className])}
      >
        <TabsWrapper
          sx={{
            background: wrapperColor
          }}
        >
          <StyledTabs
            className={cnTabs('Buttons', {}, ['primaryColor3-bg'])}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile={true}
            tabs={tabs}
            TabProps={{
              sx: TabPropsSX
            }}
            onChange={onChangeTab}
            value={activeId}
          />
        </TabsWrapper>
      </TabsBlock>
      <TabWrapper
        className={cnTabs('Tab')}
      >
        {activeId && prepairedWidgets}
      </TabWrapper>
      {children}
    </>
  )
};