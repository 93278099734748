import { Box, styled } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { CheckMarkPreorderMade } from '../CommonElements/CheckMarkPreorderMade';

export const StyledAvatar = styled(Avatar)({
    borderRadius: '4px 4px 0 0',
    height: '158px', // в макете
    width: '100%',
    '.MuiAvatar-root>img': {
        display: 'block',
        minWidth: '100%',
        minHeight: '100%',
    },
    '.MuiAvatar-img': {
        minWidth: '100%',
        minHeight: '100%',
        objectFit: 'cover',
    },
}) as typeof Avatar;

export const AvailableBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
}) as typeof Box;

export const StarBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
}) as typeof Box;

export const ShopInfoBox = styled(Box)({
    display: 'flex',
    gap: '8px',
}) as typeof Box;

export const ContentBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '8px 0 30px 0',
    position: 'relative',
}) as typeof Box;

export const OrderBlockWrapBox = styled(Box)({
    flex: 1,
}) as typeof Box;

export const ChipsWrapBox = styled(Box)({
    position: 'absolute',
    bottom: '8px',
    left: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
}) as typeof Box;
