import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { Selectize, Button } from 'uielements/src'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index'
import './Filters_type_calendar.scss'
import { mapStateToProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnFilter, IFilterStateToProps } from './Filters_type_calendar.index'
import { i18n, Translate } from 'localization';
import { cnFilters } from '../Filters.index'
import { DialogSelectFilterColor } from 'blocks/Dialogs/Calendar/SelectFilterColor/SelectFilterColor';
// import { ShopSelectFilterColor } from 'blocks/Dialogs/Calendar/SelectFilterColor/SelectFilterColor';
import { GUID_EMPTY } from 'utils/src/constants.prn'
import { useContextFiltersChange } from '../Filters.hooks'
import { CalendarViewType } from 'utils/src'

export const isWorkflowCalendar = (calendar: any) => calendar.value.includes('form');

const allCalendarFilter = {
  // color: "#340C3D"
  id: GUID_EMPTY,
  selected: true,
  title: "Все",
  type: "cIds",
  value: "",
  groupId: -1
}

const FiltersCalendarPresenter: React.FC<IFiltersProps> = ({
  calendars,
  changeContextv3,
  context,
  getCalendarCalFilters,
  getCalendarWorkflowFilters,
  clearCalendarWorkflowFilters,
  tag: Tag="div",
  relations,
  workflow,
  ...otherProps
}, props) => {

  const commonCalendars: any[] = [allCalendarFilter];
  const workflowCalendars: any[] = [];
  if (context.gId && context.gId !== null)
  {
    calendars = calendars.filter((c:any)=> c.groupId == context.gId /*|| c.id === GUID_EMPTY || c.viewType !== CalendarViewType.HideFromFilters*/);
  }
  else{
    calendars = calendars.filter((c:any)=> c.id === GUID_EMPTY || c.viewType !== CalendarViewType.HideFromFilters);
  }
  calendars?.forEach((calendar: any) => isWorkflowCalendar(calendar) ? workflowCalendars.push(calendar) : commonCalendars.push(calendar));

  const [isCalendarsSet, setIsCalendarsSet] = useState<boolean>(false);
  const [showStatusFilter, setShowStatusFilter] = useState<boolean>(true);

  const onChange = useContextFiltersChange(relations || ['common']);

  const contextCIds: string[] = context?.cIds || [GUID_EMPTY];

  const statusFilter = {
    name: i18n.t('pryaniky.filter.calendar.status'),
    elements: [
      {
        id: '0',
        title: i18n.t('pryaniky.filter.calendar.all'),
        type: 'filter',
        value: undefined,
      },
      {
        id: '1',
        title: i18n.t('pryaniky.filter.calendar.going'),
        type: 'filter',
        value: 'going',
      },
      {
        id: '2',
        title: i18n.t('pryaniky.filter.calendar.no'),
        type: 'filter',
        value: 'no',
      },
      {
        id: '3',
        title: i18n.t('pryaniky.filter.calendar.maybe'),
        type: 'filter',
        value: 'maybe',
      },
      {
        id: '4',
        title: i18n.t('pryaniky.filter.calendar.invited'),
        type: 'filter',
        value: 'invitationsent',
      },
    ],
  }

  // in first render selected only first(all) calendar
  // const selectedCIds: any = [GUID_EMPTY];
  // commonCalendars.filter((el: any) => el.selected).map((el: any) => el.id);

  useEffect(() => {
    if (!calendars.length) getCalendarCalFilters(context?.gId);
    if (calendars.length && !contextCIds.length && !isCalendarsSet) {
      setIsCalendarsSet(true);
      selectAllCalendars();
    }
  }, [calendars.length, context])


  const selectAllCalendars = () => {
    onChange({ cIds: undefined });
  }


  const handleChange = (selected: ISelectizeItem) => {
    const same = context.filter && context.filter.id === selected.id;
    onChange({ filter: !same ? selected : undefined });
  }

  const changeCommonCalendars = (selected: ISelectizeItem) => {

    const same = contextCIds.includes(selected.id)
    let value: string[] | undefined = [];
    if (selected.id === GUID_EMPTY) {
      value = same ? commonCalendars.filter(e => e.viewType !== CalendarViewType.HideEventsByDefault && e.id !== selected.id).map(e => e.id) : [];
    } else {
      value = (same ? contextCIds.filter(e => e !== selected.id) : [...contextCIds, selected.id]).filter(e => e !== GUID_EMPTY);
    }
    if (!value?.length) value = undefined;
    onChange({ [selected.type]: value });
  }

  const changeWorkflowCalendars = (selected: ISelectizeItem) => {
    const same = context?.cIds?.includes(selected.id)

    if (!same) {
      getCalendarWorkflowFilters(selected.id);
      setShowStatusFilter(false);
    } else {
      clearCalendarWorkflowFilters();
      setShowStatusFilter(true);
    }
    onChange(!same
      ? { cIds: [selected.id], calendars: [selected], statusIds: [], tagIds: [], filter: undefined }
      : { cIds: [], calendars: [], statusIds: [], tagIds: [] }
    );
  }

  const changeWorkflowFilter = (selected: ISelectizeItem) => {
    const same = context[selected.type]?.includes(selected.id);
    onChange({ [selected.type]: !same ? [...(context[selected.type] || []), selected.id] : context[selected.type]?.filter((el: string) => el !== selected.id) });
  }

  const onChangeColor = (el: any) => {    
    DialogSelectFilterColor({itemData:el}).then(()=>{}).catch(()=>{})
  }

  const workflowElements = workflowCalendars?.filter((v: any) => v.viewType !== 'HideFromFilters') || []
  
  return (
      <Tag {...otherProps} className={cnFilters("Calendar")} >
        <div className={cnFilters({}, [props.className])}>
          {/* <h3 className={cnFilter('Title', ['BottomLine'])}>{i18n.t('pryaniky.filter.calendar.title')}</h3> */}
          <Selectize onClick={handleChange} active={context && context.filter ? context.filter.id : undefined} name={statusFilter.name} elements={showStatusFilter ? statusFilter.elements : []} id='prn-cal_filter-statuses' />
          <div className={cnFilter('Action')}>
            <Button noBorder theme='unstyled' onClick={() => setShowStatusFilter(!showStatusFilter)}>{showStatusFilter ? i18n.t('pryaniky.filter.calendar.hide') : i18n.t('pryaniky.filter.calendar.show', { count: statusFilter.elements.length })}</Button>
          </div>


          <Selectize cut={5} onClick={changeCommonCalendars} onColorClick={onChangeColor} active={contextCIds} name={i18n.t('pryaniky.filter.calendar.calendarsText')} elements={commonCalendars || []} id='prn-cal_filter-calendars' />
          {/* {!!commonCalendars.length && <div className={cnFilter('Action')}>
          <Button noBorder theme='unstyled' onClick={() => selectAllCalendars(selectedCIds.length !== contextCIds.length)}>{selectedCIds.length !== contextCIds.length ? i18n.t('pryaniky.filter.calendar.selectAll') : i18n.t('pryaniky.filter.calendar.removeSelection')}</Button>
        </div>} */}

        {!!workflowElements.length &&
          <Selectize onClick={changeWorkflowCalendars} onColorClick={onChangeColor} active={contextCIds} name={i18n.t('pryaniky.filter.calendar.workflows')} elements={workflowElements || []} id='prn-cal_filter-workflows' />
        }

      </div>
      {/* <ShopSelectFilterColor
        isShown={showDialog}
        onClose={() => setShowDialog(false)}
        data={changeFilterItem}
      /> */}

      {!!(workflow.statuses.length || workflow.tags.length) &&  <div className={cnFilters({}, [props.className, 'Widget'])}>
        
        {!!workflow.statuses.length && <Selectize onClick={changeWorkflowFilter} active={context && context?.statusIds} name={i18n.t('pryaniky.filter.calendar.workflows.statuses')} elements={workflow.statuses || []} />}
        {!!workflow.tags.length && <Selectize onClick={changeWorkflowFilter} active={context && context?.tagIds} name={i18n.t('pryaniky.filter.calendar.workflows.tags')} elements={workflow.tags || []} />}
      
      </div>}
    </Tag>
  )
}

export const FiltersCalendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersCalendarPresenter)