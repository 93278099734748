import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';

export const currentApplications = (): IWidget<IColumn[]> => {
    return generateWidget({
        type: widgets.types.layout,
        data: [
            generateColumn({
                items: [
                    generateWidget({
                        // id: ids.info,
                        type: widgets.types.list + '/currentApplications',
                    }),
                ],
                styles: {
                    width: '100%',
                },
            }),
        ],
    });
};
