import { Translate } from 'localization';
import { Box, CircularProgress } from 'muicomponents/src';
import { DialogActions, DialogContent, DialogTitle } from 'muicomponents/src/DialogParts';
import { DraftContextProps, DraftContextProvider } from 'muicomponents/src/DraftComponents/Editor/Editor';
import { styled } from 'muicomponents/src/mui/system';
import { NewsSender, NewsSenderActions } from 'News/creator/Sender';
import React, { ComponentProps, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getAppSettings } from 'utils/src/CommonRedux/base/selectors';
import { IComponentAcceptor, withComponentEnjector } from 'utils/src/ComponentInjector';
import { createModal, IBodyProps, TransitionComponent } from 'utils/src/DialogCreator';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { NewsCreateContext } from '../Creator.constants';
import { useNewsCreator } from '../Creator.hooks';
import { NNewsCreator } from '../Creator.index';

type SendNewsRezult = {
    success: boolean;
};

type SendNewsDialogProps = NNewsCreator.OwnProps &
    IBodyProps<SendNewsRezult> &
    IComponentAcceptor & {
        creatorId: string;
        dialogTitle?: ReactNode;
    };

const StyledDialogContent = styled(DialogContent)({
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
}) as any as typeof DialogContent;

const LoaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center'
}) as typeof Box;

const StyledDialogActions = styled(DialogActions)({
    ['& .NewsSender-Actions']: {
        width: '100%',
    },
    ['& > *:not(.NewsSender-Actions)']: {
        display: 'none !important',
    },
});

const SendNewsDialogPr: FC<SendNewsDialogProps> = ({
    newsType,
    all,
    creatorId,
    groupId,
    workflowId,
    creatorOptions,
    dialogTitle,
    getNode,
    handleAccept,
    handleClose,
}) => {
    const { hash } = useLocation();
    const history = useHistory();

    const {
        postTypes,
        postTypesError,
        selectedNews: currentType,
        sended,
        news,
        selectPostType
    } = useNewsCreator(creatorId, groupId, workflowId, creatorOptions, newsType, all);

    const [newsCreatorContext, setNewsCreatorContext] = useState<
        ComponentProps<typeof NewsCreateContext['Provider']>['value']
    >({
        creatorId,
        groupId: groupId || -1,
        workflowId,
        newsType,
        all
    });

    useEffect(() => {
        !currentType && postTypes?.length && selectPostType(newsType);
    }, [postTypes]);

    useEffect(() => {
        currentType && selectPostType(newsType, true);
    }, [creatorOptions]);

    useEffect(() => {
        !currentType && selectPostType(newsType);
        return () => {
            const currentTypeInHash = hash.slice(1);
            if (newsType === currentTypeInHash) history.push({});
        };
    }, []);

    useEffect(() => {
        setNewsCreatorContext({
            creatorId,
            groupId: groupId || -1,
            workflowId,
            newsType,
            all
        });
    }, [creatorId, groupId, workflowId, newsType, all]);

    const Node = useMemo(() => {
        const currentRenderName = postTypes?.find((el: any) => el.id === currentType)?.componentRenderName;
        return (currentRenderName && getNode(`${currentRenderName}`)) || null;
    }, [postTypes, currentType]);

    useDidUpdateEffect(() => {
        if (sended) handleAccept({ success: true });
    }, [sended]);

    const name = useMemo(() => {
        return postTypes?.find((el: any) => el.id === currentType)?.displayName.toLowerCase() || '';
    }, [postTypes, currentType]);

    const { maxFileSizeLimit } = useSelector<any, { maxFileSizeLimit: number }>(getAppSettings);

    const [draftWork, setDraftWork] = useState(false);
    const draftContext = useMemo<DraftContextProps>(
        () => ({
            locker: setDraftWork,
            maxFileSizeLimit,
        }),
        [setDraftWork]
    );

    return (
        <>
            <DraftContextProvider value={draftContext}>
                <NewsCreateContext.Provider value={newsCreatorContext}>
                    <DialogTitle onClose={handleClose}>
                        {dialogTitle || <Translate i18nKey={`pryaniky.news.create.dialog`} values={{ name }} />}
                    </DialogTitle>
                    <StyledDialogContent>
                    {
                        postTypesError
                        ? <Translate i18nKey='pryaniky.news.create.error.posttypes' />
                        : !postTypes
                        ? <LoaderBox><CircularProgress /></LoaderBox>
                        : Node && <NewsSender>{Node}</NewsSender>
                    }
                    </StyledDialogContent>
                    <StyledDialogActions>
                        <NewsSenderActions disableSend={draftWork} creatorOptions={creatorOptions} />
                    </StyledDialogActions>
                </NewsCreateContext.Provider>
            </DraftContextProvider>
        </>
    );
};

const SendNewsDialogWithInjector = withComponentEnjector<SendNewsDialogProps>(SendNewsDialogPr, ['news_creator_mui5']);

export const SendNewsDialog = createModal(SendNewsDialogWithInjector, {
    maxWidth: 'md',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
        },
    },
    TransitionComponent: TransitionComponent, //LeftDirectionTransitionComponent,
    className: 'Pane BackdropOff Right',
    scroll: 'paper',
    //   preventClose
});
