import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs';
import React, { useContext, useRef, useState } from 'react';
import { IField } from 'uielements/src/FieldRender/interfaces';
import { useDebounce } from 'utils/src/hooks';
import { BaseValidator, baseValidate } from '../../../../Base.validate';
import { Editor } from '../../Editor';
import { IEditorTypeEditProps } from './edit.index';
// import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { NodeBulder } from 'uielements/src/CommonmarkRender';
import { prepareFromServer } from 'uielements/src/FieldRender';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';

const createShortDraft = (state: EditorState) => {
    const draftState = convertToRaw(state.getCurrentContent());
    const text = draftToMarkdown(draftState, rawToMd);
    const NB = new NodeBulder(text || '');
    const newRaw = NB.getDraftRaw();
    return EditorState.createWithContent(convertFromRaw(newRaw));
};

export const EditorTypeEdit: React.FC<IEditorTypeEditProps> = ({ data, onChange, typeParams }) => {
    const [autoShort, setAutoShort] = useState(false);
    const autoShortRef = useRef(autoShort);
    autoShortRef.current = autoShort;
    const { creatorId } = useContext(NewsCreateContext);

    const { additionalFields } = data;
    const afv = useRef(additionalFields?.additionalFieldsValues);
    afv.current = additionalFields?.additionalFieldsValues;

    let localData = { ...data };
    if (!data._editing) {
        // const NB = new NodeBulder( localData.text )//NodeBulder(isString(localData.text) ? localData.text : '')
        localData = {
            ...localData,
            // text: EditorState.createWithContent(convertFromRaw(NB.getDraftRaw())),
            additionalFields: {
                ...localData.additionalFields,
                additionalFieldsValues: prepareFromServer(localData.additionalFields.additionalFieldsValues),
            },
            _editing: true,
        };
    }

    const onChangeText = (text: string) => {
        localData.text = text;
        const bv = new BaseValidator<any>();
        bv.setRules(baseValidate);
        bv.addData(localData);
        onChange(localData, bv.validation().length === 0);
    };

    const onChangeHeader = (text: string) => {
        localData.header = text;
        const bv = new BaseValidator<any>();
        bv.setRules(baseValidate);
        bv.addData(localData);
        onChange(localData, bv.validation().length === 0);
    };

    const onChangeMain = (text: string) => {
        // setAutoShort(false)
        onChangeText(text);
    };

    const changeMd = useDebounce((raw: EditorState) => {
        // const text = draftToMarkdown(raw, rawToMd);
        onChangeText(createShortDraft(raw) as any);
    }, 600);

    const onChangeDraft = (fields: IField.AdditionalField[], isValid: boolean = true) => {
        localData.additionalFields.additionalFieldsValues = fields;
        const emptyRaw = EditorState.createEmpty();

        const content = fields.reduce(
            (ac, c) =>
                ac ? ac : c.fields.reduce((af, f) => (f.isBaseField && f.name === 'Text' && !af ? f.data : af), null),
            null
        );

        autoShortRef.current && changeMd(content || emptyRaw);
        // TODO: найти как добавить ошибку если нет значения
        // if (!fields[1].fields[1].data.startDate || !fields[1].fields[1].data.endDate) {
        //   добавить ошибку, может не тут
        // }
        onChange(localData, isValid);
    };

    return (
        <Editor
            additionalFields={localData.additionalFields.additionalFieldsValues}
            changeAdditional={onChangeDraft}
            // draftState={localData.additionalFields[0].additionalFieldsValues[0].draft || EditorState.createEmpty()}
            data={localData}
            autoShort={autoShort}
            setAutoShort={setAutoShort}
            onChangeText={onChangeText}
            onChangeHeader={onChangeHeader}
            allowRichEdit={typeParams?.newsRenderParams?.allowRichEdit}
        />
    );
};
