import { Box, styled, Typography } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link';
import { ComponentProps } from 'react';

interface CustomEventLinkProps extends ComponentProps<typeof Link> {
    styledEventColor?: string;
}

interface CustomEventBoxProps extends ComponentProps<typeof Box> {
    styledBorderColor?: string;
    styledEventColor?: string;
}

export const CustomEventBox = styled(Box)<CustomEventBoxProps>(({ styledEventColor, styledBorderColor }) => ({
    display: 'flex',
    fontSize: '14px',
    alignItems: 'flex-start',
    paddingTop: '3px',
    paddingBottom: '3px',
    borderRadius: '4px',
    height: '100%',
    borderColor: `${styledEventColor}`,
    backgroundColor: `${styledBorderColor}`,
}));

export const CustomEventLink = styled(Link)<CustomEventLinkProps>(({ styledEventColor }) => ({
    display: 'contents',
    color: `color-mix(in srgb, ${styledEventColor} 25%, white) !important`,
    textDecoration: 'none',
}));

export const CustomEventTitle = styled(Typography)({
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '22px',
    clipPath: 'inset(0 0 15% 0)',
    height: '100%',
}) as typeof Typography;
