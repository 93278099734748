import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';

import { IWidget, IColumn } from 'i.widgets';
import { getStructure, setStructure, backup, preparePageName, prepareMobilePageName } from 'redux/actions/Widgets'
// import { RouteComponentProps } from 'react-router';
// import { IDispatchProps, mapDispatchToProps } from 'redux/connector';
import { i18n, Translate } from 'localization';
import { IStateType as IState } from 'redux/store';
import { basename } from 'utils/src';
import { bindActionCreators, Dispatch } from 'redux';
import { toggle } from 'utils/src/CommonRedux/base/actions';

export interface IOtherData extends Object {
  [s: string]: any;
}

export interface IPageProps extends IClassNameProps, IPageOwnProps, IPageStateProps, IActionsPage {
  tag?: 'div';
  // page: {
  //   name: string;
  //   title?: string;
  //   data?: { [s: string]: any };
  //   params?: { [s: string]: any };
  // },
}

export interface IPageOwnProps {
  // context: { [s: string]: any }
  page: {
    name: string;
    /**
     * @deprecated
     */
    title?: string;
    data?: { [s: string]: any };
    params?: { [s: string]: any };
  },
  cbAfterGetData?: () => void;
  children?: any;
}

export interface IPageState {
  text?: string;
  layout?: IWidget;
}


// export interface IPageStateProps  {
//   edit: boolean;
//   saving: boolean;
//   structure: {[s: string]: any};
//   // widgets: {[s: string]: any};
//   // router: any;
//   layout: IWidget<IColumn[]>,
//   title: string;
//   context: { [s: string]: any };
// }


export const mapActionsToPropsPage = (dispatch: Dispatch) => bindActionCreators({
  getStructure,
  setStructure,
  backup,
  toggle
}, dispatch);

export type IActionsPage = ReturnType<typeof mapActionsToPropsPage>;

export const mapStateToProps = (state: IState, props: IPageOwnProps) => {
  const pageName = prepareMobilePageName(props.page.name);
  return {
    edit: state.store.edit,
    saving: state.store.saving,
    // structure: getPageStructure(state.store.structure, props.page.name),
    // widgets: state.store.widgets,
    // structure: getPageStructure(state.widgets.structures, props.page.name.split('.').join('/')),
    loading: state.widgets.togglers.loading,
    structure: state.widgets.pages[state.widgets.structures[preparePageName(pageName)]],
    layout: state.widgets.pages[state.widgets.structures[preparePageName(pageName)]] ? state.widgets.widgets[state.widgets.pages[state.widgets.structures[preparePageName(pageName)]].layout] : undefined,
    // widgets: state.widgets.widgets,
    // router: state.router,
    /**
     * @deprecated
     */
    title: state.appSettings && state.appSettings.title !== '' ? state.appSettings.title : i18n.t('project name'),
    context: state.widgets.contexts.common
  }
}

export type IPageStateProps = ReturnType<typeof mapStateToProps>;

export const cnPage = cn('Page');

export enum PageSignals {
  edit = 'edit',
  save = 'save',
  cancel = 'cancel'
};