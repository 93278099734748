import BadgesListEmpty from 'assets/png/BadgesListEmpty.png';
import { i18n, Translate } from 'localization';
import { Box, Paper } from 'muicomponents/src';
import { useListCardResize } from 'muicomponents/src/InfinityList/hook';
import InfinityList from 'muicomponents/src/InfinityList/InfinityList';
import { LinearProgress } from 'muicomponents/src/LinearProgress';
import React, {
    ComponentProps,
    ComponentType,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
    PropsWithRef,
} from 'react';
import { useSelector } from 'react-redux';
import { getGlobalEditState } from 'utils/src/CommonRedux/base/selectors';
import { useDebouncedQueryState, useDidUpdateEffect, useReffedState, useScrollListState } from 'utils/src/hooks';
import { getBadgesList } from 'utils/src/requests/requests.badges';
import { BadgesListEditTypes } from './Badges.constants';
import { cnBadgesList, NBadgesList } from './Badges.index';
import { BadgeCategoryBox, BadgeCategoryTitle, BadgesGridItemsBox } from './Badges.styled';
import { BadgesMenu } from './BadgesMenu';
import { HeaderBadges } from './HeaderBadges/HeaderBadges';
import { ItemGrid } from './ItemGrid/ItemGrid';
import { ItemList } from './ItemList/ItemList';
import ReactSortable from 'react-sortablejs';
import { clone } from 'lodash';

const BadgesPresenter: FC<NBadgesList.Props> = ({ filter, categoriesOrder, applySorting }) => {
    const ref = useRef<HTMLDivElement>();

    const pageIsEdit = useSelector(getGlobalEditState);

    const [search, searchQuery, setSearch] = useDebouncedQueryState('search');
    const [viewType, setViewType] = useState<'grid' | 'row'>('grid');
    const [checkedCategories, setCheckedCategories] = useState<string[]>([]);

    const { count, ref: listRef } = useListCardResize(4, 250);

    let {
        isFinished,
        isLoading,
        itemsCount,
        loadMore,
        values,
        triggerRef,
        error: { withError },
    } = useScrollListState(
        async function (skipCount, count) {
            return (await getBadgesList(searchQuery as string, { filter: checkedCategories }, skipCount, count)).data;
        },
        [searchQuery, checkedCategories]
    );

    /**
     * тут стейт использован спечиально, что бы перерендерить компонент и отключить кнопку дозагрузки
     */
    const [widgetIsDraggableState, setStateWidgetIsDraggableState] = useState(false);

    const startDragHandler = useCallback<ComponentProps<typeof BadgesMenu>['startEdit']>(() => {
        setStateWidgetIsDraggableState(true);
        const item = ref.current?.closest('.Widget')?.querySelector('.Widget-Blind') as HTMLDivElement;
        item.setAttribute('hidden', 'true');
    }, []);

    const endDragHandler = useCallback<ComponentProps<typeof BadgesMenu>['endEdit']>(
        (save) => {
            if (save) {
                const items: typeof categoriesOrder = prepairedCategoriesOrderRef.current.map((el) => {
                    return {
                        id: el.id,
                        items: el.items.map((el) => el.badgeUid),
                    };
                });
                applySorting?.(items);
            } else {
                setPrepairedCategoriesOrder(prepareSorting(values, categoriesOrder || []));
            }
            setStateWidgetIsDraggableState(false);
            setEditType(BadgesListEditTypes.badges);
            const item = ref.current?.closest('.Widget')?.querySelector('.Widget-Blind') as HTMLDivElement;
            item.removeAttribute('hidden');
        },
        [values, categoriesOrder]
    );

    isFinished = widgetIsDraggableState || isFinished;

    const [editType, setEditType] = useState(BadgesListEditTypes.badges);

    const categorySorting = editType === BadgesListEditTypes.categories;

    const categoriesSortRef = useRef<any>();

    const badgdesSortableRef = useRef<{ [key: string]: any }>({});

    const CategoriesWrapper = useCallback<FC<{}>>(
        ({ children }) => {
            if (viewType === 'row') {
                return <Paper sx={{ padding: '24px' }}>{children}</Paper>;
            }
            return <>{children}</>;
        },
        [viewType]
    );

    const ItemsWrapper = useCallback<
        FC<{
            Component: ComponentType<PropsWithRef<any>>;
            forwardRef?: (el: HTMLElement) => void;
            [key: string]: any;
        }>
    >(
        ({ Component, children, ...props }) => {
            if (viewType === 'row') {
                return (
                    <Component {...props} ref={props.forwardRef as any}>
                        {children}
                    </Component>
                );
            }
            return (
                <BadgesGridItemsBox
                    {...props}
                    ref={(el: HTMLElement) => {
                        if (el) {
                            if (el instanceof HTMLDivElement) {
                                listRef.current = el;
                            } else {
                                const anyEl = el as any;
                                listRef.current = anyEl.node;
                            }
                        }
                        props.forwardRef?.(el);
                    }}
                    component={Component}
                >
                    {children}
                </BadgesGridItemsBox>
            );
        },
        [viewType]
    );

    const prepareSorting = useCallback((list: typeof values, sorting: NonNullable<typeof categoriesOrder>) => {
        const categoriesDict: { [key: typeof list[number]['id']]: typeof list[number] } = {};
        const sortingDict: { [key: typeof sorting[number]['id']]: typeof sorting[number] } = {};
        const avaliableCategories = list.map((el) => {
            categoriesDict[el.id] = el;
            return el.id;
        });
        const prepOrder =
            sorting
                ?.filter((el) => avaliableCategories.includes(el.id))
                .map((el) => {
                    sortingDict[el.id] = el;
                    return el.id;
                }) || [];
        if (!prepOrder.length) return list;
        const categoriesNotIncludesInOrder = avaliableCategories.filter((el) => !prepOrder.includes(el));
        return [...prepOrder, ...categoriesNotIncludesInOrder].map((el) => {
            const item = clone(categoriesDict[el]);
            const badgesDict = item.items.reduce((a, c) => {
                return {
                    ...a,
                    [c.badgeUid]: c,
                };
            }, {} as { [key: typeof item.items[number]['badgeUid']]: typeof item.items[number] });
            const items = item.items.map((el) => el.badgeUid);
            const sortingItem = (sortingDict[el]?.items || []).filter((el) => items.includes(el));
            if (!sortingItem.length) return item;
            const badgesNotIncludedInOrder = items.filter((el) => !sortingItem.includes(el));
            item.items = [...sortingItem, ...badgesNotIncludedInOrder].map((el) => badgesDict[el]);
            return item;
        });
    }, []);

    const [prepairedCategoriesOrder, setPrepairedCategoriesOrder, prepairedCategoriesOrderRef] = useReffedState(() => {
        return prepareSorting(values, categoriesOrder || []);
    });

    useDidUpdateEffect(() => {
        categoriesSortRef.current?.option('disabled', !widgetIsDraggableState ? true : !categorySorting);
        Object.values(badgdesSortableRef.current).forEach((el: any) => {
            el?.option('disabled', !widgetIsDraggableState ? true : categorySorting);
        });
    }, [viewType, prepairedCategoriesOrder, widgetIsDraggableState, categorySorting]);

    useEffect(() => {
        setPrepairedCategoriesOrder(prepareSorting(values, categoriesOrder || []));
    }, [values, categoriesOrder]);

    const onSortChange = useCallback((newSort: string[]) => {
        const categoriesDict: {
            [
                key: typeof prepairedCategoriesOrderRef.current[number]['id']
            ]: typeof prepairedCategoriesOrderRef.current[number];
        } = {};
        prepairedCategoriesOrderRef.current.forEach((el) => {
            categoriesDict[el.id] = el;
        });
        setPrepairedCategoriesOrder(newSort.map((el) => categoriesDict[el]));
    }, []);

    const badgesSort = useCallback((catId: string, newSort: string[]) => {
        setPrepairedCategoriesOrder(
            prepairedCategoriesOrderRef.current.map((el) => {
                if (el.id === catId) {
                    const itemsDict = el.items.reduce((a, c) => {
                        return {
                            ...a,
                            [c.badgeUid]: c,
                        };
                    }, {} as { [key: typeof el.items[number]['badgeUid']]: typeof el.items[number] });
                    return {
                        ...el,
                        items: newSort.map((el) => itemsDict[el]),
                    };
                }
                return el;
            })
        );
    }, []);

    return (
        <>
            {pageIsEdit && (
                <BadgesMenu
                    editType={editType}
                    toggleEditType={setEditType}
                    edit={widgetIsDraggableState}
                    startEdit={startDragHandler}
                    endEdit={endDragHandler}
                />
            )}
            <Box
                ref={ref}
                sx={{
                    maxWidth: '100%',
                }}
            >
                <HeaderBadges
                    viewType={viewType}
                    setViewType={setViewType}
                    setCheckedCategories={setCheckedCategories}
                />

                {isLoading && <LinearProgress />}
                <InfinityList
                    triggerRef={triggerRef}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={itemsCount}
                    isError={withError}
                    loadMore={loadMore}
                    textEmpty={
                        <>
                            <b>
                                {searchQuery
                                    ? Translate.t({ i18nKey: 'pryaniky.badge.empty.title.search' })
                                    : Translate.t({ i18nKey: 'pryaniky.badge.empty.title' })}
                            </b>
                            <br />
                            {searchQuery
                                ? i18n.t('pryaniky.badge.empty.search.text', { query: searchQuery })
                                : Translate.t({ i18nKey: 'pryaniky.badge.empty.title' })}
                        </>
                    }
                    imgEmpty={BadgesListEmpty}
                >
                    <CategoriesWrapper>
                        <ReactSortable
                            ref={(refNode: any) => {
                                // define ref to change 'disabled' state because in option on props change not work
                                if (refNode?.sortable) {
                                    categoriesSortRef.current = refNode.sortable;
                                }
                            }}
                            options={{
                                group: 'categories',
                                animation: 200,
                                disabled: true,
                            }}
                            onChange={onSortChange}
                        >
                            {prepairedCategoriesOrder &&
                                prepairedCategoriesOrder.map(({ id, displayName, items }) => {
                                    const categoryTitle =
                                        displayName ||
                                        (categorySorting ? (
                                            <Translate i18nKey="pryaniky.badges.list.category.empty" />
                                        ) : null);
                                    return (
                                        <BadgeCategoryBox
                                            data-id={id}
                                            key={id}
                                            className={cnBadgesList('Category')}
                                            isGrid={viewType === 'grid'}
                                            sx={{
                                                cursor: categorySorting ? 'grab' : undefined,
                                            }}
                                        >
                                            {categoryTitle && <BadgeCategoryTitle>{categoryTitle}</BadgeCategoryTitle>}
                                            <ItemsWrapper
                                                Component={ReactSortable}
                                                forwardRef={(refNode: any) => {
                                                    // define ref to change 'disabled' state because in option on props change not work
                                                    if (refNode?.sortable) {
                                                        badgdesSortableRef.current[id] = refNode.sortable;
                                                    }
                                                }}
                                                options={{
                                                    group: id,
                                                    animation: 200,
                                                    disabled: true,
                                                }}
                                                onChange={(newSort: string[]) => badgesSort(id, newSort)}
                                            >
                                                {!categorySorting &&
                                                    items.map((badge) => {
                                                        if (viewType === 'grid') {
                                                            return (
                                                                <ItemGrid
                                                                    className={cnBadgesList('Item')}
                                                                    data-id={badge.badgeUid}
                                                                    data={badge}
                                                                    key={badge.badgeUid}
                                                                    countColumns={count}
                                                                />
                                                            );
                                                        }
                                                        return (
                                                            <ItemList
                                                                className={cnBadgesList('Item')}
                                                                data-id={badge.badgeUid}
                                                                data={badge}
                                                                key={badge.badgeUid}
                                                            />
                                                        );
                                                    })}
                                            </ItemsWrapper>
                                        </BadgeCategoryBox>
                                    );
                                })}
                        </ReactSortable>
                    </CategoriesWrapper>
                </InfinityList>
            </Box>
        </>
    );
};

export const Badges = BadgesPresenter;
