import { EditorState } from 'draft-js';
import { i18n } from 'localization';
import NewsView from 'News/containers/NewsView';
import TextRender from 'News/containers/TextRender';
import { withNewsContext } from 'News/contexts/news';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'uielements/src/Button/Button';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { formatDateSTD } from 'utils/src/utils.dates';
import { StageBar } from '../common/StageBar/StageBar';
import { ThanksSupport } from '../common/ThanksSupport/ThanksSupport';
import { TopBadge } from '../common/TopBadge/TopBadge';
import {
    ICreativetasksPropsType,
    ICreativetasksStateType,
    mapDispatchToProps,
    mapStateToProps,
    TStateProps,
} from './Creativetasks.index';
import './Creativetasks.scss';
import { DistributeForm } from './DistributeForm/DistributeForm';
import { DistributeFormContextProvider } from './DistributeForm/DistributeForm.context';
import { RewardAction } from './RewardAction/RewardAction';
import { SliderViewCreativetask } from './SliderViewCreativetask/SliderViewCreativetask';

export class CreativetasksPresenter extends React.PureComponent<
    ICreativetasksPropsType & TStateProps,
    ICreativetasksStateType
> {
    private stageContentRef = React.createRef<HTMLElement>();

    constructor(props: ICreativetasksPropsType & TStateProps) {
        super(props);
    }

    public componentDidMount = () => {
        this.initAddition();
    };

    public componentDidUpdate = (prev: ICreativetasksPropsType & TStateProps) => {
        this.initAddition();
    };

    public registerEndTaskNode = () => {
        const { id } = this.props;
        ComponentInjector.getInstance().addNode('news_additional_reply_form', <DistributeForm />, id);
        ComponentInjector.getInstance().addNode('news_additional_reply_action_' + id, <RewardAction />, id);
    };

    public unregisterEndTaskNode = () => {
        const { id } = this.props;
        ComponentInjector.getInstance().removeNode('news_additional_reply_form', id);
        ComponentInjector.getInstance().removeNode('news_additional_reply_action_' + id, id);
    };

    public componentWillUnmount = () => {
        this.unregisterEndTaskNode();
    };

    private initAddition = () => {
        const { creativeTask, id, isSingle, userData, user } = this.props;
        const { state } = creativeTask;
        const isUserAuthor = userData.baseData.isAdmin ? true : userData.baseData.id === user.id;

        // если новость открыта на отдельной странице и(&&) текущий юзер является админом или автором новости и(&&) конкурс ещё не закрыт
        if (isSingle && isUserAuthor && state === 'Going') {
            // добавляем форму завершения
            // добавляем действие для комментариев
            this.registerEndTaskNode();
        }
        // delete node only if state change is 'Canceled' or user isn't author
        // not check by isSingle because news can be in dialog and in timeline at the same time
        else if (!isUserAuthor || state === 'Canceled' || state === 'Finished') {
            // удаляем дополнительную форму, если не соотвествует условию
            // удаляем действие у комментария
            this.unregisterEndTaskNode();
        }
    };

    public render() {
        const { isSingle, creativeTask, text, id } = this.props;
        const { state, fund } = creativeTask;
        const endDateTime = new Date(creativeTask.endDateTime);
        const { currencyName, currencyNameGenitiveSingular, currencyNamePlural, isRepost } = this.props;

        const getFundName = (count: number): string => {
            const lastDigit = count % 10;
            const lastTwoDigits = count % 100;

            if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
                return currencyNamePlural;
            }

            switch (lastDigit) {
                case 1:
                    return currencyName;
                case 2:
                case 3:
                case 4:
                    return currencyNameGenitiveSingular;
                default:
                    return currencyNamePlural;
            }
        };

        return (
            <NewsView className={'type-6'}>
                <div className={'Creativetasks-Layout'}>
                    <TopBadge
                        imgClass={'TopBadge-ImgCreativetask'}
                        left={fund === 0 ? '' : i18n.t('pryaniky.post.creativetasks.fund')}
                        value={fund === 0 ? '' : fund}
                        right={getFundName(fund)}
                    />
                    <TextRender />
                </div>

                <StageBar
                    inRepost={isRepost}
                    ref={this.stageContentRef}
                    stage={<span>{i18n.t('creativetasks_' + state.toLowerCase())}</span>}
                    date={
                        <span>
                            {formatDateSTD(endDateTime, true) /*.toLocaleDateString('en-GB', { timeZone: 'UTC' })*/}
                        </span>
                    }
                    controls={
                        state === 'Going' && (
                            <>
                                {isRepost && (
                                    <Button theme="support" type={'rlink'} href={`/news/${id}`}>
                                        {'Участвовать'}
                                    </Button>
                                )}
                                <ThanksSupport
                                    newsId={id}
                                    anchor={this.stageContentRef}
                                    valueChange={this.changeFund}
                                />
                            </>
                        )
                    }
                />
            </NewsView>
        );
    }

    private changeFund = (value: number, set: boolean = false) => {
        const { userData, id, creativeTask } = this.props;
        const numberValue = !isNaN(Number(value)) ? Number(value) : 0;
        const thanksForAll = userData.extData.thanksForAll;
        if (thanksForAll < numberValue) return;
        this.props.pathNews({
            id,
            creativeTask: {
                ...creativeTask,
                fund: set ? numberValue : creativeTask.fund + numberValue,
            },
        });
    };
}

const CreativetasksPresenterWithDistribute: React.FC<React.ComponentProps<typeof CreativetasksPresenter>> = (props) => {
    const [text, setText] = React.useState(EditorState.createEmpty());

    const newsCommentsContextValue = React.useMemo(
        () => ({
            text,
            onChangeText: setText,
        }),
        [text, setText]
    );

    return (
        <DistributeFormContextProvider value={newsCommentsContextValue}>
            <CreativetasksPresenter {...props} />
        </DistributeFormContextProvider>
    );
};

export const CreativetasksStore = connect(mapStateToProps, mapDispatchToProps)(CreativetasksPresenterWithDistribute);

export const Creativetasks = withNewsContext<ICreativetasksPropsType>(CreativetasksStore);

export const initCreativetasks = () => {
    ComponentInjector.getInstance().addNode('news_types', <Creativetasks />, 'creativetasks');
    ComponentInjector.getInstance().addNode('slider_news_types', <SliderViewCreativetask />, 'creativetasks');
};
