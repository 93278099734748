import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
// import { mapDispatchToProps } from "../../redux/connector";
import { i18n, Translate } from 'localization';
import { SReplyMobileModel } from '../Replyes/Replyes.index';
import { Icon } from 'uielements/src/Icon/Icon';
import { cnNewsView, INewsViewProps, INewsViewState, mapStateToProps, mapDispatchToProps } from './NewsView.index';
import { Header } from './Header/NewsView-Header';
// import { Body } from './Body/NewsView-Body';
// import { Actions } from './Actions/NewsView-Actions';
// import { ServiceActions } from './ServiceActions/NewsView-ServiceActions';
// import { ServiceActions } from './ServiceActions/ServiceActions';
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
// import './NewsView.scss';
import { LinkLayout } from '../NewsTypes/common/LinkLayout/LinkLayout';
import { HeaderLayout } from '../NewsTypes/common/HeaderLayout/HeaderLayout';
import { CommentsLayout } from '../NewsTypes/common/CommentsLayout/CommentsLayout';
import { PostContentRender } from '../PostContentRender/PostContentRender';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { Button } from 'uielements/src/Button/Button';
import { Actions } from './Actions/Actions';
import { Like } from './Actions/Like/Like';
import { Thank } from './Actions/Thank/Thank';
import { ServiceActionsNews } from './ServiceActions/Actions';
import { createNewsTitle, } from '../NewsTypes/NewsUtils';
import { replyWithNewsContext as Reply } from '../Reply/Reply';
import { ReplyEditor } from '../ReplyEditor/ReplyEditor';
import { NewsContext, NewsContextProps } from './NewsView.context';
import { withRouter } from 'react-router-dom';
import { ReplySkeleton } from 'blocks/Skeletons/Replys/Reply-Skeleton';
import { Removed } from 'blocks/NewsTypes/Removed/Removed';
// import { NewsLikers } from 'blocks/Dialogs/News/Likers/NewsLikers';
import { classNamesDesign, PREFIX } from "utils/src/constants.prn";
// import { WindowEvents, WINDOW_EVTS, ANALYTICS, newsInViewport } from 'utils/src.project/events.prn';
import { _getCookie } from 'utils/src/debug';
import MDRender from 'uielements/src/CommonmarkRender'
import { formatDateSTD } from 'utils/src/utils.dates';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

export class _NewsViewPresenter extends React.Component<INewsViewProps, INewsViewState> {
  public el: React.RefObject<HTMLDivElement> | null = React.createRef<HTMLDivElement>();
  public bodyContentRendererRef: React.RefObject<React.ComponentType> | null = React.createRef<React.ComponentType>();
  private _viewed: boolean = false;

  public state = {
    thank: false,
    edit: false,
    liked: false,
    additionalReplyActions: undefined,
    additionalReplyForm: () => ''
  };

  public NewsContext: NewsContextProps = {
    textAreaRef: undefined,// React.createRef<HTMLInputElement>(),
    onChangeComment: (val: string) => void 0,
    commentFormValue: '',
    textAreaRefGetter: (ref: any) => {
      this.NewsContext.textAreaRef = ref
    },
    onReply: (val: string) => {
      if (this.NewsContext.textAreaRef) {
        this.NewsContext.textAreaRef.focus()
      }
    },
    isSingle: this.isSingle.bind(this),
    isRepost: () => false,
    getNews: this.getNews.bind(this)
  }

  private actions: ServiceActionsNews;

  public onDelete = () => {
    //
  };

  // public setReaded = () => this.el && this.el.current && !(this.props.data as any)._viewed && utils.elemInViewport(this.el.current) && this.props.readNews(this.props.data.id);

  public componentDidMount() {
    // if (this.el && this.el.current && ANALYTICS.social) {
      // this.setReaded();
      // document.addEventListener('scroll', this.setReaded);
    // }
  }

  public render() {
    const { additionalReplyActions, additionalReplyForm, edit, liked: likedState } = this.state;
    const {
      likeCount, thanksCount, liked, newstype, badge, id, newsTypeMain, header, user, group, date, actions, changeDate,
      commentsCount, attachments, creativeTask, poll, isNeedApprove, isShowOnTop, thanks, allowReply, linkPreviewImgUrl,
      systemNewsActionHtml, allowThanksReply
    } = this.props.data;
    const badgeId: string | undefined = newstype === 0 ? `${badge?.id}` : undefined;
    const { currentUser, className = '', enabledThanks } = this.props;
    const comments = this.props.data.comments; // toSReplyMobileModel(this.state.data.comments);
    const isAuthor = currentUser.id === user.id
    const isCompetitionsAuthor = (utils.newNewsType(newstype) === 'competitions') && isAuthor;

    let allowThank = allowThanksReply;//true;// (((!isAuthor && (utils.newNewsType(newstype) !== 'thanks')) || ((utils.newNewsType(newstype) === 'thanks') && !(thanks.participants.length === 1 && !!(thanks.participants[0].id === currentUser.id)))) && allowReply)
    allowThank = !allowReply ? false : allowThank
    if (newstype === -1) return (
      <div
        id={id}
        className={cnNewsView({}, [className])}>
        <Removed />
      </div>
    )

    const dateStr = changeDate !== date ? <><Translate i18nKey='edited' />: {formatDateSTD(changeDate || date, true)}</> : formatDateSTD(date, true);

    return (
      <div
        ref={this.el}
        id={id}
        data-news-id={id}
        className={cnNewsView({}, [className])}>
        <NewsContext.Provider value={this.NewsContext}>
          <div className={'NewsLayout'}>
            <HeaderLayout
              type={'post'}
              left={
                user.isDeleted ?
                  <Avatar
                    responsive
                    // imgUrl={user.imgUrl}
                    // imgId={user.imgId}
                    name={user.displayName}
                    size={56} /> :
                  <Button theme='unstyled' className={cnNewsView('UserImg')} type='rlink' href={'/user/' + user.id} >
                    <Avatar
                      responsive
                      imgUrl={user.imgUrl}
                      imgId={user.imgId}
                      name={user.displayName}
                      size={56} />
                  </Button>
              }
              right={
                <>
                  {actions.includes('addToFavorites.off') && <Button className={'HeaderLayout-ActionButton'} disabled={true}><Icon icon='star' className={cnNewsView('isFavorite')} /></Button>}
                  {isNeedApprove && <Button className={'HeaderLayout-ActionButton'} disabled={true}><Icon icon='eye-crossed' className={cnNewsView('isNeedApprove')} /></Button>}
                  {isShowOnTop && <Button className={'HeaderLayout-ActionButton'} disabled={true}><Icon icon='bullhorn' className={cnNewsView('isShowOnTop')} /></Button>}
                  {/* <ServiceActions news={this.props.data} announcement={isShowOnTop} onClick={this.postAction} actions={actions} id={id} /> */}
                </>
              }
              bottom={dateStr}
            >
              {
                user.isDeleted
                ? <span>{user.displayName}</span> 
                : <UserMention id={user.id}>
                  <Link className={`UserName ${classNamesDesign.primaryColor3.text}`} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>
                    {user.displayName}
                  </Link>
                </UserMention>
              }
              {' '}
              {/* {newstype !== 17 && <span className={'Markdown'}>{headerFormater(user.sex, newstype, poll, id)}</span>} */}
              {/* <PostContentRender className={'Markdown'} data={createNewsTitle(this.props.data)} /> */}
              <MDRender className={'Markdown'} type={'inline'} source={createNewsTitle(this.props.data)} />
              {group && group.pkid !== -1 ? (
                <span>
                  <Icon icon="caret-right" />
                  <Button type="rlink" className={'GrupLink'} href={'/group/' + this.props.data.group?.pkid} theme="unstyled">
                    {' ' + group.name}
                  </Button>
                </span>
              ) : (
                  undefined
                )}
            </HeaderLayout>

            {/* {edit
              ? <NewsEdit type={utils.newNewsType(newstype)} post={this.props.data} disableEdit={this.disableEdit} editeComplite={this.editeComplite} />
              : <React.Fragment>
                <Body
                  data={this.props.data}
                  type={utils.newNewsType(newstype)}
                  addReplyForm={this.addReplyForm}
                  addReplyActions={this.addReplyActions} />

                <LinkLayout {...{ linkPreviewImgUrl, systemNewsActionHtml, badgeId }} />

              </React.Fragment>
            } */}

            {!edit && <Preview className={cnNewsView('Preview') + ' horizontalPadding'} newsid={id} files={attachments ? attachments : []} />}

            {!edit && <Attachments className={cnNewsView('Attachments') + ' horizontalPadding'} files={attachments ? attachments : []} onDelete={this.onDelete} state='view' />}

            <Actions>
              {/* <Like  {...{ likeCount, liked }} buttonProps={{ 'data-liked': liked, }} onClick={this.changeLike} open={this.actionLikedByOn} className={'Action'} /> */}
              {/* {
                likeCount > 0 &&
                <NewsLikers
                  nId={id}
                  data={{}}
                  isShown={likedState}
                  onClose={this.actionLikedByOff}
                />
              } */}
              {enabledThanks && allowThank && <Thank onClick={() => this.toggleThank(true)} />}
            </Actions>
          </div>
          <CommentsLayout
            more={
              (comments && (comments.length < commentsCount)) && (
                <Button className={('MoreButton')} theme={'unstyled_center'} onClick={this.loadAllReplyes}>
                  <Translate i18nKey='pryaniky.post.comments.reveal' count={commentsCount - comments.length} values={{ count: commentsCount - comments.length }} />
                </Button>)
            }
            form={
              (window.SERVICE__ALLOW_REPLY ? true : allowReply) && <ReplyEditor
                // key={newComment.id}
                // newComment={newComment}
                newsAuthor={utils.newNewsType(newstype) === 'thanks' ? user : {}}
                toggleThank={this.toggleThank}
                isThanks={this.state.thank}
                users={utils.newNewsType(newstype) !== 'thanks' ? [user] : thanks?.participants}
                newsId={id}
              />
            }
          >
            {comments && comments.map((commentId: string, i: number) => commentId === 'skelet' ? <ReplySkeleton key={i} /> : <Reply
              additionalReplyActions={additionalReplyActions}
              fund={isCompetitionsAuthor ? creativeTask?.fund : 0}
              className=''
              setRewardComment={utils.noop}
              deleteRewardComment={utils.noop}
              allowReply={allowReply}
              rewards={false}
              comment={this.props.data.normalComments[commentId]}
              key={commentId}
              newsID={id} />)}
          </CommentsLayout>

          {
            additionalReplyForm && additionalReplyForm()
          }

          {this.props.children}
        </NewsContext.Provider>
      </div>
    )
  }

  private actionLikedBy = () => {
    utilsDep.modal({
      header: i18n.t('pryaniky.modal.likers.title'),
      type: 'likers',
      data: {
        nId: this.props.data.id
      },
      noFooter: true,
    })
  }

  private actionLikedByOn = () => this.setState({ liked: true });
  private actionLikedByOff = () => this.setState({ liked: false });

  private loadAllReplyes = () => {
    // this.props.loadCommentRequest(this.props.data.id, this.props.data.commentsCount - this.props.data.comments.length)
  }

  private postAction = (action: string) => {
    /*if (!this.actions)*/ this.actions = new ServiceActionsNews({
      id: this.props.data.id,
      enableEdit: this.enableEdit,
      post: this.props.data,
      actions: this.props.data.actions,
      allowReply: false,
      isNeedApprove: false,
      removeNews: this.removeNews,
      hideNews: this.hideNews,
      newData: this.updatePost,
      pathname: this.props.location.pathname,
    });
    this.actions.callAction(action);
  }

  private removeNews = () => {
    // this.props.deleteNewsRequest(this.props.data.id)
  }

  /**
   * remove news from front data model
   */
  private hideNews = () => {
    // this.props.deleteNews({newsId: this.props.data.id})
  }

  private changeLike = (likeCount: number, liked: boolean) => {
    // this.props.likeNewsRequest({ id: this.props.data.id, likeCount, liked })
  }

  private addReplyForm = (additionalReplyForm: () => React.ReactNode) => {
    this.setState({ additionalReplyForm });
  }

  private addReplyActions = (additionalReplyActions: (replyId: string, user: any) => React.ReactNode) => {
    this.setState({ additionalReplyActions });
  }

  private editeComplite = (post: any) => {
    this.disableEdit();
  }

  private disableEdit = () => {
    this.setState({ edit: false });
  }

  private enableEdit = () => {
    this.setState({ edit: true });
  }

  private toggleThank = (thank: boolean) => {
    if (thank !== this.state.thank) {
      this.setState({ thank }, () => {
        // this.NewsContext.textAreaRef.current && (thank && this.NewsContext.textAreaRef.current.focus())
      })
    }
  }


  private updatePost = (newPost: { [s: string]: any }) => {
    // this.props.updateNews(newPost)
    // this.setState({ data: newPost });
  };

  private getNews() {
    return this.props.data
  }

  private isSingle() {
    return this.props.match.params.id && this.props.match.params.id === this.props.newsId;
  }

}


export const _NewsView = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(_NewsViewPresenter))
