import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ICommonFiltersProps, IFilterContext, TFilterData, TFilterOptsData } from './CommonFilters.index';
import { Translate } from 'localization';
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm';
import { TOption } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/ListParamsSelector.index';
import queryString from 'query-string';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { withRouter } from 'react-router';
import { FiltersConteiner, MainConteiner, ResetBtn } from './CommonFilters.style';
import { useListCardResize } from 'muicomponents/src/InfinityList/hook';

const createDate = (filterData: TFilterData[], typeStr: string, titleStr: string, additionalFields: string[]) => {
    return filterData.map((fl) => {
        return {
            ...fl,
            opts: fl.opts.map((el) => {
                const currentAdditionalFields: { [field: string]: any } = {};
                additionalFields.forEach((field) => {
                    currentAdditionalFields[field] = el[field];
                });
                return {
                    id: el.id,
                    type: el.type || typeStr,
                    title: Translate.t({ i18nKey: `${titleStr}.${el.title}` }),
                    value: el.value,
                    ...currentAdditionalFields,
                };
            }),
        };
    });
};

function getActive(
    context: IFilterContext,
    data: TFilterData[],
    defaulIds: {
        [k: string]: string;
    },
    idx?: number
): any {
    if (!data) return undefined;
    if (idx !== undefined && data[0]?.opts && Array.isArray(data[0].opts))
        return data.reduce((acc: any[], cf) => {
            return [
                ...acc,
                cf.opts.reduce((acc: string | undefined, cur: any, idx: number, arr: any[]) => {
                    if (context[cur.type] && context[cur.type] === cur.value) {
                        return cur.id;
                    }
                    if (idx === arr.length - 1 && !acc && defaulIds[cur.type] !== undefined) {
                        return defaulIds[cur.type];
                    }
                    return acc;
                }, undefined),
            ];
        }, [])[idx];
    return data.reduce((acc: string, { opts: cur }: any, idx, arr) => {
        if (context[cur.type] && context[cur.type] === cur.value) {
            return cur.id;
        }
        if (idx === arr.length - 1 && !acc && defaulIds[cur.type] !== undefined) {
            return defaulIds[cur.type];
        }
        return acc;
    }, '');
}

const CommonFiltersPresented = ({
    changeContextv3,
    context,
    history,
    additionalFields,
    withResetBtn,
    getDataFromContext,
    getCategories,
    itemsView,
    typeStr,
    titleStr,
    filterData: f,
    headerData: h,
    defaulValues,
    defaulIds,
    relations,
    className,
    wrapProps,
    setCountFilterItems,
}: ICommonFiltersProps) => {
    const [data, setData] = useState<TFilterData[]>(createDate(f, typeStr, titleStr, additionalFields || []));

    useEffect(() => {
        getCategories && getCategories(setData);
    }, []);

    const filterState = useMemo<{
        [idx: number]: {
            active: string;
            filterOpts: TFilterOptsData[] | null;
        };
    }>(() => {
        return data.reduce((filter, fl, idx) => {
            const active = getActive(context, data, defaulIds, idx);
            if ((additionalFields || []).includes('showProps') && getDataFromContext) {
                let newFl: TFilterOptsData[] | null = fl.opts.filter((i) =>
                    Object.keys(i.showProps || {}).reduce((a, k, idx) => {
                        if (!a) return a;
                        if (typeof i.showProps[k] === 'function') return i.showProps[k](getDataFromContext(k));
                        if (i.showProps[k] !== itemsView[k as keyof typeof itemsView]) return false;
                        return a;
                    }, true as boolean)
                );
                if (active && !newFl.map((e) => e.id).includes(active) && !newFl.map((e) => e.value).includes(active)) {
                    const defObj = newFl.reduce(
                        (a: any, c) => a || ((defaulValues as any)[c.type] === c.value ? c : a),
                        undefined
                    );
                    if (defObj) handleChange(defObj);
                }
                if (!newFl.length) newFl = null;

                return {
                    ...filter,
                    [idx]: {
                        active,
                        filterOpts: newFl,
                    },
                };
            }
            return {
                ...filter,
                [idx]: {
                    active,
                    filterOpts: fl.opts,
                },
            };
        }, {});
    }, [context, data, defaulIds]);

    const handleChange = (selected: TFilterOptsData) => {
        const same = context[selected.type] && context[selected.type] === selected.value;
        const types = selected.type.split('/');
        const values = (selected.value || '').toString().split('/');
        const isMulti = types.length > 1;
        const value: { [key: string]: any } = isMulti
            ? types.reduce(
                  (a: any, c: any, idx: number) => ({
                      ...a,
                      [c]: !same ? values[idx] || defaulValues[c] || undefined : undefined,
                  }),
                  {
                      [selected.type]: !same
                          ? selected.value
                          : defaulValues[selected.type] !== undefined
                          ? defaulValues[selected.type]
                          : undefined,
                      ...(selected.clear || []).reduce(
                          (acc: any, key: string) => ({ ...acc, [key]: undefined }),
                          {} as Record<string, any>
                      ),
                  } as Record<string, string>
              )
            : {
                  [selected.type]: !same
                      ? selected.value
                      : defaulValues[selected.type] !== undefined
                      ? defaulValues[selected.type]
                      : undefined,
                  ...(selected.clear || []).reduce(
                      (acc: any, key: string) => ({ ...acc, [key]: undefined }),
                      {} as Record<string, any>
                  ),
              };
        history.push({
            search: `?${queryString.stringify(value)}`,
        });
        changeContextv3(
            (relations || ['common']).reduce((acc: any, conID) => {
                acc[conID] = value;
                return acc;
            }, {} as { [s: string]: any })
        );
    };

    const createHandleChange = (idx: number) => {
        return (v: string) => {
            const selected: TFilterOptsData = data[idx].opts.find((item) => item.id === v) || data[idx].opts[0];
            handleChange(selected);
        };
    };

    const createOptions = (idx: number): TOption[] => {
        return (filterState[idx].filterOpts || []).map((item) => {
            return {
                value: item.id,
                label: item.title,
            };
        });
    };

    const handleReset = () => {
        const clearValue: { [s: string]: any } = {};
        data.reduce((allType, filter) => {
            return [...allType, ...filter.opts.map((item) => item.type)];
        }, [] as string[]).forEach((type) => {
            clearValue[type] = undefined;
        });

        history.push({
            search: undefined,
        });
        changeContextv3(
            (relations || ['common']).reduce((acc: any, conID) => {
                acc[conID] = clearValue;
                return acc;
            }, {} as { [s: string]: any })
        );
    };

    const mainConteinerRef = useRef<HTMLElement>(null);
    const { count: countColumns, ref: filtersConteinerRef } = useListCardResize(4, 250);

    useEffect(() => {
        if (filtersConteinerRef.current)
            setCountFilterItems && setCountFilterItems(filtersConteinerRef.current.children.length);
    }, [filterState, countColumns]);

    return (
        <MainConteiner
            ref={mainConteinerRef}
            sx={
                (mainConteinerRef.current?.clientWidth || 1240) < 360
                    ? {
                          flexDirection: 'column',
                          alignItems: 'end',
                      }
                    : undefined
            }
            className={className}
        >
            <FiltersConteiner
                {...wrapProps}
                ref={filtersConteinerRef}
                sx={{
                    ...(mainConteinerRef.current?.clientWidth && mainConteinerRef.current?.clientWidth <= 500
                        ? {
                              flexDirection: 'column',
                              alignItems: 'stretch',
                          }
                        : {}),
                    ...(mainConteinerRef.current &&
                    (filtersConteinerRef.current?.children.length || 0) * 200 >
                        (mainConteinerRef.current?.clientWidth || 1240) &&
                    mainConteinerRef.current.clientWidth > 500
                        ? {
                              flexWrap: 'wrap',
                              ['& > *']: {
                                  width: `calc( (100% - ${8 * (countColumns - 1)}px) / ${countColumns} )`,
                                  flexGrow: 0,
                                  minWidth: '200px',
                              },
                          }
                        : {}),
                    ...(wrapProps?.sx || {}),
                }}
            >
                {data.map((fl, idx) => {
                    const type = fl.opts[0] && fl.opts[0].type;
                    const options = createOptions(idx);
                    if (!options.length) return null;
                    return (
                        <CheckButtonSelector
                            icon={fl.icon}
                            value={filterState[idx].active}
                            onChange={createHandleChange(idx)}
                            options={options}
                            defaultText={type && h[type] && <Translate i18nKey={h[type]} />}
                            ActionButtonProps={{
                                color: 'inherit',
                                sx: {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '8px',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                },
                            }}
                        />
                    );
                })}
            </FiltersConteiner>
            {withResetBtn && (
                <ResetBtn
                    disabled={Object.keys(filterState).reduce((acc: boolean, key) => {
                        return acc && defaulIds[data[+key].opts[0].type] === filterState[+key].active;
                    }, true)}
                    onClick={handleReset}
                    startIcon={<CleaningServicesIcon />}
                >
                    {Translate.t({ i18nKey: 'reset' })}
                </ResetBtn>
            )}
        </MainConteiner>
    );
};

export const CommonFilters = withRouter(CommonFiltersPresented);
