import { Translate } from 'localization';
import { Box, Typography } from 'muicomponents/src';
import { People as PeopleIcon } from 'muicomponents/src/Icons';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import React, { ComponentProps, FC } from 'react';
import { useSelector } from 'react-redux';
import { getCurrencyNameByCount } from 'utils/src/CommonRedux/base/selectors';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn';
import BaseRequests from 'utils/src/requests/requests.base';
import { NBadgesItem } from '../Badges.index';
import { BadgeItemValue } from '../Badges.styled';
import {
  GridItemAvatar,
  GridItemBox,
  GridItemButton,
  GridItemContent,
  GridItemDescription,
  GridItemTitle,
  GridItemUserCount,
} from './ItemGrid.styled';

export const ItemGrid: FC<NBadgesItem.Props & ComponentProps<typeof GridItemBox>> = ({
    data,
    countColumns,
    ...props
}) => {
    const currencyName = useSelector(
      getCurrencyNameByCount(data?.issueCurrency[0]?.key)
    );

    return (
        <GridItemBox {...props} countColumns={countColumns}>
            <GridItemButton href={`/badge/${data.badgeUid}`}>
                <Box sx={{ position: 'relative' }}>
                    <GridItemAvatar src={data.iconUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
                    {Boolean(data.totalCount) && (
                        <GridItemUserCount className={'primaryColor3-borderColor'}>
                            <PeopleIcon fontSize="small" />
                            {data.totalCount}
                        </GridItemUserCount>
                    )}
                </Box>
            </GridItemButton>

            <GridItemContent>
                <GridItemTitle href={`/badge/${data.badgeUid}`}>
                    <Tooltip title={data?.name?.length > 20 ? data.name : ''}>
                        <span>{data.name}</span>
                    </Tooltip>
                </GridItemTitle>
                <GridItemDescription>
                    <Tooltip title={data?.description?.length > 45 ? data.description : ''}>
                        <span>{data.description}</span>
                    </Tooltip>
                </GridItemDescription>
                {data?.issueCurrency[0]?.key && (
                    <Typography fontSize={14}>
                        <Translate i18nKey={'pryaniky.badge.item.earned'} />{' '}
                        <BadgeItemValue>
                            {data?.issueCurrency[0]?.key} {currencyName}
                        </BadgeItemValue>
                    </Typography>
                )}
            </GridItemContent>
        </GridItemBox>
    );
};
