import { styled } from 'muicomponents/src';
import { cnTableListItemCell } from '../Item/TableListItem.index';
import { ItemBox, ItemGridCell } from '../TableList.styled';

export const StyledItemBox = styled(ItemBox)({
    height: 56,
    [`& .${cnTableListItemCell('Actions')}`]: {
        flexBasis: 80,
        minWidth: 80,
    },
}) as typeof ItemBox;

export const HeaderListCell = styled(ItemGridCell)({
    fontWeight: 500,
}) as typeof ItemGridCell;
