import React, { ComponentProps } from 'react';
import { Typography, styled } from 'muicomponents/src';
import CheckIcon from '@mui/icons-material/Check';
import { cn } from '@bem-react/classname';
import { Translate } from 'localization';

const cnCheckMarkPreorderMade = cn('CheckMarkPreorderMade');

const TypographyStyled = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
}) as typeof Typography;

type ICheckMarkPreorderMadeProps = {
    typographyProps?: ComponentProps<typeof TypographyStyled>;
    iconProps?: ComponentProps<typeof CheckIcon>;
    className?: string;
    textTitle?: string;
};

export const CheckMarkPreorderMade = ({
    className,
    iconProps,
    typographyProps,
    textTitle,
}: ICheckMarkPreorderMadeProps) => {
    return (
        <TypographyStyled variant="caption" {...typographyProps} className={cnCheckMarkPreorderMade('', [className])}>
            <CheckIcon color="success" fontSize="small" {...iconProps} />
            {textTitle || <Translate i18nKey="pryaniky.shop.checkMarkPreorderMade" />}
        </TypographyStyled>
    );
};
