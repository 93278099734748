import { cn } from '@bem-react/classname';
import { Box, IconButton, styled } from 'muicomponents/src';

export const cnOpenUsersList = cn('OpenUsersList');

export const ContentBox = styled(Box)({
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
}) as typeof Box;

export const ItemBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '12px',
    padding: '8px',
    flexWrap: 'wrap',
    ['&:not(:last-child)']: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
}) as typeof Box;

export const MainInfoBox = styled(Box)({
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
}) as typeof Box;

export const ContactInfoBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '30%',
}) as typeof Box;

export const CopyIconButton = styled(IconButton)({
    marginRight: '-18px',
}) as typeof IconButton;
