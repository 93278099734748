import React, { FC } from "react";
import { createPortal } from 'react-dom';
import { BadgesListEditTypes, badgesMenuId } from "./Badges.constants";
// пока старые кнопки, да, для того что бы не було по дизайну различий
import { Button } from 'uielements/src/Button/Button';
import { Tooltip } from "muicomponents/src/Tooltip";
import { Translate } from "localization";
import { Check, Close, Dns, Sort } from "muicomponents/src/Icons";
import { styled } from "@mui/material";
import { Box } from "muicomponents/src";
import { styled as muiStyled } from "muicomponents/src/mui/system";

type BadgesMenuProps = {
    edit: boolean,
    editType: BadgesListEditTypes,
    startEdit: () => void;
    endEdit: (save?: boolean) => void;
    toggleEditType: (editType: BadgesMenuProps['editType']) => void;
}

const StyledBox = muiStyled(Box)({
    display: 'flex'
}) as typeof Box;

const StyledButton = styled(Button)({
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8px !important',
    paddingBottom: '7px !important'
}) as typeof Button;

export const BadgesMenu: FC<BadgesMenuProps> = ({
    edit,
    startEdit,
    endEdit,
    editType,
    toggleEditType
}) => {

    const root = document.getElementById(badgesMenuId);




    if (!root) return null;

    return createPortal(
        <StyledBox>
            {
                edit
                    ? <>
                        <Tooltip
                            key={'sorttype'}
                            title={
                                <Translate i18nKey={'pryaniky.badges.list.action.sorttype'}
                                    values={{
                                        action: editType === BadgesListEditTypes.categories
                                            ? Translate.t({ i18nKey: "action.off" })
                                            : Translate.t({ i18nKey: "action.on" })
                                    }}
                                />}
                        >
                            <span>
                                <StyledButton
                                    main={editType === BadgesListEditTypes.categories}
                                    onClick={() => {
                                        toggleEditType(
                                            editType === BadgesListEditTypes.categories
                                                ? BadgesListEditTypes.badges
                                                : BadgesListEditTypes.categories
                                        );
                                    }}
                                    children={<Dns fontSize="small" />}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip
                            key={'save'}
                            title={<Translate i18nKey={'apply'} />}
                        >
                            <span>
                                <StyledButton
                                    onClick={() => endEdit(true)}
                                    children={<Check fontSize="small" />}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip
                            key={'cancel'}
                            title={<Translate i18nKey={'cancel'} />}
                        >
                            <span>
                                <StyledButton
                                    onClick={() => endEdit(false)}
                                    children={<Close fontSize="small" />}
                                />
                            </span>
                        </Tooltip>
                    </>
                    : <Tooltip key={'sort'} title={<Translate i18nKey={'pryaniky.badges.list.action.sort'} />}>
                        <span>
                            <StyledButton
                                onClick={startEdit}
                                children={<Sort fontSize="small" />}
                            />
                        </span>
                    </Tooltip>
            }
        </StyledBox >,
        root
    );
};