import { useEffect, useState } from 'react';
import { convertUserShortToSelectableObject, convertUserToShortUser, SelectableObject } from 'utils';
import { byId } from 'utils/src/requests/requests.users';

interface usePreListFiltersProps {
    authorId: string;
}

export const usePreListFilters = ({ authorId }: usePreListFiltersProps) => {
    const [authorInfo, setAuthorInfo] = useState<undefined | SelectableObject>(undefined);
    const [authorLoading, setAuthorLoading] = useState(false);

    async function fetchAuthorInfo() {
        if (!authorId) {
            return setAuthorInfo(undefined);
        }

        try {
            setAuthorLoading(true);
            const authorFull = await byId(authorId);
            const { data } = await authorFull.r;

            const authorShort = convertUserShortToSelectableObject(convertUserToShortUser(data));

            setAuthorInfo(authorShort);
        } catch (error) {
            console.error('Error fetching author info:', error);
            setAuthorInfo(undefined);
        } finally {
            setAuthorLoading(false);
        }
    }

    useEffect(() => {
        fetchAuthorInfo();
    }, [authorId]);

    return { authorInfo, authorLoading };
};
