import React, { FC, useEffect, memo, useState, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    Kanban
} from './interfaces'
import { connect, useSelector } from 'react-redux'
import './style.scss'
import { Button } from 'uielements/src'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import ShortView from '../ShortView'
import MDBuilder from 'uielements/src/CommonmarkRender/MDNodeBulder'

import Board from 'react-trello'
import { Card } from './Card/Card'
import openNewsDialog from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import { LeftDirectionTransitionComponent, TDialogProps } from 'utils/src/DialogCreator';
// import { editNewsModal } from 'blocks/Workflows/Dialogs/Edit/Edit';
import { changeStatusModal } from 'blocks/Workflows/Dialogs/ChangeStatus/ChangeStatus';
import { useIsLoading } from 'utils/src/CommonRedux/LoadIndicator'
import actions from '../../redux/actionsTypes/News';
import { CircularProgress } from 'muicomponents/src'
import { omit } from 'lodash'
import { IStateType } from 'redux/store'
import { getActived, isActived } from 'utils/src/CommonRedux/LoadIndicator/redux/saga/selectors'

type IPresenterProps = Kanban.KanbanTimeline.IPresenterProps
type IOwnProps = Kanban.KanbanTimeline.IOwnProps
type IDispatchProps = Kanban.KanbanTimeline.IDispatchProps
type IStateProps = Kanban.KanbanTimeline.IStateProps
type IState = Kanban.KanbanTimeline.IState

// const LoadButton = withLoadSpin(Button)
const newsDialogParams: TDialogProps = {
    maxWidth: 'md',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
        }
    },
    TransitionComponent: LeftDirectionTransitionComponent,
    className: 'Pane BackdropOff Right',
    scroll: 'body'
};

let enableVirtual = window.location.hostname.includes('azurewebsites.net')
enableVirtual = enableVirtual ? enableVirtual : window.location.hostname.includes('localhost')
enableVirtual = false

let isDragStart = false;

const defaultColor = '#d3d9e0';
const allowedColor = '#d3e0d5';
const forbiddenColor = '#e0d3d3';

export const Presenter: FC<IPresenterProps> = ({
    className,
    // loadNewsList,
    loadKanbanNewsList,
    loadWorkflowStatuses,
    pathNews,
    getUserStatuses,
    changeStatus,
    getStatusFields,
    keys,
    values,
    params = { newsTypes: 'all' },
    statuses,
}) => {

    const statusesRef = useRef(statuses);
    statusesRef.current = statuses;

    const [currentDragNews, setCurrentDragNews] = useState<string | undefined>(undefined);
    const [lastDragNews, setLastDragNews] = useState<string | undefined>(undefined);

    const workflowId = params.newsTypes!;
    const currentNewsStatusesExists = currentDragNews && values[currentDragNews].userStatuses;

    const getLaneLoaderId = (id: string) => actions.LOAD_KANBAN_NEWS + '-' + id;

    const isLoading = useIsLoading([actions.LOAD_KANBAN_NEWS + '-' + queryString.stringify(omit(params, 'count', 'status'))]);

    useEffect(() => {
        loadWorkflowStatuses({ workflowId })
        loadKanbanNewsList({ params, count: 10, skipCount: 0 })
    }, [queryString.stringify(params)]);

    const loadNextColumnPage = (skipCount: number, statusId: string) => {
        loadKanbanNewsList({ params: { ...params, status: statusId }, count: 10, skipCount, loaderKey: statusId })
    };

    const kanbanLanePages = useRef<{[key: string]: number}>({});

    const data = statuses?.map(status => {
        const disabled = (currentNewsStatusesExists) ? values[currentDragNews!].userStatuses?.find((el) => el.id === status.id)?.isDisabled : true;
        return {
            ...status,
            disabled,
            style: { backgroundColor: !currentNewsStatusesExists ? defaultColor : disabled ? forbiddenColor : allowedColor },
            laneId: status.id,
            currentPage: kanbanLanePages.current[status.id] || 1,
            cards: keys
                .filter(key => values[key].additionalFields?.currentStatus?.id === status.id)
                .map(key => {
                    const item = values[key];
                    return {
                        ...item,
                        draggable: item.additionalFields?.currentStatus.isAllowChangeResponsibleUser,
                        laneId: status.id,
                        title: item.header,
                        description: new MDBuilder(item.text || '').getPlainText().join(' '),
                        label: '',
                    }
                })
        }
    })

    const onShowChangeFields = (newsId: string, newStatus: { [s: string]: any }, oldStatus: { [s: string]: any },) => {
        // Изначальная установка нового статуса для dnd
        pathNews({ id: newsId, additionalFields: { ...values[newsId].additionalFields, currentStatus: newStatus } });
        changeStatusModal({
            newsId,
            statusId: newStatus.id,
            data: undefined,
            loading: false,
            status: undefined,
            onChangeFields: (statusFields: any) => pathNews({ id: newsId, statusFields })
        }).then((data) => {
            changeStatus({ newsId, statusId: newStatus.id, data });
        }).catch(() => {
            pathNews({ id: newsId, additionalFields: { ...values[newsId].additionalFields, currentStatus: oldStatus } });
        }).finally(() => {
            pathNews({ id: newsId, statusFields: undefined });
        });
    }

    const onSelectStatus = (newsId: string, oldStatusId: string, newStatusId: string, data: any) => {
        const news = values[newsId]
        const newStatus = news.userStatuses?.find((status) => status.id === newStatusId);
        if (!newStatus) return;

        if (newStatus.hasFields) {
            onShowChangeFields(newsId, newStatus, news.additionalFields?.currentStatus);
            getStatusFields({ newsId, workflowId, statusId: newStatusId });
        } else {
            changeStatus({ newsId, statusId: newStatusId })
        }

    }


    // Board props
    const boardData: any = { lanes: data || [] }

    const components = {
        Card,
    }

    const handleDragStart = (newsId: string) => {
        if (!isDragStart) {
            getUserStatuses({ newsId, workflowId });
            isDragStart = true;
            setLastDragNews(newsId);
            setCurrentDragNews(newsId);
        }
    }

    const onCardMoveAcrossLanes = (sourceStatusId: string, targetStatusId: string, newsId: string) => {
        const targetStatus = data.find((el: any) => el.id === targetStatusId);
        if ((sourceStatusId !== targetStatusId) && (!targetStatus?.disabled) && currentNewsStatusesExists)
            onSelectStatus(newsId, sourceStatusId, targetStatusId, data);
        onDrop(newsId);
    }

    const onDrop = (newsId: string) => {
        isDragStart = false;
        pathNews({ id: newsId, userStatuses: undefined });
        setCurrentDragNews(undefined);
    }

    const onShowNews = (newsId: string) => openNewsDialog({ newsId }, newsDialogParams)//editNewsModal({ newsId })

    const activedLoaders = useSelector(getActived);

    const loadedHandlerDict = useRef<{[key: string]: () => void}>({});

    useEffect(() => {
        statusesRef.current?.forEach(status => {
            const loaderId = getLaneLoaderId(status.id);
            if(loadedHandlerDict.current[loaderId]){
                loadedHandlerDict.current[loaderId]();
                delete loadedHandlerDict.current[loaderId];
            }
        })
    }, [activedLoaders]);

    const nextLanePage = (pageNumber: number, columnId: string) => {
        const column = data.find(el => el.id === columnId);
        if(!column) return;
        const skipCount = column.cards.length;
        kanbanLanePages.current[columnId] = pageNumber;
        loadNextColumnPage(skipCount, columnId);
        return new Promise((res, rej) => {
            loadedHandlerDict.current[getLaneLoaderId(columnId)] = () => {
                res([]);
            }
        })
    }

    return <div className={cnClassName({}, [className])}>
        {
            isLoading &&
            <div className={cnClassName('Loader')}>
                <CircularProgress />
            </div>
        }
        {
            data?.length ?
                <Board
                    cardDragClass="draggingCard"
                    hideCardDeleteIcon
                    data={boardData}
                    components={components}
                    handleDragStart={handleDragStart}
                    onCardMoveAcrossLanes={onCardMoveAcrossLanes}
                    onCardClick={onShowNews}
                    laneDraggable={false}
                    cardDraggable={true}
                    onMouseUp={() => setTimeout(() => onDrop(lastDragNews || ''), 200)}
                    onLaneScroll={nextLanePage}
                /> :
                null
        }
    </div>

}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
