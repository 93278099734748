import * as React from 'react';
// import * as utils from 'utils/src/utils';
// import { connect } from 'react-redux';
import { cnUsersWidget, cnUsersWidgetCollegues, IUsersWidgetTypeListProps, } from './UsersWidget_type_list.index';
import { UsersWidgetPresenter } from '../UsersWidget';
import { Avatar } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender'
import { NodeBulder } from 'uielements/src/CommonmarkRender';
import PryanikyEditor, { StyleButton } from 'uielements/src/PryanikyEditorV1'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { allDisabledPreset } from 'uielements/src/PryanikyEditorV1/modulesMap';
import './UsersWidget_type_list.scss';
import { CustomSettings } from 'utils/src';
import { AccessTime, Edit } from 'muicomponents/src/Icons'
import { Box, Button as MuiButton, IconButton, Typography } from 'muicomponents/src'
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { UserListWhithDescription } from './UserListWithDescription/UserListWithDescription';

const useMoexDesign = CustomSettings.useMoexDesign();

export class UsersListWidgetPresenter extends UsersWidgetPresenter {

  constructor(props: IUsersWidgetTypeListProps) {
    super(props);
    this.state = {
      items: [],
      title: ''
    }
  }

  public getData = () => {
    // const { settings } = this.props;
    // const { list } = settings;

    // https://rftest.app.pryaniky.com/Img/UserProfiles/Preview/96m96/b6bf4311-15cd-4feb-84f8-288acd841895.png?v=313f304c-05f5-49a2-b140-b23591b6920a
    // utils.API.users.subscriptions(uId)
    // .r
    // .then((d: any) => {
    //   if (!this.mounted) return;
    //   if(utils.checkResponseStatus(d)) {
    //     const items = d.data.map((user: {[s: string]: any}) => {
    //       user.title = user.displayName;
    //       user.url = '/user/' + user.id;
    //       if(!utils.isEmptyGuid(user.imgId)) user.imgUrl = user.userPhotoUrl;
    //       return user;
    //     });
    //     this.setState({
    //       items,
    //       noData: items.length === 0,
    //       title: i18n.t('subscriptions')
    //     });
    //   }
    // });
  }


  public render() {
    const { tag: TagName = 'div', children, widget, className, settings, data } = this.props;
    const { list } = (data || settings || { list: [] })  as { list: any[] };

    return <UserListWhithDescription
      className={className}
      list={list}
      {...this.props}
    />
  }

}

export const UsersListWidget = UsersListWidgetPresenter;