import { useWikilistItemBaseImageByType } from "blocks/WikiListNew/Items/Items..hooks";
import { WikiCardAvatar, WikiImageCardButton, WikiItemCard } from "blocks/WikiListNew/Items/style";
import { Box, CollectionsHierarchySuggester, IconButton, UsersSuggester } from "muicomponents/src";
import { DialogContent } from "muicomponents/src/DialogParts";
import { Close } from "muicomponents/src/Icons";
import { InfinityList } from "muicomponents/src/InfinityList";
import React, { ComponentProps, FC, useCallback, useEffect, useState } from "react";
import { PageModelList, TValue } from "utils/src";
import { ComponentInjector } from "utils/src/ComponentInjector";
import { GUID_EMPTY } from "utils/src/constants.prn";
import { useDidUpdateEffect, useScrollListState } from "utils/src/hooks";
import { getWikiPagesV4 } from "utils/src/requests/requests.wikilist";
import { WidgetsTypes } from "utils/src/widgets/types";
import { baseWeekBlockData, baseWeekBlockSettings } from "./WeekBlockSettings.constants";
import { WeekBlockSettingsProps } from "./WeekBlockSettings.index";
import { SelectedRemoveButton, SettingsListBox, StyledWikiImageCardButton } from "./WeekBlockSettings.styled";

const method = async function (collectionAlias: string, opt: any) {
    const response = await getWikiPagesV4(collectionAlias, opt).r;
    return response.data;
}

const WeekBlockSettingsItem: FC<ComponentProps<typeof StyledWikiImageCardButton> & {
    data: PageModelList;
    active: boolean;
}> = ({
    data,
    active,
    children,
    ...props
}) => {

        const noImg = data.titleImageId === GUID_EMPTY || data.titleImageId === null;

        const pageBaseImage = useWikilistItemBaseImageByType(data.type);

        return (
            <StyledWikiImageCardButton {...props} variant={active ? 'contained' : undefined}>
                <WikiCardAvatar
                    src={noImg ? pageBaseImage : data.titleImageUrl}
                />
                {data.title}
                {children}
            </StyledWikiImageCardButton>
        )
    };

export const WeekBlockSettings: FC<WeekBlockSettingsProps> = ({
    widgetData: dataProps,
    settings: settingsProps,
    onChange,
    setValid
}) => {

    const prepareState = useCallback((data: typeof dataProps, settings: typeof settingsProps) => {
        if (!data) data = baseWeekBlockData;
        if (!settings) settings = baseWeekBlockSettings;
        const settingsKeys = Object.typedKeys(settings);
        return settingsKeys.reduce((a, c) => {
            {
                return {
                    ...a,
                    [c]: data[settings[c]]
                }
            }
        }, {} as { [key in typeof settingsKeys[number]]?: TValue<typeof dataProps> })
    }, [])

    const [state, setState] = useState(() => {
        return prepareState(dataProps, settingsProps)
    });

    const [changed, setChanged] = useState(false);

    useEffect(() => {
        setValid(Object.values(state).filter(Boolean).length === Object.typedKeys(state).length)
    }, [state]);

    useDidUpdateEffect(() => {
        setChanged(true);
        const newData: Partial<typeof dataProps> = {};
        if (state.selectedFolder) {
            newData[state.selectedFolder.id] = state.selectedFolder;
        }
        if (state.selectedItem) {
            newData[state.selectedItem.id] = state.selectedItem;
        }
        const newSetting: typeof settingsProps = {
            selectedFolder: state.selectedFolder?.id || '',
            selectedItem: state.selectedItem?.id || ''
        };
        onChange({
            data: newData,
            settings: newSetting
        });
    }, [state]);

    const {
        triggerRef,
        loadMore,
        isFinished,
        isLoading,
        values
    } = useScrollListState(async function (skipCount, count) {
        if (!state.selectedFolder) return [];
        return await method(state.selectedFolder.alias, { skipCount, count, type: 'file' })
    }, [state.selectedFolder]);

    return (
        <DialogContent sx={{
            maxHeight: '70vh'
        }}>
            <CollectionsHierarchySuggester
                value={state.selectedFolder as any}
                onChange={(_, value) => {
                    if (!Array.isArray(value)) {
                        setState(pp => ({
                            ...pp,
                            selectedFolder: value as any as typeof state['selectedFolder']
                        }));
                    }
                }}
                requestAdditionalParams={{
                    onlyGallery: true
                }}
            />
            {
                !changed && !!state.selectedItem &&
                <SettingsListBox sx={{ justifyContent: 'center' }}>
                    <WeekBlockSettingsItem data={state.selectedItem as any} active>
                        <SelectedRemoveButton onClick={() => {
                            setState(pp => ({
                                ...pp,
                                selectedItem: undefined
                            }));
                        }}>
                            <Close />
                        </SelectedRemoveButton>
                    </WeekBlockSettingsItem>
                </SettingsListBox>
            }
            {
                changed && !!state.selectedFolder &&
                <InfinityList
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={values.length}
                    triggerRef={triggerRef}
                    ContentComponent={SettingsListBox}
                    loadMore={loadMore}
                >
                    {
                        values.map(el => {
                            return <WeekBlockSettingsItem active={el.id === state.selectedItem?.id} key={el.id} data={el} onClick={() => {
                                setState(pp => ({
                                    ...pp,
                                    selectedItem: el as any as typeof state['selectedItem']
                                }));
                            }} />
                        })
                    }
                </InfinityList>
            }
        </DialogContent>
    )
}

export const initWeekBlockSettingsAdd = () => {
    ComponentInjector.getInstance().addComponent('WAddition', WeekBlockSettings, WidgetsTypes.weekblock);
};

export const initWeekBlockSettingsEdit = () => {
    ComponentInjector.getInstance().addComponent('WSettings', WeekBlockSettings, WidgetsTypes.weekblock);
};