import { WikiCardAvatar } from "blocks/WikiListNew/Items/style";
import { styled } from "muicomponents/src";
import { StyledWikiImageCardButton } from "./Settings/WeekBlockSettings.styled";

export const WeekBlockCard = styled(StyledWikiImageCardButton)({
    width: '100%'
}) as typeof StyledWikiImageCardButton;

export const WeekBlockCardAvatar = styled(WikiCardAvatar)({
    height: 'auto'
}) as typeof WikiCardAvatar;