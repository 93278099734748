/**
 * @packageDocumentation
 * @module Widget_Settings
 */
import album from './album';
import AlertWidget from './AlertWidget';
import eventslist from './EventsListWidget';
import badgesList from './badgesList';
import banner from './banner';
import birthdays from './birthdays';
import blocks from './blocks';
import breadcrumbs from './breadcrumbs';
import calendarmini from './calendarmini';
import calendarWidget from './calendarWidget';
import charts from './charts';
import common from './common';
import coursesMy from './coursesMy';
import eventstoday from './eventstoday';
import filters from './filters';
import FloatMessageWidget from './FloatMessageWidget';
import UsersCardWidget from './UsersCardWidget';
import ContentCardWidget from './ContentCardWidget';
import hierarchy from './hierarchy';
import hierarchyNew from './hierarchyNew';
import iframe from './iframe';
import info from './info';
import license from './license';
import list from './list';
import loader from './loader';
import mobileApp from './mobileAppWidget';
import myBadges from './myBadges';
import presents from './presents';
import quests from './quests';
import QuickLinks from './QuickLinks';
import quiz from './quiz';
import ratingBadge from './ratingBadge';
import ratingmini from './ratingmini';
import ratings from './ratings';
import remindFillProfile from './remindFillProfile';
import secretSanta from './secretSanta';
import secretSantaWidgetList from './secretSantaWidgetList';
import shop from './shop';
import sliderlink from './sliderlink';
import statistic from './statistic';
import statisticFilters from './statisticFilters';
import subscribers from './subscribers';
import subscriptions from './subscriptions';
import survey from './survey';
import tagsCloud from './tagsCloud';
import timeline from './timeline';
import timelinehead from './timelinehead';
import timelineonce from './timelineonce';
import userfields from './userfields';
import usersGroup from './usersGroup';
import usersList from './usersList';
import virtualUser from './virtualuser';
import wikiActueleArticles from './wikiActueleArticles';
import wikiArticles from './wikiArticles';
import wikilist from './wikilist';
import weekblock from './weekblock';

const structures: { [s: string]: any } = {
    loader,
    common,
    list,
    timeline,
    timelineonce,
    timelinehead,
    eventstoday,
    shop,
    info,
    sliderlink,
    ratingmini,
    usersGroup,
    presents,
    filters,
    charts,
    album,
    hierarchy,
    license,
    quests,
    virtualUser,
    tagsCloud,
    iframe,
    usersList,
    statistic,
    statisticFilters,
    userfields,
    blocks,
    badgesList,
    birthdays,
    mobileApp,
    secretSanta,
    secretSantaWidgetList,
    survey,
    myBadges,
    subscribers,
    subscriptions,
    ratings,
    quiz,
    coursesMy,
    banner,
    ratingBadge,
    wikiArticles,
    wikiActueleArticles,
    remindFillProfile,
    calendarmini,
    calendarWidget,
    AlertWidget,
    FloatMessageWidget,
    UsersCardWidget,
    ContentCardWidget,
    QuickLinks,
    hierarchyNew,
    wikilist,
    breadcrumbs,
    eventslist,
    weekblock
};

export const getStructure = (name: string) => structures[name] || structures.common;

export default structures;
