import React, { FC } from 'react';
import { ICurrentApplicationsWidgetProps, cnCurrentApplications } from './CurrentApplicationsWidget.index';
import { Box, Button, CircularProgress, Typography } from 'muicomponents/src';
import { useCurrentApplicationsCountManager } from 'utils';
import { ContentBox, ContentBoxBaseline } from './CurrentApplicationsWidget.styled';
import { Translate } from 'localization';
import urls from 'routes/urls';
import CardMedia from '@mui/material/CardMedia';
import MyTasks from 'assets/svg/MyTasks.svg';

export const CurrentApplicationsWidget: FC<ICurrentApplicationsWidgetProps | any> = ({
    context,
    className,
    isEditable,
    HTMLWidgetContentEdit,
    settings,
    data,
}) => {
    const currentUserId = context.uId || context.currentUserId;
    const { count: applicationsCount, loading, update } = useCurrentApplicationsCountManager(currentUserId);

    return (
        <Box>
            <ContentBox>
                <CardMedia
                    sx={{
                        width: '48px',
                        height: '48px',
                    }}
                    component="svg"
                    className={cnCurrentApplications('CurrentApplicationsHeader-Avatar')}
                    image={MyTasks}
                />
                <Typography
                    variant="subtitle1"
                    className={cnCurrentApplications('CurrentApplicationsHeader-Title')}
                    children={<Translate i18nKey={'pryaniky.currentApplications.title'} />}
                />
            </ContentBox>
            <ContentBox sx={{ justifyContent: 'space-between' }}>
                <ContentBoxBaseline>
                    <Typography
                        variant="subtitle1"
                        className={cnCurrentApplications('CurrentApplicationsContent-CountText')}
                        children={<Translate i18nKey={'pryaniky.currentApplications.countText'} />}
                    />
                    {loading ? (
                        <CircularProgress size={32} />
                    ) : (
                        <Typography
                            variant="h4"
                            className={cnCurrentApplications('CurrentApplicationsContent-Count')}
                            children={applicationsCount?.toString()}
                        />
                    )}
                </ContentBoxBaseline>
                <Button href={urls.currentApplications} variant="contained">
                    <Translate i18nKey={'pryaniky.currentApplications.moreInfo'} />
                </Button>
            </ContentBox>
        </Box>
    );
};
