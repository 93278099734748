import { useEffect, useMemo, useRef } from 'react';
import { useReffedState } from 'utils/src/hooks';
import { IShopItem, IShopOrderOptions } from 'utils/src/requests/models/api.shop';
import { confirm } from 'muicomponents/src/Confirm/Confirm';
import { i18n, Translate } from 'localization';
import { orderV4 } from 'utils/src/requests/requests.shop';
import { checkResponseStatus } from 'utils';
import { toast } from 'react-toastify';
import { IBasicResponse, IBasicResponseGeneric } from 'utils/src/requests/models/api.base';
import { IShopActions, IShopSetShopItemPayload } from 'redux/shop/actions.interfaces';
import { getLastPreOrder } from 'muicomponents/src/OrderListItem';

export const usePreorder = (
    userId: string,
    data: IShopItem,
    setShopProduct?: (payload: IShopSetShopItemPayload) => IShopActions,
    isActivePreorderInit?: boolean
) => {
    const currentPreorderIdRef = useRef<string | number>('');
    const currentPreorder = useMemo(() => {
        const preorder = getLastPreOrder(data, userId);
        if (preorder) currentPreorderIdRef.current = preorder.preOrderId;
        return preorder;
    }, [userId, data.preOrderUsers]);
    const currentPreorderRef = useRef(currentPreorder);

    const [isActivePreorder, setIsActivePreorder, isActivePreorderRef] = useReffedState<boolean>(
        typeof isActivePreorderInit === 'boolean'
            ? isActivePreorderInit
            : (currentPreorder || false) && currentPreorder.status === 'PreOrder'
    );

    useEffect(() => {
        setShopProduct && setShopProduct({ isActivePreorder });
    }, [isActivePreorder]);

    async function openCancelPreorder() {
        const result = await confirm({
            color: 'warning',
            header: i18n.t('pryaniky.list.orders.cancle.confirm.header.isPreorder', {
                id: currentPreorderIdRef.current,
            }),
            text: i18n.t('pryaniky.list.orders.cancle.confirm.text.2.isPreorder', {
                id: currentPreorderIdRef.current,
                productName: data.name,
            }),
            acceptText: i18n.t('pryaniky.list.orders.cancle.confirm.acceptText'),
            closeText: i18n.t('pryaniky.list.orders.cancle.confirm.closeText'),
        });
        if (result === 'confirm') {
            if (currentPreorderIdRef.current && isActivePreorderRef.current) {
                const opts: IShopOrderOptions = {
                    isPreorder: true,
                    preOrderId: +currentPreorderIdRef.current,
                    characteristics: data.characteristics || [],
                };
                try {
                    const response = await orderV4(data.id, currentPreorderRef.current?.productsCount || 1, opts).r;
                    if (checkResponseStatus(response)) {
                        setIsActivePreorder(false);
                        toast.success(
                            Translate.t({
                                i18nKey: 'pryaniky.shop.order.jointPurchase.notification.cancle.preorder',
                            })
                        );
                    } else {
                        toast.error(response.error_text);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }

    return {
        openCancelPreorder,
        isActivePreorder,
        setIsActivePreorder,
        currentPreorder: currentPreorderRef.current,
        onAfterMakeOrder: (r: IBasicResponseGeneric<{ orderId: string }>) => {
            currentPreorderIdRef.current = r.data?.orderId;
            if (data.isPreorder) setIsActivePreorder(true);
        },
    };
};
