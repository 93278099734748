import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IWidget, IColumn } from 'i.widgets';
import { bindActionCreators } from 'redux';
import * as utils from 'utils/src/utils';
import {
  updateContext,
  updateParentContext,
  removeWidget,
  updateWidget,
  saveWidget,
  addWidget,
  dragWidget,
  setStructureFromWidget,
  addTabToWidget,
  changeWidgetData,
  changeWidgetSettings,
  changeWidgetsViewType,
  setDraggingElem
} from 'redux/actions/Widgets';
import { getAuthUser, addToAdditionalMenu, removeFromAdditionalMenu } from 'utils/src/CommonRedux/base/actions';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import { IStateType as IState } from 'redux/store';

/**
 * кэширующий селектор. 
 * для оптимизации числа вызовов и сложных вычислений в селекторах
 * https://github.com/devSchacht/translations/blob/master/articles/reselect-selector-library-for-redux/readme.md
 */
import { defaultMemoize, createSelectorCreator } from "reselect";
import { isEqual } from 'lodash'
export interface IWidgetProps
  extends IClassNameProps,
  TypeMapStateToProps,
  IActionsDispatch,
  IWidgetOwnProps { }

// ReturnType<mapStateToProps>  к сожалению уже не получится...:(
export type TypeMapStateToProps ={
  edit: boolean;
  editType: false | "" | "widgets" | "columns";
  openedModal: any;
  authUser: any;
  widget: IWidget<Record<string, any> | IColumn, any>;
  wcontext: any;
  widgets: any;
  viewType: any;
  draggingElem: any;
}
interface IWidgetOwnProps {
  tag?: 'div' | React.ComponentType;
  type?: string;
  hide?: boolean;
  contexts: string[];
  'data-id': string | number;
  isHidden?: boolean;
}

export interface IWidgetState {
  text?: string;
  activeModal?: string;
}

export type IActionsDispatch = ReturnType<typeof mapActionsToProps>

export const mapActionsToProps = (dispatch: any) => bindActionCreators({
  updateContext,
  updateParentContext,
  updateWidget,
  saveWidget,
  removeWidget,
  addWidget,
  addTabToWidget,
  changeWidgetData,
  changeWidgetSettings,
  dragWidget,
  setStructureFromWidget,
  changeWidgetsViewType,
  setDraggingElem,
  changeVmManualy: (propPath: string, value: any): any => ({
    type: actions.CHANGE_STATE,
    payload: { propPath, value }
  }),
  addToAdditionalMenu,
  removeFromAdditionalMenu
}, dispatch);

// замента стандартного сравнения( === ) на глубокое
const createSelector = createSelectorCreator(defaultMemoize, isEqual)

const emptyArray: any = []// что бы массив не создавался новый каждый раз

// простые селекторы
const getContexts = (state: IState) => state.widgets.contexts
const getRelations = (state: IState, props: IWidgetOwnProps) =>
  state.widgets.widgets[props['data-id'] || 'empty']
    ? state.widgets.widgets[props['data-id'] || 'empty'].relations
    : emptyArray

// после всех манипуляций получается селектор, который меняет свой результат только в том случае, если реально поменялись входные данные
const makeGetWcontext = () =>
  createSelector(
    [getContexts, getRelations],
    (contexts, relations) => utils.prepareContext<any>(contexts, relations)
  );

// нужно использовать конструктор. Что бы для каждого компонента создавался свой инстанс селектора
// ускорение получается существенное, так как исключает лишние вычеслиния во всех монтированых компонентах
export const makeMapStateToProps = () => {
  const getWcontext = makeGetWcontext()// создаётся селектор
  return (state: IState, props: IWidgetOwnProps) => ({
    edit: state.store.edit as boolean,
    editType: state.store.edit && state.store.editType,
    openedModal: state.store.openedModal,  // ZENKIN TODO MAKE THIS CORRECTLY
    authUser: getAuthUser(state),
    widget: state.widgets.widgets[props['data-id'] || 'empty'] as IWidget<IColumn | Record<string, any>>,
    wcontext: getWcontext(state, props),
    widgets: state.widgets.widgets,
    viewType: state.widgets.viewType,
    draggingElem: state.widgets.dragging
  });
}

export const cnWidget = cn('Widget');
export const cnWidgetMui = cn('WidgetMui');
