import { Translate } from 'localization';
import { Button, CalendarsSuggester, Checkbox, Divider, FormControlLabel, IconButton, TextField } from 'muicomponents/src';
import { ColorPreviewPicker } from 'muicomponents/src/ColorPreviewPicker';
import { Close, HelpIcon } from 'muicomponents/src/Icons';
import { Tooltip } from 'muicomponents/src/Tooltip';
import React, { FC, useEffect } from 'react';
import { TValue, uuid } from 'utils/src';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { useReffedState } from 'utils/src/hooks';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { baseUsersEventsData, baseUsersEventsSettings } from './UsersEventsTableSettings.constants';
import { UsersEventsTableSettingsProps } from './UsersEventsTableSettings.index';
import { BlockBox, BlockHeaderBox, MainBox } from './UsersEventsTableSettings.styled';

export const UsersEventsTableSettings: FC<UsersEventsTableSettingsProps> = ({
    widgetData: data,
    settings,
    onChange,
    isValid,
}) => {
    const [dataState, setDataState, dataStateRef] = useReffedState(data ?? baseUsersEventsData);
    const [settingsState, setSettingsState, settingsStateRef] = useReffedState(() => {
        let idsReinited = false;
        const preparedBlocks = settings?.headBlocks.map((el) => {
            if (!el.id) {
                idsReinited = true;
                return {
                    ...el,
                    id: uuid(),
                };
            } else {
                return el;
            }
        });
        if (idsReinited) {
            return {
                ...(settings ?? baseUsersEventsSettings),
                headBlocks: preparedBlocks || baseUsersEventsSettings.headBlocks,
            };
        }
        return settings ?? baseUsersEventsSettings;
    });

    useEffect(() => {
        onChange({
            data: dataState,
            settings: settingsState,
        });
    }, [dataState, settingsState]);

    return (
        <MainBox>
            <BlockBox>
                {settingsState.headBlocks.map((block, idx) => {
                    return (
                        <BlockBox>
                            <BlockHeaderBox>
                                <Tooltip title={<Translate i18nKey="pryaniky.usersevents.settings.block.delete" />}>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            setSettingsState({
                                                ...settingsStateRef.current,
                                                headBlocks: settingsStateRef.current.headBlocks.filter((bl, i) => {
                                                    return i !== idx;
                                                }),
                                            });
                                        }}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </BlockHeaderBox>
                            <TextField
                                label={<Translate i18nKey="pryaniky.usersevents.settings.block.title" />}
                                value={block.title}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setSettingsState({
                                        ...settingsStateRef.current,
                                        headBlocks: settingsStateRef.current.headBlocks.map((bl, i) => {
                                            if (i === idx) {
                                                return {
                                                    ...bl,
                                                    title: value,
                                                };
                                            }
                                            return bl;
                                        }),
                                    });
                                }}
                            />
                            <TextField
                                label={<Translate i18nKey="pryaniky.usersevents.settings.block.titleUrl" />}
                                value={block.titleUrl || ''}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setSettingsState({
                                        ...settingsStateRef.current,
                                        headBlocks: settingsStateRef.current.headBlocks.map((bl, i) => {
                                            if (i === idx) {
                                                return {
                                                    ...bl,
                                                    titleUrl: value,
                                                };
                                            }
                                            return bl;
                                        }),
                                    });
                                }}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: <Tooltip title={<Translate i18nKey="pryaniky.usersevents.settings.block.titleUrl.help" />}>
                                        <HelpIcon />
                                    </Tooltip>
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!block.button}
                                        onChange={(e, checked) => {
                                            setSettingsState({
                                                ...settingsStateRef.current,
                                                headBlocks: settingsStateRef.current.headBlocks.map((bl, i) => {
                                                    if (i === idx) {
                                                        return {
                                                            ...bl,
                                                            button: checked
                                                                ? {
                                                                      text: '',
                                                                  }
                                                                : undefined,
                                                        };
                                                    }
                                                    return bl;
                                                }),
                                            });
                                        }}
                                    />
                                }
                                label={<Translate i18nKey="pryaniky.usersevents.settings.block.useButton" />}
                            />
                            {!!block.button && (
                                <>
                                    <TextField
                                        label={<Translate i18nKey="pryaniky.usersevents.settings.block.button.text" />}
                                        value={block.button?.text}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setSettingsState({
                                                ...settingsStateRef.current,
                                                headBlocks: settingsStateRef.current.headBlocks.map((bl, i) => {
                                                    if (i === idx) {
                                                        return {
                                                            ...bl,
                                                            button: {
                                                                ...bl.button,
                                                                text: value,
                                                            },
                                                        };
                                                    }
                                                    return bl;
                                                }),
                                            });
                                        }}
                                    />
                                </>
                            )}
                            <CalendarsSuggester
                                showWorkflow
                                TextFieldProps={{
                                    label: <Translate i18nKey="calendars" />,
                                }}
                                multiple
                                value={block.calendars.map((cId) => dataState.calendars[cId]) || []}
                                onChange={(event, value) => {
                                    if (Array.isArray(value)) {
                                        if (!value) {
                                            // delete dataStateRef.current.calendars[settingsStateRef.current.generatedCalerdarSettings.calendarId || ''];
                                            // setDataState({
                                            //     ...dataStateRef.current,
                                            //     calendars: {
                                            //         ...dataStateRef.current.calendars
                                            //     }
                                            // });
                                            setSettingsState({
                                                ...settingsStateRef.current,
                                                headBlocks: settingsStateRef.current.headBlocks.map((block, i) => {
                                                    if (idx === i) {
                                                        return {
                                                            ...block,
                                                            calendars: value,
                                                        };
                                                    }
                                                    return block;
                                                }),
                                            });
                                        } else {
                                            setDataState({
                                                ...dataStateRef.current,
                                                calendars: {
                                                    ...dataStateRef.current.calendars,
                                                    ...value.reduce(
                                                        (a, c) => ({
                                                            ...a,
                                                            [c.id]: c as TValue<typeof dataStateRef.current.calendars>,
                                                        }),
                                                        {}
                                                    ),
                                                },
                                            });
                                            setSettingsState({
                                                ...settingsStateRef.current,
                                                headBlocks: settingsStateRef.current.headBlocks.map((block, i) => {
                                                    if (idx === i) {
                                                        return {
                                                            ...block,
                                                            calendars: value.map((el) => el.id),
                                                        };
                                                    }
                                                    return block;
                                                }),
                                            });
                                        }
                                    }
                                }}
                            />
                            <Divider orientation="horizontal" />
                        </BlockBox>
                    );
                })}
                <Button
                    onClick={() => {
                        setSettingsState({
                            ...settingsStateRef.current,
                            headBlocks: [
                                ...settingsStateRef.current.headBlocks,
                                {
                                    id: uuid(),
                                    calendars: [],
                                    title: '',
                                },
                            ],
                        });
                    }}
                    variant={'contained'}
                >
                    <Translate i18nKey="pryaniky.usersevents.settings.block.add" />
                </Button>
            </BlockBox>
            <Divider orientation="horizontal" />
            <CalendarsSuggester
                showWorkflow
                TextFieldProps={{
                    label: <Translate i18nKey="pryaniky.usersevents.settings.productionCalendar" />,
                }}
                value={dataState.calendars[settingsState.generatedCalerdarSettings.calendarId || ''] || null}
                onChange={(event, value) => {
                    if (!Array.isArray(value)) {
                        if (!value) {
                            // delete dataStateRef.current.calendars[settingsStateRef.current.generatedCalerdarSettings.calendarId || ''];
                            // setDataState({
                            //     ...dataStateRef.current,
                            //     calendars: {
                            //         ...dataStateRef.current.calendars
                            //     }
                            // });
                            setSettingsState({
                                ...settingsStateRef.current,
                                generatedCalerdarSettings: {
                                    ...settingsStateRef.current.generatedCalerdarSettings,
                                    calendarId: null,
                                },
                            });
                        } else {
                            setDataState({
                                ...dataStateRef.current,
                                calendars: {
                                    ...dataStateRef.current.calendars,
                                    [value.id]: value as TValue<typeof dataStateRef.current.calendars>,
                                },
                            });
                            setSettingsState({
                                ...settingsStateRef.current,
                                generatedCalerdarSettings: {
                                    ...settingsStateRef.current.generatedCalerdarSettings,
                                    calendarId: value.id,
                                },
                            });
                        }
                    }
                }}
            />
            <ColorPreviewPicker
                label={<Translate i18nKey="pryaniky.usersevents.settings.headercolor.today" />}
                value={settingsState.generatedCalerdarSettings.headerTodayColor}
                onChange={(value) => {
                    setSettingsState({
                        ...settingsStateRef.current,
                        generatedCalerdarSettings: {
                            ...settingsStateRef.current.generatedCalerdarSettings,
                            headerTodayColor: value?.toString() || '',
                        },
                    });
                }}
            />
            <ColorPreviewPicker
                label={<Translate i18nKey="pryaniky.usersevents.settings.headercolor.weekend" />}
                value={settingsState.generatedCalerdarSettings.headerWeekendColor}
                onChange={(value) => {
                    setSettingsState({
                        ...settingsStateRef.current,
                        generatedCalerdarSettings: {
                            ...settingsStateRef.current.generatedCalerdarSettings,
                            headerWeekendColor: value?.toString() || '',
                        },
                    });
                }}
            />
        </MainBox>
    );
};

export const initUsersEventsTableAddSettings = () => {
    // это пока использовать не можем, потому что опять лезут ломаные стили....
    // ComponentInjector.getInstance().addComponent('WAddition', UsersEventsTableSettings, WidgetsTypes.usersevents);
};

export const initUsersEventsTableSettings = () => {
    ComponentInjector.getInstance().addComponent('WSettings', UsersEventsTableSettings, WidgetsTypes.usersevents);
};
