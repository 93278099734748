import React from 'react';
import { wrapDialogToRedux } from '../../DialogWrapper';
import { IWidgetsSettingsProps, IWidgetsSettingsState } from './WidgetsSettings.index';
import { WSettings } from '../../../WSettings/WSettings';
import { i18n } from 'localization';
import './WidgetsSettings.scss';
import { wdg } from '../Add/WidgetsAdd';
import * as utils from 'utils/src/utils';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import { widgets } from '../../../../i.widgets';
import { withComponentEnjector } from 'utils/src/ComponentInjector';
import { isString } from 'lodash';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle';
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { SettingsContext } from './WidgetsSettings.context';
import { getStructure } from 'blocks/WSettings/structures';
import { WidgetsTypes } from 'utils/src/widgets/types';

class WidgetsSettingsPresenter extends React.Component<IWidgetsSettingsProps, IWidgetsSettingsState> {
    constructor(props: IWidgetsSettingsProps) {
        super(props);
        if (props.data.wId) this.getWidget(props.data.wId);
    }

    public state: IWidgetsSettingsState = {
        isValid: true,
    };

    /**
     * componenDidUpdate
     */
    public componenDidUpdate(pp: IWidgetsSettingsProps) {
        if (this.props.data.wId && !utils.compareData(this.props.data.wId, pp.data.wId))
            this.getWidget(this.props.data.wId);
    }

    /**
     * render
     */
    public render() {
        const { state, props } = this;
        const { onClose, onConfirm, data, getComponent } = props;

        // если есть кастомный компонент - юзаем его.
        // используеся в том случае, если не хватает функциональности от дефолтного JsonForms
        const WSettingsComponent =
            getComponent(isString(data.selected) ? data.selected : data.selected?.type) || WSettings;

        return (
            <SettingsContext.Provider value={data.context}>
                <div>
                    <DialogTitle onClose={onClose}>{i18n.t('pryaniky.widgets.settings.title')}</DialogTitle>
                    {data.selected && (
                        <WSettingsComponent
                            settings={this.props.data.settings || this.props.data.data}
                            data={this.props.data.settings || this.props.data.data}
                            widgetData={this.props.data.data}
                            selected={data.selected}
                            onChange={this.onChange}
                            setValid={(value) => this.setState((s) => ({ ...s, isValid: value }))}
                        />
                    )}
                    <DialogActions
                        disableAccept={!state.isValid}
                        acceptText={i18n.t('save')}
                        closeText={i18n.t('cancel')}
                        onAccept={onConfirm}
                        onClose={onClose}
                    />
                </div>
            </SettingsContext.Provider>
        );
    }

    private checkVariableReqiure = (
        require: (data: { [s: string]: any }) => boolean | boolean,
        value: { [s: string]: any }
    ) => {
        switch (typeof require) {
            case 'boolean':
                return require;
            case 'function':
                return require(value);
        }
    };

    private fieldsInfo = (value: { [s: string]: any }) => {
        const { data } = this.props;
        const schema = getStructure(data.selected.schema);
        const arrRequire: string[] = [];
        const relations: Record<string, string[]> = {};
        Object.keys(schema.schema.properties).forEach((name) => {
            if (this.checkVariableReqiure(schema.schema.properties[name].require, value)) arrRequire.push(name);
            if (schema.schema.properties[name].relations) relations[name] = schema.schema.properties[name].relations;
        });
        let numberofNotEmpty = 0;
        arrRequire.forEach((name) => {
            const fieldValue = value[name];
            if (typeof fieldValue === 'string' && fieldValue !== '') {
                numberofNotEmpty += 1;
            } else if (typeof fieldValue === 'number' && fieldValue > 0) {
                numberofNotEmpty += 1;
            } else if (Array.isArray(fieldValue) && fieldValue.length > 0) {
                numberofNotEmpty += 1;
            } else if (fieldValue && Object.keys(fieldValue).length > 0) {
                numberofNotEmpty += 1;
            } else if (typeof fieldValue === 'boolean' && relations[name]) {
                if (fieldValue || relations[name].reduce((a, crname) => a || value[crname], false as boolean))
                    numberofNotEmpty += 1;
            }
        });
        this.setState((p) => ({
            ...p,
            isValid: numberofNotEmpty === arrRequire.length
        }));
    };

    public validate(value: { [s: string]: any }) {
        const { data } = this.props;
        this.setState((p) => ({
            ...p,
            isValid: false
        }));
        switch (data.selected.type) {
            case `${widgets.types.users}/hierarchy`: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.hierarchyNew: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.timeline: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.ratingmini: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.ratingBadges: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.filters: {
                this.fieldsInfo(value);
                return null;
            }
            case `${widgets.types.users}/group`: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.sliderlink: {
                value.slider.elements.findIndex((i: any) => i.imgId !== GUID_EMPTY) !== -1 && this.setState((p) => ({
                    ...p,
                    isValid: true
                }));
                return null;
            }
            case widgets.types.list: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.charts: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.quests: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.secretSanta: {
                this.fieldsInfo(value);
                return null;
            }
        }
        this.setState((p) => ({
            ...p,
            isValid: true
        }));
    }

    /**
     * onChange - on settings change
     */
    public onChange = (value: { [s: string]: any }) => {
        const { data } = this.props;
        let changedData = { ...value };
        switch (data.selected.type) {
            case widgets.types.users + '/list':
                store.dispatch(
                    modalChangeDate({
                        id: this.props.id || '',
                        data: {
                            data: changedData.data,
                        },
                    })
                );
                return;
            case widgets.types.blocks:
                changedData = {
                    data: value.settings,
                };
                break;
            case widgets.types.timeline:
                const { data } = value;
                if (data.timelineType === 'headlist') {
                    changedData = {
                        data: {
                            ...data,
                            tagId: data.selectedTags?.id,
                            newsTypes: data.selectedNews,
                        },
                    };
                }
                break;
            case widgets.types.html:
                changedData = {
                    data: value.settings,
                };
                break;
            case widgets.types.wiki:
                changedData = {
                    data: value.settings,
                };
                break;
            case WidgetsTypes.selectedContext:
            case widgets.types.weekblock:
            case widgets.types.usersevents:
                store.dispatch(
                    modalChangeDate({
                        id: this.props.id || '',
                        data: {
                            data: value.data,
                            settings: value.settings,
                        },
                    })
                );
                return;
            case widgets.types.breadcrumbs:
                changedData = {
                    data: value.settings,
                };
                break;
            case widgets.types.FloatMessageWidget:
                changedData = {
                    data: value.settings,
                };
                break;
            case widgets.types.UsersCardWidget:
                changedData = {
                    data: value.settings,
                };
                break;
            case widgets.types.ContentCardWidget:
                changedData = {
                    data: value.settings,
                };
                break;
            case widgets.types.presents:
                changedData = {
                    data: value.data,
                };
                break;
            case widgets.types.sliderlink:
                changedData = {
                    ...value,
                    data: {
                        ...value.data,
                        slider: {
                            ...value.data.slider,
                            elements: value.data.slider.elements.filter(
                                (el: any) => !el.deleted && el.imgId !== GUID_EMPTY
                            ),
                        },
                    },
                };
                break;

            case widgets.types.virtualUser:
                changedData = {
                    ...value,
                    data: {
                        ...value.data,
                        virtualUser: {
                            ...value.data.virtualUser,
                            elements: value.data.virtualUser.elements.filter((el: any) => !el.deleted),
                        },
                    },
                };
                break;
        }
        this.validate(changedData.data);
        store.dispatch(
            modalChangeDate({
                id: this.props.id || '',
                data: {
                    settings: changedData.data,
                },
            })
        );
    };

    /**
     * getWidget - get widget from store by id
     * @param {string} id - widget id
     */
    public getWidget = (id: string) =>
        store.dispatch(
            modalChangeDate({
                id: this.props.id || '',
                data: {
                    selected: wdg(
                        this.jsonformsDataWidgetTypesDiff(store.getState().widgets.widgets[id].type),
                        store.getState().widgets.widgets[id].type
                    ),
                },
            })
        );

    /**
     * jsonforms
     */
    public jsonformsDataWidgetTypesDiff(type: string) {
        const thrueType = type.substr(type.indexOf('/') + 1);

        switch (thrueType) {
            case 'list/wikilist': {
                return 'wikilist';
            }
            case 'users/group': {
                return 'usersGroup';
            }
            case 'users/list': {
                return 'usersList';
            }
            case 'timeline': {
                const widg = store.getState().widgets.widgets[this.props.data.wId!];
                if (widg.settings?.timelineType === 'headlist') {
                    return 'timelinehead';
                }
                if (
                    widg.settings?.newsTypes === 'events' &&
                    widg.settings?.timelineType === 'slider' &&
                    widg.settings?.once
                ) {
                    return 'eventstoday';
                }
            }
        }
        return type.split('/')[1];
    }
}

export const WidgetsSettings = withComponentEnjector(
    wrapDialogToRedux<IWidgetsSettingsProps>(WidgetsSettingsPresenter),
    ['WSettings']
);
