import CardMedia from '@mui/material/CardMedia';
import MyTasks from 'assets/svg/MyTasks.svg';
import { Translate } from 'localization';
import { Box, CircularProgress, Typography } from 'muicomponents/src';
import { Button } from 'muicomponents/src/Button/Button';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import urls from 'routes/urls';
import { useMyTasksCountManager } from 'utils/src';
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';
import { IRequest } from 'utils/src/requests/service';
import { IMyTasksProps, cnMyTasks } from './MyTasks.index';
import { ContentBox, ContentBoxBaseline } from './MyTasks.styled';

let request: IRequest;

export const MyTasksPreseter: FC<IMyTasksProps> = ({ context }) => {
    const currentUserId = context.uId || context.currentUserId;

    const { count: tasksCount, loading, update } = useMyTasksCountManager(currentUserId);

    const tasksModuleIsEnabled = useSelector(moduleIsEnabled('tasks'));
    if (!tasksModuleIsEnabled) return null;
    return (
        <Box>
            <ContentBox>
                <CardMedia
                    sx={{
                        width: '48px',
                        height: '48px',
                    }}
                    component="svg"
                    className={cnMyTasks('MyTasksHeader-Avatar')}
                    image={MyTasks}
                />
                <Typography
                    variant="subtitle1"
                    className={cnMyTasks('MyTasksHeader-Title')}
                    children={<Translate i18nKey={'pryaniky.myTasks.title'} />}
                />
            </ContentBox>
            <ContentBox sx={{ justifyContent: 'space-between' }}>
                <ContentBoxBaseline>
                    <Typography
                        variant="subtitle1"
                        className={cnMyTasks('MyTasksContent-CountText')}
                        children={<Translate i18nKey={'pryaniky.myTasks.countText'} />}
                    />
                    {loading ? (
                        <CircularProgress size={32} />
                    ) : (
                        <Typography
                            variant="h4"
                            className={cnMyTasks('MyTasksContent-Count')}
                            children={tasksCount?.toString()}
                        />
                    )}
                </ContentBoxBaseline>
                <Button href={urls.tasks} variant="contained">
                    <Translate i18nKey={'pryaniky.myTasks.moreInfo'} />
                </Button>
            </ContentBox>
        </Box>
    );
};
