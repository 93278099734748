import React, { Component } from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { IUsersWidgetStateProps, cnUsersWidget, cnUsersWidgetHierarchy } from './../UsersWidget.index';
import { mapDispatchToProps, IDispatchProps } from "redux/connector";
import { i18n, Translate } from 'localization';
import { mapStateToProps } from './UsersWidget_type_hierarchy.index';
import './UsersWidget_type_hierarchy.scss';
import { IUsersWidgetProps } from '../UsersWidget.index';
import { ListRound } from '../../ListRound/ListRound';
import { Button, Icon, Avatar } from 'uielements/src'
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import { Box, Paper, Typography } from 'muicomponents/src';
import Stack from '@mui/material/Stack';
import { Delete } from 'muicomponents/src/Icons'
import { Avatar as MuiAvatar } from 'muicomponents/src/Avatar';
import noDataImage from 'assets/svg/NoData.svg'
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { UsersSuggester } from "muicomponents/src/Suggester/type/UsersSuggester"
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';

export class UsersHierarchyWidgetPresenter extends Component<IUsersWidgetProps, any> {
  public headOrSub = this.props.settings?.headusers ? "headusers" : "subusers"
  public head = this.props.settings?.headusers;

  constructor(props: IUsersWidgetProps) {
    super(props);
    this.state = {
      items: [],
      title: i18n.t('pryaniky.hierarchy.' + (this.head ? 'head' : 'bottom')),
      newUser: null,
      add: false,
      show: false
    };
  }

  public componentDidMount() {
    this.getData(this.props);
  }

  public componentDidUpdate(prevProps: IUsersWidgetProps) {
    const prevuId = prevProps.context.uId || prevProps.authUser.profileData.userId
    const uId = this.props.context.uId || this.props.authUser.profileData.userId
    if (prevuId !== uId) {
      this.getData(this.props);
    }

  }


  public getData = (props: IUsersWidgetProps) => {
    const uId = this.props.context.uId || props.authUser.profileData.userId

    this.props.settings?.hierarchy && utils.API.hierarchy.getHierarchyByUserForWidget(0, 20, { selectedHeadUser: uId, hierarchyName: this.props.settings?.hierarchy.name, catfilter: this.headOrSub })
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          const items = d.data.map((user: { [s: string]: any }) => {
            user.title = user.displayName;
            user.url = '/user/' + user.id;
            if (utils.isEmptyGuid(user.imgId)) user.imgUrl = user.userPhotoUrl;
            return user;
          });
          this.setState({
            items,
            noData: items.length === 0,
            title: (this.head ? this.props.settings?.hierarchy.headname : this.props.settings?.hierarchy.subname) || i18n.t('pryaniky.hierarchy.' + (this.head ? 'head' : 'bottom')),
            show: true
          });
        } else if (d.error_code === 21002) { // deleted
          this.setState({ show: false })
        }
      });
  }

  private add = () => {
    if (this.state.newUser && this.props.settings?.hierarchy.name) {
      // починила добавление юзера вне контекста профиля пользователя this.props.authUser.baseData.id берет id страничку
      const topId = this.head ? this.state.newUser.id : (this.props.context.uId ? this.props.context.uId : this.props.authUser.baseData.id)
      // починила добавление юзера вне контекста профиля пользователя this.props.authUser.baseData.id берет id страничку
      const bottomId = this.head ? (this.props.context.uId ? this.props.context.uId : this.props.authUser.baseData.id) : this.state.newUser.id
      utils.API.hierarchy.changeHierarchyAction(
        encodeURIComponent(this.props.settings?.hierarchy.name),// что бы слеши не ломали роутинг
        topId,
        bottomId,
        true
      )
      this.setState({ items: [...this.state.items, { ...this.state.newUser, url: `/user/${this.state.newUser.id}` }], newUser: null, add: false })
    } else {
      this.setState({ add: false })
    }
  }

  private onDelete = (item: any) => {
    const topId = this.head ? item.id : (this.props.context.uId ? this.props.context.uId : this.props.authUser.baseData.id)
    const bottomId = this.head ? (this.props.context.uId ? this.props.context.uId : this.props.authUser.baseData.id) : item.id
    utils.API.hierarchy.changeHierarchyAction(
      this.props.settings?.hierarchy.name,
      topId,
      bottomId,
      false
    )
    this.setState({ items: this.state.items.filter((val: any) => val.id !== item.id) })
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, widget, className, authUser, settings } = this.props;
    const { items, noData, title, show } = this.state;
    const additionalProps: { [s: string]: any } = {
      // hide: noData
    }
    if (!show && !this.props.edit) return null;
    if (this.props.edit && !items.length) return <TagName {...this.props} ></TagName>;
    if ((!settings || !settings?.hideIfNoData) && items.length === 0 && !authUser.baseData.isAdmin) return <TagName {...this.props} className={cnUsersWidget({}, [customClassNames, className])}>
      {/* if ((!settings||!settings?.hideIfNoData) && items.length === 0  ) return <TagName {...this.props} className={cnUsersWidget({}, [customClassNames, className])}> */}
      <div className={cnUsersWidget('Header-Title')} children={title} />
      <div className={cnUsersWidget('Header-noData')}> <Avatar precentage={100} shape={'rectangle'}
        imgUrl={noDataImage}
      />
      </div>
      <span className={cnUsersWidget('Header-Title-Text')}> {i18n.t('pryaniky.widgets.settings.type.birthdays.noData.text')} </span>
    </TagName>;
    if (settings?.hideIfNoData && items.length === 0) return <TagName className={cnUsersWidget({})} {...{ ...this.props, isHidden: items.length === 0 || !items.length }} />;


    if (settings && settings.hierarcheListView) {

      return <TagName {...this.props} className={cnUsersWidgetHierarchy({})}>
        <Paper sx={{ padding: '16px' }}>
          <Box>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              {authUser.baseData.isAdmin && <div style={{ width: "100%" }}>
                {this.state.add ?
                  <form className={'UsersWidget_type_hierarchy_HBox'} onSubmit={this.add}>
                    <div className={'UsersWidget_type_hierarchy_input'} style={{ width: "100%" }}>

                      <UsersSuggester
                        multiple={false}
                        TextFieldProps={{
                          label: <Translate i18nKey={'pryaniky.hierarchy.select_user'} />
                        }}
                        value={this.state.newUser || []}
                        onChange={(event, newUser) => { this.setState({ newUser }) }}
                        disablePortal={true}
                        filterSelectedOptions
                        filterOptions={(options, state) => {
                          const selectedItemsIds = items.map((item: any) => item.id)
                          return options.filter(option => !selectedItemsIds.includes(option.id))
                        }}
                      />
                    </div>
                    <Button theme={'unstyled'} className={'UsersWidget_type_hierarchy_add'} onClick={this.add}>
                      <Icon icon={this.state.newUser ? 'plus' : 'times'} />
                    </Button>
                  </form> :
                  <div className={'UsersWidget_type_hierarchy_HBox'}>

                    <Typography
                      variant="subtitle1"
                      className={cnUsersWidget('Name', ['UsersWidget_type_hierarchy_name'])}
                      children={title}
                      sx={{ fontWeight: "600" }} />

                    {/* <h4 className={cnUsersWidget('Name', ['UsersWidget_type_hierarchy_name'])} children={title} /> */}
                    <Button theme={'unstyled'} onClick={() => this.setState({ add: true })}>
                      <Icon icon={'plus'} />
                    </Button>
                  </div>}
              </div>}
              {!authUser.baseData.isAdmin && <h4 className={cnUsersWidget('Name')} children={title} />}
            </Stack>
            <Box
              className={cnUsersWidgetHierarchy('Content')}
              sx={{ display: "flex", gap: "18px", flexDirection: "column" }}>
              {items.length !== 0 && items.map((item: any) => <Box className={cnUsersWidgetHierarchy('Item')}>
                <MuiAvatar sx={{
                  width: "48px",
                  height: "48px"
                }}
                  srcSet={item.imgUrl !== undefined ? item.imgUrl : `https://pryaniky.com/Img/UserProfiles/Preview/96m96/${item.id}.png`} />
                <Box className={cnUsersWidgetHierarchy('Item-Info')} sx={{ display: "flex", gap: "8px", gridGap: "8px" }}>
                  <Box sx={{ width: "90%" }}>
                    <UserMention id={item.id.toString()}>
                      <Link className={cnUsersWidgetHierarchy(`Item-Info-Name`, [ classNamesDesign.primaryColor3.text ])} href={appGeneratePath(mainUrls.user.id, { id: item.id.toString() })}>
                        {item.title || item.displayName}
                      </Link>
                    </UserMention>
                    <Typography variant={"body2"} sx={{
                      color: " rgba(0, 0, 0, 0.60)",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>{item.position}</Typography>
                  </Box>
                  {authUser.baseData.isAdmin && <IconButton
                    size={"small"}
                    className={cnUsersWidgetHierarchy('Delete')}
                    onClick={() => this.onDelete(item)}
                  >
                    <Delete />
                  </IconButton >}
                </Box>
              </Box>)}

            </Box>
          </Box>
        </Paper>
      </TagName>
    }

    return (
      <TagName {...this.props} {...additionalProps} className={cnUsersWidget({}, [customClassNames, className])}>
        {authUser.baseData.isAdmin && <div>
          {this.state.add ?
            <form className={'UsersWidget_type_hierarchy_HBox'} onSubmit={this.add}>
              <div className={'UsersWidget_type_hierarchy_input'}>

                <UsersSuggester
                  multiple={false}
                  TextFieldProps={{
                    label: <Translate i18nKey={'pryaniky.hierarchy.select_user'} />
                  }}
                  value={this.state.newUser || []}
                  onChange={(event, newUser) => { this.setState({ newUser }) }}
                  disablePortal={true}
                  filterSelectedOptions
                  filterOptions={(options, state) => {
                    const selectedItemsIds = items.map((item: any) => item.id)
                    return options.filter(option => !selectedItemsIds.includes(option.id))
                  }}
                />
              </div>
              <Button theme={'unstyled'} className={'UsersWidget_type_hierarchy_add'} onClick={this.add}>
                <Icon icon={this.state.newUser ? 'plus' : 'times'} />
              </Button>
            </form> :
            <div className={'UsersWidget_type_hierarchy_HBox'}>
              <h4 className={cnUsersWidget('Name', ['UsersWidget_type_hierarchy_name'])} children={title} />
              <Button theme={'unstyled'} onClick={() => this.setState({ add: true })}>
                <Icon icon={'plus'} />
              </Button>
            </div>}
        </div>}
        {!authUser.baseData.isAdmin && <h4 className={cnUsersWidget('Name')} children={title} />}
        {items.length !== 0 && <ListRound items={items} cut={true} size={56} onDelete={authUser.baseData.isAdmin ? this.onDelete : undefined} />}
        {children}
      </TagName>
    )
  }

}

export class UsersHierarchyWidgetPresenterDown extends UsersHierarchyWidgetPresenter {
  public head = this.props.settings?.headusers;
}
export class UsersHierarchyWidgetPresenterTop extends UsersHierarchyWidgetPresenter {
  public head = this.props.settings?.headusers;
}

export const UsersHierarchyWidgetTop = connect<IUsersWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(UsersHierarchyWidgetPresenterTop)
export const UsersHierarchyWidgetDown = connect<IUsersWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(UsersHierarchyWidgetPresenterDown)


// export const withUsersWidgetTypeSubscriptions = withBemMod<IUsersWidgetTypeSubscriptionsProps>(
//   cnUsersWidget(),
//   { type: 'subscriptions' },
//   (Presenter) => (
//     (props) => (
//       <Presenter {...props}/>
//     )
//   )
// );