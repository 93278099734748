import MoreVertIcon from '@mui/icons-material/MoreVert';
import { i18n, Translate } from 'localization';
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { ISplitButtonOption } from 'muicomponents/src/SplitButton/SplitButton.index';
import React, { FC, useMemo } from 'react';
import { toast } from 'react-toastify';
import { modalChangeDate } from 'redux/actions/Modals';
import { store } from 'redux/store';
import * as utilsProj from 'utils.project/utils.project';
import { CustomSettings, PageTypes } from 'utils/src';
import { withComponentEnjector } from 'utils/src/ComponentInjector';
import { useFileUploader } from 'utils/src/hooks';
import * as utils from 'utils/src/utils';
import { IWikiItemProps } from './ContextMenu.index';
import './ContextMenu.scss';

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

export const ContextMenuPresenter: FC<IWikiItemProps> = ({
    injectionNodes,
    injectionComponents,
    onEdit,
    btnRender,
    removeItems,
    updateItemList,
    context,
    data,
    ...props
}) => {
    const prepeiredContextActions = useMemo(() => {
        type ContextAction = {
            content: React.ReactNode;
            id?: string;
        };

        let actions: ContextAction[] = [
            {
                content: <Translate i18nKey={'pryaniky.wikilist.item.еdit'} />,
                id: 'edit',
            },
        ];

        if (data.isDraft) {
            actions.push({
                content: <Translate i18nKey={'pryaniky.modal.wikipage.delete'} />,
                id: 'delete',
            });
        }

        if (props.isAdmin) {
            actions = [
                ...injectionNodes.map((node) => ({ content: node })),
                ...injectionComponents.map((Component) => ({ content: <Component {...props} data={data} /> })),
                {
                    content: <Translate i18nKey={'pryaniky.wikilist.item.еdit'} />,
                    id: 'edit',
                },
                {
                    content: <Translate i18nKey={'pryaniky.modal.wikipage.delete'} />,
                    id: 'delete',
                },
                {
                    content: (
                        <Translate i18nKey={`pryaniky.modal.wikipage.${data.isArchived ? 'unarchive' : 'archive'}`} />
                    ),
                    id: 'archive',
                },
            ];
        }

        if (useFavoriteWikiItems) {
            actions.unshift({
                content: (
                    <Translate
                        i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorites' : 'toFavorites'}`}
                    />
                ),
                id: 'favorites',
            });
        }

        if (data.type === PageTypes.file && !data.isDraft) {
            actions.unshift(
                {
                    content: <Translate i18nKey={'pryaniky.files.open'} />,
                    id: 'open',
                },
                {
                    content: <Translate i18nKey={'pryaniky.files.download'} />,
                    id: 'download',
                },
                {
                    content: <Translate i18nKey={'pryaniky.files.item.upload'} />,
                    id: 'upload',
                }
            );

            const pageActions = data.actions?.map((el) => el.id);
            const openIsAllow = pageActions ? pageActions.includes('open') || pageActions.includes('file.open') : true;
            if (!openIsAllow) {
                actions = actions.filter((el) => el.id !== 'open');
            }

            const downloadIsAllow = pageActions
                ? pageActions.includes('download') || pageActions.includes('file.download')
                : true;
            if (!downloadIsAllow) {
                actions = actions.filter((el) => el.id !== 'download');
            }
        }

        return actions;
    }, [injectionNodes, injectionComponents, data.isDraft, data.type, props.isAdmin, data.actions]);

    const deleteWikiPage = (data: any) => {
        utilsProj.confirm({
            text: i18n.t('pryaniky.modal.wikipage.confirm.delete'),
            onConfirm: () =>
                utils.API.pages.deletePage(data.id).r.then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code === 0) {
                        toast.success(i18n.t('pryaniky.modal.wikipage.deleted'));
                        removeItems([data]);
                    }
                }),
        });
    };

    const copyWikiPage = (data: any) => {
        utilsProj.confirm({
            text: i18n.t('pryaniky.modal.wikipage.confirm.copy'),
            onConfirm: () =>
                utils.API.pages.copyPage(data.id).r.then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code === 0) {
                        toast.success(i18n.t('pryaniky.modal.wikipage.copied'));
                        window.location.href = response.data.url;
                        // removeItems([data]);
                    }
                }),
        });
    };
    const favoriteWikiPage = (data: any) => {
        utils.API.pages.favoritePage(data.id, !data.isFavorites).r.then((response) => {
            if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
            else if (response.error_code === 0) {
                toast.success(i18n.t(`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorited' : 'toFavorited'}`));
                updateItemList(data, { ...data, isFavorites: !data.isFavorites });
            }
        });
    };

    const sendDataForEditPage = (isArchived?: boolean, fileData?: any[]) => {
        !fileData
            ? utilsProj.confirm({
                  text: data.isArchived
                      ? i18n.t('pryaniky.modal.wikipage.confirm.unarchieve.edit')
                      : i18n.t('pryaniky.modal.wikipage.confirm.archieve.edit'),
                  onConfirm: () => {
                      const value = isArchived !== undefined ? isArchived : data.isArchived;
                      return utils.API.pages.getById(data.id).r.then((response) => {
                          if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                          else if (response.error_code === 0) {
                              utils.API.pages
                                  .setSettings({
                                      ...response.data,
                                      type: data.type || PageTypes.page,
                                      isArchived: value,
                                      moderators: data.isInheritingRootRights ? [] : data.moderators,
                                  })
                                  .r.then((response) => {
                                      if (!response) {
                                          toast.error(i18n.t('pryaniky.toast.error.server'));
                                      } else if (response.error_code === 0) {
                                          toast.success(i18n.t('edited.success'));

                                          updateItemList(data, { ...data, isArchived: value });

                                          // store.dispatch(modalChangeDate({ id: data.id || '', data: { ...data, isArchived: isArchived !== undefined ? isArchived : data.isArchived } }))
                                      }
                                  });
                          } else if (response.error_text) toast.error(response.error_text);
                      });
                  },
              })
            : utils.API.pages.getById(data.id).r.then((response) => {
                  console.log('fileData', fileData, fileData[0].name, fileData[0].id);
                  if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                  else if (response.error_code === 0) {
                      utils.API.pages
                          .setSettings({
                              ...response.data,
                              moderators: data.isInheritingRootRights ? [] : data.moderators,
                              file: fileData ? fileData[0] : data.file,
                              titleImageUrl: fileData ? fileData[0].previewUrl : data.titleImageUrl,
                              titleImageId: fileData ? fileData[0].id : data.titleImageId,
                              title: fileData ? fileData[0].name : data.title,
                          })
                          .r.then((response) => {
                              if (!response) {
                                  toast.error(i18n.t('pryaniky.toast.error.server'));
                              } else if (response.error_code === 0) {
                                  toast.success(i18n.t('edited.success'));

                                  updateItemList(data, {
                                      ...data,
                                      file: fileData ? fileData[0] : data.file,
                                      titleImageUrl: fileData ? fileData[0].previewUrl : data.titleImageUrl,
                                      titleImageId: fileData ? fileData[0].id : data.titleImageId,
                                      title: fileData ? fileData[0].name : data.title,
                                  });

                                  store.dispatch(
                                      modalChangeDate({
                                          id: data.id || '',
                                          data: {
                                              ...data,
                                              isArchived: isArchived !== undefined ? isArchived : data.isArchived,
                                          },
                                      })
                                  );
                              }
                          });
                  } else if (response.error_text) toast.error(response.error_text);
              });
    };

    const { openSelectFile, isLoading } = useFileUploader({
        uploadUrl: `/SetData/v4/files/uploadVersion/${data.file?.id}`,
        maxFilesCount: 1,
        uploadOnAdd: true,
        callbacks: {
            loadFinish(item, response) {
                if (response?.error_code === 3001) {
                    toast.error(i18n.t(`pryaniky.file.upload.error.3001`));
                } else if (response?.error_code !== 0) {
                    toast.error(response?.error_text);
                } else {
                    toast.success(i18n.t(`pryaniky.file.upload.success`));
                }
            },
            loadError(item) {
                if (item?.error?.error_text) {
                    toast.error(item?.error?.error_text);
                } else {
                    toast.error(i18n.t('pryaniky.upload_file.error.unknown_error'));
                }
            },
        },
    });

    const clickAction = (id: string) => {
        switch (id) {
            case 'edit':
                onEdit();
                break;
            case 'copy':
                copyWikiPage(data);
                break;
            case 'delete':
                deleteWikiPage(data);
                break;
            case 'archive':
                archivedPage();
                break;
            case 'favorites':
                favoriteWikiPage(data);
                break;
            case 'upload':
                // FilesAdd({ fileId: props.data.file.id, multiple: false }).then((fileData) => {
                //     // console.log("data fileData", fileData.files)
                //     sendDataForEditPage(props.data.isArchived, fileData.files)
                // }).catch(() => { })
                openSelectFile();
                break;
            default:
                break;
        }
    };

    const archivedPage = () => {
        sendDataForEditPage(!data.isArchived);
    };

    const preparedOptions = useMemo<ISplitButtonOption[]>(
        () =>
            prepeiredContextActions.map((action) => ({
                id: action.id || '',
                content: action.content,
            })),
        [prepeiredContextActions]
    );

    const handleMenuAction = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: ISplitButtonOption) => {
        event.preventDefault();
        event.stopPropagation();
        clickAction(item.id);
    };

    return (
        <SplitButton
            type="iconButton"
            loading={isLoading}
            variant="text"
            color="primary"
            icon={<MoreVertIcon />}
            menuButtonProps={{
                sx: {
                    background: '#fff !important',
                },
            }}
            size="small"
            enablePortal
            handleMenuItemClick={handleMenuAction}
            options={preparedOptions}
        />
    );
};

export const ContextMenu = withComponentEnjector(ContextMenuPresenter, ['wiki_list_item']);
