import { Page } from 'blocks/Page/Page';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { pageNames } from 'redux/pageNames';
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';
import { getVacanciesSettingsModule } from '../redux/module.vacancies';

const VacanciesPagePr: FC<{}> = ({}) => {
    return <Page page={{ name: pageNames.vacancies }} />;
};

export const VacanciesPage: FC<{}> = () => {
    const tasksModuleIsEnabled = useSelector(moduleIsEnabled('ocvacancies'));

    if (!tasksModuleIsEnabled) {
        return <Redirect to={'/404?path=' + window.location.pathname} />;
    }

    return (
        <DynamicModuleLoader modules={[getVacanciesSettingsModule()]}>
            <VacanciesPagePr />
        </DynamicModuleLoader>
    );
};
