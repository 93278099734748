import { DeepPartial } from 'utils/src';
import { NPolls } from '../types/Polls/Polls.interface';
import * as actionsTypesCreator from './actionsTypes.newsCreator';
import { NewsCreateReducer } from './types.newsCreator';

export enum NewsChangeReasonsTypes {
    pollAsnwerAdd = 'pollAsnwerAdd',
    pollAsnwerRemove = 'pollAsnwerRemove',
    pollAnswerChange = 'pollAnswerChange',
    newsSendPrepare = 'newsSendPrepare',
}

export type NewsChangeReasons = {
    type: NewsChangeReasonsTypes.pollAsnwerAdd
} | {
    type: NewsChangeReasonsTypes.pollAsnwerRemove
} | {
    type: NewsChangeReasonsTypes.pollAnswerChange,
    id: NPolls.Create['poll']['answers'][number]['id']
} | {
    type: NewsChangeReasonsTypes.newsSendPrepare
};

export namespace NNewsCreateActions {

    export type InitCreator = {
        type: typeof actionsTypesCreator.NEWS_CREATOR_INIT;
        payload: keyof NewsCreateReducer;
    };

    export type ChangeItem = {
        type: typeof actionsTypesCreator.NEWS_CREATOR_ITEM_CHANGE;
        payload: {
            id: keyof NewsCreateReducer;
            item: Partial<NewsCreateReducer[keyof NewsCreateReducer]>;
        };
    };

    export type ChangeItemNews = {
        type: typeof actionsTypesCreator.NEWS_CREATOR_ITEM_NEWS_CHANGE;
        payload: {
            id: keyof NewsCreateReducer;
            news: DeepPartial<NewsCreateReducer[keyof NewsCreateReducer]['news']>;
            reason?: NewsChangeReasons;
        }
    };

    export type PostNews = {
        type: typeof actionsTypesCreator.NEWS_CREATOR_POST_NEWS;
        payload: keyof NewsCreateReducer;
    };

    export type ValidateNews = {
        type: typeof actionsTypesCreator.NEWS_CREATOR_VALIDATE_NEWS;
        payload: keyof NewsCreateReducer;
    }

    export type ActionFunction<Type extends Actions['type']> = (payload: Extract<Actions, { type: Type }>['payload']) => Extract<Actions, { type: Type }>;

    export type Action<Type extends Actions['type']> = Extract<Actions, { type: Type }>;

    export type Actions =
        InitCreator |
        ChangeItem |
        ChangeItemNews |
        PostNews |
        ValidateNews;
};