import React, { FC, useMemo } from 'react';
import { TableVirtualizedItemProps } from "muicomponents/src/TableVirtualized";
import { CellBox, HeadCellBox, SpaceBox } from './Cell.styled';
import { styled } from 'muicomponents/src';
import { lineClampStyles } from 'blocks/UsersEvents/UsersEvents.constants';
import { BottomBorderedBox } from '../UsersEventsTable.styled';
import { cn } from '@bem-react/classname';

const cnCell = cn('TableCell');

const HeadUserWrapper = styled('div')({
    // display: 'flex',
    // flexDirection: 'column',
    // minHeight: '100%',
    // height: '100% !important',
    ['& .TableCell']: {

        flex: 1
    },
    ['& .TableCell > .Box:first-child']: {
        flex: 1
    }
});

export const CellComponent: FC<TableVirtualizedItemProps & {
    isHeadUser: boolean;
    level?: number;
    className?: string;
}> = (({
    isHeadUser,
    level,
    ...props
}) => {

    const evenLevel = useMemo(() => {
        return !((level || 0) % 2);
    }, [level]);

    if (isHeadUser) {
        return (
            <HeadCellBox
                {...props}
                level={level}
                evenLevel={evenLevel}
                className={cnCell({ level: evenLevel ? 'even' : 'odd' }, [props.className])}
                ref={props.registerChild as any}
            >
                {props.children}
                <SpaceBox className='SpaceBox' />
            </HeadCellBox>
        )
    }
    return <CellBox
        {...props}
        level={level}
        evenLevel={evenLevel}
        className={cnCell({ level: evenLevel ? 'even' : 'odd'  }, [props.className])}
        ref={props.registerChild}
    />
});

export const TableCellNamesHeaderBox = styled(CellComponent)({
    maxWidth: 200,
    minWidth: 200,
    display: "flex",
    alignItems: 'baseline'
}) as typeof CellComponent;

export const TableCellNamesSubLoaderBox = styled('span')({
    marginLeft: 'auto'
});

export const TableCellDaysBox = styled(CellComponent)({
    maxWidth: 80,
    textAlign: 'center'
}) as typeof CellComponent;

export const TableCellDivisionBox = styled(CellComponent)({
    maxWidth: 260
}) as typeof CellComponent;

export const MoreCellComponent = styled(CellComponent)({
    paddingBottom: 0,
    paddingTop: 0,
    borderRightWidth: 0
}) as typeof CellComponent;