import React, { FC } from 'react';
import { IOrdersListProps, mapDispatchToProps, mapStateToProps } from './OrdersList.index';
import { ListHeader } from 'muicomponents/src/ListHeader/ListHeader';
import { OrderListItem } from 'muicomponents/src/OrderListItem/OrderListItem';
import { InfinityList } from 'muicomponents/src/InfinityList/InfinityList';
import { useScrollListState, useDebouncedQueryState } from 'utils/src/hooks';
import { getOrders } from 'utils/src/requests/requests.shop';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Translate, i18n } from 'localization';
import { useLazyQueryEx } from 'utils/src/hooks';
import { orderCancel } from 'utils/src/requests/requests.shop';
import { orderV4 } from 'utils/src/requests/requests.shop';
import { openShopMakeOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import { IShopOrder, IShopOrderOptions } from 'utils/src/requests/models/api.shop';
import { connect } from 'react-redux';
import { openShopAgreeToParticipateInMultiOrder } from 'blocks/Dialogs/Shop/ShopAgreeToParticipateInMultiOrder/ShopAgreeToParticipateInMultiOrder';
import { toast } from 'react-toastify';
import { currentUserMinusMyThanksCount, currentUserPlusMyThanksCount } from 'utils/src/CommonRedux/users/actions';
import { IShopMakeShopOrderPayload } from 'redux/shop/actions.interfaces';
import { OrderManageTypes } from 'blocks/Dialogs/Shop/ShopAgreeToParticipateInMultiOrder/ShopAgreeToParticipateInMultiOrder.index';
import { checkResponseStatus } from 'utils';
import { getLastPreOrder } from 'muicomponents/src/OrderListItem';

async function getOrdersPromise(opt: any) {
    const response = await getOrders(opt);
    return await response.r;
}
async function orderCancelPromise(id: number) {
    const response = await orderCancel(id);
    return await response.r;
}
async function orderV4Promise(id: number, count: number, opts?: IShopOrderOptions) {
    const response = await orderV4(id, count, opts);
    return await response.r;
}
const currencyNameSelector = (state: any) => {
    return {
        currencyNameNominativeSingular: state.store.appSettings.currencyNameNominativeSingular,
        currencyNameGenitivePlural: state.store.appSettings.currencyNameGenitivePlural,
        currencyNameGenitiveSingular: state.store.appSettings.currencyNameGenitiveSingular,
    };
};
const method = (search?: string) =>
    async function (skipCount: number, count: number) {
        return (await getOrdersPromise({ skipCount, count, search })).data;
    };

const OrdersListPresenter: FC<IOrdersListProps> = ({ uid }) => {
    const [search, searchQuery, setSearch] = useDebouncedQueryState('search');

    const {
        isFinished,
        isLoading,
        loadMore,
        triggerRef,
        values,
        error: { withError },
        actions: { updateItem },
    } = useScrollListState(method(searchQuery as string), [searchQuery]);

    const { send, isLoading: idCanceling } = useLazyQueryEx((id: number) => orderCancelPromise(id));
    const { send: sendV4, isLoading: loadingV4 } = useLazyQueryEx(
        (id: number, count: number, opts?: IShopOrderOptions) => orderV4Promise(id, count, opts),
        { allowUndefinedData: true }
    );

    /**
     * @todo добавить сообщение в случае ошибки
     * @param item
     */

    const dispatch = useDispatch();
    const createHandlerFunc = (item: IShopOrder) => {
        const {
            id: orderId,
            additionalFields,
            characteristics,
            userComment: comment,
            forUser: presentFor,
            thanksPrice,
            productName: name,
            multiOrderItem,
            investedValue: alreadyInvested,
            investors,
            user: purchaseOrganizer,
            productId,
            productCount,
            status,
        } = item;
        const currentInvestorObj = Array.isArray(investors) && investors.find((investor) => investor.id === uid);
        const maxInvestedValue =
            typeof alreadyInvested === 'number' && !Number.isNaN(alreadyInvested)
                ? thanksPrice - alreadyInvested + (currentInvestorObj?.invested || 0)
                : undefined;
        const updateInvestor = (
            newInvestedValue: number,
            newInviteToGroupOrder: IShopMakeShopOrderPayload['inviteToGroupOrder'],
            investorStatus?: 'investor' | 'invited' | 'refused',
            orderStatus?: string | 'Cancelled' | 'AwaitingApproval' | 'Investing' | 'OnVerification'
        ) => {
            const newInvestorsArr: typeof investors = Array.isArray(investors)
                ? investors
                      .filter((investor) => {
                          return (
                              !newInviteToGroupOrder ||
                              newInviteToGroupOrder
                                  .map((idObj) => {
                                      return idObj.id;
                                  })
                                  .includes(`${investor.id}`)
                          );
                      })
                      .map((investor) => {
                          return investor.id === uid
                              ? {
                                    ...investor,
                                    invested: newInvestedValue || 0,
                                    groupOrderStatus: investorStatus || investor.groupOrderStatus,
                                }
                              : investor;
                      })
                : investors;
            const newAlreadyInvested =
                (Array.isArray(newInvestorsArr) &&
                    newInvestorsArr.reduce((sum, investor) => {
                        return sum + investor.invested;
                    }, 0)) ||
                alreadyInvested;
            updateItem(item, {
                ...item,
                investedValue: newAlreadyInvested,
                investors: newInvestorsArr,
                status: orderStatus || newAlreadyInvested === thanksPrice ? 'AwaitingApproval' : status,
            });
        };

        const onRefusal = async function () {
            if (currentInvestorObj?.groupOrderStatus !== 'refused') {
                const opts: IShopOrderOptions = {
                    groupOrderId: orderId,
                    presentFor: presentFor ? [{ id: presentFor.id }] : [],
                    characteristics,
                    investedValue: +0,
                };
                try {
                    await sendV4(+productId, productCount || 1, opts);
                    updateInvestor(0, undefined, 'refused');
                    dispatch(currentUserPlusMyThanksCount(currentInvestorObj?.invested || 0));
                    toast.success(Translate.t({ i18nKey: 'pryaniky.shop.order.jointPurchase.notification.refused' }));
                } catch (error) {
                    console.error(error);
                }
            }
        };

        return {
            onCancle: async function () {
                try {
                    await send(item.id);
                    updateItem(item, {
                        ...item,
                        status: 'Cancelled',
                    });
                    toast.success(Translate.t({ i18nKey: 'pryaniky.shop.order.jointPurchase.notification.cancle' }));
                } catch (e) {
                    console.error(e);
                }
            },
            onCanclePreorder: async function () {
                const preorder = getLastPreOrder(item, uid);

                if (preorder) {
                    const opts: IShopOrderOptions = {
                        isPreorder: true,
                        preOrderId: preorder.preOrderId,
                    };
                    try {
                        const response = await sendV4(+productId, preorder.productsCount, opts);
                        if (checkResponseStatus(response)) {
                            updateItem(item, {
                                ...item,
                                status: 'Cancelled',
                            });
                            toast.success(
                                Translate.t({
                                    i18nKey: 'pryaniky.shop.order.jointPurchase.notification.cancle.preorder',
                                })
                            );
                        } else {
                            toast.error(response.error_text);
                        }
                    } catch (error) {
                        if ((error as { error_text?: string })?.error_text) {
                            toast.error((error as { error_text?: string })?.error_text || '');
                        }
                        console.error(error);
                    }
                }
            },
            onChange: async function () {
                const value = (await openShopMakeOrder({
                    data: {
                        id: +productId,
                        additionalFields,
                        characteristics,
                        comment,
                        presentFor,
                        thanksPrice,
                        name,
                        isMultiOrder: multiOrderItem,
                        investedValue: currentInvestorObj?.invested,
                        inviteToGroupOrder: investors,
                        groupOrderId: orderId !== undefined ? `${orderId}` : undefined,
                    },
                    multiOrderItem,
                    purchaseOrganizer,
                    typeForm: 'changeOrder',
                    selectedCount: productCount,
                    minInvestedValue: currentInvestorObj?.invested || 0,
                    maxInvestedValue,
                    isWithRequest: true,
                })) as IShopMakeShopOrderPayload;
                const { investedValue: newInvestedValue, inviteToGroupOrder: newInviteToGroupOrder } = value;
                newInvestedValue &&
                    dispatch(currentUserMinusMyThanksCount(newInvestedValue - (currentInvestorObj?.invested || 0)));
                updateInvestor(newInvestedValue || thanksPrice, newInviteToGroupOrder, 'investor');
                toast.success(Translate.t({ i18nKey: 'pryaniky.shop.order.jointPurchase.notification.changeOrder' }));
            },
            onParticipate: (typeForm?: OrderManageTypes) =>
                async function () {
                    const value = (await openShopAgreeToParticipateInMultiOrder({
                        orderData: item,
                        fullCost: thanksPrice,
                        investedValue: currentInvestorObj?.invested,
                        maxInvestedValue,
                        minInvestedValue: Math.max(currentInvestorObj?.invested || 0, 1),
                        alreadyInvested: alreadyInvested || undefined,
                        typeForm,
                        onRefuse: typeForm === OrderManageTypes.makeAgreeToParticipate ? onRefusal : undefined,
                        onClose: () => {},
                        investorStatus: currentInvestorObj?.groupOrderStatus,
                        isWithRequest: true,
                    })) as IShopMakeShopOrderPayload;

                    const { investedValue: newInvestedValue, inviteToGroupOrder: newInviteToGroupOrder } = value;

                    newInvestedValue &&
                        dispatch(currentUserMinusMyThanksCount(newInvestedValue - (currentInvestorObj?.invested || 0)));
                    updateInvestor(newInvestedValue || thanksPrice, newInviteToGroupOrder, 'investor');
                    typeForm === 'makeAgreeToParticipate'
                        ? toast.success(
                              Translate.t({ i18nKey: 'pryaniky.shop.order.jointPurchase.notification.participate' })
                          )
                        : toast.success(
                              Translate.t({
                                  i18nKey: 'pryaniky.shop.order.jointPurchase.notification.chageParticipate',
                              })
                          );
                },
            onRefusal,
        };
    };

    const currencyNames = useSelector(currencyNameSelector);
    return (
        <Box>
            <ListHeader
                searchValue={search}
                onChangeSearch={(e) => {
                    setSearch(e.target.value);
                }}
            />

            <Paper component="div" sx={{ marginTop: '16px', padding: '8px 0px' }}>
                <InfinityList
                    isError={withError}
                    triggerRef={triggerRef}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={values.length}
                    titleEmpty={i18n.t('pryaniky.OrdersList.titleEmpty')}
                    textEmpty={
                        searchQuery && searchQuery !== ''
                            ? i18n.t('pryaniky.OrdersList.textEmpty', { query: searchQuery })
                            : undefined
                    }
                    loadMore={loadMore}
                >
                    {values.map((item) => {
                        const HandlerFuncsObj = createHandlerFunc(item);
                        return (
                            <>
                                <OrderListItem
                                    key={item.id}
                                    value={item as any}
                                    currencyNames={currencyNames}
                                    cancleProcessing={idCanceling}
                                    otherProcessing={loadingV4}
                                    cancleOrder={HandlerFuncsObj.onCancle}
                                    cancleOrderPreorder={HandlerFuncsObj.onCanclePreorder}
                                    changeOrder={HandlerFuncsObj.onChange}
                                    participateInOrder={HandlerFuncsObj.onParticipate(
                                        OrderManageTypes.makeAgreeToParticipate
                                    )}
                                    changeParticipateInOrder={HandlerFuncsObj.onParticipate(
                                        OrderManageTypes.changeAgreeToParticipate
                                    )}
                                    refusedParticipateInOrder={HandlerFuncsObj.onRefusal}
                                    uid={uid}
                                />
                                <Divider variant="inset" sx={{ marginLeft: '0px' }} />
                            </>
                        );
                    })}
                </InfinityList>
            </Paper>
        </Box>
    );
};
export const OrdersList = connect(mapStateToProps, mapDispatchToProps)(OrdersListPresenter);
