import { Translate } from 'localization';
import { Box, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link/Link';
import React, { FC, useEffect, useRef, useState } from 'react';
import { CacheHelper } from 'utils/src/CacheHelper';
import { IRatingsData } from 'utils/src/requests/models/api.ratings';
import * as utils from 'utils/src/utils';
import { IUserRatingsProps } from './UserRatings.index';
import { UserRatingsSkeleton } from './UserRatingsSkeleton';
import { ColoredBorderLinearProgress, FlexBox, MarkerAvatar, ProgressBox } from './styled';

export const UserRatings: FC<IUserRatingsProps> = (props: IUserRatingsProps) => {
    const [ratings, setRatings] = useState<IRatingsData[]>();
    const [loading, setLoading] = useState(false);
    const serverData = useRef(false);

    const getData = () => {
        const context = props.context;
        const cacheKey = `ratings.byUser_${context.uId || 0}_${context.rIds || 0}`;
        let ignoreCache = false;
        setLoading(true);
        CacheHelper.get('userRating', cacheKey)
            .then((value) => {
                if (serverData.current || ignoreCache) return;
                value && setRatings(value.data);
                setLoading(false);
            })
            .catch((e) => console.warn('CacheHelper, userRating:', e));

        utils.API.ratings.byUser(context.uId, context.rIds).r.then((response) => {
            if (utils.checkResponseStatus(response)) {
                ignoreCache = true;
                CacheHelper.set('userRating', cacheKey, utils.clone(response))
                    .then(() => {
                        serverData.current = true;
                        setRatings(response.data);
                        setLoading(false);
                    })
                    .catch((e) => console.warn('CacheHelper, userRating:', e));
            }
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading) return <UserRatingsSkeleton />;
    if (!ratings?.length)
        return (
            <>
                <Typography>
                    <Translate i18nKey={'pryaniky.placeholder.widget.nodata'} />
                </Typography>
            </>
        );

    return (
        <>
            {ratings?.map((rating, i) => {
                return (
                    <Box key={i}>
                        {rating.groupName && (
                            <Typography variant="h6" sx={{ mt: 4 }}>
                                {rating.groupName}
                            </Typography>
                        )}

                        {rating.ratingValues.map((elem) => {
                            const percent: number = parseInt(elem.percantage, 10);
                            return (
                                <Box key={elem.id} sx={{ mt: 1 }}>
                                    <FlexBox>
                                        <Avatar src={elem.imgUrl} sx={{ mr: 2 }} />
                                        <Box>
                                            <Link href={elem.url || ''}>
                                                <Typography variant="body2" color="primary">
                                                    {elem.name}
                                                </Typography>
                                            </Link>
                                            <Typography>
                                                <Typography display="inline" variant="body2">
                                                    {elem.value}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    </FlexBox>

                                    <ProgressBox>
                                        <ColoredBorderLinearProgress
                                            customColor={elem.color}
                                            variant="determinate"
                                            value={percent}
                                        />
                                        {elem.markerImgUrl && (
                                            <MarkerAvatar src={elem.markerImgUrl} sx={{ left: `${percent - 2}%` }} />
                                        )}
                                    </ProgressBox>
                                </Box>
                            );
                        })}
                    </Box>
                );
            })}
        </>
    );
};
