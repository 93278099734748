import * as React from 'react';
import * as utils from 'utils/src/utils';

import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";
import { AchievementsLoaderWidget } from '../../AchievementsLoader/AchievementsLoader'
import { useSelector } from 'react-redux';
import { getCurrentAllowPostTypes } from 'redux/sagas/AppSettings/selectors';

const type = 'LoadAchievements';


const Wrapper = (props: any) => {

  const pt = useSelector(getCurrentAllowPostTypes(-1, undefined, 'achievements'))
  // console.log('pt', pt)
  const withAM = pt?.find((v: any) => v.id === "achievements.moderation")
  if (!withAM) return null
  return (
    <widgets.components.common {...props} className={cnWidget({ type })}>
      <AchievementsLoaderWidget />
    </widgets.components.common>
  )
}

export default class WidgetTypeLoadAchievementsPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    let { tag: TagName = 'div' } = this.props;
    // console.log('LoadAchievements', this.props)

    if (this.props.edit)
      return (
        <widgets.components.common {...this.props} className={cnWidget({ type })}>
          LoadAchievements edit
        </widgets.components.common>
      );



    return <Wrapper {...this.props} />
  }
}