import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { getClientStructure, updateContext } from 'redux/actions/Widgets'
import { IWidget } from 'i.widgets';

export interface IIntegrateProps extends IClassNameProps, IIntegrateOwnProps, RouteComponentProps<IIntegrateUrlParams>, IDispatchProps, IActionsIntegrate, IIntegrateStateProps {
  tag?: string | React.ComponentType<any>;
}

export interface IIntegrateOwnProps {
  data: any;
  widget: IWidget;
}

export interface IIntegrateUrlParams {
  type: string;
  id?: string;
  subtype?: string;
}

export interface IIntegrateState {
  text?: string;
}

export interface IActionsIntegrate {}

export const mapActionsToPropsPage = {};

export interface IIntegrateStateProps {}

export const mapStateToProps = (state: any, props: IIntegrateProps) => ({})

export const cnIntegrate = cn('Integrate');