import { EditorState } from 'draft-js';
import { createContext } from 'react';

interface NewsCommentsContextProps {
    text: EditorState;
    onChangeText: (newState: EditorState) => void;
}

export const NewsCommentsContext = createContext<NewsCommentsContextProps>({
    text: EditorState.createEmpty(),
    onChangeText: () => {},
});
