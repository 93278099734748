import { Translate } from "localization";
import { TColumnSimplified } from "Widgets_v2/types";

export const getTranslatedTabName = (tab: TColumnSimplified) => {
  const idKey = `pryaniky.custom.tabs.${tab.id}`;
  const nameKey = `pryaniky.tabs.${tab.name}`;
  let text = Translate.t({ i18nKey: idKey });
  if(text === idKey) {
    text = Translate.t({ i18nKey: nameKey })
  }
  if(text === nameKey) {
    text = tab.title;
  }
  return text;
};