import React from 'react';
import { cnWidgetMui } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from 'i.widgets';
import { IWidgetTypeCurrentApplicationsProps } from './Widget_type_currentApplications.index';
import './Widget_type_currentApplications.scss';
import { CurrentApplicationsWidget } from 'blocks/CurrentApplicationsWidget/CurrentApplicationsWidget';
const type = 'currentApplications';

export default class WidgetTypeCurrentApplicationsPresenter extends WidgetPresenter<IWidgetTypeCurrentApplicationsProps> {
    public render() {
        if (!this.props.widget) return null;
        const subtype = utils.widgetSubtype(this.props.widget.type);
        return (
            <widgets.components.common {...this.props} className={cnWidgetMui({ type, subtype })}>
                <CurrentApplicationsWidget
                    // contexts={this.relations.concat(this.props.widget.id)}
                    context={this.props.wcontext}
                    isEditable={this.props.widget.isEditable}
                    isEdit={this.props.widget.HTMLWidgetContentEdit}
                    settings={this.props.widget.settings || this.props.widget.data}
                />
            </widgets.components.common>
        );
    }
}
