import { useDispatch, useSelector } from "react-redux";
import { FNewsPostType } from "utils/src/BaseTypes/news.types";
import { newsCreatorItemNewsChange } from "../../redux/actions.newsCreator";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "../../redux/selectors.newsCreate";
import { NewsCreateReducer } from "../../redux/types.newsCreator";
import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import { useContext } from "react";
import { NCreativeTasks } from "./CreativeTasks.interface";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
import { DeepPartial } from "utils/src";

export const useCreativeTasksEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const { groupId, workflowId, newsType, all } = useContext(NewsCreateContext);

    const data = useSelector(getNewsCreateItemNewsFields(['header', 'text', 'expires', 'thanksCount', 'creativeTask', 'draftRawState'])(id));

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews'])(id));

    const renderParams = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', groupId, workflowId, newsType, all))?.renderParams as NCreativeTasks.RenderParams | undefined;

    const changeNews = (news: DeepPartial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    };

    return {
        data,
        renderParams,
        ...creatorFields,
        changeNews
    };
};