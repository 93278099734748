import { put, takeLeading, takeEvery, takeLatest } from 'redux-saga/effects';
import { call, select } from 'utils/src/saga.effects';

import { GET_CURRENCY_TIMELINE, setCurrencyTimeline, setCurrencyTimelineLoadingFinished } from './actions';

import { IGetCurrencyTimelineAction } from './actions.interfaces';

import {} from './interfaces';

import { IGetUserTimelineResponse } from 'utils/src/requests/models/api.user.timeline';

import { getUserTimeline } from 'utils/src/requests/requests.user.timeline';

import { newNewsType } from 'utils/src/utils';
import { normalizeNews } from 'News/redux/saga/utils';
import { appendNewsList } from 'News/redux/actions';

import { checkResponseStatus, getErrorText } from 'utils/src/utils';
import { toast } from 'react-toastify';

function* getCurrencyTimeLine(action: IGetCurrencyTimelineAction) {
    try {
        yield put(setCurrencyTimelineLoadingFinished({ loading: true }));
        const { skipCount, count, context, currentUserId, groupValues } = action.payload;
        const response: IGetUserTimelineResponse = yield* call(
            getUserTimeline,
            skipCount,
            count,
            context,
            currentUserId,
            groupValues
        );
        if (checkResponseStatus(response)) {
            const newNews = response.data
                .filter((elem) => elem.transactionModelType !== 'ShopItem' && elem.news)
                .map((elem) => ({
                    ...elem.news,
                    newsTypeMain: newNewsType(elem.news.newstype),
                    timelineInfo: {
                        value: elem.value,
                        transactionType: elem.transactionType,
                        isDeleted: elem.isNewsDeleted || elem.isReplyDeleted,
                    },
                }));

            yield put(appendNewsList({ ...normalizeNews(newNews), group: 'ct/' + currentUserId }));
            yield put(setCurrencyTimeline(response.data));

            yield put(setCurrencyTimelineLoadingFinished({ loading: false, finished: response.isFinished }));
        } else {
            toast.error(getErrorText(response));
            yield put(setCurrencyTimelineLoadingFinished({ loading: false, finished: false }));
        }
    } catch (e) {
        yield put(setCurrencyTimelineLoadingFinished({ loading: false, finished: false }));
        console.error('get currency timeline error', e);
    }
}

const currencyTimeline = function* currencyTimeline() {
    yield takeLatest(GET_CURRENCY_TIMELINE, getCurrencyTimeLine);
};

export default currencyTimeline;
