import { connect } from 'react-redux';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { Skeleton } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { cnVirtcurrency, cnVirtcurrencyNew, IVirtcurrencyProps, mapStateToProps, mapActionsToProps } from './Virtcurrency.index';
import './Virtcurrency.scss';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { AskingHand, Diamond } from 'muicomponents/src/Icons';
import { Icon as IconMUI } from 'muicomponents/src/Icons/Icon';
import { Wallets } from 'blocks/Wallets/Wallets';
import { Box, Button as MuiButton, Typography } from 'muicomponents/src'
import CanGive from './CanGive.svg';
import CanShop from './CanShop.svg';
import Team from './Team.svg';
import { CustomSettings } from 'utils/src';
import { TypographyText, TypographyCurrency, ContentBox } from './style'
import CardMedia from '@mui/material/CardMedia';
import CurrencyTooltip from 'blocks/CurrencyTooltip';

const useMoexDesign = CustomSettings.useMoexDesign();

export class VirtcurrencyPresenter extends React.Component<IVirtcurrencyProps, {}> {
  private hideForAll: boolean = false;

  constructor(props: IVirtcurrencyProps) {
    super(props);
    const { context } = this.props;
    this.hideForAll = context.fontsize === 'hideForAll';
  }

  public componentDidMount() {
    const { context } = this.props;
    if ((context.integrated || useMoexDesign) && context.uId) {
      this.props.updateContext('common', {
        mythanksCount: -1,
        thanksForAll: -1,
        thanksForSubordinate: -1,
      });

      utils.API.users.byId(context.uId, { fullData: true }).r.then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          this.props.updateContext('common', {
            mythanksCount: d.data.extData.mythanksCount,
            thanksForAll: d.data.extData.thanksForAll,
            thanksForSubordinate: d.data.extData.thanksForSubordinate,
          });
        } else {
          // toast.error('something wrong')
        }
      });
    }

  }

  public render() {
    const customClassNames = '';
    const {
      tag: TagName = 'div',
      children,
      className = '',
      authUser,
      mythanksCount,
      thanksForAll,
      context,
      additionalColor1
    } = this.props;
    const additionalProps: { [s: string]: any } = {
      hide: false,
    };

    // const user = useSelector(getUserById(authUser.baseData.id === context.uId ? authUser.baseData.id : context.uId, true))
    const collection = authUser.extData.thanksForAllByCollection || {};
    const collectionThanksForSubordinate = authUser.extData.thanksForSubordinate;
    const collectionKeys = Object.keys(collection)
    const props = { ...this.props };
    const hideForAll = context.fontsize === 'hideForAll' // магия для интеграции


    const userControl = authUser.baseData.isAdmin || !context.uId || authUser.baseData.id === context.uId || decodeURI(context.uId).replace(' ', '\\').split('\\').map(el => el.toLowerCase()).includes(authUser.baseData.externalUserId.toLowerCase())

  
    if (!authUser) return null;

    if (useMoexDesign) {
      return <TagName {...props} {...additionalProps} className={cnVirtcurrencyNew({}, [customClassNames, className])}>
        <Typography
          variant="subtitle1"
          className={cnVirtcurrencyNew('Name')}
          children={i18n.t('pryaniky.widgets.virtcurrency.my.mems')} />

        <ContentBox sx={{ paddingBottom: "8px" }} className={cnVirtcurrencyNew('ContentBox')}>

          {userControl && (
            <Box className={cnVirtcurrencyNew('Block')}>


              <Box className={cnVirtcurrencyNew('Content')}>
                <CardMedia
                  component="svg"
                  className={cnVirtcurrencyNew("CardArticleItem-Avatar")}
                  image={CanGive}
                />
                <Box className={cnVirtcurrencyNew('Text')}  >
                  <TypographyText
                    variant="caption"
                    className={cnVirtcurrencyNew('Text-Count')}
                    children={i18n.t('pryaniky.widgets.virtcurrency.forGive.mems')}
                  />
                  <CurrencyTooltip
                    userId={context.uId || authUser.baseData.id}
                    summaryRender={(sum) =>
                      <TypographyCurrency
                        variant="h5"
                        className={cnVirtcurrencyNew('Text-Count2', { loading: context.mythanksCount === -1 })}
                      >
                        {typeof context.thanksForAll === "number" ? (
                          sum >= 0 ? (
                            sum
                          ) : (
                            <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                          )
                        ) : (
                          sum
                        )}
                      </TypographyCurrency>
                    }
                  />
                  
                </Box>
              </Box>

            </Box>
          )}
          {userControl && (
            <Box className={cnVirtcurrencyNew('Block')}>
              <Box className={cnVirtcurrencyNew('Content')}>
                <CardMedia
                  component="svg"
                  className={cnVirtcurrencyNew("CardArticleItem-Avatar")}
                  image={Team}
                />
                <Box className={cnVirtcurrencyNew('Text')}  >
                  <TypographyText
                    variant="caption"
                    className={cnVirtcurrencyNew('Text-Count')}
                    children={i18n.t('pryaniky.widgets.virtcurrency.forGive.Team.mems')}
                  />
                  <TypographyCurrency
                    variant="h5"
                    className={cnVirtcurrencyNew('Text-Count2', { loading: context.mythanksCount === -1 })}
                  >
                    {typeof context.thanksForSubordinate === "number" ? (
                      context.thanksForSubordinate >= 0 ? (
                        context.thanksForSubordinate
                      ) : (
                        <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                      )
                    ) : (
                      collectionThanksForSubordinate
                    )}
                  </TypographyCurrency>
                </Box>
              </Box>

            </Box>
          )}

        </ContentBox>

        <ContentBox className={cnVirtcurrencyNew('ContentBox')}>
          {
            !hideForAll &&
            <Box className={cnVirtcurrencyNew('Block')}>
              {/* <h4 className={cnVirtcurrencyNew('Header')}>{i18n.t('pryaniky.widgets.virtcurrency.forGive')}</h4> */}
              <Box className={cnVirtcurrencyNew('Content')}>

                <CardMedia
                  component="svg"
                  className={cnVirtcurrencyNew("CardArticleItem-Avatar")}
                  image={CanShop}

                />
                <Box className={cnVirtcurrencyNew('Text')} >
                  <TypographyText
                    variant="caption"
                    className={cnVirtcurrencyNew('Text-Count')}
                    children={i18n.t('pryaniky.widgets.virtcurrency.forSpend.mems')}
                  />

                  <TypographyCurrency
                    variant="h5"
                    className={cnVirtcurrencyNew('Text-Count2', { loading: context.mythanksCount === -1 })}
                  >
                    {typeof context.mythanksCount === "number" ? (
                      context.mythanksCount >= 0 ? (
                        context.mythanksCount
                      ) : (
                        <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                      )
                    ) : (
                      mythanksCount
                    )}
                  </TypographyCurrency>
                </Box>
              </Box>
            </Box>
          }

          <MuiButton
            color='primary'
            className={cnVirtcurrencyNew('Title-GoToShopBTN')}
            variant={'contained'}
            href={`/shop`}
          >
            {i18n.t('pryaniky.widgets.virtcurrency.shop')}
          </MuiButton>
        </ContentBox>


      </TagName>
    }
    return (
      <TagName {...props} {...additionalProps} className={cnVirtcurrency({}, [customClassNames, className])}>
        {(authUser.baseData.isAdmin || !context.uId || authUser.baseData.id === context.uId || decodeURI(context.uId).replace(' ', '\\').split('\\').map(el => el.toLowerCase()).includes(authUser.baseData.externalUserId.toLowerCase())) && (
          <div className={cnVirtcurrency('Block')}>
            <h4 className={cnVirtcurrency('Header')}>{i18n.t('pryaniky.widgets.virtcurrency.my.finances')}</h4>
            <div className={cnVirtcurrency('Content')}>
              <IconMUI color={"action"} className={cnVirtcurrency('Icon')}>
                <AccountBalanceWalletIcon color={"action"} />
              </IconMUI>
              <div className={cnVirtcurrency('Text')}>
                <span className={cnVirtcurrency('Text-Count')}>{i18n.t('pryaniky.widgets.virtcurrency.forSpend')}</span>
                <span className={cnVirtcurrency('Text-Count2', { loading: context.mythanksCount === -1 })}>
                  {context.mythanksCount ? (
                    context.mythanksCount >= 0 ? (
                      context.mythanksCount
                    ) : (
                      <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                    )
                  ) : (
                    mythanksCount
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
        {
          !hideForAll &&
          <div className={cnVirtcurrency('Block')}>
            <div className={cnVirtcurrency('Content')}>
              <IconMUI color={"action"} className={cnVirtcurrency('Icon')} >
                <AskingHand color={"action"} />
              </IconMUI>
              <div className={cnVirtcurrency('Text')} >
                <span className={cnVirtcurrency('Text-Count')}>{i18n.t('pryaniky.widgets.virtcurrency.forGive')}</span>
                <span className={cnVirtcurrency('Text-Count2', { loading: context.thanksForAll === -1 })}>
                  {context.thanksForAll ? (
                    context.thanksForAll >= 0 ? (
                      context.thanksForAll
                    ) : (
                      <Skeleton size="md" shape="line" style={{ width: '100%' }} />
                    )
                  ) : (
                    thanksForAll
                  )}
                </span>
              </div>
            </div>
          </div>
        }

        {collectionKeys.map((key: string) => <div className={cnVirtcurrency('Block')}>
          <div className={cnVirtcurrency('Content')}>
            <IconMUI color={"action"} className={cnVirtcurrency('IconColl')}>
              <Diamond color={"action"} />
            </IconMUI>
            <div className={cnVirtcurrency('Text')}>
              <span className={cnVirtcurrency('Text-Count')}>{collection[key].name}</span>
              <span className={cnVirtcurrency('Text-Count2')}>
                {collection[key].value}
              </span>
            </div>
          </div>
        </div>)}
        <Wallets uid={authUser.baseData.id} />
        {children}
      </TagName>
    );
  }
}

export const Virtcurrency = connect(mapStateToProps, mapActionsToProps)(VirtcurrencyPresenter);