import { EditorState } from 'draft-js';
import React, { ComponentProps, createContext, FC, useContext } from 'react';

interface DistributeFormContextProps {
    text: EditorState;
    onChangeText: (newState: EditorState) => void;
}

const DistributeFormContext = createContext<DistributeFormContextProps>({
    text: EditorState.createEmpty(),
    onChangeText: () => {},
});

export const useDistributeFormContext = () => useContext(DistributeFormContext);

export const DistributeFormContextProvider: FC<ComponentProps<typeof DistributeFormContext['Provider']>> = ({children, ...props}) => {
    return (
        <DistributeFormContext.Provider {...props}>
            {children}
        </DistributeFormContext.Provider>
    )
}