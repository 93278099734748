import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    StatisticsFull,

} from './interfaces'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import './style.scss'
import { SearchInput } from 'uielements/src';
import { Button } from 'uielements/src';
import { formatDateSTD } from 'utils/src/utils.dates'
import { tSt } from 'LMSModule/utils/i18n.adaptor'
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { Avatar, AvatarSize } from 'muicomponents/src/Avatar/Avatar';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';

type IPresenterProps = StatisticsFull.UsersListItem.IPresenterProps
type IOwnProps = StatisticsFull.UsersListItem.IOwnProps
type IDispatchProps = StatisticsFull.UsersListItem.IDispatchProps
type IStateProps = StatisticsFull.UsersListItem.IStateProps
type IState = StatisticsFull.UsersListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    courseId,
    sessionId,
    status,
    courseName,
    authorUser,
    sessionName,
    beginDate,
    endDate,
    courseTime,
    progress
}) => {
    useEffect(() => {

    }, [])
    return <tr className={cnClassName({}, [className])}>
        <td className={cnClassName('Col1')}><Button href={`/TeacherOffice/Course/${courseId}`} type={'rlink'} theme={'unstyled'}>{courseName}</Button></td>
        <td className={cnClassName('Col2')}>
            <div className={cnClassName('UserItem')}>
                <UserMention id={authorUser.id}>
                    <Link className={cnClassName('UserMention')} href={appGeneratePath(mainUrls.user.id, { id: authorUser.id })}>
                        <Avatar className={cnClassName('UserAvatar')} src={authorUser.imgUrl} size={AvatarSize.l}>
                            {authorUser.displayName.substring(0,1)}
                        </Avatar>
                    </Link>
                </UserMention>
                {/* <div className={cnClassName('UserType')}>Преподаватель</div> */}
                {/* </div> */}
            </div>
        </td>
        {/* <td className={cnClassName('Col3')}>{ }</td> */}
        <td className={cnClassName('Col4')}>{formatDateSTD(beginDate)}</td>
        <td className={cnClassName('Col5')}>{formatDateSTD(endDate)}</td>
        <td className={cnClassName('Col6')}>{courseTime}</td>
        <td className={cnClassName('Col7')}>{tSt('item.user.col.' + status)}</td>
        <td className={cnClassName('Col8')}>{progress}</td>
    </tr>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));
