import React, { FC, useEffect, useState } from 'react'
import { cnItem, mapDispatchToProps, mapStateToProps } from './Item.index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import './Item.scss'
import { Button, Avatar } from 'uielements/src';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

const getFirstOrDefault = (array: any[], defaultValue: any = null) => {
    if (array.length > 0) return array[0]
    return defaultValue
}

export const ItemPresenter: FC<any> = ({ className, student, cid, unitCount }) => {
    if (!student.user) return <div className={cnItem({}, [className])}>USER NULL</div>
    const { logs, user, userId, completeUnitCount } = student
    const firsLog = getFirstOrDefault(logs || [], { sessionId: '0', completePercentage: 0 })
    const currentStep = completeUnitCount;//Math.round(firsLog.completePercentage / (100 / unitCount))
    const link = `/TeacherOffice/Course/${cid}?tab=student&sid=${firsLog.sessionId}&uid=${userId}`;

    return <div className={cnItem({}, [className])}>
        <div className={cnItem('AvatarBox')}>
            <Avatar className={cnItem('Avatar')} size={56} imgUrl={user.userPhotoUrl} name={user.displayName} />
        </div>
        <div className={cnItem('Info')}>
            <div>
                <UserMention id={user.id}>
                    <Link className={cnItem('Title', [ classNamesDesign.primaryColor3.text ])} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>
                        {user.displayName}
                    </Link>
                </UserMention>
            </div>
            <div>{user.position}</div>
            <div>{user.division}</div>
        </div>
    </div>
}

export const BasicItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ItemPresenter));
