import { Box, styled } from 'muicomponents/src';

export const InfoDelivery = styled(Box)({
    display: 'flex',
    alignItems: 'start',
    gap: 8,
    order: 2,
}) as typeof Box;

export const InfoOrder = styled(Box)({
    order: 9,
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flexWrap: 'wrap',
}) as typeof Box;

export const ProductContainer = styled(Box)({
    display: 'flex',
    marginBottom: 16,
    position: 'relative',
}) as typeof Box;

export const IsGiftContainer = styled(Box)({
    position: 'absolute',
    top: '8px',
    left: '8px',
    zIndex: '60',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
}) as typeof Box;

export const InfoBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginLeft: '24px',
    width: '60%',
    '.CommonmarkRender-Paragraph': {
        fontWeight: '500',
    },
}) as typeof Box;

export const InfoActionsBox = styled(Box)({
    order: 13,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'flex-start',
}) as typeof Box;
