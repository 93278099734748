// import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import { stateToProps, mapDispatchToProps } from "../../redux/connector";
import ReactMarkdown from 'react-markdown';
import { Button } from 'uielements/src/Button/Button';
import { PryanikyVideo } from 'uielements/src/PryanikyVideo/PryanikyVideo';
import { FileViewer } from 'uielements/src/FileViewer/FileViewer';
import { IPostContentRenderProps, IPostContentRenderState, IPositionType } from './PostContentRender.index';
import breaks from 'remark-breaks'
import { generateLinkData } from 'utils/src/utils'
import './PostContentRender.scss';
import { domainForAuthAdd } from 'utils/src/debug';
import { getCookie } from 'utils/src/utils';
import clsx from 'clsx';
import ucMap from 'blocks/PryanikyEditor/draft-js-emoji-plugin/src/emoji_uc_group.json'
import emojione from 'emojione';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

const unicodeRegex = new RegExp(emojione.unicodeRegexp, 'g');

const findWithRegex = function findWithRegex(regex: any, text: string = '') {
  let matchArr: any = [];
  let start: number = 0; // eslint-disable-line
  let tempText = text;
  // Go through all matches in the text and return the indizes to the callback
  while ((matchArr = regex.exec(text)) !== null) {
    // eslint-disable-line
    if (matchArr.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    start = matchArr.index;
    const end = start + matchArr[0].length;
    const code = text.substring(start, end)

    var re = new RegExp(code, 'g');

    tempText = tempText.replace(re, `[${code}](#EMOJICHEAT-${code})`)

    // callback(start, start + matchArr[0].length);
  }
  return tempText
};



const Emoji = ({
  theme = {},
  className,
  decoratedText,
  ...props
}: any) => {
  const shortName = emojione.toShort(decoratedText);

  if (!emojione.emojioneList[shortName]) return null

  let emojiDisplay = null;

  // short name to image url code steal from emojione source code
  const shortNameForImage =
    emojione.emojioneList[shortName].unicode[
    emojione.emojioneList[shortName].unicode.length - 1
    ];
  // const backgroundImage = `url(${imagePath}${shortNameForImage}.${imageType}${cacheBustParam})`;
  const smile = (ucMap as any)[shortNameForImage]
  emojiDisplay = smile ? (
    <span
      className={`emojione-24-${smile.group} _${smile.uc}`}
      title={emojione.toShort(decoratedText)}
    // style={{ backgroundImage }}
    >
      {props.children}
    </span>
  ) : null;


  return emojiDisplay;
};

const OpenebleImg = ({ src, style, imageArray }: any) => {
  const [open, setOpen] = React.useState(false)
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  let algClass = '';
  if (style.float === 'left') algClass = 'alg-left';
  if (style.float === 'right') algClass = 'alg-right';
  return (
    <React.Fragment>
      <img src={src + (domainForAuthAdd.reduce((acc, curr) => acc || window.location.hostname.indexOf(curr) >= 0, false) ? `?auth=${getCookie('authAt')}` : '')} style={style} className={'img-in-post ' + algClass} alt='img' onClick={onOpen} />
      {open && <FileViewer files={imageArray} id={src} onClose={onClose} />}
    </React.Fragment>

  )
}

export class PostContentRenderPresenter extends React.Component<IPostContentRenderProps, IPostContentRenderState> {
  public static defaultProps = {
    maxLen: 50,
    offsetLen: 25
  }

  public el: HTMLElement | null;

  private imageArray: any[] = [];

  // private isCut: boolean = false;

  constructor(props: IPostContentRenderProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    // this.isCut = false;
    this.state = {
      isOpen: false,
      text: ''
      // text: props.data.split('/Img/Attachments/').join('http://testrating34.pryaniky.com/Img/Attachments/').replace(/@\[([^[\]]*?);([^[\]]*?);[concat]*\]/gi, '[$2](/user/$1)')

    };

    // const found = this.state.text.match(/\[!\[pryanikyVideo-\w{1,}\]\(http[s]*:\/\/[\w{0,}./0-9]*\)\]\(http[s]*:\/\/[\w{0,}./0-9]*\)/gi);

  }


  public getWordPositions(sourcesText: string) {
    const getPositionArray = (str: string, reg: RegExp) => {
      let text = str;
      const positionsArray: IPositionType[] = [];
      let position = 0;
      let gPos = 0;;
      while (position > -1) {
        position = text.search(new RegExp(reg, "gi"))
        if (position > -1) {
          gPos = gPos + position;

          const m = text.match(new RegExp(reg));
          let end = 0
          let value: RegExpMatchArray = [], length = 0;
          if (m) {
            end = gPos + m[0].length
            length = m[0].length;
            value = m;
          }
          positionsArray.push({ start: gPos, end, value, length });
          text = text.slice(position + 1);

        }
        else break;
      }
      return positionsArray;
    }

    const removePosition = (from: IPositionType[], remove: IPositionType[]) => {
      if (remove.length === 0) return from;
      const temp: IPositionType[] = [...from];
      from.forEach(f => {
        remove.forEach(r => {
          const idx = temp.indexOf(f);
          if (r.start - 2 <= f.start && r.end + 2 >= f.end && idx !== -1) temp.splice(idx, 1);// temp[idx] = { start: 0, end:0, value:f.value, length:0 };
        });
      });
      return temp;
    }
    /*
        onsole.log('header count: ', this.state.text.match(/^#{1,1}(.)+$...^#{6,6}(.)+$/gi))
        onsole.log('bold count: ', this.state.text.match(/(?<!\*|\\\*)\*{2,2}[^\*\n].+?[^\*]\*{2,2}(?!\*|\\)/gi))
        onsole.log('image count: ', this.state.text.match(/!\[[^\[\]]*?\]\(.*?\)/gi))
        onsole.log('link count: ', this.state.text.match(/[^!]\[[^\[\]]*?\]\(.*?\)|^\[*?\]\(.*?\)/gi))
        onsole.log('numbered list count: ', this.state.text.match(/(^\d{1,3}\. .*$)+|(^ {1,10}\d{1,3}\. .*$)+/gi))
        onsole.log('hard line count: ', this.state.text.match(/[\n]/gi))
        onsole.log('words list : ', this.state.text.match(/[\s\n]/g))
        onsole.log('word positions', getPositionArray(this.state.text, /[\s\n]/))
        onsole.log('link positions', getPositionArray(this.state.text, /[^!]\[[^\[\]]*?\]\(.*?\)|^\[*?\]\(.*?\)/))
        onsole.log('image positions', getPositionArray(this.state.text, /!\[[^\[\]]*?\]\(.*?\)|^!\[*?\]\(.*?\)/))
        onsole.log('lines positions', getPositionArray(this.state.text, /[\n]/))
    */



    const wordPos = getPositionArray(sourcesText, /[\s\n,.\-=\(\)\"\[\]]/);
    const imagePos = getPositionArray(sourcesText, /!\[[^[\]]*?\]\(.*?\)|^!\[*?\]\(.*?\)/);
    const linkPos = getPositionArray(sourcesText, /[^!]\[[^\[\]]*?\]\(.*?\)|^\[*?\]\(.*?\)/);
    const imgRemoved = removePosition(wordPos, [...linkPos, ...imagePos]);


    return imgRemoved;
  }

  public change = (s: any) => {
    this.imageArray = [];
    this.setState({ isOpen: true })
  };
  public render() {
    let cc = this.props.data ? this.props.data : '';
    cc = findWithRegex(unicodeRegex, cc)

    let nvd = cc
      // .split('/Img/Attachments/').join('http://testrating34.pryaniky.com/Img/Attachments/')
      .replace(/@\[([^[\]]*?);([^[\]]*?);[concat]*\]/gi, '[$2](/user/$1)')
      // .split('-$').join('<span class="underline">')
      // .split('$-').join('</span>')
      ;
    const { maxLen, offsetLen, noCut } = this.props;

    const data = nvd;
    if (!this.state.isOpen && !noCut) {
      const positions = this.getWordPositions(data);

      const wordArr: string[] = data.split('');

      const baseHR = data.indexOf('\n---')

      if (baseHR !== -1) {
        nvd = [data.split('\n---')[0], '\n\n---\n'].join(' ');
      } else if (positions.length > maxLen + offsetLen) {
        wordArr.splice(positions[maxLen].start, wordArr.length);
        nvd = [...wordArr, '...', '\n\n---\n'].join('');
      }
    }


    return (
      <React.Fragment>
        <ReactMarkdown
          className={'Markdown'}
          source={this.state.isOpen || noCut ? data : nvd}
          escapeHtml={false}
          transformLinkUri={null}
          plugins={[breaks]}
          renderers={{
            html: this.htmlRender,
            image: this.image,
            thematicBreak: this.hrRender,
            link: this.linkRender,
            table: this.tableRender,
            delete: this.delete
            // break: this.breakRender,
          }}
        />
        <div className="clear" />
      </React.Fragment>
    )
  }

  public breakRender = (d: any) => {
    return <br />
  }

  public linkRender = (d: any) => {
    if ((d.href as string).includes('#EMOJICHEAT-')) {
      return <Emoji decoratedText={(d.href as string).replace('#EMOJICHEAT-', '')} />
    }
    if (d.children && d.children.length > 0 && d.children[0].props && d.children[0].props.alt && d.children[0].props.src) {
      const type = d.children[0].props.alt.match(/pryanikyVideo-\w{1,}/)[0].split('-')[1];
      return <PryanikyVideo type={type} previewUrl={d.children[0].props.src} dataUrl={d.href} />;
    }
    const splitedHref = d.href.split('/');

    const { href: toLink, target, type: linkType } = generateLinkData(d.href)

    if (toLink.toLowerCase().includes('user')) {
      const uId = splitedHref[splitedHref.length - 1]
      return <UserMention id={uId}>
        <Link className={`${classNamesDesign.primaryColor3.text}`} href={appGeneratePath(mainUrls.user.id, { id: uId })}>
          {d.children}
        </Link>
      </UserMention>
    }

    return (
      <Button type={linkType} target={target} theme='unstyled' onClick={(e: any) => e.stopPropagation()} href={toLink} >
        {d.children}
      </Button>
    )
  }


  public image = (d: any) => {
    let style: any = {
      float: 'none'
    };
    if (d.alt) {
      try {
        style = JSON.parse(d.alt);
      } catch (e) {
        //console.warn('fatal error in JSON.parse, wrong json data in image d.alt:', d.alt)
      }

      style.float = (style.alignment === "right" || style.alignment === "left") ? style.alignment : 'none';
      style.width += '%';
      delete style.alignment;
    }


    // delete style['transition'];
    // delete style.width;
    // return <img src={d.src} style={style} />;
    /* let onLoad = (ev) => {
        if (this.props.notice && ev.target.style.float !== '' && ev.target.style.float !== 'none' && ev.target.parentElement) {
            ev.target.parentElement.classList.add('clear');
        }
    } */


    if (!this.imageArray.find((val: any) => val.id === d.id)) {
      this.imageArray.push({ id: d.src, originalUrl: d.src, action: 'ImagePreview' })
    }
    // if (!self._srcLoad(d.src) && this.state.isOpened === false) 
    // return (<img src={d.src} style={style} className={'img-in-post '} alt='img' />);
    return <OpenebleImg src={d.src} style={style} imageArray={this.imageArray} />
    // return (<img onLoad={onLoad} style={style} src={d.src} data-src={d.src} className={'img-in-post'} />);
  }

  public hrRender = (d: any) => {
    if (!this.state.isOpen/* && !this.isCut*/) {
      // this.isCut = true; 
      return (<span className='ReadMore' onClick={this.change}>Читать дальше...</span>);
    } else {
      return (<hr />);
    }
  };

  public delete(d: any) {
    return <u>{d.children}</u>
  }


  public htmlRender(d: any) {
    if (d.value === '<br>') return <br />;
    return <p />
    // if (d.value.indexOf('iframe') !== -1 && d.value.indexOf('pryanikyVideo') !== -1) {
    //   const links = d.value.match(/http[s]*:\/\/[\w{0,}./0-9]*/gi);
    //   let type = d.value.match(/type="\w{1,}"/)[0].split('=')[1];
    //   type = type.substring(1, type.length - 1);
    //   return <PryanikyVideo type={type} previewUrl={links[0]} dataUrl={links[1]} />;
    // }
    // if (d.value.indexOf('</iframe>') >= 0) return <div dangerouslySetInnerHTML={{ __html: d.value }} />;
    // return <p dangerouslySetInnerHTML={{ __html: d.value }} />;
  }

  public tableRender = (d: any) => {
    return <table className={'table-in-post'}>{d.children}</table>;
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const PostContentRender = PostContentRenderPresenter;
//   compose(
//     withBemMod(cnPostContentRender(), {})
//   )(
//     PostContentRenderPresenter
//   )
// )
