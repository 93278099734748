import { Box, styled } from 'muicomponents/src';
import { ActionButton } from 'muicomponents/src/ItemsListDialog/ActionButton/ActionButton';

export const DatesBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
    ['& .DateRangePicker .MuiInputBase-input.MuiOutlinedInput-input']: {
        padding: '8.5px 14px',
        font: 'inherit',
        flex: 1,
    },
}) as typeof Box;

export const ResetButton = styled(ActionButton)({
    padding: '8px 14px',
}) as typeof ActionButton;
