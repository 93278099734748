import React, { FC, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import {
    WidgetArticlesBox,
    TypographyArticles,
    ListContentBox,
    SkeletonWrapBox,
    SkeletonMainWrapBox,
    MainSkeletonStyle,
    SkeletonStyle,
    EditWrapBox,
    AdditionalButtonsWrapBox,
    DividerStyled,
    UppercaseLink,
    CardMediaWrapBox,
    CardMediaStyle,
    HeaderBox,
    HeaderWrapBox,
    HeaderButtonsBox,
    HeaderButtonsWrapBox,
} from './WikiActueleArticlesWidget.styled';
import { SettingsWikiActueleArticlesWidgetWidget, cnSettingsActueleArticles } from './WikiActueleArticlesWidget.index';
import { initWidgetSettings, initWidgetAddition } from './SettingsActueleArticlesDialog/SettingsActueleArticlesDialog';
import { Translate } from 'localization';
import { IRequest } from 'utils/src/requests/service';
import { Box, Typography } from 'muicomponents/src';
import { getWikiPagesActualList } from 'utils/src/requests/requests.wikilist';
import { WikiListItem } from './Item/ItemWikiActualArticles';
import { checkResponseStatus, mainUrls } from 'utils/src';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'muicomponents/src/Icons';
import Skeleton from '@mui/material/Skeleton';
import emptyArticlesList from 'assets/png/emptyArticlesList.png';
import MDRender from 'muicomponents/src/CommonmarkRender';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'muicomponents/src/Link/Link';
import { CustomSettings } from 'utils/src';
import { Divider } from 'muicomponents/src/Divider';
import { useNodeMediaQuery } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher';
import { generatePath } from 'react-router';
import queryString from 'query-string';
import { useReffedState } from 'utils/src/hooks';

const useMoexDesign = CustomSettings.useMoexDesign();

initWidgetSettings();
initWidgetAddition();

export const WikiActualeArticlesSkeleton = () => {
    return (
        <SkeletonMainWrapBox>
            <SkeletonWrapBox>
                <MainSkeletonStyle variant="text" width={'100%'} />
            </SkeletonWrapBox>
            <Box display="flex" flexDirection={'column'} alignItems="flex-start">
                <SkeletonStyle variant="text" height={'44px'} />
                <SkeletonStyle variant="text" height={'44px'} />
                <SkeletonStyle variant="text" height={'60px'} />
            </Box>
        </SkeletonMainWrapBox>
    );
};

const skeletonArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const minItemWidth = CustomSettings.actualArticlesItemMinWidth() || 230;

export const WikiActueleArticlesWidgetPreseter: FC<SettingsWikiActueleArticlesWidgetWidget.Props> = ({
    settings,
    isEdit,
}) => {
    const requestRef = useRef<IRequest>();

    const [actualContent, setActualContent, actualContentRef] = useReffedState<any[] | undefined>(undefined);
    const [refBreack, point700] = useNodeMediaQuery({ minWidth: 700 });

    const ref = useRef<HTMLDivElement>();

    const [page, setPage] = useState<number>(0);
    const pageRef = useRef(page);
    pageRef.current = page;

    const [count, setCount] = useState(actualContent?.length || skeletonArr.length);
    const countRef = useRef(count);
    countRef.current = count;

    const calculateItemsCount = useCallback(() => {
        if (!ref.current) return;
        let gap = parseFloat(window.getComputedStyle(ref.current).gap);
        if (isNaN(gap)) gap = 0;
        // get current wrapper width
        const width = ref.current.clientWidth;
        // calculate new items count based on min item width plus calculated gap by item
        const itemsForView = Math.max(Math.floor((width - minItemWidth) / (minItemWidth + gap) + 1), 1);
        // calculate real place for new item by new count
        const placeForNewItem = (width - gap * (itemsForView - 1)) / itemsForView;
        // set new count& get new data if count not equal current & we sure about size for new item
        if (countRef.current !== itemsForView && placeForNewItem >= minItemWidth) {
            setCount((prevCount) => {
                setPage((prevPage) => {
                    if (itemsForView >= (actualContentRef.current?.length || skeletonArr.length)) {
                        return 0;
                    }
                    if (prevPage === 0) return 0;
                    // Что тут происходит:
                    // Мы хотим, чтобы самый левый элемент оставался видимым
                    // Мы знаем, что позиция самого левого элемента считается по уравнению page*count + 1
                    // Высчитываем на какой странице будет лежать этот элемент в новой расскладки
                    // Для этого делем на новый count
                    // Минус 1, потому что страницы начинаются с 0, а позиции элементов с 1
                    // округление для целого значения страницы
                    return actualContentRef.current?.length
                        ? Math.ceil(
                              Math.min(prevPage * prevCount + 1, actualContentRef.current.length) / itemsForView - 1
                          )
                        : 0;
                });
                return itemsForView;
            });
            if (!actualContent?.length) getActualContentRef.current(itemsForView);
        }
    }, [actualContent, skeletonArr]);

    const observer = useMemo(() => {
        return new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === ref.current) {
                    calculateItemsCount();
                }
            }
        });
    }, [actualContent, skeletonArr]);

    useEffect(() => {
        calculateItemsCount();
        setPage(0);
        if (ref.current) {
            observer.observe(ref.current!);
        }
    }, [ref.current, actualContent, skeletonArr, settings]);

    const prepairedArr = useMemo(() => {
        return (actualContent || skeletonArr).slice(page * count, count + page * count);
    }, [count, page, actualContent]);

    const backBlock = useMemo(() => {
        return 0 >= page;
    }, [count, page]);

    const forwardBlock = useMemo(() => {
        return count + page * count >= (actualContent?.length || skeletonArr.length);
    }, [count, page, actualContent]);

    const getRubrcsIds = useMemo(() => {
        return settings?.rubric?.map((el: any) => el.id);
    }, [settings?.rubric]);

    const getActualContent = useCallback(
        (countItems: number) => {
            if (!settings?.collection?.alias) return;
            if (requestRef.current) requestRef.current.ac.abort();
            const count =
                settings?.rubric?.length && settings?.rubric?.length > countItems
                    ? settings?.rubric?.length
                    : settings?.countShowItems || Math.max(countItems, 5);
            requestRef.current = getWikiPagesActualList({
                skipCount: 0,
                count: count,
                tagUids: settings.rubric ? getRubrcsIds : undefined,
                alias: settings.collection.alias,
                order: settings.sort || 'changedate',
                sort: 'desc',
            });
            return requestRef.current.r
                .then((d: any) => {
                    if (checkResponseStatus(d)) {
                        return setActualContent(d.data);
                    } else {
                        if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
                    }
                })
                .catch((e) => {});
        },
        [settings?.rubric, settings?.sort, settings?.collection?.alias, getRubrcsIds, settings?.countShowItems]
    );
    const getActualContentRef = useRef(getActualContent);
    getActualContentRef.current = getActualContent;

    if (!settings?.collection && isEdit) {
        return (
            <EditWrapBox>
                <TypographyArticles>
                    <Translate i18nKey="pryaniky.widgets.article.no.settings" />
                </TypographyArticles>
            </EditWrapBox>
        );
    }
    if (!settings?.collection && !isEdit) {
        return null;
    }
    const linkToFolder = useMemo(() => {
        const queryStringParams =
            getRubrcsIds?.length === 1 ? `?${queryString.stringify({ catFilter: getRubrcsIds })}` : '';
        const path = settings?.collection?.alias
            ? generatePath(mainUrls.wikilist.collectionAlias, { collectionAlias: settings?.collection?.alias })
            : mainUrls.wikilist.root;
        return path + queryStringParams;
    }, [settings?.collection?.alias, getRubrcsIds]);

    const AdditionalButtons = useMemo(() => {
        return (
            <AdditionalButtonsWrapBox>
                {useMoexDesign && (
                    <>
                        <Link
                            className={cnSettingsActueleArticles('Header-OfferNews')}
                            href={
                                'https://support.moex.com/support/Login.aspx?lang=ru&guestlogin=1&singleton=3&id=9355685'
                            }
                            sx={{ textTransform: 'uppercase' }}
                        >
                            <Translate i18nKey={'pryaniky.widgets.settings.type.offer.news'} />
                        </Link>
                        <DividerStyled orientation="vertical" />
                    </>
                )}
                <UppercaseLink className={cnSettingsActueleArticles('Header-ButtonToAll')} href={linkToFolder}>
                    <Translate i18nKey={'pryaniky.widgets.settings.type.allMaterials'} />
                </UppercaseLink>
            </AdditionalButtonsWrapBox>
        );
    }, [useMoexDesign]);

    if (settings?.hideIfNoData && actualContent?.length === 0) return null;

    const listContentBoxSX = useMemo(() => {
        if (actualContent && count < actualContent?.length && ref.current) {
            const curWidth = `calc((100% - ${
                parseFloat(window.getComputedStyle(ref.current).gap) * (count - 1)
            }px)/${count} )`;
            return {
                ['& > *']: {
                    flexGrow: 0,
                    minWidth: curWidth,
                    maxWidth: curWidth,
                },
            };
        } else {
            return undefined;
        }
    }, [actualContent, count, ref.current]);

    if (!settings?.hideIfNoData && actualContent?.length === 0)
        return (
            <WidgetArticlesBox className={cnSettingsActueleArticles('EmptyList')}>
                <Box
                    sx={{
                        padding: '24px',
                        display: 'flex',
                        justifyContent: settings?.name !== '' ? 'space-between' : 'flex-end',
                    }}
                >
                    <Link href={linkToFolder}>
                        <TypographyArticles minHeight={32}>
                            <MDRender source={settings?.name} type={'inline'} countLineCut={1} />
                        </TypographyArticles>
                    </Link>
                    <Link href={linkToFolder}>
                        <Translate i18nKey={'pryaniky.widgets.settings.type.allMaterials'} />
                    </Link>
                </Box>
                <CardMediaWrapBox>
                    <CardMediaStyle
                        component="img"
                        className={cnSettingsActueleArticles('EmptyList-Avatar')}
                        image={emptyArticlesList}
                    />
                    <Typography>
                        <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
                    </Typography>
                </CardMediaWrapBox>
            </WidgetArticlesBox>
        );

    return (
        <WidgetArticlesBox
            ref={(el) => {
                (refBreack as any).current = el;
            }}
        >
            <Box className={cnSettingsActueleArticles('')}>
                {actualContent && (
                    <HeaderWrapBox>
                        <HeaderBox
                            className={cnSettingsActueleArticles('Header')}
                            sx={{
                                justifyContent: settings?.name !== '' ? 'space-between' : 'flex-end',
                            }}
                        >
                            <Link className={cnSettingsActueleArticles('Header-Title')} href={linkToFolder}>
                                <TypographyArticles minHeight={32}>
                                    <MDRender source={settings?.name} type={'inline'} countLineCut={1} />
                                </TypographyArticles>
                            </Link>

                            <HeaderButtonsWrapBox>
                                {(point700 || settings?.name === '') && AdditionalButtons}
                                {actualContent?.length > 0 && actualContent?.length > count && (
                                    <HeaderButtonsBox>
                                        <div
                                            onClick={() => {
                                                if (backBlock) return;
                                                setPage(page - 1);
                                            }}
                                        >
                                            <KeyboardArrowLeft
                                                color={
                                                    actualContent?.length <= count || backBlock ? 'disabled' : 'primary'
                                                }
                                            />
                                        </div>
                                        {count === 1 && page + 1 + '/'}
                                        {count > 1 &&
                                            `${Math.min(
                                                Math.max(page * count + 1, 0),
                                                actualContent?.length
                                            )}-${Math.min(page * count + count, actualContent?.length)}/`}
                                        {actualContent?.length}
                                        <div
                                            onClick={() => {
                                                if (forwardBlock) return;
                                                setPage(page + 1);
                                            }}
                                        >
                                            <KeyboardArrowRight
                                                color={
                                                    actualContent?.length <= count || forwardBlock
                                                        ? 'disabled'
                                                        : 'primary'
                                                }
                                            />
                                        </div>
                                    </HeaderButtonsBox>
                                )}
                            </HeaderButtonsWrapBox>
                        </HeaderBox>
                        {!point700 && settings?.name !== '' && AdditionalButtons}
                    </HeaderWrapBox>
                )}
                <ListContentBox ref={ref} sx={listContentBoxSX}>
                    {prepairedArr?.map((el) =>
                        actualContent ? (
                            <WikiListItem
                                item={el}
                                hideName={settings.hideName}
                                hideDescription={settings.hideDescription}
                            />
                        ) : (
                            <WikiActualeArticlesSkeleton />
                        )
                    )}
                </ListContentBox>
            </Box>
        </WidgetArticlesBox>
    );
};
