import * as React from 'react';
import * as utils from 'utils/src/utils';


// import { withBemMod } from '@bem-react/core';

// import { cnWidget } from '../Widget.index';
// import { IWidgetTypeWikiProps } from './Widget_type_wiki.index';
import './Widget_type_badgesList.scss';
import { cnWidget, IWidgetProps } from '../Widget.index';

import WidgetPresenter from '../Widget';
import { widgets } from "i.widgets";
import { Badges } from 'blocks/Badges/Badges';
import { Badges as BadgesMui } from 'blocks/BadgesNew/Badges';

const type = 'badgesListMui'

export default class WidgetTypeWikiPresenter extends WidgetPresenter<IWidgetProps> {

  public render() {
    if (!this.props.widget) return null;
    // const subtype = utils.widgetSubtype(this.props.widget.type);
    // const Component = this.getComponent(types);
    // const saveData = (d: any) => {
    //   this.props.widget.data = d
    //   this.props.updateWidget({
    //     ...this.props.widget,
    //     HTMLWidgetContentEdit: false
    //   });
    // }
    // const saveWidgetData = () => { this.props.setStructureFromWidget(this.props.widget.id) }
    const updateWidget: React.ComponentProps<typeof BadgesMui>['applySorting'] = (newSort) => {
      this.props.updateWidget({
        ...this.props.widget,
        settings: {
          ...this.props.widget.settings,
          categoriesOrder: newSort
        }
      });
    }
    return (
      <widgets.components.common {...this.props} className={cnWidget({ type })}>
        <BadgesMui filter={this.props.widget.settings?.tag?.id} applySorting={updateWidget} categoriesOrder={this.props.widget.settings?.categoriesOrder} />
      </widgets.components.common>
    )
    // <Component
    //   {...this.props}
    //   editToggle={this.props.widget.HTMLWidgetContentEdit}
    //   saveWidgetData={saveWidgetData}
    //   updateWidget={this.props.updateWidget}
    //   saveData={saveData}
    //   data={this.props.widget.data || undefined}
    //   className={cnWidget({ type, subtype })}
    //   context={this.props.wcontext}
    //   tag={widgets.components.common}
    // />
  }

}


// export const withWidgetTypeWiki = withBemMod<IWidgetTypeWikiProps>(
//   cnWidget(),
//   { type }, 
//   (Presenter) => (
//     (props) => (
//       <Presenter {...props}/>
//     )
//   )
// );