import React, { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog';
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { useDidUpdateEffect, useReffedState, useScrollListState } from 'utils/src/hooks';
import { NothingFoundText } from 'blocks/ListMui/_type/NotingFoundText';
import { ITableListProps, TableListSearchActionProps, tasksListClasses } from './TableList.index';
import { checkResponseStatus, expireColumnName, TaskModelColumnType } from 'utils/src';
import { HeaderRootBox, SearchActionsBox, SearchActionsSelect } from './TableList.styled';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { TableListItem } from './Item';
import { TableListBody } from './Body';
import { TableListComponent } from './List';
import { TableListContext, tableListContextDefaultValue } from './TableList.context';

function TableListSearchAction<F>({
    selectedFilter,
    onFilterChange,
    actionsEl,
    filterOptions,
}: TableListSearchActionProps<F>) {
    return (
        <SearchActionsBox className={tasksListClasses.searchActions}>
            <SearchActionsSelect
                size="small"
                value={selectedFilter}
                onChange={(e) => {
                    onFilterChange(e.target.value as F);
                }}
                options={filterOptions}
            />
            {actionsEl}
        </SearchActionsBox>
    );
}

export function TableList<T>({
    context,
    defaultFilter,
    getData,
    createActionsEl,
    filterOptions,
    isExpireDays,
    isWithActionButton,
    bottomContent,
    topContent,
    selectedFilter,
    onFilterChange,
    strForLocalization,
    createItemProps,
}: ITableListProps<T>) {
    const authUser = useSelector(getAuthUser);
    const userId = context.uId || context.userId || authUser?.baseData.id;
    const userIdRef = useRef<string>(userId);
    userIdRef.current = userId;

    const [contextState, setContextState, contextStateRef] = useReffedState({
        ...tableListContextDefaultValue,
        userId,
    });
    useDidUpdateEffect(() => {
        setContextState({
            ...contextStateRef.current,
            userId,
        });
    }, [userId]);

    const [activeFilter, setActiveFilter] = useState<T>(selectedFilter || defaultFilter);

    useEffect(() => {
        if (onFilterChange && selectedFilter !== activeFilter) onFilterChange(activeFilter);
    }, [activeFilter]);

    useEffect(() => {
        if (selectedFilter && selectedFilter !== activeFilter) setActiveFilter(selectedFilter);
    }, [selectedFilter]);

    const listStateHook: ComponentProps<typeof ItemsList>['listStateHook'] = (
        { defaultSelected },
        middlewareParams
    ) => {
        const { search, debouncedSearch, changeSearch, onChangeTab } = useDefaultStaticStates(
            middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id
        ); // состояние для поиска и табов

        // получаем список тэгов
        const state = useScrollListState(
            async function (skipCount, count) {
                const response = await getData({
                    userId: userIdRef.current,
                    skipCount,
                    count,
                    filter: activeFilter,
                    search: debouncedSearch,
                });

                if (!checkResponseStatus(response)) throw response;

                const columns: typeof response.data.columns = [];

                response.data.columns.forEach((col) => {
                    columns.push(col);

                    if (isExpireDays && col.name === 'dueDate') {
                        columns.push({
                            name: expireColumnName,
                            type: TaskModelColumnType.string,
                            isVisible: true,
                        });
                    }
                });

                setContextState({
                    ...contextStateRef.current,
                    allowAdd: response.data.allowAdd,
                    columns,
                });

                return response.data.rows;
            },
            [debouncedSearch, activeFilter, getData],
            {},
            true
        );

        const actionsEl = useMemo(() => {
            return createActionsEl && createActionsEl(state.actions, contextStateRef.current);
        }, [state.actions, createActionsEl, contextState]);

        return {
            middlewareParams: {
                ...middlewareParams,
                listHeaderProps: {
                    ...middlewareParams.listHeaderProps,
                    SearchActions: (
                        <TableListSearchAction
                            selectedFilter={activeFilter}
                            onFilterChange={setActiveFilter}
                            actionsEl={actionsEl}
                            filterOptions={filterOptions}
                        />
                    ),
                },
            },
            state,
            defaultSelected,
            middleware: defaultMiddleware,
            search,
            debouncedSearch,
            onChangeTab,
            changeSearch,
        };
    };

    const createItemPropsWithContext = useCallback(
        (props) => {
            return createItemProps && createItemProps(props, contextState);
        },
        [contextState]
    );

    return (
        <TableListContext.Provider value={contextState}>
            <ItemsList<any>
                BodyComponent={TableListBody}
                ListComponent={(props) => (
                    <TableListComponent
                        {...props}
                        isWithActionButton={isWithActionButton}
                        strForLocalization={strForLocalization}
                    />
                )}
                ItemComponent={(props) => (
                    <TableListItem
                        {...props}
                        isWithActionButton={isWithActionButton}
                        strForLocalization={strForLocalization}
                        {...(createItemPropsWithContext(props) || {})}
                    />
                )}
                listHeaderProps={{
                    SearchInputProps: {
                        sx: {
                            marginBottom: 0,
                        },
                    },
                    rootBoxProps: {
                        component: HeaderRootBox,
                    },
                    bottomContent,
                    topContent,
                }}
                listComponentProps={{
                    textEmpty: <NothingFoundText />,
                }}
                listStateHook={listStateHook}
            />
        </TableListContext.Provider>
    );
}
