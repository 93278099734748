// import * as React from 'react';
import { i18n } from 'localization';
import { connect } from 'react-redux';
import { IDispatchProps, mapDispatchToProps } from 'redux/connector';
import * as utils from 'utils/src/utils';
import { IUsersWidgetStateProps, mapStateToProps } from './../UsersWidget.index';
// import { withBemMod } from '@bem-react/core';

// import { cnUsersWidget } from '../UsersWidget.index';
// import { IUsersWidgetTypeSubscribersProps } from './UsersWidget_type_subscribers.index';
import { addHandler, removeHandler, SignalHandler } from 'utils/src/signals';
import { UsersWidgetPresenter } from '../UsersWidget';
import { IUsersWidgetProps } from '../UsersWidget.index';
import './UsersWidget_type_subscribers.scss';

export enum UsersSubscribersWidgetSignals {
    subscribeByUser = 'subscribeByUser',
}

export class UsersSubscribersWidgetPresenter extends UsersWidgetPresenter {
    public componentDidMount() {
        super.componentDidMount();
        addHandler(UsersSubscribersWidgetSignals.subscribeByUser, this.handler);
    }

    public componentDidUnmount(): void {
        super.componentWillUnmount();
        removeHandler(UsersSubscribersWidgetSignals.subscribeByUser, this.handler);
    }

    public handler: SignalHandler = (action: string = UsersSubscribersWidgetSignals.subscribeByUser) => {
        if (action === UsersSubscribersWidgetSignals.subscribeByUser) {
            this.getData(this.props);
        }
    };

    public getData = (props: IUsersWidgetProps) => {
        const { uid, wcontext } = props;
        utils.API.users.subscribers(wcontext.uId ? wcontext.uId : uid).r.then((d: any) => {
            if (!this.mounted) return false;
            if (utils.checkResponseStatus(d)) {
                const items = d.data.map((user: { [s: string]: any }) => {
                    user.title = user.displayName;
                    user.url = '/user/' + user.id;
                    /**
                     * userPhotoUrl is old parameter
                     */
                    if (!utils.isEmptyGuid(user.imgId)) user.imgUrl = user.userPhotoUrl || user.imgUrl;
                    return user;
                });
                this.setState({
                    items,
                    noData: items.length === 0,
                    title: i18n.t('subscribers'),
                });
            }
        });
    };
}

export const UsersSubscribersWidget = connect<IUsersWidgetStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
)(UsersSubscribersWidgetPresenter);

// export const withUsersWidgetTypeSubscribers = withBemMod<IUsersWidgetTypeSubscribersProps>(
//   cnUsersWidget(),
//   { type: 'subscribers' },
//   (Presenter) => (
//     (props) => (
//       <Presenter {...props}/>
//     )
//   )
// );
