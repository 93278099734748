import React, { FC, useState } from 'react';
import './Item.scss';
import { IItemProps, cnAuthorItem } from './Item.index'
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link';

export const AuthorItem: FC<IItemProps> = ({ className, author, size = 56, fixedToolTip }) => {
    const [zIndex, setZIndex] = useState(0)
    const mouseEnther = () => setZIndex(1)
    const mouseLeave = () => setZIndex(0)
    return (
        <div className={cnAuthorItem({}, [className])} style={{zIndex}} onMouseEnter={mouseEnther} onMouseLeave={mouseLeave}>
            <UserMention id={author.id}>
                <Link href={appGeneratePath(mainUrls.user.id, { id: author.id })}>
                    <Avatar className={cnAuthorItem('Avatar')} src={author.userPhotoUrl || author.imgUrl} size={size}>
                        {author.displayName.substring(0,1)}
                    </Avatar>
                </Link>
            </UserMention>
        </div>
    );
};
