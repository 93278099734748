import { useCallback, useRef, useState } from 'react';
import { baseAnswer } from './Polls.constants';
import { NPolls } from './Polls.interface';
import { v1 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from 'News/creator/redux/selectors.newsCreate';
import { newsCreatorItemNewsChange } from 'News/creator/redux/actions.newsCreator';
import { NewsCreateReducer, NewsPostTypeWithDraftRaw } from 'News/creator/redux/types.newsCreator';
import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import { useContext } from "react";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
import { DeepPartial, ExtractByParam } from 'utils/src';
import { useChangeCreator } from 'News/creator/hooks';
import { NewsChangeReasons, NewsChangeReasonsTypes } from 'News/creator/redux/actions.types.newsCreator';
import { useReffedState } from 'utils/src/hooks';

/**
 * @param answersArray 
 * @returns 
 */
export const usePollAnswers = (answersArray: NPolls.Create['poll']['answers']) => {

    const answerChangeReason = useRef<NewsChangeReasons | undefined>(undefined);

    const [ answers, setAnswersObject, answersRef ] = useReffedState(answersArray.reduce((a, c) => ({ ...a, [c.id]: c }), {} as { [key: typeof answersArray[number]['id']]: typeof answersArray[number] }));

    const setAnswer = useCallback((answer: typeof answersArray[number]) => {
        answerChangeReason.current = {
            type: NewsChangeReasonsTypes.pollAnswerChange,
            id: answer.id
        };
        setAnswersObject({
            ...answersRef.current,
            [answer.id]: answer
        });
    }, []);

    const addAnswer = useCallback(() => {
        answerChangeReason.current = {
            type: NewsChangeReasonsTypes.pollAsnwerAdd
        };
        const newAnswer = {
            ...baseAnswer,
            id: uuid(),
            position: Object.typedKeys(answersRef.current).length
        };
        setAnswersObject({
            ...answersRef.current,
            [newAnswer.id]: newAnswer
        });
    }, []);

    const removeAnswer = useCallback((id: keyof typeof answers) => {
        answerChangeReason.current = {
            type: NewsChangeReasonsTypes.pollAsnwerRemove
        };
        setAnswersObject(Object.typedKeys(answersRef.current).filter(el => el !== id).reduce((a, id) => ({ ...a, [id]: answersRef.current[id] }), {} ));
    }, [])

    return {
        answersIds: Object.typedKeys(answers),
        answers,
        answersRef,
        answerChangeReason,
        setAnwsers: setAnswersObject,
        setAnswer,
        addAnswer,
        removeAnswer
    }

};

type PollType = ExtractByParam<NewsPostTypeWithDraftRaw, 'componentRenderName', 'polls'>;
type FieldsToCreator = Extract<keyof PollType, 'text' | 'expires' | 'poll' | 'draftRawState'>;

const PollsFields: FieldsToCreator[] = ['text', 'expires', 'poll', 'draftRawState'];

export const usePollsEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const { groupId, workflowId, newsType, all } = useContext(NewsCreateContext);

    const data = useSelector(getNewsCreateItemNewsFields(PollsFields)(id)) as Pick<PollType, FieldsToCreator>;

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews'])(id));

    const renderParams = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', groupId, workflowId, newsType, all))?.renderParams as NPolls.RenderParams | undefined;
    
    const changeCreator = useChangeCreator(id);

    const changeNews = (news: DeepPartial<typeof data>, reason?: NewsChangeReasons) => {
        dispatch(newsCreatorItemNewsChange({ id, news, reason }));
    };

    return {
        data,
        renderParams,
        ...creatorFields,
        changeCreator,
        changeNews
    };
};