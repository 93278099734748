import { сreativetasksSubReducer } from 'blocks/NewsTypes/Creativetasks/redux/reducer';
import { eventsSubReducer } from 'blocks/NewsTypes/Events/redux/reducer';
import { ideasSubReducer } from 'blocks/NewsTypes/Ideas/redux/reducer';
import { pollsSubReducer } from 'blocks/NewsTypes/Polls/redux/reducer';
import { thanksSubReducer } from 'blocks/NewsTypes/Thanks/redux/reducer';
import { workflowsSubReducer } from 'blocks/NewsTypes/Workflows/redux/reducer';
import { clone, omit, union, xor } from 'lodash';
import queryString from 'query-string';
import { TNewsReducerActions } from '../actions/types';
import actions from '../actionsTypes/News';
import { defaultNews } from './default';
import { INewsReducer } from './types';

export function newsReducer(state = defaultNews, action: TNewsReducerActions): INewsReducer {
    switch (action.type) {

        case actions.SET_CURRENT_RENDERED: {
            const { id, value } = action.payload
            return {
                ...state,
                virtualize: {
                    ...state.virtualize,
                    [state.currentGroup]: {
                        ...(state.virtualize[state.currentGroup] || {}),
                        currnetRendered: {
                            ...(state.virtualize[state.currentGroup]?.currnetRendered || {}),
                            [id]: value
                        }
                    }
                }
            }
        }

        case actions.SET_HEIGHTS: {
            const { height, id } = action.payload
            return {
                ...state,
                virtualize: {
                    ...state.virtualize,
                    [state.currentGroup]: {
                        ...(state.virtualize[state.currentGroup] || {}),
                        heights: {
                            ...(state.virtualize[state.currentGroup]?.heights || {}),
                            [id]: height
                        }
                    }
                }
            }
        }

        case actions.PREPEND_NEWS:
        case actions.APPEND_NEWS:
        case actions.SET_NEWS: {
            const { values, keys, commentsKeys, commentsValues, group = 'all' } = action.payload
            return {
                ...state,
                values: { ...state.values, ...values },
                groupedKeys: {
                    ...state.groupedKeys,
                    [group]: action.type === actions.SET_NEWS ? keys : action.type === actions.PREPEND_NEWS ? union(keys, state.groupedKeys[group]) : union(state.groupedKeys[group], keys),
                },
                commentsValues: { ...state.commentsValues, ...commentsValues },
                commentsKeys: actions.SET_NEWS ? commentsKeys : union(state.commentsKeys, commentsKeys),
                groupsStates: {
                    ...state.groupsStates,
                    [group]: {
                        ...state.groupsStates[group],
                        isFinished: action.type === actions.APPEND_NEWS ?
                            keys.length < (state.groupsStates[group]?.count || 10) :
                            (state.groupsStates[group]?.isFinished || false)
                    }
                }
                // isFinished: action.type === actions.APPEND_NEWS ? keys.length < state.count : state.isFinished
            }
        }

        case actions.UPGRADE_NEWS: {
            const { values, keys, commentsKeys, commentsValues } = action.payload
            return {
                ...state,
                values: { ...state.values, ...values },
                groupedKeys: {
                    ...state.groupedKeys,
                },
                commentsValues: { ...state.commentsValues, ...commentsValues },
                commentsKeys: union(state.commentsKeys, commentsKeys),
            }
        }

        case actions.PREPEND_COMMENTS:
        case actions.ADD_COMMENTS: {
            const { id, commentsKeys, commentsValues } = action.payload
            return {
                ...state,
                values: {
                    ...state.values,
                    [id]: {
                        ...(state.values[id] || {}),
                        comments: action.type === actions.PREPEND_COMMENTS ? union(commentsKeys, state.values[id]?.comments || []) : union(state.values[id]?.comments || [], commentsKeys)
                    }
                },
                commentsKeys: action.type === actions.PREPEND_COMMENTS ? union(commentsKeys, state.commentsKeys) : union(state.commentsKeys, commentsKeys),
                commentsValues: { ...state.commentsValues, ...commentsValues }
            }
        }

        case actions.UNSET_NEWS: {
            const { id } = action.payload
            const groupedKeys = Object.keys(state.groupedKeys).reduce<typeof state.groupedKeys>((acc, cur) => {
                acc[cur] = state.groupedKeys[cur].filter(v => v !== id)
                return acc
            }, {})
            return {
                ...state,
                groupedKeys
            }
        }

        case actions.UNSET_REPLY: {
            const { newsId, replyId } = action.payload
            return {
                ...state,
                values: {
                    ...state.values,
                    [newsId]: {
                        ...state.values[newsId],
                        commentsCount: state.values[newsId].commentsCount - 1,
                        comments: state.values[newsId].comments.filter(v => v !== replyId)
                    }
                }
            }
        }

        case actions.UPDATE_REPLY_SUCCESS: {
            const { id } = action.payload;
            return {
                ...state,
                commentsValues: {
                    ...state.commentsValues,
                    [id]: {
                        ...state.commentsValues[id],
                        ...action.payload,
                    },
                },
            };
        }

        case actions.TOGGLE_EDIT: {
            const { id, enable } = action.payload
            if (enable !== undefined) {
                return {
                    ...state,
                    edit: enable ? union(state.edit, [id]) : state.edit.filter(v => v !== id)
                }
            }
            return {
                ...state,
                edit: xor(state.edit, [id])
            }
        }

        case actions.SET_UPDATE_ERROR: {
            const { error_code, error_text, id } = action.payload
            let updateErrors = {
                ...state.updateErrors,
                [id]: {
                    error_code, error_text, id
                }
            }
            if (error_code === 0) {
                updateErrors = omit(updateErrors, [id])
            }
            return {
                ...state,
                updateErrors
            }
        }

        case actions.CHANGE_REPLY_FORM: {
            const { reply, newsId } = action.payload
            return {
                ...state,
                commentsForms: {
                    ...state.commentsForms,
                    [newsId]: {
                        ...state.commentsForms[newsId],
                        ...reply
                    }
                }
            }
        }

        case actions.TOGGLE_COMMENT_LOADING: {
            const { id } = action.payload
            return {
                ...state,
                commentsLoading: xor(state.commentsLoading, [id])
            }
        }

        case actions.TOGGLE_COMMENT_SENDING: {
            const { id } = action.payload
            return {
                ...state,
                commentsSending: xor(state.commentsSending, [id])
            }
        }

        case actions.CHANGE_FIELD: {
            const { field, value } = action.payload as any
            return {
                ...state,
                [field]: value
            }
        }

        case actions.CHANGE_STATUS_FIELD: {
            const { field, value, group } = action.payload
            const gr = queryString.stringify(omit(group, ['count']))
            return {
                ...state,
                groupsStates: {
                    ...state.groupsStates,
                    [gr]: {
                        ...state.groupsStates[gr],
                        [field]: value,
                    }
                }
            }
        }

        case actions.PATH_NEWS: {
            return {
                ...state,
                values: {
                    ...state.values,
                    [action.payload.id]: {
                        ...state.values[action.payload.id],
                        ...action.payload
                    }
                }
            }
        }

        case actions.PATH_REPLY: {
            return {
                ...state,
                commentsValues: {
                    ...state.commentsValues,
                    [action.payload.id]: {
                        ...state.commentsValues[action.payload.id],
                        ...action.payload
                    }
                }
            }
        }

        case actions.PATH_CREATOR: {
            return {
                ...state,
                newsCreator: {
                    ...state.newsCreator,
                    ...action.payload
                }
            }
        }

        case actions.PATH_CREATOR_NEWS: {
            const prepairedPayload = clone(action.payload);
            switch (state.newsCreator.currentNews.componentRenderName) {
                case 'polls': {
                    prepairedPayload.poll = {
                        ...(state.newsCreator.currentNews as any).poll,
                        ...prepairedPayload.poll
                    }
                    break;
                }
                case 'events': {
                    prepairedPayload.uevent = {
                        ...(state.newsCreator.currentNews as any).uevent,
                        ...prepairedPayload.uevent
                    }
                    break;
                }
                case 'workflow': {
                    prepairedPayload.additionalFields = {
                        ...(state.newsCreator.currentNews as any).additionalFields,
                        ...prepairedPayload.additionalFields
                    }
                    break;
                }
                case 'ideas': {
                    prepairedPayload.idea = {
                        ...(state.newsCreator.currentNews as any).idea,
                        ...prepairedPayload.idea
                    }
                    break;
                }
            }
            return {
                ...state,
                newsCreator: {
                    ...state.newsCreator,
                    currentNews: {
                        ...state.newsCreator.currentNews,
                        ...prepairedPayload
                    }
                }
            }
        }

        case actions.RESET: {
            const root: keyof INewsReducer = action.payload as any
            return {
                ...state,
                [root]: defaultNews[root]
            }
        }

        case actions.SET_READED: {
            if (state.readed.includes((action as any).payload)) return state;
            return {
                ...state,
                readed: [...state.readed, (action as any).payload]
            };
        }

        case actions.TOGGLE_THANK: {
            return {
                ...state,
                commentsForms: {
                    ...state.commentsForms,
                    [action.payload.id]: {
                        ...state.commentsForms[action.payload.id],
                        thankReplyEnabled: action.payload.value
                    }
                }
            }
        }

        case actions.ACTIVE_NEWS: {
            return {
                ...state,
                activeStatuses: {
                    ...state.activeStatuses,
                    [action.payload.params]: action.payload.id
                }
            }
        }

        case actions.BANNERS_SET_READED: {
            return {
                ...state,
                banners: {
                    ...state.banners,
                    readed: [...state.banners.readed, action.payload.id]
                }
            }
        }

        case actions.BANNERS_CLEAR_READED: {

            return {
                ...state,
                banners: {
                    ...state.banners,
                    readed: []
                }
            }
        }

        case actions.BANNERS_SET_CLOSED: {
            return {
                ...state,
                banners: {
                    ...state.banners,
                    closed: [...state.banners.closed, action.payload.id]
                }
            }
        }

        case actions.BANNERS_CLEAR_CLOSED: {
            return {
                ...state,
                banners: {
                    ...state.banners,
                    closed: []
                }
            }
        }

        default: {
            return [
                pollsSubReducer,
                eventsSubReducer,
                сreativetasksSubReducer,
                thanksSubReducer,
                workflowsSubReducer,
                ideasSubReducer,

                (state: INewsReducer) => state,
            ].reduce((prev, curr) => {
                return curr(prev, action)
            }, state)
        }
    }
}

