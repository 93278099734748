import { Box } from 'muicomponents/src';

import { styled } from 'muicomponents/src/mui/system';

export const ContentBox = styled(Box)({
    display: ' flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    gridGap: '8px',
}) as typeof Box;

export const ContentBoxBaseline = styled(Box)({
    display: ' flex',
    alignItems: 'baseline',
    gap: '8px',
    gridGap: '8px',
}) as typeof Box;
