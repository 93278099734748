import React, { FC, useEffect, useState } from 'react';
// import './EventsList.scss'
import moment from 'moment';
import { connect } from 'react-redux';
import { ListItem } from '../ListItem/ListItem';
import { cnEventsList, IEventListProps, mapDispatchToProps, mapStateToProps } from './EventsList.index';
// import { Button } from 'uielements/src'
import Box from '@mui/material/Box';
import { Translate } from 'localization';
// import Typography from '@mui/material/Typography'
// import { CalendarIcon } from '../CalendarIcon/CalendarIcon'
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Button } from 'muicomponents/src/Button/Button';
import { NewsCreatorButton } from 'News/creator/Creator/CreatorButton/CreatorButton';
import { calendarNewsCreatorId } from '../Calendar.constants';
import { useCalenderNewsCreatorOptions, useCanCalendarCreateEvent } from '../Calendar.hooks';

const EventsEmptyBG = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '8px 16px',
    // border: '1px solid #D3D9E0',
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    marginBottom: '12px',
    marginTop: '12px',
}) as typeof Paper;

const ActionsBox = styled(Box)({
    display: 'flex',
    minHeight: '8px',
}) as typeof Box;

const viewFilter = (data: string[], date: string, view: string) => {
    let filterViewData: any[] = [];

    const formatDate = (date: Date) => moment(new Date(date)).format().slice(0, 10);

    const selectedDate = moment(new Date(date));

    switch (view) {
        case 'month':
            const monthStart = selectedDate.clone().startOf('month').format();
            const monthEnd = selectedDate.clone().endOf('month').format();
            filterViewData = data.filter(
                (date) => new Date(date) >= new Date(monthStart) && new Date(date) <= new Date(monthEnd)
            );
            break;
        case 'week':
            const weekStart = selectedDate.clone().startOf('week').format();
            const weekEnd = selectedDate.clone().endOf('week').format();
            filterViewData = data.filter(
                (date) => new Date(date) >= new Date(weekStart) && new Date(date) <= new Date(weekEnd)
            );
            break;
        case 'day':
            filterViewData = data.filter((dataSate) => dataSate === formatDate(new Date(date)));
            break;
        default:
            break;
    }
    return filterViewData;
};

const EventsEmpty: FC<
    {
        hide: boolean;
        filterViewData: string[];
        today: string;
        setHide: (hide: boolean) => void;
    } & Pick<IEventListProps, 'groupId' | 'workflowId'> &
        PaperProps
> = ({ hide, filterViewData, today, setHide, groupId, workflowId, ...props }) => {
    const creatorOptions = useCalenderNewsCreatorOptions();

    const canCreateEvent = useCanCalendarCreateEvent({ gId: groupId, workflowId });

    const isShowMoreAvailable =
        hide &&
        filterViewData?.[0] &&
        filterViewData[0].slice(0, 7) === today.slice(0, 7) &&
        filterViewData[0] !== today;

    const isEmptyBlock = isShowMoreAvailable || canCreateEvent;

    if (!isEmptyBlock) {
        return null;
    }

    return (
        <EventsEmptyBG {...props} elevation={0} className={cnEventsList('Item-Event', { event: 'noEvent' })}>
            {/* <Box className={cnEventsList('Item-Event-Info', { event: 'noEvent' })}>
      <Typography>
        <Translate i18nKey={'pryaniky.calendar.listItem.noEvents'} />
      </Typography>
    </Box> */}

            {isShowMoreAvailable && (
                <Button className={cnEventsList('ShowMore')} variant={'contained'} onClick={() => setHide(false)}>
                    <Translate i18nKey={'pryaniky.calendar.eventList.showCompletedEvents'} />
                </Button>
            )}

            {canCreateEvent && (
                <NewsCreatorButton
                    variant="contained"
                    newsType={creatorOptions.workflowId || 'events'}
                    creatorId={calendarNewsCreatorId}
                    creatorOptions={creatorOptions.options}
                    groupId={groupId}
                    workflowId={creatorOptions.workflowId || workflowId}
                >
                    <Translate i18nKey={'pryaniky.calendar.eventList.addEvent'} />
                </NewsCreatorButton>
            )}
        </EventsEmptyBG>
    );
};

const EventsListPresenter: React.FC<IEventListProps> = (props) => {
    const { data, date, view, groupId, workflowId } = props;

    const filterViewData = viewFilter(data, date, view);

    const [hide, setHide] = useState(true);

    useEffect(() => {
        setHide(true);
    }, []);
    const formatDate = (date: Date) => moment(new Date(date)).format().slice(0, 10);
    const today = moment(new Date()).clone().format().slice(0, 10);

    return (
        <Box className={cnEventsList()}>
            <ActionsBox>
                {/* <CalendarIcon element={filterViewData[0]} /> */}
                <EventsEmpty
                    hide={hide}
                    groupId={groupId}
                    workflowId={workflowId}
                    filterViewData={filterViewData}
                    today={today}
                    setHide={setHide}
                    sx={{ flex: '1' /*, marginLeft: '12px'*/ }}
                />
            </ActionsBox>

            {filterViewData.map((el) => {
                if (el.slice(0, 7) === today.slice(0, 7) && el < today && hide) return null;
                return <ListItem key={el} el={el} groupId={groupId} workflowId={workflowId} />;
            })}
        </Box>
    );
};

export const EventsList = connect(mapStateToProps, mapDispatchToProps)(EventsListPresenter);
