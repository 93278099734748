import { isString } from 'lodash'


/**
 * 
 * declarate store selectors
 */
type stateType = any//затычка

/**
 * возвращает объект appSettings
 * @param state 
 */
export const getAppSettings = (state: stateType) => state.appSettings

/**
 * получает массив всех типов публикаций
 * @param state 
 */
export const getCompanyPostTypes = (state: stateType) =>
    getAppSettings(state).companyPostTypes

/**
 * получает объект доступных пользователю длля публикации типов
 * @param state 
 */
export const getAllowPostTypes = (state: stateType) =>
    getAppSettings(state).allowPostType

/**
* 
* @param state 
*/
export const getAllowPostError = (state: stateType) =>
    getAppSettings(state).error

/**
 * 
 * @param groupPKID 
 */
export const getCurrentAllowPostTypes = (groupPKID: number | string = -1, workflowId?: string, newsType?: string, all?: boolean) =>
    (state: stateType) => {
        const allowPostType = getAllowPostTypes(state)
        const keys = {
            default: 'dash',
            group: groupPKID,
            workflow: workflowId
        }
        if(newsType) {
            keys.default = keys.default + '&' + newsType;
            keys.group = keys.group + '&' + newsType;
            keys.workflow = keys.workflow + '&' + newsType;
        }
        if (groupPKID === -1 && !workflowId) return allowPostType[keys.default]
        if (workflowId) {
            const postTypes = allowPostType.workflows[keys.workflow!]
            return postTypes ? postTypes : []
        }
        else {
            const postTypes = allowPostType.groups[keys.group!]
            return postTypes ? postTypes : []
        }
    }

/**
 * возвращает массив включеных в компании типов публикаций
 * @param state 
 */
export const getEnabledPostTypes = (state: stateType) =>
    getCompanyPostTypes(state).filter((v: any) => v.enabled)

/**
 * возвращает массив ид типов постов. string[]
 * @param state 
 */
export const getPostTypesIds = (state: stateType) =>
    getCompanyPostTypes(state).map((v: any) => v.id)

/**
 * 
 * @param id 
 */
export const getCompanyPostTypeById = (id: string) =>
    (state: stateType) => {
        const postType = getCompanyPostTypes(state);
        if ('thankUser' === id) id = 'thanks'
        return postType.find((val: any) => val.id === id)
    }

/**
 * получает параметры типа публикации по указануму шв
 * @param id какой тип публикации
 * @param groupPKID в какой группе. по умолчанию -1(основная лента)
 */
export const getAllowPostTypeById = (id: string | number, groupPKID: number | string = -1, workflowId?: string, newsType?: string, all?: boolean) =>
    (state: stateType) => {
        const allowPostType = getCurrentAllowPostTypes(groupPKID, workflowId, newsType, all)(state);
        return allowPostType.find((val: any) => isString(id) ? val.id === id : val.idNumber === id)
    }


export const getAllowToGroupTransfer = (state: stateType) => getAppSettings(state).allowToGroupTransfer