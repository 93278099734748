import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { ChangeUserNetwork } from 'blocks/Dialogs/Users/ChangeNetwork/ChangeNetwork';
import {
    Box,
    Button,
    Checkbox,
    DivisionsSuggester,
    LoadingButton,
    PositionsSuggester,
    TextField,
    TimeZoneSuggester,
    Typography,
} from 'muicomponents/src';
import { FormControl, InputLabel } from 'muicomponents/src/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getAppSettings } from 'utils/src/CommonRedux/base/selectors';
import { getUser, updateUser, updateUserPhoto } from 'utils/src/CommonRedux/users/actions';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { NSubnetworksSettingsAPI } from 'utils/src/requests/models/admin.subnetworks';
import { changePasswordDialog } from '../../../Dialogs/ChangePassword/ChangePassword';
import FieldsRender from './FieldsRender/FieldsRender';
import { IUserProps } from './ProfileEdit.index';
import {
    ActionsBox,
    BtnsBox,
    CatTypography,
    FieldsRenderBox,
    FlexControl,
    FlexField,
    HeadBox,
    ImgLoaderBox,
    LineBox,
    ProfileAvatar,
    TopButton,
    WrapBox,
} from './styled';
import { DatePicker } from 'muicomponents/src/DatePicker';
import { Select } from 'muicomponents/src/Select';
import { i18n, Translate } from 'localization';
import { VpnKey as VpnKeyIcon, Notifications as NotificationsIcon } from 'muicomponents/src/Icons';
import { ProfileEditSkeleton } from './Skeleton/ProfileEditSkeleton';
import { PhoneInput } from 'muicomponents/src/PhoneInput';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { useUpdateStore } from './ProfileEdit.hooks';
import { DialogCropper } from 'muicomponents/src/CropperDialog';
import { ICropperDialogResponse } from 'muicomponents/src/CropperDialog/CropperDialog.index';
import moment from 'moment';
import { toast } from 'react-toastify';
import { mainUrls } from 'utils/src';

const fieldIsDisabled = (user: any, propertyName: string) => {
    if (!user?.profileData?.userInfoFields?.entities?.fields) return false;
    if (user.isAdmin) return false;
    const fields = user.profileData.userInfoFields.entities.fields;
    const fieldId = (user.profileData.userInfoFields.result as string[]).find(
        (id) => fields[id].field.propertyName === propertyName
    );
    if (!fieldId) return false;
    const field = fields[fieldId];
    return field.field.isReadOnly;
};

const initialBaseFieldsState = {
    lastName: '',
    firstName: '',
    middleName: '',
    birthday: '',
    sex: '',
    language: '',
    positionTags: [] as { [key: string]: any }[],
    divisionTags: [] as { [key: string]: any }[],
    phone: '',
    eMail: '',
    isAdmin: false,
    showMyNewsByDefault: false,
    userTimeZone: null as string | null
};

export const ProfileEdit: FC<IUserProps> = ({ welcomeUserId }) => {
    const history = useHistory();

    const appSettings = useSelector(getAppSettings);

    const networksSettings = appSettings?.networksSettings as NSubnetworksSettingsAPI.AppSettings | undefined;

    const { id } = useParams<{ id: string }>();
    const userId = welcomeUserId ? welcomeUserId : id;

    const dispatch = useDispatch();
    const authUser = useSelector(getAuthUser);
    const user = useSelector(getUserById(userId));
    const isAuthUserAdmin = authUser.baseData.isAdmin;
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [avatarState, setAvatarState] = useState({
        userLargePhotoUrl: '',
        largeImgId: '',
        userPhotoUrl: '',
    });
    const defaultTimeZone = appSettings.defaultTimeZone;
    // const [wasUserUpdated, setWasUserUpdated] = useState(false)

    // локальный стейт для хранения вводимых пользователем значений:
    // нужен, чтобы не менять состояние в Redux по каждому onChange инпута - это очень утежеляет интерфейс
    // меняем состояние в Redux по нажатию кнопки saveEdit.
    // После обновления Redux обновленный профиль пользователя улетает на бек
    const [baseFieldsState, setBaseFieldsState] = useState(initialBaseFieldsState);
    useEffect(() => {
        setBaseFieldsState({
            lastName: user?.profileData.lastName,
            firstName: user?.profileData.firstName,
            middleName: user?.profileData.middleName,
            birthday:
                user?.extData.birthday !== '0001-01-01T00:00:00.000Z'
                    ? user?.extData.birthday
                    : '1900-01-01T00:00:00.000Z',
            sex: user?.baseData.sex,
            language: user?.baseData.language,
            positionTags: user?.baseData.positionTags,
            divisionTags: user?.baseData.divisionTags,
            phone: user?.baseData.phone,
            eMail: user?.baseData.eMail,
            isAdmin: user?.baseData.isAdmin,
            showMyNewsByDefault: user?.profileData.showMyNewsByDefault,
            userTimeZone: user?.extData.userTimeZone || defaultTimeZone
        });
        setAvatarState({
            userLargePhotoUrl: user?.baseData.userLargePhotoUrl,
            largeImgId: user?.baseData.largeImgId,
            userPhotoUrl: user?.baseData.userPhotoUrl,
        });
    }, [user, defaultTimeZone]);

    function updateBaseFields(field: keyof typeof initialBaseFieldsState, value: any) {
        setBaseFieldsState({ ...baseFieldsState, [field]: value });
    }

    const [customFieldsState, setCustomFieldsState] = useState<any>();
    const [customFieldsErrors, setCustomFieldsErrors] = useState<{ [key: string]: object | undefined }>({});

    const mondatorySystemFields = useMemo(() => {
        const arr: string[] = [];
        if (!user) return arr;
        if (!user.profileData.userInfoFields.entities.fields) return arr;

        const values = Object.values(user.profileData.userInfoFields.entities.fields);
        values.forEach((val: any) => {
            if (val.field.displayMode === 'system' && val.field.isMandatory) {
                arr.push(val.field.name);
            }
        });
        return arr;
    }, [user?.profileData?.userInfoFields.entities.fields]);

    const {
        multiDivisionsAllowed,
        allowCreateDivisions,
        multiPositionsAllowed,
        allowCreatePositions,
    } = useMemo(() => {
        const positionField = user?.profileData?.userInfoFieldsOriginal.find(
            (el: any) => el.field.propertyName === 'position'
        );
        const multiPositionsAllowed = positionField?.field.allowMultipleValues;
        const allowCreatePositions = !positionField?.field.selectOnlyFromCollection;
        const divisionField = user?.profileData?.userInfoFieldsOriginal.find(
            (el: any) => el.field.propertyName === 'division'
        );
        const multiDivisionsAllowed = divisionField?.field.allowMultipleValues;
        const allowCreateDivisions = !divisionField?.field.selectOnlyFromCollection;
        return { multiDivisionsAllowed, allowCreateDivisions, multiPositionsAllowed, allowCreatePositions };
    }, [userId]);

    const isFeedbackEnabled = appSettings.enabledModules.feedback;
    const [systemFieldsErrors, setSystemFieldsErrors] = useState<{ [key: string]: string }>({});

    // функция updateStore обновляет профиль пользователя в Redux
    const { updateStore, wasStoreUpdated } = useUpdateStore({
        customFieldsState,
        user,
        initBaseState: baseFieldsState,
        dispatch,
        userId: userId,
        avatarState,
        setSystemFieldsErrors,
        mondatorySystemFields,
        multiDivisionsAllowed,
        multiPositionsAllowed,
        customFieldsErrors,
    });

    const useWasSaved = useCallback((state: boolean) => {
        // redirect to user page if we aren't at welcome page
        if (!welcomeUserId) {
            history.replace(generatePath(mainUrls.user.id, { id: userId }));
        }
        if (state) toast.success(<Translate i18nKey={'changes saved'} />);
    }, []);

    useDidUpdateEffect(() => {
        // асинхронное редактирование пользователя - отправляем данные на бек
        dispatch(updateUser(userId, user, setSaving, useWasSaved, true));
    }, [wasStoreUpdated]);

    useEffect(() => {
        // auth user is not admin and this is not him user edit page and stop current function
        if (!isAuthUserAdmin && authUser.baseData.id !== userId) {
            history.replace(generatePath(mainUrls.user.id, { id: userId }));
            return;
        }
        if (!user) dispatch(getUser(userId));
    }, [userId]);

    if (loading || !user) return <ProfileEditSkeleton />;

    const onImgLoad = (loadedImg: ICropperDialogResponse | null) => {
        if (loadedImg) {
            setAvatarState({
                userLargePhotoUrl: loadedImg.previewUrl,
                largeImgId: loadedImg.id,
                userPhotoUrl: loadedImg.previewUrl,
            });
            user.baseData.largeImgId = loadedImg.id;
            dispatch(updateUserPhoto(userId, user, setSaving, useWasSaved));
        }
    };

    return (
        <WrapBox sx={{ maxWidth: welcomeUserId ? '100%' : '781px' }} className={'UserEdit'}>
            <HeadBox>
                <ImgLoaderBox>
                    <ProfileAvatar variant="rounded" src={avatarState.userLargePhotoUrl} />
                    <Button
                        id="editprofile-opencropper"
                        variant="text"
                        onClick={() =>
                            DialogCropper({})
                                .then((value) => onImgLoad(value))
                                .catch((err: any) => {
                                    console.error(err);
                                })
                        }
                    >
                        <Typography fontSize="small" color="primary">
                            <Translate i18nKey={'pryaniky.post.create.photo'} />
                        </Typography>
                    </Button>
                </ImgLoaderBox>
                <BtnsBox>
                    <TopButton
                        size="small"
                        variant="outlined"
                        onClick={() =>
                            changePasswordDialog({
                                userId: user?.baseData?.id || userId,
                                currentUser: authUser,
                            })
                        }
                    >
                        <VpnKeyIcon fontSize="small" sx={{ mr: 1 }} />
                        <Typography fontSize="small">
                            <Translate i18nKey={'pryaniky.changepassword'} />
                        </Typography>
                    </TopButton>
                    {(isAuthUserAdmin || authUser.baseData.id === userId) && (
                        <TopButton
                            fullWidth={false}
                            size="small"
                            variant="outlined"
                            href={`/notifiSettings/${user.baseData.id}/common`}
                        >
                            <NotificationsIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography fontSize="small">
                                <Translate i18nKey={'pryaniky.profile.notifisettings'} />
                            </Typography>
                        </TopButton>
                    )}
                </BtnsBox>
            </HeadBox>

            <Box>
                <FormControl fullWidth>
                    <LineBox>
                        <FlexField
                            disabled={fieldIsDisabled(user, 'displayName')}
                            label={<Translate i18nKey={'pryaniky.profile.lastName'} />}
                            value={baseFieldsState.lastName}
                            onChange={(e: any) => updateBaseFields('lastName', e.target.value)}
                            helperText={systemFieldsErrors.lastName}
                            error={'lastName' in systemFieldsErrors}
                            autoComplete="off"
                        />
                        <FlexField
                            disabled={fieldIsDisabled(user, 'displayName')}
                            label={<Translate i18nKey={'pryaniky.profile.name'} />}
                            value={baseFieldsState.firstName}
                            onChange={(e: any) => updateBaseFields('firstName', e.target.value)}
                            helperText={systemFieldsErrors.firstName}
                            error={'firstName' in systemFieldsErrors}
                            autoComplete="off"
                        />
                        <FlexField
                            disabled={fieldIsDisabled(user, 'displayName')}
                            label={<Translate i18nKey={'pryaniky.profile.middleName'} />}
                            value={baseFieldsState.middleName}
                            onChange={(e: any) => updateBaseFields('middleName', e.target.value)}
                            helperText={systemFieldsErrors.middleName}
                            error={'middleName' in systemFieldsErrors}
                            autoComplete="off"
                        />
                    </LineBox>
                </FormControl>

                <LineBox>
                    <FlexControl>
                        <DatePicker
                            type="date"
                            disabled={fieldIsDisabled(user, 'birthday')}
                            disableFuture
                            keepOffset
                            minDate={moment('1900-01-01T00:00:00.000Z')}
                            label={<Translate i18nKey={'birthday'} />}
                            value={baseFieldsState.birthday}
                            onChange={(dateValue) => {
                                updateBaseFields('birthday', dateValue);
                            }}
                            TextFieldProps={{
                                error: 'birthday' in systemFieldsErrors,
                                helperText: systemFieldsErrors.birthday,
                            }}
                        />
                    </FlexControl>

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel htmlFor="sex">
                            <Translate i18nKey={'sex'} />
                        </InputLabel>
                        <Select
                            labelId="sex"
                            label={<Translate i18nKey={'sex'} />}
                            disabled={fieldIsDisabled(user, 'sex')}
                            value={baseFieldsState.sex}
                            onChange={(e) => updateBaseFields('sex', e.target.value)}
                            options={[
                                { title: <Translate i18nKey={'female'} />, value: 'f' },
                                { title: <Translate i18nKey={'male'} />, value: 'm' },
                            ]}
                        />
                    </FormControl>

                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel htmlFor="language">
                            <Translate i18nKey={'language'} />
                        </InputLabel>
                        <Select
                            labelId="language"
                            label={<Translate i18nKey={'language'} />}
                            disabled={fieldIsDisabled(user, 'language')}
                            value={baseFieldsState.language}
                            onChange={(e) => updateBaseFields('language', e.target.value)}
                            options={appSettings.languages.map((el: string, i: number) => ({
                                title: <Translate i18nKey={`pryaniky.languages.${el}`} />,
                                value: el,
                            }))}
                        />
                    </FormControl>
                </LineBox>

                {isFeedbackEnabled && (
                    <LineBox>
                        <FormControl fullWidth>
                            <TextField
                                label={<Translate i18nKey={'feedback_url'} />}
                                value={
                                    window.location.protocol +
                                    '//' +
                                    window.location.host +
                                    '/feedback/' +
                                    user.baseData.id
                                }
                            />
                        </FormControl>
                    </LineBox>
                )}

                <CatTypography>
                    <Translate i18nKey={'pryaniky.profile.commonInfo'} />:
                </CatTypography>

                <LineBox>
                    <PositionsSuggester
                        fullWidth
                        disabled={fieldIsDisabled(user, 'position')}
                        TextFieldProps={{
                            label: <Translate i18nKey={'pryaniky.profile.position'} />,
                            error: 'positionTags' in systemFieldsErrors,
                            helperText: systemFieldsErrors.positionTags,
                        }}
                        value={baseFieldsState.positionTags as any}
                        onChange={(e, value, reason) => {
                            if (Array.isArray(value)) {
                                if ('createOption' === reason) return;
                                if (!multiPositionsAllowed && baseFieldsState.positionTags.length === 1) {
                                    setBaseFieldsState({
                                        ...baseFieldsState,
                                        positionTags: [value[value.length - 1]].filter(Boolean),
                                    });
                                } else {
                                    setBaseFieldsState({ ...baseFieldsState, positionTags: value });
                                }
                            }
                        }}
                        multiple
                        createItems={{
                            allow: allowCreatePositions,
                            callback: (item) => {
                                if(!multiPositionsAllowed) {
                                    setBaseFieldsState({ ...baseFieldsState, positionTags: [item].filter(Boolean) });
                                } else {
                                    const newTags = [...baseFieldsState.positionTags, item];
                                    setBaseFieldsState({ ...baseFieldsState, positionTags: newTags });
                                }
                            },
                            splitter: [],
                            keyCode: 13,
                        }}
                    />
                </LineBox>

                <LineBox>
                    <DivisionsSuggester
                        fullWidth
                        disabled={fieldIsDisabled(user, 'division')}
                        TextFieldProps={{
                            label: <Translate i18nKey={'pryaniky.profile.division'} />,
                            error: 'divisionTags' in systemFieldsErrors,
                            helperText: systemFieldsErrors.divisionTags,
                        }}
                        multiple
                        value={baseFieldsState.divisionTags as any}
                        onChange={(e, value, reason) => {
                            if ('createOption' === reason) return;
                            if (Array.isArray(value)) {
                                if (!multiDivisionsAllowed && baseFieldsState.divisionTags.length === 1) {
                                    setBaseFieldsState({
                                        ...baseFieldsState,
                                        divisionTags: [value[value.length - 1]].filter(Boolean),
                                    });
                                } else {
                                    setBaseFieldsState({ ...baseFieldsState, divisionTags: value.filter(Boolean) });
                                }
                            }
                        }}
                        createItems={{
                            allow: allowCreateDivisions,
                            callback: (item) => {
                                if(!multiDivisionsAllowed) {
                                    setBaseFieldsState({ ...baseFieldsState, divisionTags: [item].filter(Boolean) });
                                } else {
                                    const newTags = [...baseFieldsState.divisionTags, item];
                                    setBaseFieldsState({ ...baseFieldsState, divisionTags: newTags as any });
                                }
                            },
                            splitter: [],
                            keyCode: 13,
                        }}
                    />
                </LineBox>

                <LineBox>
                    <FormControl fullWidth>
                        <PhoneInput
                            disabled={fieldIsDisabled(user, 'phone')}
                            onChange={(value) => updateBaseFields('phone', value)}
                            value={baseFieldsState.phone}
                            label={<Translate i18nKey={'pryaniky.registration.phone'} />}
                            error={'phone' in systemFieldsErrors}
                            helperText={systemFieldsErrors.phone}
                        />
                    </FormControl>
                </LineBox>
                
                <LineBox>
                    <FormControl fullWidth>
                        <TimeZoneSuggester
                            value={baseFieldsState.userTimeZone}
                            onChange={(e, value) => {
                                const prepairedValue = Array.isArray(value) ? value.join(',') : value;
                                updateBaseFields('userTimeZone', prepairedValue);
                            }}
                            allowExternalValue
                            TextFieldProps={{
                                InputProps: {
                                    startAdornment: null,
                                }
                            }}
                        />
                    </FormControl>
                </LineBox>

                <LineBox>
                    <FormControl fullWidth>
                        <TextField
                            disabled={fieldIsDisabled(user, 'email') || fieldIsDisabled(user, 'eMail')}
                            fullWidth
                            label={<Translate i18nKey={'pryaniky.profile.email'} />}
                            value={baseFieldsState.eMail}
                            onChange={(e: any) => updateBaseFields('eMail', e.target.value)}
                            error={'eMail' in systemFieldsErrors}
                            helperText={systemFieldsErrors.eMail}
                        />
                    </FormControl>
                </LineBox>

                {authUser.baseData.isAdmin && authUser.baseData.id !== user.baseData.id && (
                    <InputLabel htmlFor="isAdmin" className={'Profile-isAdminFlag'}>
                        <Checkbox
                            id="isAdmin"
                            checked={baseFieldsState.isAdmin}
                            onChange={() => updateBaseFields('isAdmin', !baseFieldsState.isAdmin)}
                        />
                        <Translate i18nKey={'pryaniky.profile.isAdmin'} />
                    </InputLabel>
                )}

                <InputLabel htmlFor="showMyNewsByDefault" className="Profile-ShowMyNewsByDefault">
                    <Checkbox
                        id="showMyNewsByDefault"
                        disabled={fieldIsDisabled(user, 'showMyNewsByDefault')}
                        checked={baseFieldsState.showMyNewsByDefault}
                        onChange={() =>
                            setBaseFieldsState({
                                ...baseFieldsState,
                                showMyNewsByDefault: !baseFieldsState.showMyNewsByDefault,
                            })
                        }
                    />
                    <Translate i18nKey={'pryaniky.profile.alwaysMyNews'} />
                </InputLabel>

                <LineBox>
                    {networksSettings?.enableSubNetworks && (
                        <Button onClick={() => ChangeUserNetwork({ id: userId })}>
                            <Translate i18nKey="pryaniky.user.network.button" />
                        </Button>
                    )}
                </LineBox>

                {user.profileData.userInfoFields.result.length !== 0 && (
                    <FieldsRenderBox>
                        <FieldsRender
                            userId={userId}
                            user={user}
                            fieldsState={customFieldsState}
                            setFieldsState={setCustomFieldsState}
                            setFieldsErrors={setCustomFieldsErrors}
                            fieldsErrors={customFieldsErrors}
                            isAuthUserAdmin={isAuthUserAdmin}
                            disablePastForDatePickers={false}
                        />
                    </FieldsRenderBox>
                )}

                <ActionsBox>
                    <LoadingButton
                        id="user-profile-saveEdit"
                        variant="contained"
                        onClick={updateStore}
                        loading={saving}
                    >
                        <Translate i18nKey={'save'} />
                    </LoadingButton>
                    {welcomeUserId ? (
                        <Button
                            id="user-profile-cancleEdit"
                            onClick={() => dispatch(updateUser(userId, user, setLoading, undefined, true))}
                            sx={{ mr: 1 }}
                        >
                            <Translate i18nKey={'cancel'} />
                        </Button>
                    ) : (
                        <Button
                            href={generatePath(mainUrls.user.id, { id: userId })}
                            id="user-profile-cancleEdit"
                            sx={{ mr: 1 }}
                        >
                            <Translate i18nKey={'cancel'} />
                        </Button>
                    )}
                </ActionsBox>
            </Box>
        </WrapBox>
    );
};
