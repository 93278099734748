import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';

import { mapStateToProps, IListStateProps, IListProps, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IListTypeOrdersProps } from './List_type_orders.index';

import './List_type_orders.scss';
import { AbstractList } from '../../List';
import { Header } from 'blocks/List/Header/List-Header';
import { ListLite, Button, Loading, Avatar, ListPlaceholder, TypedListPlaceholder } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { IShopOrder } from 'utils/src/requests/models/api.shop';
import { toast } from 'react-toastify';
import { Item } from '../../Item/List-Item';
import Characteristics from 'uielements/src/Characteristics'
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { createModal, IBodyProps } from 'utils/src/DialogCreator'
import DialogBody from 'uielements/src/MaterialElements/DialogParts/DialogBody'
import withConfirmDialog from 'uielements/src/HOCs/Button/withConfirmDialog'
import { formatDateNumbers } from 'utils/src/utils.dates';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { Link } from 'muicomponents/src/Link';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';

const ConfirmBtn = withConfirmDialog(Button)

const DescriptionDialog: React.FC<{
  order: IShopOrder,
  currencyNameSingle: string,
  currencyNamePlural: string,
  currencyNameSemiPlural: string
} & IBodyProps> = ({
  order,
  currencyNamePlural,
  currencyNameSemiPlural,
  currencyNameSingle,
  handleClose
}) => {
    return <DialogBody
      header={<Translate i18nKey={'pryaniky.list.orders.orderNo'} values={{ number: order.id, product: order.productName }} />}
      onClose={handleClose}
      contentPadding={'normal'}
      closeText={i18n.t('pryaniky.list.orders.dialog.close')}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <Avatar size={64} imgUrl={order.imgUrl} />
        <div style={{ marginLeft: '16px' }}>
          <div style={{fontWeight: 'bold', fontSize:'16px'}}>
            <Translate i18nKey={'pryaniky.list.orders.orderNo'} values={{ number: order.id, product: order.productName }} />
          </div>
          <div>
            {order.forUser && order.forUser.id !== order.user.id ? (
              <>
                {' '}
                <Translate i18nKey={'pryaniky.list.orders.for'} />
                {' '}
                <UserMention id={order.forUser.id}>
                  <Link className={classNamesDesign.primaryColor3.text} href={appGeneratePath(mainUrls.user.id, { id: order.forUser.id })}>{order.forUser.displayName}</Link>
                </UserMention>
              </>
            ) : (
                <Translate i18nKey={'pryaniky.list.orders.forSelf'} />
              )}
          </div>
          <div>
            {formatDateNumbers(order.date, true)}
          </div>
        </div>
      </div>

      <div >
        <div>
          <Translate i18nKey={'pryaniky.list.orders.price'} count={order.thanksPrice} values={{
            single: currencyNameSingle,
            plural: currencyNamePlural,
            semiPlural: currencyNameSemiPlural,
          }} />
        </div>
        <div>
          <Translate i18nKey={'pryaniky.list.orders.status.' + order.status} />
        </div>
        <div>
          {order.userComment && <Translate i18nKey={'pryaniky.list.orders.comment'} values={{ comment: order.userComment }} />}
        </div>
        <div>
          {order.moderatorComment && <Translate i18nKey={'pryaniky.list.orders.moderatorComment'} values={{ comment: order.moderatorComment }} />}
        </div>
        {/* <div>
        {order.status === 'OnVerification' && (
          <Button onClick={this.cancelOrder(el)} className="m-0">
            <Translate i18nKey={'pryaniky.list.orders.cancel'} />
          </Button>
        )}
      </div> */}
      </div>

      {order.characteristics && <div style={{marginTop: '16px'}}>
        <Characteristics inline readonly onChange={() => { }} characteristics={order.characteristics} />
      </div>}

      {order.attachment && <div style={{ marginTop: '16px' }}>
        <Attachments files={[order.attachment]} state='view' />
      </div>}
    </DialogBody>
  }
const openDialog = createModal(DescriptionDialog)


const textStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
export class ListOrdersPresenter extends AbstractList<IListTypeOrdersProps, IListState> {
  public static defaultProps = {
    requestOptions: {
      uid: undefined,
      skipCount: 0,
      count: 6,
      search: '',
    },
  };

  public type = 'orders';

  public constructor(props: IListTypeOrdersProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  }

  public search = (text: string) => {
    // this.searchString = text;
    // this.setState(
    //   {
    //     hideData: text.length !== 0,
    //     clearData: text.length !== 0,
    //     filteredData: [],
    //     isFinished: false,
    //   },
    //   this.getData
    // );
    const { toggle } = this.props;
    this.searchString = text;
    toggle({
      id: this.listId,
      data: {
        hideData: text.length !== 0,
        clearData: true,
        isFinished: false
      }
    });
    setTimeout(this.getData, 10);
  };

  public getData = () => {
    // const { requestOptions, context, edit } = this.props;
    const { id, props } = this;
    const { requestOptions, context, edit } = props;
    const { clearData, isLoading } = props;
    const { clearDataFromList, getOrders, toggle } = props;
    if (edit || isLoading) return;
    const opts = Object.assign({}, requestOptions);
    if (this.searchString && this.searchString !== '') opts.search = this.searchString;
    toggle({
      id,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    if (clearData) clearDataFromList({ id, data: null });

    getOrders.call(this, id, opts);

    // if (this.searchString) opts.search = this.searchString;
    // opts.skipCount = this.state[this.dataVariable].length;
    // this.setState({
    //   isLoading: true,
    //   isFinished: false,
    // });
    // utils.API.shop.getOrders(opts).r.then((response: IListResponseWithData<IShopOrder[]>) => {
    //   if (!this.mounted) return;
    //   if (!utils.checkResponseStatus(response)) {
    //     toast.error(i18n.t('server error'));
    //     this.setState({ loading: false });
    //   } else {
    //     this.setState({
    //       ...this.state,
    //       [this.dataVariable]: [...this.state[this.dataVariable], ...response.data],
    //       isFinished: response.isFinished,
    //       clearData: false,
    //       isLoading: false,
    //     });
    //   }
    // });
  };

  public renderChildren = () => {
    // const { isFinished, isLoading, selected } = this.state;
    // const data: any[] = this.state[this.dataVariable];
    const { data, isFinished, isLoading } = this.props;
    return (
      <React.Fragment>
        <Header
          className={'rounded-top'}
          type={'common'}
          search={{
            placeholder: i18n.t('pryaniky.list.orders.search'),
            onChange: this.search,
          }}
        />
        {isFinished && !data.length ? (
          <TypedListPlaceholder type={this.searchString ? 'search' : 'orders'} />
        ) : (
            <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData}>
              {data.map((el, i) => (
                <Item
                  lId={this.id}
                  iId={el}
                  type="common"
                  key={el}
                  // data={this.generateViewData(el, i)} 
                  data_key={el}></Item>
              ))}
            </ListLite>
          )}
      </React.Fragment>
    );
  };

  private cancelOrder = (el: IShopOrder) => () => {
    el.status = 'Cancelling';
    this.prepareData(el);
    this.props.changeListItem({ id: this.listId, data: { item: el.id, data: el } });
    utils.API.shop.orderCancel(el.id).r.then((response) => {
      if (!response) toast.error('pryaniky.toast.error.server');
      else if (response.error_code) toast.error(response.error_text);
      else {
        toast.success(i18n.t('pryaniky.list.orders.cancel.success'));
        el.status = 'Cancelled';
        this.prepareData(el);
        this.props.changeListItem({ id: this.listId, data: { item: el.id, data: el } });
      }
    });
  }

  private getUpdatedOrder = (idx: number) => {
    utils.API.shop
      .getOrders({
        search: this.searchString,
        skipCount: idx,
        count: 1,
      })
      .r.then((response) => {
        if (response && !response.error_code)
          this.setState(prevState => {
            prevState[this.dataVariable][idx] = response.data[0];
            return prevState;
          });
      });
  };

  private openDescriptionDialog = (order: IShopOrder) => () => {
    openDialog({
      order,
      currencyNameSingle: this.props.currencyNameSingle,
      currencyNamePlural: this.props.currencyNamePlural,
      currencyNameSemiPlural: this.props.currencyNameSemiPlural
    })
  }

  public prepareData(el: any) {
    el.view_data = {
      avatar: <Avatar size={56} imgUrl={el.imgUrl} />,
      info: [
        [
          <Translate i18nKey={'pryaniky.list.orders.orderNo'} values={{ number: el.id, product: el.productName }} />,
          el.forUser && el.forUser.id !== el.user.id ? (
            <>
              {' '}
              <Translate i18nKey={'pryaniky.list.orders.for'} />
              {' '}
              <UserMention id={el.forUser.id}>
                <Link className={classNamesDesign.primaryColor3.text} href={appGeneratePath(mainUrls.user.id, { id: el.forUser.id })}>{el.forUser.displayName}</Link>
              </UserMention>
            </>
          ) : (
              <Translate i18nKey={'pryaniky.list.orders.forSelf'} />
            ),
          formatDateNumbers(el.date, true)
        ],
        [
          <div onClick={this.openDescriptionDialog(el)} style={{ cursor: 'pointer' }}>
            <div style={textStyle}>
              <Translate i18nKey={'pryaniky.list.orders.price'} count={el.thanksPrice} values={{
                single: this.props.currencyNameSingle,
                plural: this.props.currencyNamePlural,
                semiPlural: this.props.currencyNameSemiPlural,
              }} />
            </div>
            <div style={textStyle}>
              <Translate i18nKey={'pryaniky.list.orders.status.' + el.status} />
            </div>
            <div style={textStyle}>
              {el.userComment && <Translate i18nKey={'pryaniky.list.orders.comment'} values={{ comment: el.userComment }} />}
            </div>
            <div style={textStyle}>
              {el.moderatorComment && <Translate i18nKey={'pryaniky.list.orders.moderatorComment'} values={{ comment: el.moderatorComment }} />}
            </div>
          </div>,
          <div>
            {el.status === 'OnVerification' && (
              <ConfirmBtn onConfirm={this.cancelOrder(el)} className="m-0">
                <Translate i18nKey={'pryaniky.list.orders.cancel'} />
              </ConfirmBtn>
            )}
          </div>
        ],
        el.characteristics && [
          <Characteristics inline readonly onChange={() => { }} characteristics={el.characteristics} />
        ],
        [el.attachment && <Attachments files={[el.attachment]} state='view' />],

      ].filter(Boolean),
    }
  }

  // private generateViewData = (el: IShopOrder, idx: number) => ();
}

export const List = connect(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListOrdersPresenter);
