import { put, takeLatest, takeLeading } from 'redux-saga/effects';
import { call, select } from 'utils/src/saga.effects';

import {
    CALENDAR_GET_CAL_FILTERS,
    CALENDAR_GET_EVENTS,
    CALENDAR_GET_WORKFLOW_FILTERS,
    CALENDAR_SET_CAL_FILTER_COLOR,
    setCalendarCalFilters,
    setCalendarEvents,
    setCalendarLoading,
    setCalendarWorkflowFilters,
} from './actions';

import {
    ICalendarGetEventsAction,
    ICalendarGetWorkflowFilterAction,
    ICalendarSetCalFilterColorAction,
} from './action.interfaces';

import { ICalendarCalFilter } from './interfaces';

import {
    ICalendarCalFiltersResponse,
    ICalendarEventData,
    ICalendarEventsResponse,
    ICalendarWorkflowFiltersResponse,
} from 'utils/src/requests/models/api.calendar';

import {
    getCalendarCalFiltersRequest,
    getCalendarEventsRequestV4,
    getCalendarWorkflowFiltersRequest,
    setCalendarCalFilterColorRequest,
} from 'utils/src/requests/requests.calendar';

import { getCalendarCalFiltersSelector, getCalendarEventsSelector } from './selectors';

import { i18n } from 'localization';
import moment from 'moment';
import { toast } from 'react-toastify';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { checkResponseStatus, getErrorText } from 'utils/src/utils';

const formatDate = (date: Date) => moment(new Date(date)).format().slice(0, 10);

export const prepareCalendarEvents = (resp: ICalendarEventData[]) => {
    const data: string[] = [];
    const dates: { [key: string]: string[] } = {};
    const events: { [key: string]: ICalendarEventData } = {};
    const calendarEvents: ICalendarEventData[] = [];

    resp.forEach((cEvent) => {
        const start = new Date(cEvent.start).toISOString();
        const end = new Date(cEvent.end).toISOString();
        const dateKey = formatDate(new Date(start));

        if (!data.includes(dateKey)) data.push(dateKey);
        dates[dateKey] = [...(dates[dateKey] ? dates[dateKey] : []), cEvent.id];
        events[cEvent.id] = cEvent;
        calendarEvents.push({ ...cEvent, start: new Date(start), end: new Date(end) });
    });

    // добавление плашки, если на чегодня нет событий
    const dateKeyToday = formatDate(new Date());
    if (!(dateKeyToday in dates)) {
        dates[dateKeyToday] = ['null'];
        data.push(dateKeyToday);
    }

    data.sort();

    return { data, dates, events, calendarEvents };
};

function* getCalendarEvents(action: ICalendarGetEventsAction) {
    try {
        const { filter, currentCalendarDate, cIds, statusIds, tagIds, gid } = action.payload;
        const start = moment(currentCalendarDate).startOf('month').startOf('week').format().slice(0, 10).toString();
        const end = moment(currentCalendarDate).endOf('month').endOf('week').format().slice(0, 10).toString();
        yield put(setCalendarLoading(true));
        const response: ICalendarEventsResponse = yield* call(getCalendarEventsRequestV4, {
            start,
            end,
            filter,
            cIds,
            statusIds,
            tagIds,
            gid,
        });
        if (checkResponseStatus(response)) {
            const responseData = response.data.events;

            // const data: string[] = [];
            // const dates: { [key: string]: string[] } = {};
            // const events: { [key: string]: ICalendarEventData } = {};
            // const calendarEvents: ICalendarEventData[] = [];

            // responseData.forEach(cEvent => {
            //   const start = new Date(cEvent.start).toISOString();
            //   const end = new Date(cEvent.end).toISOString();
            //   const dateKey = formatDate(new Date(start));

            //   if (!data.includes(dateKey)) data.push(dateKey);
            //   dates[dateKey] = [...(dates[dateKey] ? dates[dateKey] : []), cEvent.id];
            //   events[cEvent.id] = cEvent;
            //   calendarEvents.push({ ...cEvent, start: new Date(start), end: new Date(end) });

            // });

            // // добавление плашки, если на чегодня нет событий
            // const dateKeyToday = formatDate(new Date());
            // if (!(dateKeyToday in dates)) {
            //   dates[dateKeyToday] = ['null'];
            //   data.push(dateKeyToday);
            // }

            // data.sort();

            const { data, dates, events, calendarEvents } = prepareCalendarEvents(responseData);

            yield put(setCalendarEvents({ data, dates, events, calendarEvents }));
        } else {
            toast.error(getErrorText(response));
        }
        yield put(setCalendarLoading(false));
    } catch (e) {
        console.error('get calendar events error', e);
    }
}

function* getCalendarCalFilters(action?: any) {
    try {
        const response: ICalendarCalFiltersResponse = yield* call(getCalendarCalFiltersRequest, {
            showWorkflow: true,
            gId: action.payload ?? -1,
        });
        if (checkResponseStatus(response)) {
            const calendars: ICalendarCalFilter[] = [];
            const calendarsIds: string[] = [];
            response.data.forEach((calFilter) => {
                calendars.push({
                    ...calFilter,
                    color: calFilter.color,
                    id: calFilter.id,
                    title: calFilter.displayName,
                    type: 'cIds',
                    groupId: calFilter.groupId,
                    value: calFilter.name,
                    selected: calFilter.selected,
                });
                calendarsIds.push(calFilter.id);
            });

            yield put(setCalendarCalFilters({ calendars, calendarsIds }));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get calendar cal filters error', e);
    }
}

function* getCalendarWorkflowsFilters(action: ICalendarGetWorkflowFilterAction) {
    try {
        const response: ICalendarWorkflowFiltersResponse = yield* call(
            getCalendarWorkflowFiltersRequest,
            action.payload
        );
        if (checkResponseStatus(response)) {
            const statuses =
                response.data.statuses?.map((status) => ({
                    id: status.id,
                    title: status.name,
                    type: 'statusIds',
                    value: status.id,
                })) || [];
            const tags =
                response.data.tags?.map((tag) => ({
                    id: tag.id,
                    title: tag.displayName,
                    type: 'tagIds',
                    value: tag.id,
                })) || [];

            yield put(setCalendarWorkflowFilters({ statuses, tags }));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error(' getCalendarWorkflowsFilters error', e);
    }
}

/**
 * устанавливает цвет фильра(клик на цветную точку)
 * @param action
 */
function* setCalendarCalFilterColor(action: ICalendarSetCalFilterColorAction) {
    try {
        const { calendarId, color: _color, commonColor } = action.payload;
        let color = _color;
        const response: IBasicResponse = yield* call(
            setCalendarCalFilterColorRequest,
            calendarId,
            color?.slice(1) || '0',
            { commonColor }
        );
        if (checkResponseStatus(response)) {
            if (!color) {
                yield call(getCalendarCalFilters);
                const filters = yield* select(getCalendarCalFiltersSelector);
                color = filters.find((v) => v.id === calendarId)?.color || undefined;
                // console.log('colors', filters)
            }

            if (color) {
                const stateCalendars: ICalendarCalFilter[] = yield* select(getCalendarCalFiltersSelector);
                const calendars = stateCalendars.map((calendar) =>
                    calendar.id === calendarId ? { ...calendar, color: color! } : calendar
                );
                yield put(setCalendarCalFilters({ calendars }));

                const stateCalendarEvents: ICalendarEventData[] = yield* select(getCalendarEventsSelector);
                const calendarEvents = stateCalendarEvents.map((event) =>
                    event.calendarId === calendarId ? { ...event, eventColor: color!, eventBorderColor: color! } : event
                );
                yield put(setCalendarEvents({ calendarEvents }));
            }

            toast.success(i18n.t('pryaniky.administration.toast.success'));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('set calendar filter color erroe', e);
    }
}

const calendar = function* calendar() {
    yield takeLatest(CALENDAR_GET_EVENTS, getCalendarEvents);
    yield takeLeading(CALENDAR_GET_CAL_FILTERS, getCalendarCalFilters);
    yield takeLatest(CALENDAR_SET_CAL_FILTER_COLOR, setCalendarCalFilterColor);
    yield takeLatest(CALENDAR_GET_WORKFLOW_FILTERS, getCalendarWorkflowsFilters);
};

export default calendar;
