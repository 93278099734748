import { TFilterData } from '../../CommonFilters.index';
import { userRoles } from 'utils/src/constants.prn';

const usrsFilterClear = ['arfilter', 'usersCatFilter/usersRole/userBlock/arfilter'];
const usrsShopFilterClear = [...'usersRole/userBlock'.split('/'), 'usersCatFilter/usersRole/userBlock'];
const usrsREgistrationsFilterClear = [...'usersRole/userBlock'.split('/'), 'usersCatFilter/usersRole/userBlock'];

export const headerData: Record<string, string> = {
    'usersCatFilter/usersRole/userBlock': 'pryaniky.filter.users.title',
    'usersCatFilter/usersRole/userBlock/arfilter': 'pryaniky.filter.users.accessrequests.title',
    'usersCatFilter/usersRole/userBlock/arfilter/shopAccess': 'pryaniky.filter.users.shopaccess.title',
};

export const filterData: TFilterData[] = [
    // all filters
    {
        opts: [
            {
                id: 'all',
                title: 'all',
                type: 'usersCatFilter/usersRole/userBlock',
                value: '//',
                clear: usrsFilterClear,
            },
            {
                id: 'notinitialized',
                title: 'notinitialized',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'notinitialized',
                showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) },
                clear: usrsFilterClear,
            },
            {
                id: 'initialized',
                title: 'initialized',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'initialized',
                showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) },
                clear: usrsFilterClear,
            },
            {
                id: 'blocked',
                title: 'blocked',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'userBlock//on',
                showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) },
                clear: usrsFilterClear,
            },
            {
                id: 'administrators',
                title: 'administrators',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'role/SiteAdmin',
                clear: usrsFilterClear,
            }, // roles
            {
                id: 'premoderated',
                title: 'premoderated',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'premoderated',
                showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) },
                clear: usrsFilterClear,
            },
            {
                id: 'photomoderated',
                title: 'photoonmoderation',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'photomoderated',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    modules: (modules: any) => modules.photomoderation,
                },
                clear: usrsFilterClear,
            },
            {
                id: 'guest',
                title: 'guest',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'role/Guest',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    modules: (modules: any) => modules.guest,
                },
                clear: usrsFilterClear,
            }, // roles
            {
                id: 'user',
                title: 'user',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'role/Employee',
                showProps: { networks: (nets: Record<string, any>[]) => nets.length > 1 },
                clear: usrsFilterClear,
            }, // roles
            {
                id: 'notAllowedToNetwork',
                title: 'notAllowedToNetwork',
                type: 'usersCatFilter/usersRole/userBlock',
                value: 'role/notAllowedToNetwork',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    networks: (nets: Record<string, any>[]) => nets.length > 1,
                },
                clear: usrsFilterClear,
            }, // roles
        ],
    },
    {
        opts: [
            {
                id: 'accessrequests/All',
                title: 'accessrequests.All',
                type: 'usersCatFilter/usersRole/userBlock/arfilter',
                value: 'accessrequests///All',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    enableAutoRegistration: true,
                },
                clear: usrsREgistrationsFilterClear,
            },
            {
                id: 'accessrequests/New',
                title: 'accessrequests.New',
                type: 'usersCatFilter/usersRole/userBlock/arfilter',
                value: 'accessrequests///New',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    enableAutoRegistration: true,
                },
                clear: usrsREgistrationsFilterClear,
            },
            {
                id: 'accessrequests/WatingMailApprove',
                title: 'accessrequests.WatingMailApprove',
                type: 'usersCatFilter/usersRole/userBlock/arfilter',
                value: 'accessrequests///WatingMailApprove',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    enableAutoRegistration: true,
                },
                clear: usrsREgistrationsFilterClear,
            },
            {
                id: 'accessrequests/Approved',
                title: 'accessrequests.Approved',
                type: 'usersCatFilter/usersRole/userBlock/arfilter',
                value: 'accessrequests///Approved',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    enableAutoRegistration: true,
                },
                clear: usrsREgistrationsFilterClear,
            },
            {
                id: 'accessrequests/Canceled',
                title: 'accessrequests.Canceled',
                type: 'usersCatFilter/usersRole/userBlock/arfilter',
                value: 'accessrequests///Canceled',
                showProps: {
                    roles: (roles: string[]) => roles.includes(userRoles.sileAdmin),
                    enableAutoRegistration: true,
                },
                clear: usrsREgistrationsFilterClear,
            },
        ],
    },
    {
        opts: [
            {
                id: 'on',
                title: 'shopaccess.on',
                type: 'usersCatFilter/usersRole/userBlock/arfilter/shopAccess',
                value: 'shopaccess////true',
                showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) },
                clear: usrsShopFilterClear,
            },
            {
                id: 'off',
                title: 'shopaccess.off',
                type: 'usersCatFilter/usersRole/userBlock/arfilter/shopAccess',
                value: 'shopaccess////false',
                showProps: { roles: (roles: string[]) => roles.includes(userRoles.sileAdmin) },
                clear: usrsShopFilterClear,
            },
        ],
    },
];

export const defaulValues = {
    'usersCatFilter/usersRole/userBlock': '//',
};

export const defaulIds = {
    'usersCatFilter/usersRole/userBlock': 'all',
};
