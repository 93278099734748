import { IExpertEvalutionResultsProps } from './ExpertEvalutionResults.index';
import * as React from 'react'
import './ExpertEvalutionResults.scss'
// import { expertResults } from '../IdeasSides';
import { UserList } from 'uielements/src/UserList/UserList';
import { ButtonPopover } from '../../common/ButtonPopover/ButtonPopover'
import { IdeaFieldView } from '../Fields/view/Field';
import { normalize, schema } from 'normalizr';
import { cloneObject } from 'utils/src/utils';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { StickyTable } from 'uielements/src/StickyTable/StickyTable';
import { ActionButtons } from '../../../ActionButtons/ActionButtons';
import { FieldsHeader } from '../FieldsHeader/FieldsHeader';
import { i18n, Translate } from 'localization';
import { Icon } from 'uielements/src';
import { confirm } from 'utils.project/utils.project';
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import { setExperts } from '../redux/actions'
import { useLazyQuery } from 'utils/src/hooks'
import {
    API
} from 'utils/src/utils'
import { Avatar } from 'muicomponents/src/Avatar';
import { AvatarSize } from 'muicomponents/src/Avatar/Avatar';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';

const normalizeData = (data: any) => {
    const clone = cloneObject(data);
    const field = new schema.Entity('fields', { field: {} }, { idAttribute: (value) => value.field.id });
    const categorys = new schema.Entity('categorys', { fields: [field] }, { idAttribute: 'category' });
    const fieldsSchema = new schema.Object({ fields: new schema.Array(categorys) });

    const normalizedData = normalize(clone, fieldsSchema);
    return normalizedData;
}

const createTableModel = (value: any, deleteExpert: any, showDeleteExpers: boolean) => {
    const rowHeaders: React.ReactNode[] = [];
    const experts = value.result.expertises.map((el: any) => el.user)

    const tRows: any = [];
    const colHeader = value.result.expertises.map((val: any) => {
        return <div className="ExpertAvatar">
            {showDeleteExpers && <div className="ExpertDelete" onClick={() => deleteExpert(experts, val.user.id)}><Icon icon='cross-mark' /></div>}
            <UserMention id={val.user.id}>
                <Link href={appGeneratePath(mainUrls.user.id, { id: val.user.id })}>
                    <Avatar sizeAlias={'s'} src={val.user.userPhotoUrl} >
                        {val.user.displayName.substring(0,1)}
                    </Avatar>
                </Link>
            </UserMention>
        </div>
    })
    tRows.push({
        cells: value.result.expertises.map((v: any, i: number) => {
            return <div>{v.expStatus === "completed" ? <Icon icon="check" /> : "-"}</div>
        })
    });
    rowHeaders.push(<h4 className="AgreedToRate">Согласились оценить</h4>);
    value.result.fields.forEach((val: any) => {
        val !== '' && rowHeaders.push(<h3>{val}</h3>);//
        val !== '' && tRows.push({ cells: [] });

        value.entities.categorys[val].fields.forEach((c: any) => {
            const currentField = value.entities.fields[c];
            if (currentField.field.fieldType === "MatrixOnePerRow") {

            } else {
                rowHeaders.push(
                    <Tooltip placement='left' title={currentField.field.description}>
                        <div>{currentField.field.name}</div>
                    </Tooltip>
                )
            }


            // tRows.push({ cells: new Array(value.result.expertises.length).fill('') });

            if (currentField.field.fieldType === "MatrixOnePerRow") {

                currentField.field.rows.forEach((row: any, rowIndex: number) => {
                    rowHeaders.push(row.name);
                    const matrixRows = {
                        cells: value.result.expertises.map((v: any, i: number) => {
                            return currentField.expertAnswers[rowIndex][i] !== '-' ? currentField.field.answers[currentField.expertAnswers[rowIndex][i]].text : '-';
                        })
                    };

                    tRows.push(matrixRows);
                })
            }
            else {
                const arr: any[] = [];
                const chunk = currentField.field.answers.length === 0 ? 1 : currentField.field.answers.length;
                for (let i = 0, j = currentField.expertAnswers.length; i < j; i += chunk) {
                    arr.push(currentField.expertAnswers.slice(i, i + chunk));
                }
                const fieldsN: any = {};
                fieldsN.answers = arr;
                fieldsN.values = arr;
                fieldsN.value = arr;
                fieldsN.field = currentField.field;


                switch (currentField.field.fieldType) {
                    case "ResponsibleForStatusUser":
                    case "OfferResponsibleForStatusUsers":
                        tRows.push({
                            cells: value.result.expertises.map((v: any, i: number) => {
                                if (currentField.expertAnswers.length - 1 < i) return undefined;
                                return <UserList cut={false} action={false} key={i} activeAction={false} data={[currentField.expertAnswers[i]]} />
                            })
                        });
                        break;
                    case "String":
                        tRows.push({
                            cells: value.result.expertises.map((v: any, i: number) => {
                                fieldsN.value = currentField.expertAnswers[i] || ''
                                fieldsN.values = arr.length > i ? arr[i] : [];
                                const newFie = { ...fieldsN };
                                return <IdeaFieldView field={newFie} key={i} />
                            })
                        });
                        break;
                    case "Single":
                        tRows.push({
                            cells: value.result.expertises.map((v: any, i: number) => {
                                const findAnswered = fieldsN.answers[i].findIndex((el: any) => el === "True")

                                // fieldsN.value = currentField.expertAnswers[i] || ''
                                // fieldsN.values = arr.length > i ? arr[i] : [];
                                if (findAnswered !== -1) {

                                    const newFie = { ...fieldsN.field.answers[findAnswered], selected: true };

                                    return <div className="SingleTypeFieldAnswer">{newFie.text}</div>
                                } else {
                                    return <>-</>
                                }


                                // 
                                // return <IdeaFieldView field={newFie} key={i} />
                            })
                        });
                        break;
                    default:
                        tRows.push({
                            cells: value.result.expertises.map((v: any, i: number) => {
                                fieldsN.value = arr.length > i ? (fieldsN.answers[i][0] ? fieldsN.answers[i][0] : '') : '';
                                fieldsN.values = arr.length > i ? arr[i] : [];
                                const newFie = { ...fieldsN };
                                return <IdeaFieldView field={newFie} key={i} />
                            })
                        });
                }

            }
        })
    })

    return { rows: tRows, rowHeaders, colHeader };
}

export const ExpertEvalutionResults: React.FC<IExpertEvalutionResultsProps> = ({ ideaId, statusID, showDeleteExpers, anchor }) => {

    const [
        query,
        isLoading,
        result,
        isError,
        count,
        setResult,
        // errorInfo,
        // errorStr
    ] = useLazyQuery(async function () {
        return await (await API.news.ideas.getExpertiseResult(ideaId, statusID)).r
    }, (response) => {
        if (response.error_code === 0) {
            return createTableModel(normalizeData((response as any).data), deleteExpert, showDeleteExpers)
        } else throw new Error('error_load_results')
    })

    const { dispatchActionParam } = useLazyExecutSaga(() => setExperts({
        ideaId,
        experts: [],
        newExperts: []
    }), [ideaId])

    const deleteExpert = (experts: any[], userId: string) => {
        const newExperts = experts.filter((el) => el.id !== userId);
        confirm({
            text: 'Удалить эксперта?',
            onConfirm: () => dispatchActionParam({
                payload: {
                    ideaId,
                    experts,
                    newExperts
                }
            })//setExperts(ideaId, newExperts, experts)
        })
    }

    return (
        <ButtonPopover anchor={anchor} label={i18n.t('pryaniky.idea.show.evaluation')} isLoading={isLoading} closeAction={() => setResult(null)} openAction={query}>
            {result ? (close) => (
                <div className="ExpertEvalutionResults">

                    <FieldsHeader>{i18n.t('pryaniky.idea.evalution.results')}</FieldsHeader>

                    <StickyTable rowsHeaders={result.rowHeaders} columnsHeaders={result.colHeader} rows={result.rows} />

                    <ActionButtons cancle={close} cancleText={i18n.t('close')} />
                </div>) : () => <div></div>}
        </ButtonPopover>);
}