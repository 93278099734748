import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import * as React from 'react';
import { connect } from 'react-redux';
import { NodeBulder } from 'uielements/src/CommonmarkRender';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import * as utils from 'utils/src/utils';
import { EditorForm } from '../../EditorForm/EditorForm';
import { EditorTypeNews as ReplyForm } from '../../NewsTypes/Reply/Editor/containers/news/news';
import { ReplyType } from '../../NewsTypes/Reply/Reply.type';
import { rawToMd } from '../../PryanikyEditor/convertorConfigs';
import { cnEdit, IEditProps, IEditState, mapDispatchToProps, mapStateToProps } from './Reply-Edit.index';
import './Reply-Edit.scss';
export class EditPresenter extends React.PureComponent<IEditProps, IEditState> {
    constructor(props: IEditProps) {
        super(props);
        const newPost: any = utils.cloneObject(props.comment);
        const nb = new NodeBulder(newPost.text || '');
        let text = EditorState.createWithContent(convertFromRaw(nb.getDraftRaw()));
        this.state = {
            comment: { ...newPost, text, ratingEnabled: props.withRating },
            isValid: false,
            validFile: true,
        };
    }

    public render() {
        const customClassNames = '';
        const { children, className = '' } = this.props;
        const { comment, isValid, validFile } = this.state;
        return (
            <div className={cnEdit({}, [customClassNames, className])}>
                <EditorForm
                    files={comment.attachments || []}
                    onChangeFile={this.onChangeFile}
                    save={this.sendNews}
                    cancle={this.props.disableEdit}
                    isValid={!isValid}
                    validationErrors={[]}
                >
                    <ReplyForm onChange={this.onChange} data={comment} />
                </EditorForm>
                {children}
            </div>
        );
    }
    private onChangeFile = (files: any[], validate: boolean) => {
        // this.setState((s: IEditState) => {
        //   s.comment.attachments = files;
        //   s.validFile = validate;
        //   return s;
        // })
        this.setState({
            ...this.state,
            validFile: validate,
            comment: {
                ...this.state.comment,
                attachments: files,
            },
        });
    };

    private onChange = (comment: ReplyType, validate: boolean) => {
        this.setState({ comment, isValid: validate });
    };

    private sendNews = () => {
        this.setState(
            (s: IEditState) => {
                s.isValid = false;
                return s;
            },
            () => {
                const text = draftToMarkdown(convertToRaw(this.state.comment.text.getCurrentContent()), rawToMd);
                this.props.updateReply({
                    newsId: this.props.newsId,
                    reply: { ...this.state.comment, text },
                });
                this.props.disableEdit();
            }
        );
    };
}

export const Edit = connect(mapStateToProps, mapDispatchToProps)(EditPresenter);
