import { Box } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { BaseSuggester as BaseSuggesterMui, UsersSuggester as UsersSuggesterMui } from 'muicomponents/src/Suggester';
import React from 'react';
import { IStateType } from 'redux/store';

const defaultSuggesterStyle = {
    '& .MuiInputBase-root': {
        paddingRight: '39px !important',
    },
};
export interface IFilterProps<TypeFilter> {
    filter: TypeFilter;
    onChangeFilter: React.Dispatch<React.SetStateAction<TypeFilter>>;
    filterCleanButton: React.ReactNode;
    filterConfirmButton: React.ReactNode;
    confirmFiltersByButton?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SuggestersBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    // margin: '12px 0 0',
    width: '100%',
    gap: '6px',
}) as typeof Box;

export const BaseSuggester = styled(BaseSuggesterMui)({
    ...defaultSuggesterStyle,
}) as typeof BaseSuggesterMui;

export const UsersSuggester = styled(UsersSuggesterMui)({
    ...defaultSuggesterStyle,
}) as typeof UsersSuggesterMui;

export const getNewsTypes = (state: IStateType) => state.appSettings.companyPostTypes;
