import React, { useEffect } from 'react';
import {
    cnAdditionalMenu,
    IAdditionalMenuProps,
    AdditionalMenuActionsToProps,
    AdditionalMenuStateToProps,
} from './AdditionalMenu.index';
import './AdditionalMenu.scss';
import { connect } from 'react-redux';
import { IAdditionalMenuItem } from 'utils/src/CommonRedux/base/reducer';
import { Button, Icon } from 'uielements/src';
import { useLeftMenu } from 'blocks/LeftMenuMui/LeftMenu.hooks';
import { Box } from 'muicomponents/src';
import { minimalMenuWidth } from 'blocks/LeftMenuMui/LeftMenu.constants';
import { useHistory } from 'react-router';

const topButton: IAdditionalMenuItem = {
    id: 'topButton',
    onClick: () => window.scrollTo(0, 0),
    children: <Icon icon="chevron-up" />,
    additionalProps: {
        id: 'TOPBUTTON',
    },
};

const AdditionalMenuPresenter: React.FC<IAdditionalMenuProps> = ({
    data,
    addToAdditionalMenu,
    removeFromAdditionalMenu,
}) => {
    useEffect(() => {
        let issh = data.findIndex((d) => d.id === topButton.id) >= 0;
        document.addEventListener('scroll', () => {
            if (window.scrollY > 200 && !issh) {
                addToAdditionalMenu({ item: topButton });
                issh = true;
            } else if (window.scrollY < 100 && issh) {
                removeFromAdditionalMenu({ id: topButton.id });
                issh = false;
            }
        });
    }, []);

    const { opened: isOpenLeftMenu, hide: isHideLeftMenu } = useLeftMenu();
    const history = useHistory();

    return (
        <Box
            className={cnAdditionalMenu('Wrapper Layout-Inner')}
            sx={{
                left: isHideLeftMenu ? 'unset' : `calc(50% + ${isOpenLeftMenu ? 150 : minimalMenuWidth / 2}px)`,
                width: `calc(100% - ${isHideLeftMenu ? 0 : isOpenLeftMenu ? 300 : minimalMenuWidth}px)`,
            }}
        >
            <Box
                className={cnAdditionalMenu()}
                sx={{
                    right: history.location.pathname.includes('notifiSettings/') ? '150px' : '5%',
                }}
            >
                {data.map((b) => (
                    <Button
                        key={b.id}
                        className={cnAdditionalMenu('Item')}
                        type={b.type}
                        href={b.href}
                        onClick={b.onClick}
                        children={b.children}
                        {...b.additionalProps}
                    />
                ))}
            </Box>
        </Box>
    );
};

export const AdditionalMenu = connect(
    AdditionalMenuStateToProps,
    AdditionalMenuActionsToProps
)(AdditionalMenuPresenter);
