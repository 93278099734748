import React, { FC, useCallback, useRef, useState } from 'react';
import { createModal, IBodyProps, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogActions, DialogTitle } from 'muicomponents/src/DialogParts';
import { Translate } from 'localization';
import { TextField } from 'muicomponents/src';
import { DatePicker } from 'muicomponents/src/DatePicker';
import {
    checkResponseStatus,
    NValidate,
    ResponseError,
    taskModelSimple,
    TaskModelSimple,
    validate_v2,
} from 'utils/src';
import { cloneDeep, pick } from 'lodash';
import { useReffedState } from 'utils/src/hooks';
import { createUpdateTask } from 'utils/src/requests/requests.tasks';
import { toast } from 'react-toastify';
import { StyledDialogContent } from './UpdateTask.styled';
import { v1 as uuid } from 'uuid';
import { PrepareErrorText } from 'News/utils/utils.validate';

type UpdateTaskProps = IBodyProps<TaskModelSimple> & {
    userId: string;
    initData?: TaskModelSimple;
};

const validateRules: NValidate.ValidateRulesObject<TaskModelSimple> = {
    displayName: { notNull: { value: true } },
};

const UpdateTaskPr: FC<UpdateTaskProps> = ({ userId, initData, handleAccept, handleClose }) => {
    const userIdRef = useRef(userId);
    userIdRef.current = userId;

    const [state, setState, stateRef] = useReffedState<TaskModelSimple>(() => {
        if (initData) {
            return initData;
        }
        return {
            ...cloneDeep(taskModelSimple),
            id: uuid(),
        };
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors, errorsRef] = useReffedState<NValidate.ValidateResponse<typeof state>>({});

    const confirmCreate = useCallback(async function () {
        const newErrors = validate_v2(stateRef.current, validateRules);
        if (Object.values(newErrors).filter(Boolean).length) {
            setErrors(newErrors);
            return;
        }
        try {
            setLoading(true);
            const response = await createUpdateTask({
                userId: userIdRef.current,
                model: stateRef.current,
            });
            if (checkResponseStatus(response)) {
                toast.success(
                    <Translate
                        i18nKey="createdWith"
                        values={{
                            text: Translate.t({ i18nKey: 'success' }).toLowerCase(),
                        }}
                    />
                );
                handleAccept(stateRef.current);
            } else {
                toast.error(<Translate i18nKey="pryaniky.error.default" />);
                throw new ResponseError('Create task error');
            }
        } catch (error) {
            if (error instanceof ResponseError) {
                console.error(error);
            } else {
                throw error;
            }
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <>
            <DialogTitle onClose={handleClose}>
                <Translate
                    i18nKey={initData ? 'updatingWith' : 'createWith'}
                    values={{
                        text: initData
                            ? Translate.t({ i18nKey: 'pryaniky.tasks.name', count: 2 }).toLowerCase()
                            : Translate.t({ i18nKey: 'pryaniky.tasks.name.create' }).toLowerCase(),
                    }}
                />
            </DialogTitle>
            <StyledDialogContent>
                <TextField
                    fullWidth
                    label={<Translate i18nKey={'pryaniky.task.field.displayName'} />}
                    value={state.displayName}
                    onChange={(e) => {
                        const value = e.target.value;
                        setState({
                            ...state,
                            displayName: value,
                        });
                        setErrors({
                            ...errors,
                            ...validate_v2({ displayName: value }, pick(validateRules, 'displayName')),
                        });
                    }}
                    error={!!errors.displayName}
                    helperText={!!errors.displayName && PrepareErrorText(errors.displayName)}
                />
                <DatePicker
                    type="datetime"
                    TextFieldProps={{
                        fullWidth: true,
                        label: <Translate i18nKey={'pryaniky.task.field.dueDate'} />,
                    }}
                    value={state.dueDate}
                    onChange={(value) => {
                        setState({
                            ...state,
                            dueDate: value || '',
                        });
                    }}
                />
                <TextField
                    fullWidth
                    label={<Translate i18nKey={'pryaniky.task.field.type'} />}
                    value={state.type}
                    onChange={(e) => {
                        setState({
                            ...state,
                            type: e.target.value,
                        });
                    }}
                />
                <TextField
                    fullWidth
                    label={<Translate i18nKey={'pryaniky.task.field.url'} />}
                    value={state.url}
                    onChange={(e) => {
                        setState({
                            ...state,
                            url: e.target.value,
                        });
                    }}
                />
                <TextField
                    fullWidth
                    label={<Translate i18nKey={'systemName'} />}
                    value={state.systemName}
                    onChange={(e) => {
                        setState({
                            ...state,
                            systemName: e.target.value,
                        });
                    }}
                />
            </StyledDialogContent>
            <DialogActions
                closeText={<Translate i18nKey={'cancel'} />}
                acceptText={<Translate i18nKey={initData ? 'save' : 'create'} />}
                onClose={handleClose}
                onAccept={confirmCreate}
                isLoading={loading}
                disableAccept={!!Object.values(errors).filter(Boolean).length}
            />
        </>
    );
};

export const UpdateTask = createModal(UpdateTaskPr, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        },
    },
    TransitionComponent,
    scroll: 'body',
});
