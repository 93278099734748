import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IDispatchProps } from 'redux/connector';
import { RouteComponentProps } from 'react-router';
import { getClientStructure, updateContext } from 'redux/actions/Widgets'

export interface IWDialogProps extends IClassNameProps, RouteComponentProps<IWDialogUrlParams>, IDispatchProps, IActionsWDialog, IWDialogStateProps {
  tag?: string | React.ComponentType<any>;
}

export interface IWDialogUrlParams {
  type: string;
  id?: string;
  subtype?: string;
}

export interface IWDialogState {
  text?: string;
  isShow: boolean;
}

export interface IActionsWDialog {
  getClientStructure?: any;
  updateContext? :any;
}

export const mapActionsToPropsPage = {
  getClientStructure,
  updateContext,
};

export interface IWDialogStateProps {
  // structure: { [s: string]: any };
  layout: { [s: string]: any };
}

export const mapStateToProps = (state: any, props: IWDialogProps) => ({
  // structure: state.widgets.pages[state.widgets.structures['widgets' + (props.match.params.id ? '/' + props.match.params.id : '')]],
  // layout: state.widgets.pages[state.widgets.structures['widgets' + (props.match.params.id ? '/' + props.match.params.id : '')]] ? state.widgets.widgets[state.widgets.pages[state.widgets.structures['widgets' + (props.match.params.id ? '/' + props.match.params.id : '')]].layout] : undefined,
  // structure: getPageStructure(state.widgets.structures, 'widgets' + (props.match.params.id ? '/' + props.match.params.id : '')),
})

export const cnWDialog = cn('WDialog');