import React, { FC, useState, useEffect, useLayoutEffect } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { WidgetDialogBox } from './WidgetSettingsDialog.styled';
import { IWidgetSettingsProps, ISettingsItem } from './WidgetSettingsDialog.index';
import { NumberTextField, TextField } from 'muicomponents/src/TextField/TextField';
import { UploadButton } from 'muicomponents/src/UploadButton/UploadButton';
import { useFileUploader, useReffedState } from 'utils/src/hooks';
import { Button } from 'muicomponents/src/Button/Button';
import Switch from '@mui/material/Switch';
import uuid from 'uuid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import Delete from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { i18n, Translate } from 'localization';
import { Select, SelectOption } from 'muicomponents/src/Select';
import { Box, Typography } from 'muicomponents/src';

const StyledImg = styled('img')({
    width: '36.5px',
    height: '36.5px',
});
const DurationValConteiner = styled(Box)({
    display: 'grid',
    gap: '8px',
    gridTemplateColumns: '1fr 2fr',
}) as typeof Box;

const FirstLatterUpperText = styled(Typography)({
    ['&:first-letter']: {
        textTransform: 'uppercase',
    },
}) as typeof Typography;

const Item = ({
    settings,
    onChange,
    onEnebled,
    onDelete,
}: {
    settings: ISettingsItem;
    onChange: (settnigs: ISettingsItem) => void;
    onEnebled: (enabled: boolean) => void;
    onDelete: () => void;
}) => {
    const [state, setState] = useState<typeof settings>(
        {
            ...(settings || {}),
            durationShowTimer: settings?.durationShowTimer || 1,
            measurementInSeconds: settings?.measurementInSeconds || '60',
        } || {}
    );

    const { isLoading, onFileChange } = useFileUploader({
        allowTypes: ['image/jpeg', 'image/png', 'image/gif'],
        uploadOnAdd: true,
        maxFilesCount: 1,
        callbacks: {
            loadFinish(item, response) {
                if (response?.error_code === 0) {
                    setState((s) => ({ ...s, file: response.data[0] }));
                }
            },
        },
    });

    useEffect(() => {
        onChange(state);
    }, [state]);

    return (
        <Stack direction={'row'} gap={2} alignItems="flex-start">
            <Stack gap={2} flex="1">
                <TextField
                    required
                    label={i18n.t('pryaniky.FloatMessageWidget.settings.message')}
                    value={settings?.text}
                    onChange={(e) => setState((s) => ({ ...s, text: e.target.value }))}
                />
                <TextField
                    label={i18n.t('pryaniky.FloatMessageWidget.settings.link')}
                    value={settings?.url}
                    onChange={(e) => setState((s) => ({ ...s, url: e.target.value }))}
                />

                <Stack gap={2} direction="row">
                    <StyledImg src={settings?.file?.previewUrl} />

                    <UploadButton inputId={`file-${state.id}`} loading={isLoading} onChangeInput={onFileChange}>
                        {i18n.t('pryaniky.FloatMessageWidget.settings.upload_image')}
                    </UploadButton>
                </Stack>

                <Stack>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={settings?.isShowTimer}
                                onChange={(e) => {
                                    setState((s) => ({ ...s, isShowTimer: e.target.checked }));
                                }}
                            />
                        }
                        label={<Translate i18nKey="pryaniky.FloatMessageWidget.settings.durationShowTimer" />}
                    />
                    <Typography sx={{ color: !state?.isShowTimer ? 'rgba(0, 0, 0, 0.38)' : 'inherit' }}>
                        <Translate i18nKey="pryaniky.FloatMessageWidget.settings.durationDescription" />
                    </Typography>
                    <DurationValConteiner>
                        <NumberTextField
                            disabled={!settings?.isShowTimer}
                            hideApperance
                            inputProps={{
                                min: 0,
                            }}
                            value={settings?.durationShowTimer}
                            onChange={(e) => {
                                setState((s) => ({ ...s, durationShowTimer: e.target.value }));
                            }}
                        />
                        <Select
                            disabled={!settings?.isShowTimer}
                            options={[
                                {
                                    title: (
                                        <FirstLatterUpperText>
                                            <Translate
                                                i18nKey={'pryaniky.inputs.time.minute'}
                                                count={+(state?.durationShowTimer || 5)}
                                            />
                                        </FirstLatterUpperText>
                                    ),
                                    value: '60',
                                },
                                {
                                    title: (
                                        <FirstLatterUpperText>
                                            <Translate
                                                i18nKey={'pryaniky.inputs.time.hour'}
                                                count={+(state?.durationShowTimer || 5)}
                                            />
                                        </FirstLatterUpperText>
                                    ),
                                    value: '3600',
                                },
                                {
                                    title: (
                                        <FirstLatterUpperText>
                                            <Translate
                                                i18nKey={'pryaniky.inputs.time.day'}
                                                count={+(state?.durationShowTimer || 5)}
                                            />
                                        </FirstLatterUpperText>
                                    ),
                                    value: '86400',
                                },
                            ]}
                            value={state?.measurementInSeconds}
                            defaultValue={state?.measurementInSeconds}
                            onChange={(v) => {
                                setState((s) => ({ ...s, measurementInSeconds: v.target.value as string }));
                            }}
                        />
                    </DurationValConteiner>
                </Stack>
                <FormControlLabel
                    control={<Switch checked={settings.enabled} onChange={(e) => onEnebled(e.target.checked)} />}
                    label={i18n.t('pryaniky.FloatMessageWidget.settings.enabled')}
                />
            </Stack>
            <Tooltip title={i18n.t('pryaniky.FloatMessageWidget.settings.delete')}>
                <IconButton
                    onClick={() => {
                        onDelete();
                    }}
                >
                    <Delete color="error" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export const WidgetSettingsDialog: FC<IWidgetSettingsProps> = ({ onChange, data, settings, setValid }) => {
    const [state, setState] = useState<ISettingsItem[]>(() => {
        let items = settings?.items || [];
        return items;
    });

    useEffect(() => {
        onChange({
            settings: { items: state },
        });
        const allTexts = state.every((item) => !!item.text);
        const allFiles = state.every((item) => !!item.file);
        setValid(allTexts && allFiles);
    }, [state]);
    return (
        <WidgetDialogBox>
            <Stack divider={<Divider />} gap={2}>
                {state.map((item) => (
                    <Item
                        key={item.id}
                        onDelete={() => setState((p) => p.filter((v) => v.id !== item.id))}
                        onEnebled={(enabled) =>
                            setState((p) =>
                                p.map((v) => (v.id === item.id ? { ...v, enabled } : { ...v, enabled: false }))
                            )
                        }
                        onChange={(s) => setState((p) => p.map((v) => (v.id === item.id ? s : v)))}
                        settings={item}
                    />
                ))}

                <Button onClick={() => setState((s) => [...s, { id: uuid(), enabled: false }])}>
                    {i18n.t('pryaniky.FloatMessageWidget.settings.add_item')}
                </Button>
            </Stack>
        </WidgetDialogBox>
    );
};

export const initWidgetAddition = () =>
    ComponentInjector.getInstance().addComponent('WAddition', WidgetSettingsDialog, 'pryaniky/FloatMessageWidget');
export const initWidgetSettings = () =>
    ComponentInjector.getInstance().addComponent('WSettings', WidgetSettingsDialog, 'pryaniky/FloatMessageWidget');
