import { Box, Typography, styled } from 'muicomponents/src';
import React, { FC, ComponentProps } from 'react';
import { cnShopProduct } from '../ProductCardMui/ProductCardMui.index';
import { YellowPurseBtn } from './YellowPurseBtn';
import { Translate } from 'localization';

const WrapBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
}) as typeof Box;

interface IPriceElemProps extends ComponentProps<typeof Box> {
    fromPriceText: string | null;
    price: number | string;
    priceCurrencyText: string;
}

export const PriceElem: FC<IPriceElemProps> = ({
    fromPriceText,
    price,
    priceCurrencyText,
    className,
    ...props
}: IPriceElemProps) => {
    return (
        <WrapBox {...props} className={cnShopProduct('Info-Prise', [className])}>
            <YellowPurseBtn />
            <Box>
                <Typography variant="subtitle2" fontWeight="600">
                    {!price && !fromPriceText ? (
                        <Translate i18nKey={'pryaniky.shop.noPrice'} />
                    ) : (
                        `${fromPriceText || ''} ${price} ${priceCurrencyText || ''}`
                    )}
                </Typography>
            </Box>
        </WrapBox>
    );
};
