import React, { FC, useEffect, useState, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMSTO
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
import { IButtonProps } from 'uielements/src/Button/Button.index'
import withConfirmDialog, { IWithLoadSpin } from 'blocks/HOCs/Button/withConfirmDialog'
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import MDRender, { usePlainText } from 'uielements/src/CommonmarkRender'


const ConfirmBtn = withConfirmDialog<IButtonProps>(Button)

type IPresenterProps = LMSTO.TOTracksListItem.IPresenterProps
type IOwnProps = LMSTO.TOTracksListItem.IOwnProps
type IDispatchProps = LMSTO.TOTracksListItem.IDispatchProps
type IStateProps = LMSTO.TOTracksListItem.IStateProps
type IState = LMSTO.TOTracksListItem.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    data
}) => {
    const [shortText, titleText] = usePlainText(data.news.name, 50)

    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Type')}>
            {/* Тип материала: */}
            <b>{tTr('trackStats.typeofmaterial')}</b>
            <div>{tTr('trackStats.typeofmaterial.' + data.news.type)}</div>
        </div>
        <div className={cnClassName('Info')}>
            <div title={titleText}><b>{shortText}</b></div>
            {/* <div><MDRender type={'inline'} source={data.news.name} textCut={true} maxCharCount={50} /></div> */}
            <div><MDRender source={data.news.description} textCut={true} maxCharCount={0} /></div>
        </div>
        <div>
            {/* Ознакомились: Х */}
            {tTr('trackStats.usersCount', { count: data.usersCount })}
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
