import React, { FC } from 'react';
import { connect } from 'react-redux';
import { ICustomNodePositionsProps, cnCustomNodePositions, mapCustomNodePositionsStateToProps, mapCustomNodePositionsDispatchToProps } from './CostomNodePosition.index'
import { GUID_EMPTY } from 'utils/src/constants.prn'
import './CostomNodePosition.scss'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import { Tooltip, Button, Avatar, Icon } from 'uielements/src';
import { NastoychiiBelyiTooltip as MuiTooltip } from 'uielements/src/MaterialElements/Tooltip';
import { Bable } from 'blocks/Bable/Bable'
import { cn } from '@bem-react/classname';
import { i18n, Translate } from 'localization';
import * as utils from 'utils.project/utils.project'
import { Attachments as AttachmentsView } from 'uielements/src/Attachments/Attachments';
import VacancyOpenNoUser from 'assets/svg/VacancyOpenNoUser.svg'
import FullTimeWorker from 'assets/svg/FullTimeWorker.svg'
import Temporary from 'assets/svg/Temporary.svg'
import HalfTimeWorker from 'assets/svg/HalfTimeWorker.svg'
import Intern from 'assets/svg/Intern.svg'
import Medicine from 'assets/svg/Medicine.svg'
import Baby from 'assets/svg/Baby.svg'
import { makeStyles } from '@material-ui/core/styles';
import buttonAdd from 'assets/svg/buttonAdd.svg'
import { Button as MuiButton } from 'muicomponents/src'
import { useOrgContext } from '../../context'
import { UserMention } from 'muicomponents/src/UserMention/UserMention';

export const cnPositionTooltip = cn('PositionTooltip');
export const cnPositionTooltipUser = cn('PositionTooltipUser');
export const cnMenuTooltip = cn('MenuTooltip');



const useStyles = makeStyles((theme) => ({
    noMaxWidth: {
        maxWidth: 'none',
    },
}));

interface IPositionTooltipProps {
    position: any;
}

const noopArray: any[] = [];

export const PositionTooltip: React.FC<IPositionTooltipProps> = ({ position }) => {
    const noName = "открытая вакансия"
    return (
        <>
            <div className={cnPositionTooltip('')}>
                <Avatar className={cnPositionTooltip('Img')} name={position.type.name !== "" ? i18n.t(`pryaniky.orgchart.positionType.${position.type.name}`) : noName} imgId={GUID_EMPTY} imgUrl={""} size={40} />
                <div className={cnPositionTooltip('Data')}>
                    <div className={cnPositionTooltip('Name')}>{i18n.t('pryaniky.orgchart.positions.user.vacancy.file')}</div>
                    {(position.vacancy && position.vacancy.description !== "") && <div className={cnPositionTooltip('Name-Vacancy')}>
                        {position.vacancy && position.vacancy.description}
                    </div>}
                    {(position.type.description && position.type.description !== "") && <div className={cnPositionTooltip('Description')}>

                        <img className={cnCustomNodePositions((position.type.name && position.type.name === '') ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge-TypeIMG')}
                            src={TypeIconDictionary[position.type]} />
                        <span><Translate i18nKey={`pryaniky.orgchart.positionType.${position.type.description}`} /></span>
                    </div>}
                    {position.isVacancyOpen && <div className={cnPositionTooltip('VacancyOpen')}>
                        {position.vacancy?.attachments && <AttachmentsView className={cnPositionTooltip('Attachments', ['horizontalPadding'])}
                            files={position.vacancy?.attachments ? position.vacancy.attachments : noopArray}
                            onDelete={() => { }}
                            state='view' />}
                    </div>}
                </div>
            </div>
        </>
    )
}

const TypeIconDictionary: { [key: string]: string } = {
    fullTimeEmployee: FullTimeWorker,
    halfTimeWorker: HalfTimeWorker,
    temporaryWorker: Temporary,
    trainee: Intern,
    decree: Baby,
    sickLeave: Medicine
}

const TypeBadge: React.FC<{
    uIsAdmin?: ICustomNodePositionsProps['uIsAdmin']
    employee?: ICustomNodePositionsProps['data']['employee']
    isVacancyOpen?: ICustomNodePositionsProps['data']['isVacancyOpen']
    type: ICustomNodePositionsProps['data']['type'],
    filterShowOnlyOpenVacancy: ICustomNodePositionsProps['filterShowOnlyOpenVacancy']
}> = ({
    type,
    employee,
    isVacancyOpen,
    uIsAdmin,
    filterShowOnlyOpenVacancy
}) => {

        if (!(uIsAdmin || employee !== null || isVacancyOpen)) return null

        return <Bable className={cnCustomNodePositions(filterShowOnlyOpenVacancy && !isVacancyOpen ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge')}>
            {/* !onlyVacancyOpen ? 'Vacancy-TypeBadge-onlyVacancy' : */}
            <img className={cnCustomNodePositions(type.name === '' ? 'Vacancy-TypeBadge-NoTypeIMG' : 'Vacancy-TypeBadge-TypeIMG')}
                src={TypeIconDictionary[type.name]} />
        </Bable>
    }



const VacancyTooltip: React.FC<{
    data: ICustomNodePositionsProps['data']
}> = ({
    children,
    data
}) => {
        const tooltipClasses = useStyles();

        return <MuiTooltip
            className={cnCustomNodePositions('Tooltip')}
            title={<PositionTooltip position={data} />}
            disableFocusListener
            interactive
            PopperProps={{
                disablePortal: false,
                popperOptions: {
                    positionFixed: true,
                    modifiers: {
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "window" // where "window" is the boundary
                        }
                    }
                }
            }}
            classes={{
                tooltip: tooltipClasses.noMaxWidth
            }}
        >
            {children as any}
        </MuiTooltip>
    }


const EditNode: FC<{
    data: ICustomNodePositionsProps['data'],
    dataEdit: ICustomNodePositionsProps['dataEdit'],
    uIsAdmin: ICustomNodePositionsProps['uIsAdmin'],
    onClickEdit: ICustomNodePositionsProps['onClickEdit'],
    deletePositionFromUnit: ICustomNodePositionsProps['deletePositionFromUnit'],
    filterShowOnlyOpenVacancy: ICustomNodePositionsProps['filterShowOnlyOpenVacancy']
}> = ({
    data,
    dataEdit,
    uIsAdmin,
    onClickEdit,
    deletePositionFromUnit,
    filterShowOnlyOpenVacancy
}) => {
        const tooltipClasses = useStyles();

        const actions = ["delete", "edit"]


        const onActionClick = (action: string) => {
            if (action === "delete") {
                utils.confirm({
                    text: data && data.employee !== null ? i18n.t('pryaniky.orgchart.positions.user.delete') : i18n.t('pryaniky.orgchart.positions.user.position.delete'),
                    title: data && data.employee !== null ? i18n.t('pryaniky.orgchart.positions.user.delete.title') : i18n.t('pryaniky.orgchart.positions.delete.title'),
                    onConfirm: () => {
                        data && deletePositionFromUnit({ position: { ...data, employee: null }, id: data && data.id })
                    }
                })
            }
            if (action === "edit") {
                onClickEdit && onClickEdit(data && data.id)
            }
        }

        if (!dataEdit) return null

        return <div className={cnCustomNodePositions('Vacancy-ImgBoxEdit')}>
            <MuiTooltip
                disableFocusListener
                interactive
                PopperProps={{
                    disablePortal: true
                }}
                classes={{
                    tooltip: tooltipClasses.noMaxWidth
                }}

                title={<div>
                    <ul className={cnCustomNodePositions('ActionsMenu')}>
                        {actions.map((action) => (
                            <li className={cnCustomNodePositions('ActionsMenu-Item')} key={action} >
                                <Button onClick={() => onActionClick(action)} className={cnCustomNodePositions('ActionsMenu-Item-ActionBTN')} theme="unstyled">
                                    {dataEdit.employee === null && action === "delete" ?
                                        <Translate i18nKey={`pryaniky.orgchart.service.actions.position.${action}`} /> :
                                        <Translate i18nKey={`pryaniky.orgchart.service.actions.${action}`} />}
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>}
            >
                <div
                    className={'BadgeItem-ImgLink'}
                >
                    {!!dataEdit.employee ?
                        <Avatar
                            className={cnCustomNodePositions(dataEdit.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')}
                            imgUrl={dataEdit.employee.userPhotoUrl || dataEdit.employee.imgUrl}
                            imgId={dataEdit.employee.imgId}
                            name={dataEdit.employee.displayName}
                            size={42} /> :
                        <VacancyAvatar data={dataEdit} uIsAdmin={uIsAdmin} />}

                </div>
            </MuiTooltip>

            <TypeBadge
                filterShowOnlyOpenVacancy={filterShowOnlyOpenVacancy}
                type={data.type}
                employee={data.employee}
                isVacancyOpen={data.isVacancyOpen}
                uIsAdmin={uIsAdmin}
            />


        </div>
    }


const VacancyAvatar: FC<{
    data: ICustomNodePositionsProps['data'] | ICustomNodePositionsProps['dataEdit']
    uIsAdmin?: ICustomNodePositionsProps['uIsAdmin']
}> = ({
    data,
    uIsAdmin
}) => {
        if (!data!.isVacancyOpen && !uIsAdmin) return null

        return <Avatar
            className={cnCustomNodePositions(data!.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')}
            imgUrl={VacancyOpenNoUser}
            name={i18n.t(`pryaniky.orgchart.positionType.${data!.type.name}`)}
            size={36} />

    }


const ViewNodeEmployee: FC<{
    data: ICustomNodePositionsProps['data']
}> = ({
    data
}) => {
        return <UserMention id={data.employee!.id}>
            <div>
                <Button
                    theme={'unstyled'}
                    type={'rlink'}
                    href={'/user/' + data.employee!.id}
                    className={'BadgeItem-ImgLink'}

                >
                    <Avatar
                        className={cnCustomNodePositions(data.isVacancyOpen ? 'VacancyOpen' : 'Vacancy')}
                        imgUrl={data.employee!.userPhotoUrl || data.employee!.imgUrl}
                        imgId={data.employee!.imgId}
                        name={data.employee!.displayName}
                        size={36}
                    />
                </Button>

            </div>

        </UserMention>
    }

const ViewNodeVacancy: FC<{
    data: ICustomNodePositionsProps['data'],
    uIsAdmin?: ICustomNodePositionsProps['uIsAdmin']
}> = ({
    data,
    uIsAdmin
}) => {
        return <VacancyTooltip data={data}>
            <div>
                <Button
                    theme={'unstyled'}
                    className={'BadgeItem-ImgLink'}
                >
                    <VacancyAvatar data={data} uIsAdmin={uIsAdmin} />
                </Button>
            </div>
        </VacancyTooltip>
    }

const ViewNode: FC<{
    data: ICustomNodePositionsProps['data'],
    uIsAdmin?: ICustomNodePositionsProps['uIsAdmin'],
    getLast: ICustomNodePositionsProps['getLast'],
    howManyLeft: ICustomNodePositionsProps['howManyLeft'],
    showMore: ICustomNodePositionsProps['showMore'],
    filterShowOnlyOpenVacancy: ICustomNodePositionsProps['filterShowOnlyOpenVacancy']
}> = ({
    data,
    uIsAdmin,
    getLast,
    howManyLeft,
    showMore,
    filterShowOnlyOpenVacancy
}) => {
        let view: React.ReactNode = null
        if (data.employee && !filterShowOnlyOpenVacancy) {
            view = <ViewNodeEmployee
                data={data}
            />
        }
        else {
            view = <ViewNodeVacancy
                data={data}
                uIsAdmin={uIsAdmin}
            />
        }
        return <div className={cnCustomNodePositions('Vacancy-preBox')} >
            <div className={cnCustomNodePositions('Vacancy-ImgBox')}>
                {view}

                <TypeBadge
                    filterShowOnlyOpenVacancy={filterShowOnlyOpenVacancy}
                    type={data.type}
                    employee={data.employee}
                    isVacancyOpen={data.isVacancyOpen}
                    uIsAdmin={uIsAdmin}
                />
            </div>

            {getLast && howManyLeft !== 0 && <Button theme="unstyled" className={cnCustomNodePositions('VacancyButton')} onClick={() => showMore && showMore(0)}>
                +{howManyLeft}
            </Button>}
        </div>

    }


const CustomNodePositionsPresenter: React.FC<ICustomNodePositionsProps> = ({
    data,
    dataEdit,
    editPositions,
    uIsAdmin,
    onClickEdit,
    deletePositionFromUnit,
    getLast,
    howManyLeft,
    showMore,
    filterShowOnlyOpenVacancy
}) => {

    if (!data && !dataEdit) return null

    return (
        <div className={cnCustomNodePositions('')}>

            {editPositions ?
                <EditNode
                    data={data}
                    dataEdit={dataEdit}
                    uIsAdmin={uIsAdmin}
                    onClickEdit={onClickEdit}
                    deletePositionFromUnit={deletePositionFromUnit}
                    filterShowOnlyOpenVacancy={filterShowOnlyOpenVacancy}
                /> :
                <ViewNode
                    data={data}
                    uIsAdmin={uIsAdmin}
                    getLast={getLast}
                    howManyLeft={howManyLeft}
                    showMore={showMore}
                    filterShowOnlyOpenVacancy={filterShowOnlyOpenVacancy}
                />}

        </div>


    )
}



export const CostomNodePosition = connect(
    mapCustomNodePositionsStateToProps,
    mapCustomNodePositionsDispatchToProps
)(CustomNodePositionsPresenter)