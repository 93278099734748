import { newsCreatorItemNewsChange } from 'News/creator/redux/actions.newsCreator';
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from 'News/creator/redux/selectors.newsCreate';
import { NewsCreateReducer, NewsPostTypeWithDraftRaw } from 'News/creator/redux/types.newsCreator';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getAllowPostTypeById } from 'redux/sagas/AppSettings/selectors';
import { getCurrentUser, getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';
import { NThanks } from './Thanks.interface';
import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import { ExtractByParam } from 'utils/src';
import { useChangeCreator } from 'News/creator/hooks';

type ThanksType = ExtractByParam<NewsPostTypeWithDraftRaw, 'componentRenderName', 'thanks'>;
type FieldsToCreator = Extract<keyof ThanksType, 'text' | 'users' | 'thanksCount' | 'rewardReasons' | 'groups' | 'draftRawState'>;

const ThanksFields: FieldsToCreator[] = ['text', 'users', 'thanksCount', 'rewardReasons', 'groups', 'draftRawState'];

export const useThanksEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const { groupId, workflowId, newsType, all } = useContext(NewsCreateContext);

    const data = useSelector(getNewsCreateItemNewsFields(ThanksFields)(id)) as Pick<ThanksType, FieldsToCreator>;
    const { formSettings } = useSelector(getNewsCreateItemFields(['formSettings'])(id))

    const maxThanksArgumentsCount = useSelector((state: any) => state.store.appSettings?.maxThanksArgumentsCount || 0);
    const maxThanksValue = useSelector((state: any) => state.store.appSettings?.maxThanksValue || 0);

    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews'])(id));

    const thanksParams = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', groupId, workflowId, newsType, all))?.thanksRenderParams as NThanks.RenderParams | undefined;

    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const changeCreator = useChangeCreator(id);

    const changeNews = (news: Partial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    };

    return {
        data,
        isAdmin,
        maxThanksArgumentsCount,
        maxThanksValue,
        thanksParams,
        ...creatorFields,
        changeCreator,
        changeNews,
        formSettings
    };
};


/**
 * 
 * @param param0 
 */
export const useThanksChange = ({
    user,
    users,
    groups,
    rewardReasons,
    thanksCount
}: Pick<NThanks.Create, 'users' | 'groups' | 'thanksCount' | 'rewardReasons'> & {
    user: { [key: string]: any }
}
) => {
    // считаем общее количество пользователей в выбранных группах + отдельно выбранных пользователей
    const usersCount = groups?.reduce((a, c) => a + (c.membersCount || 0), users?.length || 0) || 0;
    // общее количество валючи для списания
    const thanksToUse = thanksCount * usersCount;
    // получаем модель текущего пользователя
    const currentUser = user;

    const subUsersCount = users?.filter((u) => !!u.subUser).length
    const thanksToSubUsers = subUsersCount * thanksCount
    // useSelector(getCurrentUser, shallowEqual);
    // получаем общий и дополнительные кошельки
    const { thanksForAll, thanksForAllByCollection = {}, thanksForSubordinate = 0 } = currentUser.extData;
    // получаем имена коллекций с дополнительными кошельками(для отключения при выборе в саджестере)
    const collectionsWithWallet = Object.keys(thanksForAllByCollection).map(id => thanksForAllByCollection[id].name) as string[];
    // определяем использует ли коллекция кошелек
    if (!rewardReasons) rewardReasons = [];
    const selectedReason = rewardReasons[0];
    const selectedCollection = selectedReason ? (collectionsWithWallet.find(el => el === (selectedReason as any).category) || 'all') : undefined;
    // суммарное количество валюты во всех кошельках
    let allThanksCount = thanksForAll + thanksForSubordinate + Object.keys(thanksForAllByCollection).reduce((a, id) => a + thanksForAllByCollection[id].value, 0);
    // если выбрана коллекция без личного кошелька
    if (selectedCollection === 'all') allThanksCount = thanksForAll+ thanksForSubordinate;
    // если выбрана коллекция с личным кошельком
    if (selectedCollection && selectedCollection !== 'all') allThanksCount = thanksForSubordinate + Object.keys(thanksForAllByCollection).map(id => thanksForAllByCollection[id]).find(el => el.name === selectedCollection)?.value;
    // доступное колличество валюты на текущий момент
    // const avaliableThanksCount = allThanksCount - thanksToUse;
    return {
        allThanksCount,
        thanksToUse,
        thanksToSubUsers,
        // avaliableThanksCount,
        selectedCollection,
        collectionsWithWallet
    };
}