import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";

import { mapStateToProps, IUsersStateProps, cnUsers, IUsersProps, IUsersState } from './Users.index';
import './Users.scss';
import { Page } from '../Page/Page';
import { v1 as uuid } from 'uuid';
// import { generateWidget, generateColumn } from 'i.widgets';
import { Button } from 'uielements/src/Button/Button';
import { i18n, Translate } from 'localization';
import { pageNames } from 'redux/pageNames';

export class UsersPresenter extends React.Component<IUsersProps, IUsersState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: IUsersProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
    // this.props.context[pageName] = {}
  }

  public render() {
    // const customClassNames = '';
    // const { tag: TagName = 'div', children, className = '' } = this.props;
    return (
      // <TagName ref={el => this.el = el} className={cnUsers({}, [customClassNames, className])}>
      <React.Fragment>
        {/* <PageMui 
          className={cnUsers()}
          page={{
            name: pageName
          }} /> */}
        <Page 
          className={cnUsers()}
          page={{
            name: pageNames.users,
            title: i18n.t('colleagues')
          }} />
      </React.Fragment>
        // {children}
      // {/* </TagName> */}
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

  // public loadFilterData = (fname: string, contextVariable: string, filterContext: string) => {
  //   if(fname === 'groups') {
  //     if(this.props.store.edit) return ;
  //     let filterElements = this.props.context[contextVariable][filterContext];
  //     this.props.changeVm('context.' + contextVariable + '.loading', 'groups')
  //     if(!filterElements) filterElements = [];
  //     utils.API.groups.list(filterElements.length, 10, {groupListFilter: 'my'})
  //     .r
  //     .then((d: any) => {
  //       if(utils.checkResponseStatus(d)) {
  //         this.props.changeVm('context.' + contextVariable + '.' + filterContext, filterElements.concat(d.data.map((group: {[s: string]: any}) => {
  //           group.type = 'group';
  //           group.title = group.name;
  //           group.id = group.pkid;
  //           return group;
  //         })));
  //         this.props.changeVm('context.' + contextVariable + '.loading', undefined)
  //       }
  //     }); 
  //   }
  // }

}


export const Users = connect<IUsersStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(UsersPresenter)
//   compose(
//   withBemMod(cnUsers(), {})
// )(UsersPresenter))
