import React, { useEffect, useState } from 'react';
import {
    INotifiSettingTablesProps,
    mapStateToProps,
    mapDispatchToProps,
    cnNotifiSettingTable,
    IParams,
} from './NotifiSettingsTable.index';
import { withTranslation } from 'react-i18next';
import { Translate } from 'localization';
import { connect } from 'react-redux';
import { INotifiSettingsG } from 'utils/src/requests/models/api.notifications';
import { useParams, useHistory } from 'react-router-dom';
import './NotifiSettingsTable.scss';
import { mapOrder } from 'utils/src/utils';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { StickyTable, Button } from 'uielements/src';
import { generatePath } from 'react-router';
import { mainUrls } from 'utils/src';

const NotifiSettingsTablePresenter: React.FC<INotifiSettingTablesProps> = ({
    settings,
    authUser,
    getNotifiSettings,
    setNotifiSettings,
    saveNotifiSettings,
    clearNotifiSettings,
}) => {
    const history = useHistory();

    const [_, update] = useState({});
    const { uid, gid, nid, tab } = useParams<IParams>();

    useEffect(() => {
        if (!authUser.baseData.isAdmin && authUser.baseData.id !== uid) {
            history.replace(generatePath(mainUrls.user.id, { id: uid }));
            return;
        }
        getNotifiSettings({ uid, gid, nid });
    }, [tab]);

    if (!settings) return null;

    const prepareRows = (item: INotifiSettingsG) =>
        item.n.map((v) => {
            // для последовательной сортировки галочек
            const destinationsKeys = settings.destinations.map((e) => e.key);
            return {
                cells: mapOrder(v.values, destinationsKeys, 'destination').map((s, i: number) => (
                    <div>
                        <Checkbox
                            size="small"
                            key={i}
                            value={s.value}
                            onChange={(value) => {
                                s.value = value;
                                setNotifiSettings(settings);
                                update({});
                            }}
                        />
                    </div>
                )),
            };
        });

    return (
        <div className={cnNotifiSettingTable()}>
            <div className={cnNotifiSettingTable('Body')}>
                {settings.g &&
                    settings.g.map((item, idx: number) => (
                        <div className="Widget" key={idx}>
                            <h2>{item.displayName}</h2>
                            <div className={cnNotifiSettingTable('Container')}>
                                <StickyTable
                                    columnsHeaders={settings.destinations.map((v) => v.value)}
                                    rowsHeaders={item.n.map((v) => (
                                        <span>{v.displayName}</span>
                                    ))}
                                    rows={prepareRows(item)}
                                />
                            </div>
                        </div>
                    ))}
            </div>

            <div className={cnNotifiSettingTable('Footer')}>
                <Button main onClick={() => saveNotifiSettings({ uid, gid, nid })}>
                    <Translate i18nKey="pryaniky.notifications.settings.table.save" />
                </Button>
            </div>
        </div>
    );
};

export const NotifiSettingsTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(NotifiSettingsTablePresenter));
