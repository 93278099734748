import React, { useState, useMemo } from 'react';
import { Translate } from 'localization';
import { Button, Typography } from 'muicomponents/src';
import { SerchWithFiltersBox } from '../SearchMui.styled';
import { CleaningServices } from 'muicomponents/src/Icons';
import { Box } from 'muicomponents/src';
import { TSearchValue } from '../../../redux/search/interfaces';
import { FilterPages } from '../SearchFilters/FilterPages/FilterPages';
import { FilterNews } from '../SearchFilters/FilterNews/FilterNews';
import {
    defaultAdditionalNewsFilter,
    defaultAdditionalPageFilter,
    defaultAdditionalFileFilter,
} from 'redux/search/reducer';
import { setAdditionalFilter, setActiceTab, clearAdditionalFilter } from 'redux/search/actions';
import _ from 'lodash';
import { FilterFiles } from '../SearchFilters/FilterFiles/FilterFiles';
import { useSelector } from 'react-redux';
import { getAdditionalFilters, getFilters } from 'redux/search/selectors';
import { ItemsListHeader } from 'muicomponents/src/ItemsListDialog/ItemsListHeader/ItemsListHeader';
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm';
import { cn } from '@bem-react/classname';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from 'utils/src/hooks';
import { minSearchSymblosCount } from '../SearchMui.utils';

export const cnClassName = cn('SpreadSearchTabs');

interface TSearchTabs {
    setAdditionalFilter: typeof setAdditionalFilter;
    clearAdditionalFilter: typeof clearAdditionalFilter;
    activeTab?: TSearchValue;
    /**
     * Текст строки поиска
     */
    searchQuery?: string;
    /**
     * Событие изменение строки поиска
     * @param query новое значение строки поиска
     * @returns
     */
    changeSearchQuery?: (query: string, reason?: 'enter') => void;
}

const filterAll = {
    displayName: Translate.t({ i18nKey: 'pryaniky.search.filters.type.all' }),
    filterName: 'all',
    isActive: true,
    pkid: 0,
    position: 0,
    showAtQuickSearch: false,
    showAtSearchPage: true,
};

export const SearchTabs: React.FC<TSearchTabs> = ({
    activeTab,
    setAdditionalFilter,
    clearAdditionalFilter,
    changeSearchQuery,
    searchQuery,
}) => {
    const prevStateFilters = useSelector(getFilters);

    const prevStateAdditionalFilters = useSelector(getAdditionalFilters);

    const tabs = useMemo(() => {
        return [filterAll, ...prevStateFilters].map((filter: any) => ({
            ...filter,
            label: Translate.t({ i18nKey: filter.displayName.charAt(0).toUpperCase() + filter.displayName.slice(1) }),
            value: filter.filterName,
        }));
    }, [prevStateFilters]);

    const [filterPage, setFilterPage] = useState(prevStateAdditionalFilters.pages);
    const [filterNews, setFilterNews] = useState(prevStateAdditionalFilters.news);
    const [filterFile, setFilterFile] = useState(prevStateAdditionalFilters.files);
    const [confirmFiltersByButton, setConfirmFiltersByButton] = useState(false);

    const prevNews = useMemo(() => {
        return prevStateAdditionalFilters.news;
    }, [activeTab, filterNews, prevStateAdditionalFilters.news]);
    const prevPages = useMemo(() => {
        return prevStateAdditionalFilters.pages;
    }, [activeTab, filterPage, prevStateAdditionalFilters.pages]);
    const prevFiles = useMemo(() => {
        return prevStateAdditionalFilters.files;
    }, [activeTab, filterFile, prevStateAdditionalFilters.files]);

    const history = useHistory();

    // кнопка дизейблится если  состояния фильтров локальное и в редаксе  равны, так как это значит, что запрос с уже выбраными параметрами бы отправлен на бэк
    const disableFilterApllyButton = useMemo(() => {
        if (activeTab === 'pages') {
            return (
                filterPage?.author === prevPages?.author &&
                filterPage?.searchOnlyTitle === prevPages?.searchOnlyTitle &&
                filterPage?.categoryType === prevPages?.categoryType &&
                (filterPage?.materialType || '') === (prevPages?.materialType || '') &&
                filterPage?.order === prevPages?.order &&
                filterPage?.sort === prevPages?.sort
            );
        }

        if (activeTab === 'news') {
            return (
                filterNews?.author === prevNews?.author &&
                filterNews?.type === prevNews?.type &&
                filterNews?.startDate === prevNews?.startDate &&
                filterNews?.endDate === prevNews?.endDate
            );
        }

        if (activeTab === 'files') {
            return filterFile?.searchOnlyName === prevFiles?.searchOnlyName;
        }
    }, [activeTab, filterNews, filterPage, filterFile, prevStateFilters]);

    const searchTypeSuggester = useMemo(() => {
        let searchString = '';
        if (searchQuery) searchString = `?search=${searchQuery}`;
        return (
            <CheckButtonSelector
                value={tabs.find((el) => el.value === activeTab)?.value || 'all'}
                onChange={(value) => {
                    if (value !== '') {
                        history.push(`/search/${value}${searchString}`);
                    } else {
                        history.push(`/search/all${searchString}`);
                    }
                }}
                options={tabs}
            />
        );
    }, [tabs, activeTab, searchQuery]);

    const filterConfirmButton = useMemo(() => {
        return (
            <Button
                size="small"
                variant="contained"
                sx={{ height: '30px', marginTop: '8px' }}
                disabled={disableFilterApllyButton}
                onClick={() => {
                    activeTab === 'pages'
                        ? setAdditionalFilter('pages', filterPage)
                        : activeTab === 'news'
                        ? setAdditionalFilter('news', filterNews)
                        : activeTab === 'files'
                        ? setAdditionalFilter('files', filterFile)
                        : null;
                    setConfirmFiltersByButton(false);
                }}
            >
                <Translate i18nKey="apply" />
            </Button>
        );
    }, [disableFilterApllyButton, activeTab, filterNews, filterPage, filterFile]);

    const setFilterConfirm = useMemo(() => {
        if (confirmFiltersByButton) return;
        return activeTab === 'pages'
            ? setAdditionalFilter('pages', filterPage)
            : activeTab === 'news'
            ? setAdditionalFilter('news', filterNews)
            : activeTab === 'files'
            ? setAdditionalFilter('files', filterFile)
            : null;
    }, [activeTab, filterNews, filterPage, filterFile, confirmFiltersByButton]);

    const filterClearButton = useMemo(() => {
        return (
            <Button
                size="small"
                variant="outlined"
                // sx={{ height: '30px', padding: "3px 8px", textTransform: "inherit" }}
                startIcon={<CleaningServices />}
                onClick={() => {
                    if (activeTab === 'pages') {
                        setFilterPage(defaultAdditionalPageFilter);
                        clearAdditionalFilter('pages');
                    } else if (activeTab === 'news') {
                        setFilterNews(defaultAdditionalNewsFilter);
                        clearAdditionalFilter('news');
                    } else if (activeTab === 'files') {
                        setFilterFile(defaultAdditionalFileFilter);
                        clearAdditionalFilter('files');
                    }
                }}
            >
                <Translate i18nKey="search.filterd.clear" />
            </Button>
        );
    }, [disableFilterApllyButton, activeTab]);

    const [{ search: lastSearchQuery = '' }] = useSearchParams() as any as [{ search: string | undefined }];

    const searchHelperText = useMemo(() => {
        if ((searchQuery || '').length >= 2 && lastSearchQuery === searchQuery) return null;
        const minSearchText =
            (searchQuery || '').length < 2 ? (
                <div>
                    <Translate i18nKey="pryaniky.search.minSearchText" values={{ count: minSearchSymblosCount }} />
                </div>
            ) : null;
        const enterText =
            lastSearchQuery !== searchQuery ? (
                <div>
                    <Translate i18nKey="pryaniky.search.enterText" />
                </div>
            ) : null;
        return (
            <>
                {minSearchText}
                {enterText}
            </>
        );
    }, [lastSearchQuery, searchQuery]);

    return (
        <SerchWithFiltersBox className={cnClassName('')}>
            <ItemsListHeader
                searchQuery={searchQuery}
                changeSearchQuery={changeSearchQuery}
                SearchInputProps={{
                    onKeyPress: (e) => {
                        if (e.charCode === 13) {
                            changeSearchQuery?.(searchQuery?.replace(/[\/\\]+/g, '') || '', 'enter');
                        }
                    },
                    helperText: searchHelperText,
                }}
                SearchActions={searchTypeSuggester}
                bottomContent={
                    (activeTab === 'pages' || activeTab === 'news' || activeTab === 'files') && (
                        <>
                            <Box sx={{ textTransform: 'none' }}>
                                {activeTab === 'pages' && (
                                    <FilterPages
                                        filter={filterPage}
                                        onChangeFilter={setFilterPage}
                                        filterConfirmButton={filterConfirmButton}
                                        filterCleanButton={filterClearButton}
                                    />
                                )}
                                {activeTab === 'news' && (
                                    <FilterNews
                                        filter={filterNews}
                                        onChangeFilter={setFilterNews}
                                        filterConfirmButton={filterConfirmButton}
                                        filterCleanButton={filterClearButton}
                                        confirmFiltersByButton={setConfirmFiltersByButton}
                                    />
                                )}
                                {activeTab === 'files' && (
                                    <FilterFiles
                                        filter={filterFile}
                                        onChangeFilter={setFilterFile}
                                        filterConfirmButton={filterConfirmButton}
                                        filterCleanButton={filterClearButton}
                                    />
                                )}
                            </Box>
                        </>
                    )
                }
            />
        </SerchWithFiltersBox>
    );
};
