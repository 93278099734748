import { isWorkflowCalendar } from "blocks/Filters/_type/Filters_type_calendar";
import moment from "moment";
import { baseModels } from "News/creator/Creator/Creator.hooks";
import { NewsCreatorButton } from "News/creator/Creator/CreatorButton/CreatorButton";
import { ComponentProps, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { ICalendarCalFilter } from "redux/calendar/interfaces";
import { getCalendarStateField } from "redux/calendar/selectors";
import { getCompanyPostTypes, getCurrentAllowPostTypes } from "redux/sagas/AppSettings/selectors";
import { getContextById } from "redux/sagas/context/selectors";
import { getGroupByPKID } from "redux/sagas/Groups/selectors";
import { ObjectDate } from "utils/src";
import { useCalendarContext } from "./Calendar.context";

export const useCanCalendarCreateEvent = (context: any) => {

  const globalPostTypes = useSelector(getCompanyPostTypes);
  const contextPostTypes = useSelector(getCurrentAllowPostTypes(context.gId, context.workflowId));

  // check by group data if have group data
  const groupData = useSelector(getGroupByPKID(context.gId || ''));
  if (groupData) {
    return groupData.enabledNewsTypes.includes('events');
  }

  if (context.workflowId && globalPostTypes.find((el: any) => el.id === context.workflowId)?.renderParams?.calendar) {
    return true
  }

  return (context.gId || context.workflowId ? contextPostTypes : globalPostTypes).find((el: any) => el.componentRenderName === 'events')?.enabled;
};

export const useCalendarDisallowParallelEvents = (context: any) => {
  const globalPostTypes = useSelector(getCompanyPostTypes);
  if(context.workflowId) {
    return globalPostTypes.find((el: any) => el.id === context.workflowId)?.renderParams?.calendar?.disallowParallelEvents || false;
  }
  return false;
};

const excludeCalndarsFromPresetToPostForm = ['birthday'];

export const useCalenderNewsCreatorOptions = (dateString?: ObjectDate, forceTime = false) => {

  const context = useCalendarContext();

  const loadedCalendars = useSelector(getCalendarStateField('calendars'));
  const pageContext = useSelector(getContextById());



  return useMemo<{
    workflowId: ComponentProps<typeof NewsCreatorButton>['workflowId'],
    options: ComponentProps<typeof NewsCreatorButton>['creatorOptions']
  }>(() => {
    const calendarIdForForm = context.cIds?.length === 1 ? context.cIds[0] : [];
    const calendar = (loadedCalendars ?? []).find(el => !excludeCalndarsFromPresetToPostForm.includes(el.name) && el.id === calendarIdForForm);
    const date = moment(dateString);
    let modelDate = baseModels.events.uevent.startDateTime;
    if (date.isValid()) {
      if (forceTime) {
        modelDate = date.toISOString();
      } else if (moment().startOf('day').diff(date, 'days') === 0) {
        const now = moment();
        date.hours(now.hours());
        date.minutes(now.minutes());
      } else {
        date.hours(9);
      }
      modelDate = date.toISOString();
    }
    return {
      workflowId: calendar && isWorkflowCalendar(calendar) ? calendar.value.replace('form', '') : pageContext?.workflowId || context.workflowId,
      options: {
        formSettings: {
          root: {
            hideGroupSelector: true
          },
          additionalFields: {
            DateTimeRange: {
              defaultStartDateTime: moment(dateString).toISOString()
            }
          }
        },
        models: {
          events: {
            ...baseModels.events,
            expires: modelDate,
            uevent: {
              ...baseModels.events.uevent,
              startDateTime: modelDate,
              calendar: calendar as typeof baseModels.events.uevent.calendar
            }
          }
        }
      }
    }
  }, [dateString, loadedCalendars, context?.cIds, forceTime]);
};