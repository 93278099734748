import { LocationChangeAction, LOCATION_CHANGE as ROUTER_LOCATION_CHANGE } from 'connected-react-router';
import { put, take, takeEvery } from 'redux-saga/effects';
import { select } from 'utils/src/saga.effects';
import actionsWidgets from '../../actionsTypes/Widgets';
// import { setContext } from '../../actions/Widgets';
import { allUserFiltersKeys } from 'blocks/List/_type/users/List_type_users.utils';
import { i18n, Translate } from 'localization';
import queryString from 'query-string';
import { match, matchPath } from 'react-router';
import { IStateType } from 'redux/store';
import { getModuleNameByPathname, getUrlTemplateByPathName, isAllowOpenNewsDialog } from 'utils.project/utils.project';
import { toggleIsEditable } from 'utils/src/CommonRedux/base/actions';
import actionsCommon from 'utils/src/CommonRedux/base/actionsTypes';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import usersActions from 'utils/src/CommonRedux/users/actionsTypes';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { defaultTimelineFilter, myTimelineFilter } from 'utils/src/constants.prn';
import { CustomSettings } from 'utils/src/CustomSettings';
import { IGroupFullInfo } from 'utils/src/requests/models/api.groups';
import urls from '../../../routes/urls';
import { addGroupActionType, loadGroupActionType } from '../../actions/Groups';
import { default as actionsGroups, default as groupsActions } from '../../actionsTypes/Groups';
import { getGroupByPKID } from '../Groups/selectors';
import { getContextById } from './selectors';
(window as any).matchPath = matchPath;

let wikilistSort = (window as any).wikilistCustomSort;

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

interface ISpecificContextOptionByModuleUrl {
    group: (keyof IGroupFullInfo)[];
    tag: string[];
    users: typeof allUserFiltersKeys;
}
/**
 * options from data to context
 */
const specificContextOptionByModuleUrl: ISpecificContextOptionByModuleUrl = {
    group: ['isGroupAdmin', 'isContentDisabled', 'disallowUserPublications'],
    tag: ['collectionType'],
    users: allUserFiltersKeys,
};

const prepareTitle = (data: string[]) =>
    data
        .filter((e) => e)
        .join(' - ')
        .replace(/( - ){2,}/g, ' - ')
        .replace(/^( - )/g, '')
        .trim();
let isInited = false;
/**
 * set common contex on page when url change
 * @param { LocationChangeAction } act - action
 */
function* commonContextReset(act: LocationChangeAction) {
    try {
        /**
         * открытие новости в диалоге
         * в этом случае перехода насамом деле нет, ничего менять ненужно
         */
        const allowOpenNews = isAllowOpenNewsDialog(act.payload.location.pathname, act.payload.location.state);
        if (allowOpenNews) return;

        if (act.payload.isFirstRendering && isInited) return;
        isInited = true;

        const authUserId = yield* select((state: IStateType) => state.store.authUid);
        const authUser = yield* select(getCurrentUser);
        // const authUser = getAuthUser(state).profileData?.showMyNewsByDefault;
        // current context data
        const cd = yield* select(getContextById());
        // url template
        const { pathTemplate: ut } = getUrlTemplateByPathName(act.payload.location.pathname.replace(/\/$/, ''));
        // match path data to change
        const dt = matchPath(act.payload.location.pathname, ut);
        // url search data
        const dts = queryString.parse(act.payload.location.search, { parseBooleans: true });
        // url hash data
        const dth = queryString.parse(act.payload.location.hash);
        // url match parameters
        const urlParams: Record<string, string> = (dt && dt.params) || {};
        if (window.PRN_SERVICE.reportings_contexts) {
            console.log('cd', cd);
            console.log('ut', ut);
            console.log('dt', dt);
            console.log('dts', dts);
            console.log('dth', dth);
            console.log('urlParams', urlParams);
        }
        // current context prepaired data
        let ccpd: Record<string, any> = {};
        switch (ut) {
            case urls.badge:
                ccpd = {
                    ...ccpd,
                    ...Object.keys(urlParams).reduce(
                        (a, key) => ({ ...a, [key === 'id' ? 'bId' : key]: urlParams[key] }),
                        {}
                    ),
                };
                break;
            case urls.creativetasks:
                ccpd = {
                    ...ccpd,
                    newsTypes: 'creativetasks',
                    oneNewsType: 'creativetasks',
                };
                break;
            case urls.dash: {
                let baseFilter: any = [{ ...defaultTimelineFilter, title: i18n.t('pryaniky.filter.feed.all') }];
                let baseNewsType = 'all';

                if (authUser?.profileData?.showMyNewsByDefault) {
                    baseFilter = [{ ...myTimelineFilter, title: i18n.t('pryaniky.filter.feed.myfeed') }];
                    baseNewsType = 'myfeed';
                }

                const savedFilters = localStorage.getItem('timlineFilters') || '';
                if (savedFilters.length && savedFilters !== 'undefined') {
                    const parsed = JSON.parse(savedFilters)[act.payload.location.pathname] || baseFilter;
                    baseFilter = parsed;
                    baseNewsType = parsed.map((el: any) => el.value).join(',');
                }

                ccpd.filter = baseFilter;
                ccpd.newsTypes = baseNewsType;
                break;
            }
            case urls.events:
                ccpd = {
                    ...ccpd,
                    newsTypes: 'events',
                    oneNewsType: 'events',
                };
                break;
            case urls.wikilist[urls.wikilist.findIndex((i) => i === ut)]:
                ccpd = {
                    ...ccpd,
                    order:
                        dts.order ||
                        wikilistSort?.order ||
                        CustomSettings.wikiFoldersDefaultOrder()?.[urlParams?.collectionAlias || ''] ||
                        'position',
                    sort:
                        dts.sort ||
                        wikilistSort?.sort ||
                        CustomSettings.wikiFoldersDefaultSort()?.[urlParams?.collectionAlias || ''] ||
                        'asc',
                    searchOnlyTitle: dts.searchOnlyTitle || false,
                    viewType: wikilistSort?.viewType || 0,
                    type: dts.type || wikilistSort?.type || 'all',
                    search: '',
                    archived: dts.archived || false,
                    catFilter: dts.catFilter || '',
                    authors: '',
                    authorId: dts.authorId || '',
                    authorsObj: null,
                    coll: true,
                    draft: dts.draft || false,
                    showFavorites: dts.showFavorites !== undefined ? dts.showFavorites : useFavoriteWikiItems,
                    ...urlParams,
                };
                break;
            case urls.group[urls.group.findIndex((i) => i === ut)]:
                let baseFilter: any = [{ ...defaultTimelineFilter, title: i18n.t('pryaniky.filter.feed.all') }];
                let baseNewsType = 'all';

                if (authUser?.profileData?.showMyNewsByDefault) {
                    baseFilter = [{ ...myTimelineFilter, title: i18n.t('pryaniky.filter.feed.myfeed') }];
                    baseNewsType = 'myfeed';
                }

                const savedFilters = localStorage.getItem('timlineFilters') || '';
                if (savedFilters.length && savedFilters !== 'undefined') {
                    const parsed = JSON.parse(savedFilters)[act.payload.location.pathname];
                    if (parsed && parsed.length > 0) {
                        baseFilter = parsed;
                        baseNewsType = parsed.map((el: any) => el.value).join(',');
                    }
                }

                ccpd = {
                    ...ccpd,
                    order:
                        dts.order ||
                        wikilistSort?.order ||
                        CustomSettings.wikiFoldersDefaultOrder()?.[urlParams?.collectionAlias || ''] ||
                        'position',
                    sort:
                        dts.sort ||
                        wikilistSort?.sort ||
                        CustomSettings.wikiFoldersDefaultSort()?.[urlParams?.collectionAlias || ''] ||
                        'asc',
                    searchOnlyTitle: dts.searchOnlyTitle || false,
                    viewType: wikilistSort?.viewType || 0,
                    type: dts.type || wikilistSort?.type || 'all',
                    search: '',
                    archived: dts.archived || false,
                    catFilter: dts.catFilter || '',
                    authors: '',
                    authorId: dts.authorId || '',
                    authorsObj: null,
                    coll: true,
                    draft: dts.draft || false,
                    showFavorites: dts.showFavorites !== undefined ? dts.showFavorites : useFavoriteWikiItems,
                    filter: baseFilter,
                    newsTypes: baseNewsType,
                    ...Object.keys(urlParams).reduce(
                        (a, key) => ({
                            ...a,
                            ...(key === 'id'
                                ? {
                                      gId: urlParams[key],
                                      groupId: urlParams[key],
                                  }
                                : {
                                      key: urlParams[key],
                                  }),
                            // [(key === 'id' ? 'gId' : key)]: urlParams[key]
                        }),
                        {}
                    ),
                    ...specificContextOptionByModuleUrl.group.reduce(
                        (acc, cur) => ({ ...acc, [cur]: cd[cur] }),
                        {} as Record<string, any>
                    ),
                };
                break;
            case urls.ideas:
                ccpd = {
                    ...ccpd,
                    newsTypes: 'ideas',
                    oneNewsType: 'ideas',
                };
                break;
            case urls.achievements:
                ccpd = {
                    ...ccpd,
                    newsTypes: 'achievements',
                    oneNewsType: 'achievements',
                };
                break;
            case urls.news:
                ccpd = {
                    ...ccpd,
                    ...Object.keys(urlParams).reduce(
                        (a, key) => ({ ...a, [key === 'id' ? 'nId' : key]: urlParams[key] }),
                        {}
                    ),
                };
                break;
            case urls.notices:
                ccpd = {
                    ...ccpd,
                    newsTypes: 'notices',
                    postOff: true,
                };
                break;
            case urls.competitions:
                ccpd = {
                    ...ccpd,
                    newsTypes: 'creativetasks',
                    oneNewsType: 'creativetasks',
                };
                break;
            case urls.polls:
                ccpd = {
                    ...ccpd,
                    newsTypes: 'polls',
                    oneNewsType: 'polls',
                };
                break;
            case urls.rating[urls.rating.findIndex((i) => i === ut)]:
                ccpd = {
                    ...ccpd,
                    ...Object.keys(urlParams).reduce(
                        (a, key) => ({ ...a, [key === 'id' ? 'rId' : key]: urlParams[key] }),
                        {}
                    ),
                };
                break;
            case urls.tag[urls.tag.findIndex((i) => i === ut)]:
                ccpd = {
                    ...ccpd,
                    page: 'tag',
                    ...Object.keys(urlParams).reduce(
                        (a, key) => ({ ...a, [key === 'id' ? 'tId' : key]: urlParams[key] }),
                        {}
                    ),
                    // ...specificContextOptionByModuleUrl.tag.reduce((acc, cur) => ({ ...acc, [cur]: cd[cur] }), {} as Record<string, any>),
                    ...specificContextOptionByModuleUrl.tag.reduce(
                        (a, k) => ({ ...a, ...(dts[k] ? { [k]: dts[k] } : {}) }),
                        {}
                    ),
                    // ...Object.keys({ ...urlParams, ...dts }).reduce((a, key) => ({ ...a, [(key === 'id' ? 'tId' : key)]: urlParams[key] }), {})
                };
                break;
            case urls.users:
                ccpd = {
                    ...ccpd,
                    ...specificContextOptionByModuleUrl.users.reduce(
                        (a, k) => ({
                            ...a,
                            ...(dts[k]
                                ? {
                                      [k]: k === 'units' ? [dts[k]] : dts[k],
                                  }
                                : {}),
                        }),
                        {}
                    ),
                };
                break;
            case urls.user[urls.user.findIndex((i) => i === ut)]:
            case urls.userNew[urls.userNew.findIndex((i) => i === ut)]: {
                ccpd = {
                    ...ccpd,
                    ...Object.keys(urlParams).reduce(
                        (a, key) => ({
                            ...a,
                            ...(key === 'id'
                                ? {
                                      uId: urlParams[key],
                                      userId: urlParams[key],
                                  }
                                : {
                                      [key]: urlParams[key],
                                  }),
                            // [(key === 'id' ? 'uId' : key)]: urlParams[key],
                        }),
                        {}
                    ),
                    ...(cd.uId !== urlParams.id ? { mythanksCount: -1, thanksForAll: -1 } : {}),
                };
                break;
            }
            case urls.welcome: {
                ccpd = {
                    ...ccpd,
                    uId: authUserId,
                    // ...Object.keys(urlParams).reduce((a, key) => ({ ...a, [(key === 'id' ? 'uId' : key)]: urlParams[key] }), {})
                };
                break;
            }
            case urls.workflows: {
                const workflowType = urlParams.type;
                ccpd = {
                    ...ccpd,
                    workflowId: workflowType,
                    newsTypes: workflowType,
                    oneNewsType: workflowType,
                };
                break;
            }
            // case urls.calendar: {
            //   ccpd = {
            //     ...ccpd,
            //     ...specificContextOptionByModuleUrl.calendar.reduce((a, k) => ({ ...a, ...(dts[k] ? { [k]: dts[k] } : {}) }), {})
            //   };
            //   break;
            // }
        }
        if (window.PRN_SERVICE.reportings_contexts) {
            console.log('common context: ', ccpd);
        }
        //сброс состояния редактирования страницы
        yield put({
            type: actionsCommon.TOGGLE,
            payload: {
                variable: 'edit',
                value: false,
            },
        });
        yield put({
            type: actionsWidgets.SET_CONTEXT,
            payload: {
                paths: ['common'],
                innerPath: '',
                value: ccpd,
            },
        });
        yield put(toggleIsEditable(ccpd.isGroupAdmin ?? false));
    } catch (e) {
        console.warn('reset common context error', e);
    }
}

/**
 * set group context proprerties to common context when group data is loaded
 * @param action
 */
function* setGroupDataContext(action: addGroupActionType | loadGroupActionType) {
    try {
        // url template
        const { pathTemplate: ut } = getUrlTemplateByPathName(window.location.pathname);
        if (ut === urls.group[urls.group.findIndex((i) => i === ut)]) {
            let group: addGroupActionType['payload']['group'] = yield select(getGroupByPKID(action.payload.groupPKID));
            if (!group) {
                switch (action.type) {
                    case actionsGroups.ADD_GROUP:
                        let act = action as addGroupActionType;
                        group = act.payload.group;
                        break;
                    default:
                        break;
                }
            }

            yield put({
                type: actionsWidgets.UPDATE_CONTEXT,
                payload: {
                    id: 'common',
                    context: specificContextOptionByModuleUrl.group.reduce(
                        (acc, cur) => ({ ...acc, [cur]: group[cur] }),
                        {} as Record<string, any>
                    ),
                },
            });
        }
    } catch (error) {
        console.warn('set common context from group data error', error);
    }
}

function* setTitle(action: LocationChangeAction) {
    try {
        const title_items: string[] = [];
        title_items[9] = yield* select((state: IStateType) => state.store.appSettings?.title || '');

        // дэволтное имя пряники убрано для росбанка
        // Translate.t({ i18nKey: 'project name' }));
        const module = getModuleNameByPathname(action.payload.location.pathname);
        const { pathTemplate: ut } = getUrlTemplateByPathName(action.payload.location.pathname);
        const urlMatch = matchPath(action.payload.location.pathname, ut);
        if (module) title_items[8] = Translate.t({ i18nKey: `pryaniky.title.${module}` });
        let title = prepareTitle(title_items);
        switch (module) {
            case 'user':
                document.title = title;
                let match = urlMatch as match<{ id: string }>;
                let user: any;
                if (match?.params.id) user = yield* select(getUserById(match?.params.id));
                if (!user) {
                    yield take(usersActions.ADD_USER);
                    if (match?.params.id) user = yield* select(getUserById(match?.params.id));
                }
                title_items[0] = user?.baseData?.displayName || '';
                title = prepareTitle(title_items);
                break;
            case 'group':
                document.title = title;
                match = urlMatch as match<{ id: string }>;
                let group: any;
                if (match?.params.id) group = yield* select(getGroupByPKID(match?.params.id));
                if (!group) {
                    yield take(groupsActions.ADD_GROUP);
                    if (match?.params.id) group = yield* select(getGroupByPKID(match?.params.id));
                }
                title_items[0] = group?.name || '';
                title = prepareTitle(title_items);
                break;
            default:
                break;
        }
        document.title = title;
    } catch (error) {
        console.error('set title error: ', error);
    }
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeEvery(ROUTER_LOCATION_CHANGE, commonContextReset);
    // yield takeEvery(ROUTER_LOCATION_CHANGE, setTitle);
    yield takeEvery([actionsGroups.ADD_GROUP, actionsGroups.CHECK_GROUP_CONTEXT], setGroupDataContext);
};

/**
 * export root saga
 */
export default root;
