import React, { FC, memo } from 'react'
import {
    cnClassName,
    IMiniPlaceholderProps
} from './MiniPlaceholder.index'
import './MiniPlaceholder.scss'


export const MiniPlaceholder: FC<IMiniPlaceholderProps> = memo(({
    className,
    children
}) => {

    return <div className={cnClassName({}, [className])}>
        {children}
    </div >
})

