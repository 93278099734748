import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit';
import { defaultRepostNews } from 'blocks/NewsTypes/Repost/Repost.type';
import { Translate } from 'localization';
import BaseView from 'News/containers/BaseView';
import NewsItem from 'News/containers/NewsItem';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { v1 as uuid } from 'uuid';
import { IRepostFormProps, IStateProps, mapStateToProps } from './RepostForm.index';
import './RepostForm.scss';

// import { NewsView } from 'blocks/NewsView/BaseView/BaseView'

const getRepostKey = (key: string) => `pryaniky.repost.${key}`;

export const RepostFormPresenter: FC<IRepostFormProps & IStateProps> = ({
    user,
    newsId,
    group,
    currencyName,
    handleAccept,
    handleClose,
}) => {
    const onClose = () => {};
    return (
        <div>
            <DialogTitle onClose={handleClose}>
                <Translate i18nKey={getRepostKey('title')} />
            </DialogTitle>
            <div className={'RepostForm'}>
                <NewsEdit
                    create
                    // disableAttach
                    successMsg={<Translate i18nKey={getRepostKey('successMsg')} />}
                    failMsg={<Translate i18nKey={getRepostKey('failMsg')} />}
                    cancleEdit={handleClose}
                    compliteEdit={() => {
                        handleAccept({});
                    }}
                    defaultData={{ ...defaultRepostNews, user: user.baseData, id: uuid(), parentGuid: newsId, group: group }}
                    type={'createRepost'}
                >
                    <div className={'RepostForm-RepostedNews'}>
                        <NewsItem id={newsId} ViewComponent={BaseView} />
                    </div>
                </NewsEdit>
            </div>
        </div>
    );
};

export const Connected = connect(mapStateToProps)(RepostFormPresenter);

export default createModal<any, IRepostFormProps>(Connected, {
    maxWidth: 'sm',
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
            overflowY: 'hidden',
        },
    },
    TransitionComponent,
    scroll: 'body',
});
