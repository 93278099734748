import { Box, IconButton, styled } from 'muicomponents/src';
import { ItemBox } from '../TableList.styled';
import { cnTableListItemCell } from './TableListItem.index';
import { Link } from 'muicomponents/src/Link';

export const StyledItemBox = styled(ItemBox)({
    alignItems: 'flex-start',
    [`& .${cnTableListItemCell('Actions')}`]: {
        flexBasis: 80,
        minWidth: 80,
    },
}) as typeof ItemBox;

export const DispalyNameBox = styled(Box)({
    display: 'flex',
    gap: 8,
    gridGap: 8,
}) as typeof Box;

export const FlexBox = styled(Box)({
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
}) as typeof Box;
export const UsersBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    ['& > .MuiLink-root:not(:first-child)']: {
        marginRight: '-5px',
        overflow: 'unset',
    },
    ['& .MuiAvatar-root']: {
        border: '2px solid white',
    },
}) as typeof Box;
export const PlusNButton = styled(IconButton)({
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(189, 189, 189, 1)',
    color: 'white',
    font: 'inherit',
    fontWeight: 'bold',
}) as typeof IconButton;

export const AutorLink = styled(Link)({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ['-webkit-line-clamp']: '2',
    ['-webkit-box-orient']: 'vertical',
}) as typeof Link;

export const ExpiredBox = styled(Box)(({ color }) => {
    return {
        color: color === 'error' ? '#FF0508' : undefined,
    };
}) as typeof Box;
