import React, { FC, useState, useEffect } from "react";
import {
  IContentCardWidgetProps,
  cnContentCardWidget,
} from "./ContentCardWidget.index";
import {
  initWidgetAddition,
  initWidgetSettings,
} from "./ContentCardWidget/ContentCardWidget";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { IconButton } from "muicomponents/src/IconButton/IconButton";
import { Box } from "muicomponents/src/Box/Box";
import { Link } from "muicomponents/src/Link/Link";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { isObject } from "lodash";
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { UsersListItem } from "muicomponents/src/ItemsListDialog/UserListItem/UserListItem";
import { ItemsListBody } from "muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody";
import { CardItemLayout } from "muicomponents/src/ItemsListDialog/CardItem/CardItem";
import { IListItemProps } from "muicomponents/src/ItemsListDialog/ItemsListDialog.index";
import { Button, Typography } from "muicomponents/src";
import { NewsAttachV3 } from "utils/src";
import MDRender from "muicomponents/src/CommonmarkRender";
import { Translate } from 'localization';

initWidgetAddition();
initWidgetSettings();

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    boxShadow: "0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    padding: "24px",
    paddingTop: "20px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const StyledImg = styled("img")({
  width: "180px",
  height: "180px",
  "@media(min-width: 1280px)": {
    width: "180px",
    height: "180px",
  },
  "@media(min-width: 1360px)": {
    width: "200px",
    height: "200px",
  },
  "@media(min-width: 1440px)": {
    width: "220px",
    height: "220px",
  },
  "@media(min-width: 1600px)": {
    width: "240px",
    height: "240px",
  },
  "@media(min-width: 1900px)": {
    width: "260px",
    height: "260px",
  },
});
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  letterSpacing: "0.15px",
}));

const ContentCardItem = ({
  item,
}: React.PropsWithChildren<
  IListItemProps<{
    title?: string;
    desctiption?: string;
    url?: string;
    file?: NewsAttachV3;
    enabled?: boolean;
    id: string;
  }>
>) => {
  const { desctiption, file, title, url } = item;
  // console.log("item", item);
  return (
    <CardItemLayout
      imgAspectRatio={'1 / 1'}
      cardSx={{ minWidth: "262px", maxWidth: "262px" }}
      className={cnContentCardWidget("CardItemLayout")}
      alt={title}
      image={file?.originalUrl}
      header={<Tooltip title={title || ''}><Typography><Link sx={{ textDecoration: "none" }} href={url}>{title}</Link></Typography></Tooltip>}
      children={<MDRender textCut={true} maxCharCount={100} maxCharOffset={15} source={desctiption || ""} />}
      //   secondaryContent={"formatDateSTD(item.tag.timestamp)"}
      actions={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "8px",
            flex: "1",
          }}
        >
          {/* <Button variant="outlined" size="small">
                  Добавить себе
              </Button> */}
          {url && (
            <Button variant="outlined" size="small" href={url}>
              {/* Открыть */}
              <Translate
                i18nKey={'pryaniky.ContentCardWidget.item.open.content'}
              />
            </Button>
          )}
        </Box>
      }
    />
  );
};

const CardListLayout = ({ children }: any) => {
  return (
    <Box
      className={cnContentCardWidget("CardListLayout")}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "24px",
      }}
    >
      {children}
    </Box>
  );
};
const BodyListComponent = ({ children }: any) => {
  return (
    <Box
      className={cnContentCardWidget("BodyListComponent")}
      sx={{ marginBottom: "24px" }}
    >
      {children}
    </Box>
  );
};

export const ContentCardWidget: FC<IContentCardWidgetProps | any> = ({
  contexts,
  className,
  isEditable,
  HTMLWidgetContentEdit,
  ...props
}) => {
  let items = props.widget.settings?.items;
  let title = props.widget.settings?.title;
  if (!items) return null;
  if (isObject(items)) {
    items = Object.values(items);
  }

  // return <Box>
  //     {items.filter((v: any) => v.enabled).map((item: any) => <Item onClose={() => setShow(false)} key={item.id} settings={item} />)}
  // </Box>

  return (
    <Box className={cnContentCardWidget()}>
      <Typography
        variant="h6"
        sx={{ margin: "24px 0px 16px" }}
        className={cnContentCardWidget("Title")}
      >
        {title}
      </Typography>

      <ItemsList<{} & any>
        disableQR
        BodyComponent={BodyListComponent}
        ItemComponent={ContentCardItem}
        ListComponent={CardListLayout}
        disableItemsAction
        method={async function (skipCount, { search, count }) {
          return items;
        }}
      />
    </Box>
  );
};
