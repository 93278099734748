import { prepareUrls } from 'utils/src';
import { mainUrls } from 'utils/src/Urls/main';

// ДЛИННЫЕ ПУТИ ДОЛЖНЫ СТОЯТЬ ПЕРВЫМИ, дальше по уменьшению
// ИЛИ используем prepareUrls - он отсортирует как надо

const urls = {
    deviceInfo: '/deviceinfo',
    _widgetresponsive: '/_widgetresponsive',
    _widgetdebug: '/_widgetdebug',
    _develop: '/_develop',
    _appsDebug: '/apps/debug',
    login: '/login',
    logout: prepareUrls(mainUrls.logout),
    dash: '/dash',
    users: prepareUrls(mainUrls.users),
    user: prepareUrls(mainUrls.user),
    profileEdit: prepareUrls(mainUrls.profileEdit),
    userNew: ['/usernew/:id/:tab', '/usernew/:id'],
    invite: '/invite',
    group: prepareUrls(mainUrls.group),
    groups: mainUrls.groups,
    messages: ['/messages/:type/:id', '/messages'],
    news: '/news/:id',
    tag: prepareUrls(mainUrls.tag),
    badge: '/badge/:id',
    badges: '/badges',
    notificationsSettings: ['/notifications/settings/:id', '/notifications/settings'],
    pages: '/pages/:id+',
    rating: ['/leaderboard/:id', '/rating/:id'],
    mountainRating: '/mountainRating/:id',
    shop: prepareUrls(mainUrls.shop),
    shopProduct: prepareUrls(mainUrls.shopProduct),
    orders: prepareUrls(mainUrls.orders),
    competitions: '/competitions',
    ideas: '/ideas',
    birthdays: '/birthdays/:variant?/:date?',
    birthdaysNoMonth: '/birthdays',
    lms: '/lms',
    lmsTest: '/lms/test/:cid',
    lmsResult: '/lms/result/:cid/:sid',
    lmsCourse: '/lms/:id',
    lmsTeacherCourse: '/TeacherOffice/Course/:id',
    lmsTeacherOffice: '/TeacherOffice',
    lmsCourseCreate: '/TeacherOffice/CourseCreate',
    lmsStatistics: '/TeacherOffice/Statistics/type/:id',
    lmsStatisticsUser: '/TeacherOffice/statistics/user/:id',
    tracksManage: '/TracksManage',
    learningTracks: '/LearningTracks',
    trackManage: '/TrackManage/:id',
    trackView: '/TrackView/:id',
    photoalbum: '/photoalbum/:id',
    album: '/album/:gid/:aid',
    widgets: ['/widgets/:type/:subtype/:id', '/widgets/:type/:subtype', '/widgets/:type'],
    integrate: '/integrate',
    wdialogs: '/wdialogs',
    questsmoderate: '/quests/moderate',
    events: '/events',
    polls: '/polls',
    creativetasks: '/creativetasks',
    calendar: '/calendar',
    settingsDesign: '/settings/design',
    notices: '/notices',
    thanks: '/thanks',
    wikilist: prepareUrls(mainUrls.wikilist),
    secretsanta: prepareUrls(mainUrls.secretsanta),
    board: '/board',
    search: ['/search/:tab', '/search'],
    quiz: '/quiz/go/:id',
    notifiSettings: [
        '/notifiSettings/:uid/:tab/:gid/:nid',
        '/notifiSettings/:uid/:tab/:nid',
        '/notifiSettings/:uid/:tab',
    ],
    notfound: '/404',
    error500: '/500',
    welcome: '/welcome',
    quests: '/quests',
    orgchart: '/orgchart',
    workflows: '/workflows/:type',
    dashboard: '/dashboard',
    statistics: '/statistics',
    survey: '/survey/go/:id',
    widgets_v2: '/widgets_v2',
    widgets_horizontal_v2: '/widgets_horizontal_v2',
    vacancies: '/vacancies',
    vacancy: prepareUrls(mainUrls.vacancy),
    achievements: '/achievements',
    external: '/external',
    tasks: '/tasks',
    currentApplications: '/myRequests',
    externalRedirect: prepareUrls(mainUrls.externalRedirect),
};

export const exactPathnames: string[] = [urls.shop, urls.lms, urls.lmsTeacherOffice];

export default urls;
