import React, { FC, useEffect, useMemo, useState, memo, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { PriorityNav } from 'blocks/PriorityNav/PriorityNav';

type IPresenterProps = News.NewsCreator.IPresenterProps
type IOwnProps = News.NewsCreator.IOwnProps
type IDispatchProps = News.NewsCreator.IDispatchProps
type IStateProps = News.NewsCreator.IStateProps
type IState = News.NewsCreator.IState


/**
 * @deprecated
 * @param param0 
 * @returns 
 */
export const Presenter: FC<IPresenterProps> = ({
    className,
    postTypes,
    groupId,
    loadAllowPostTypes,
    pathCreator,
    currentType,
    getNode
}) => {

    useEffect(() => { loadAllowPostTypes(groupId) }, [])

    const setCurrentType = useCallback((type: any) => {
        pathCreator({
            currentType: type.id
        })
    }, [postTypes])

    return <div className={cnClassName({}, [className])}>
        <PriorityNav elements={postTypes} activeElemId={currentType as any} onChange={setCurrentType} />
        {currentType ? getNode(`${currentType}`) : 'undefined type'}
    </div>
}

/**
 * @deprecated
 */
const Pr = connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<IPresenterProps>(memo(Presenter), ['news_creator']));
export default Pr
