import React, { useMemo } from 'react'
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapStateToProps, IUsersWidgetStateProps } from './../UsersWidget.index';
import { mapDispatchToProps, IDispatchProps } from "redux/connector";
import { i18n, Translate } from 'localization';
import './UsersWidget_type_subscriptions.scss';

import { UsersWidgetPresenter } from '../UsersWidget';
import { IUsersWidgetProps } from '../UsersWidget.index';
import { Avatar } from 'uielements/src';
import { cnUserWidgetGroup, cnUserWidgetGroupNew } from './UsersWidget_type_group.index';
import './UsersWidget_type_group.scss';
import { user } from 'pageStructures/user';
import { IListItem } from 'blocks/ListRound/ListRound.index';
import { GroupUsersDialog } from '../../Dialogs/Group/Users/GroupUsers';
import noDataImage from 'assets/svg/NoData.svg'
import { CustomSettings, mainUrls } from 'utils/src';
import { AccessTime } from 'muicomponents/src/Icons'
import { Box, Button as MuiButton, Typography } from 'muicomponents/src'
import { Link } from 'muicomponents/src/Link';
import { appGeneratePath } from 'utils/src/utils.path';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { classNamesDesign } from 'utils/src/constants.classes';

const useMoexDesign = CustomSettings.useMoexDesign();
export class UsersGroupWidgetPresenter extends UsersWidgetPresenter {

  constructor(props: IUsersWidgetProps) {
    super(props);
    this.state = {
      ...this.state,
      numCount: 5,
      toggle: false,
    }
  }

  public getData = (props: IUsersWidgetProps) => {
    let { gId: gid, count } = props.context;
    if(props.settings?.group?.pkid) {
      gid = props.settings?.group?.pkid;
    }
    if(props.settings?.countView) {
      // костыль для полнимания, что пользователей больше и окобращения кнопки
      count = props.settings?.countView + 1
    }
    utils.API.users.list({ gid, count })
      .r
      .then((d: any) => {
        if (!this.mounted) return;
        if (utils.checkResponseStatus(d)) {
          const users = (this.props.settings || {}).users || [];
          const topUsers = users.map((i: any) => i.id);
          const items = [...users, ...utils.shuffle(d.data.filter((i: any) => !topUsers.includes(i.id)))].map(((user: { [s: string]: any }): IListItem => ({
            ...user,
            id: user.id,
            title: user.displayName,
            url: `/user/${user.id}`,
            imgUrl: !utils.isEmptyGuid(user.imgId) ? user.userPhotoUrl || user.imgUrl : undefined
          })));
          this.setState({
            items,
            noData: items.length === 0,
            title: i18n.t('subscriptions')
          }, () => {

          });
        }
      });

  }
  public render() {

    // const { companyName } = utils.getAuthCookies();
    const customClassNames = '';
    const { tag: TagName = 'div', children, widget, className, context } = this.props;
    const { items, noData, title, numCount, toggle, dialog } = this.state;
    const { countView = 5 } = context || {};
    const additionalProps: { [s: string]: any } = {
      hide: noData,
    }

    const nameWithAvatar = !context.name && !!context.group

    const name = context.name || context.group?.displayName || i18n.t('pryaniky.widgets.users.participants.title');

    if (this.props.edit && items.length === 0) return <TagName {...this.props} ></TagName>
    if (!widget.settings?.hideIfNoData && items.length === 0) return <TagName {...this.props} className={cnUserWidgetGroup({}, [customClassNames, className])}>
      <div className={cnUserWidgetGroup('HeaderNoData-Title')} children={<Translate i18nKey={'pryaniky.widgets.users.participants.noData.title'} />} />
      <div className={cnUserWidgetGroup('HeaderNoData-noData')}> <Avatar precentage={100} shape={'rectangle'}
        imgUrl={noDataImage}
      />
      </div>
      <span className={cnUserWidgetGroup('HeaderNoData-Title-Text')}>  <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} /></span>
    </TagName>;

    if (items.length === 0) return null;

    if (useMoexDesign) {
      return <TagName className={cnUserWidgetGroupNew({}, [className])} {...this.props} {...additionalProps} >
        <GroupUsersDialog
          isShown={dialog && dialog === 'groupUsersDialog.show' || false}
          data={{ gId: context.group && context.group.pkid }}
          title={name}
          onClose={() => this.setState({ dialog: 'groupUsersDialog.hide' })} />
        <Box
          className={cnUserWidgetGroupNew('BackgroundImg')}
        >
          {
            nameWithAvatar
            ? <Box className={cnUserWidgetGroupNew('Header')}>
              <Avatar size={50} imgUrl={context.group.imgUrl} imgId={context.group.imgId} name={name} />
              <Typography variant="subtitle1" className={cnUserWidgetGroupNew('Name')} children={name} />
            </Box>
            : <Typography
              variant="subtitle1"
              className={cnUserWidgetGroupNew('Name')}
            >
              {name}
            </Typography>
          }
          <Box>
            <Box
              className={cnUserWidgetGroupNew('Content')}
              sx={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.72)",
                background: "rgba(255, 255, 255, 0.16)",
                backdropFilter: "blur(12px)",
                display: "flex",
                padding: "12px",
                flexDirection: "column",
                alignItems: "flex-start",

              }}>
              {items.map((item, i) => {
                if (!toggle && i >= countView) return null
                return (
                  <Box className={cnUserWidgetGroupNew('Item')}>
                    <Avatar size={48} name={item.title} imgId={item.imgId} imgUrl={item.imgUrl !== undefined ? item.imgUrl : `https://pryaniky.com/Img/UserProfiles/Preview/96m96/${item.id}.png`} />
                    <Box className={cnUserWidgetGroupNew('Item-Info')} sx={{
                      ".UserWidgetGroupNew-Item-Info-Name": {
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }
                    }}>
                      <UserMention id={item.id.toString()}>
                        <Link sx={{ display: 'block' }} className={cnUserWidgetGroupNew(`Item-Info-Name`, [ classNamesDesign.primaryColor3.text ])} href={appGeneratePath(mainUrls.user.id, { id: item.id.toString() })}>
                          {item.title}
                        </Link>
                      </UserMention>
                      <Typography
                        variant={"body2"}
                        sx={{
                          color: " rgba(0, 0, 0, 0.60)",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}>{item.position}</Typography>
                    </Box>
                  </Box>
                )
              })}
              {children}
              {
                countView < items.length &&
                <Box className={''} >
                  <MuiButton variant={"text"} sx={{ padding: "4px 5px", lineHeight: "22px" }} onClick={() => this.setState({ dialog: 'groupUsersDialog.show' })}>{i18n.t('pryaniky.widgets.users.participants.moex.more')}</MuiButton>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </TagName>
    }


    return (

      <TagName {...this.props} {...additionalProps} >
        <GroupUsersDialog
          isShown={dialog && dialog === 'groupUsersDialog.show' || false}
          data={{ gId: context.group && context.group.pkid }}
          title={name}
          onClose={() => this.setState({ dialog: 'groupUsersDialog.hide' })} />
          {
            nameWithAvatar
            ? <div className={cnUserWidgetGroup('Header')}>
              <Avatar size={50} imgUrl={context.group.imgUrl} imgId={context.group.imgId} name={name} />
              <h4 className={cnUserWidgetGroup('Name')} children={name} />
            </div>
            : <h4 className={cnUserWidgetGroup('Name')} children={name} />
          }
        <div>
          {items.map((item, i) => {
            if (!toggle && i >= countView) return null
            return (
              <div className={cnUserWidgetGroup('Item')}>
                <Avatar size={40} name={item.title} imgId={item.imgId} imgUrl={item.imgUrl !== undefined ? item.imgUrl : `https://pryaniky.com/Img/UserProfiles/Preview/96m96/${item.id}.png`} />
                <div className={cnUserWidgetGroup('Item-Info')}>
                  <UserMention id={item.id.toString()}>
                    <Link className={classNamesDesign.primaryColor3.text} href={appGeneratePath(mainUrls.user.id, { id: item.id.toString() })}>
                      {item.title}
                    </Link>
                  </UserMention>
                  <p>{item.position}</p>
                </div>
              </div>
            )
          })}
          {/* <Avatar imgUrl={.imgUrl}/> */}
          {children}
          {
            countView < items.length &&
            <div className={''}>
              <Link onClick={() => this.setState({ dialog: 'groupUsersDialog.show' })}>{i18n.t('pryaniky.widgets.users.participants.more')}</Link>
            </div>
          }
        </div>
      </TagName>

    )
  }
}

export const UsersGroupWidget = connect<IUsersWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(UsersGroupWidgetPresenter)
