import { lineClampStyles, tableConstantProps } from "blocks/UsersEvents/UsersEvents.constants";
import { styled, Typography } from "muicomponents/src";
import React, { ComponentProps, FC } from "react";
import { BorderedBox, BottomBorderedBox, TopBorderedBox } from "../UsersEventsTable.styled";

const LeveledBox: FC<ComponentProps<typeof BorderedBox> & { level?: number, evenLevel?: boolean }> = ({ level, evenLevel, ...props }) => {
    return (
        <BorderedBox {...props} />
    );
};

export const CellBox = styled(LeveledBox)(({ level, evenLevel }) => ({
    padding: '14px 16px',
    width: '100%',
    background: !evenLevel ? '#f7f7f7' : undefined,
    paddingLeft: level ? `${16 * (level + 1)}px` : undefined
})) as typeof LeveledBox;

export const SpaceBox = styled(TopBorderedBox)({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '105%',
    background: '#fff',
    minHeight: 15
}) as typeof TopBorderedBox;

export const HeadCellBox = styled(CellBox)({
    paddingBottom: 29,
    position: 'relative',
    // minHeight: `calc(100% - ${15}px)`
    // minHeight: tableConstantProps.estimatedRowSize
}) as typeof CellBox;

export const TableContentBox = styled(Typography)({
    ...lineClampStyles,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // display: '-webkit-box'
}) as typeof Typography;