/**
 * @packageDocumentation
 * @module List_type_users
 */
import React, { FC, useMemo, useRef, useState } from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';
import MDRender from 'uielements/src/CommonmarkRender';
import { mapStateToProps, IListStateProps, IListProps, IListState, mapActionsToProps } from './../../List.index';
import { IListTypeUsersProps, avalibleFilters, TItemType } from './List_type_users.index';
import { i18n, Translate } from 'localization';
import './List_type_users.scss';
import { AbstractList } from '../../List';
import { Header } from 'blocks/List/Header/List-Header';
import { ListLite, Avatar, Button } from 'uielements/src';
import { Item } from 'blocks/List/Item/List-Item';
import { IStateType as IState } from 'redux/store';
import { FieldsRender } from 'blocks/InfoBlock/User/FieldsRender';
import moment from 'moment';
import { QRCode } from 'uielements/src/QRCode/QRCode';

import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog';
import { useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index';
import { useDidUpdateEffect, useScrollListState, useSearchParams } from 'utils/src/hooks';
import { defaultMiddleware } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { list } from 'utils/src/requests/requests.users';
import { mapFromUserList } from 'muicomponents/src/FieldRender/mapUserListFields';
import { isValidDate } from 'utils/src/utils.dates';
import { UserSettedAdditionalFilters, UserAddittionalContent, allUserFiltersKeys } from './List_type_users.utils';
import { useContextFiltersChange } from 'blocks/Filters/Filters.hooks';
import { FiltersSOCOrgchartUnitsProps } from 'blocks/Filters/_type/Filters_type_socogchartunits/Filters_type_socogchartunits.index';
import { ListItemBodyElementClickActionType } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { UserAdditionalFieldType } from 'utils/src';
import { Box, styled } from 'muicomponents/src';
import { UsersFilters } from 'blocks/Filters/CommonFilters/_type/Users/UsersFilters';

const useGetRequersParams = (context: any, requestOptions: any) => {
    const opts = useMemo(() => {
        const opts = Object.assign({}, requestOptions);
        Object.keys(context).forEach((cKey: string) => {
            if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
            if (cKey === 'tId') opts['showcontext'] = 'bytag';
            if (cKey === 'units' && typeof context[cKey] === 'string') opts[avalibleFilters[cKey]] = [context[cKey]];
        });
        return opts;
    }, [context, requestOptions]);
    return opts;
};

const ResetButtonConteiner = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
}) as typeof Box;

export const N_ListUsersPresenter: FC<any> = ({
    context,
    wcontext,
    isGroupAdmin,
    authUser,
    uIsAdmin,
    requestOptions,
    staticFilters,
    relations,
    ...props
}) => {
    const [{ search: defaultSearchParameter, ...searchParams }, updateSearchParams] =
        useSearchParams<{ search: string }>();
    const searchParamsRef = useRef(searchParams);
    searchParamsRef.current = searchParams;

    const contextUpdate = useContextFiltersChange<FiltersSOCOrgchartUnitsProps['context']>(
        props.widget?.relations || []
    );

    // const oneContextFilterIsEnabled = useMemo(() => {
    //     return allUserFiltersKeys.filter((el) => el !== 'units').reduce((a, key) => a || !!context[key], false);
    // }, [context]);

    const showAdditionalFilters = useMemo(() => {
        if (staticFilters) return false;
        return (
            (!context[WidgetsTypes['filters/socorgchartunits']] && !!context.units?.length)
        );
    }, [context, staticFilters]);

    const [countFilterItems, setCountFilterItems] = useState<number>(0);

    return (
        <ItemsList<TItemType>
            BodyComponent={ItemsListBody}
            listStateHook={({ defaultSelected }, middlewareParams) => {
                const { search, debouncedSearch, changeSearch, tabsValue, onChangeTab } = useDefaultStaticStates(
                    middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id,
                    defaultSearchParameter
                ); // состояние для поиска и табов

                useDidUpdateEffect(() => {
                    defaultSearchParameter &&
                        updateSearchParams({
                            ...searchParamsRef.current,
                        });
                }, [defaultSearchParameter, debouncedSearch]);

                //преоброзование параметров для запроса
                const requestParams = useGetRequersParams(context, requestOptions);

                // получаем список пользователей и достаём запиханное туда колличество пользователей
                const state = useScrollListState(
                    async function (skipCount, count) {
                        const response = await (
                            await list({
                                skipCount,
                                count,
                                search: debouncedSearch,
                                gid: context.groupId,
                                extended: true,
                                ...requestParams,
                            })
                        ).r;
                        const result = (await response) as any as {
                            data: (IBaseUser & { fields?: any; actions: string[] | null })[];
                            totalUserCount: number;
                            companyUsersCount: number;
                        } & IBasicResponse;

                        if (result.error_code !== 0) throw result;

                        return result.data.map((item) => ({
                            ...item,
                            url: `/user/${item.id}`,
                            actions: item.actions || [],
                            fields: mapFromUserList(item.fields || []),
                        })) as TItemType[];
                    },
                    [debouncedSearch, requestParams],
                    {
                        selectableSingle: middlewareParams.selectableSingle,
                    }
                );

                return {
                    middlewareParams: {
                        ...middlewareParams,
                        itemMiddleware: (props) => {
                            return {
                                ...props,
                                bodyElemClick: (event, item, action) => {
                                    switch (action.type) {
                                        case ListItemBodyElementClickActionType.userOrgChart:
                                            event.preventDefault();
                                            contextUpdate({
                                                units: [action.data.id],
                                            });
                                            updateSearchParams({
                                                ...searchParamsRef.current,
                                                units: [action.data.id],
                                            });
                                            break;
                                        case ListItemBodyElementClickActionType.userFieldClick:
                                            switch (action.data.field.type) {
                                                case UserAdditionalFieldType.tags:
                                                    if (!action.data.fieldDataItem) return;
                                                    event.preventDefault();
                                                    contextUpdate({
                                                        tId: action.data.fieldDataItem.id,
                                                    });
                                                    updateSearchParams({
                                                        ...searchParamsRef.current,
                                                        tId: action.data.fieldDataItem.id,
                                                    });
                                                    break;
                                            }
                                            break;
                                    }
                                },
                                additionalContent: <UserAddittionalContent {...props} />,
                            };
                        },
                    },
                    state,
                    defaultSelected,
                    search,
                    tabsValue,
                    changeSearch,
                    onChangeTab,
                    middleware: defaultMiddleware,
                };
            }}
            ItemComponent={UsersListItem}
            listHeaderProps={{
                rootBoxProps: {
                    sx: {
                        paddingBottom: '20px',
                    },
                },
                // filtersContent: (
                //     <UsersFilters
                //         context={context}
                //         relations={relations}
                //         countFilterItems={countFilterItems}
                //         setCountFilterItems={setCountFilterItems}
                //     />
                // ),
                topContent: (
                    showAdditionalFilters && <ResetButtonConteiner>
                        <UserSettedAdditionalFilters
                            units={!context[WidgetsTypes['filters/socorgchartunits']] ? context.units : []}
                            changeUnits={(units) => {
                                contextUpdate({
                                    units,
                                });
                                updateSearchParams({
                                    ...searchParamsRef.current,
                                    units,
                                });
                            }}
                            showAdditionalFilters={showAdditionalFilters}
                        />
                    </ResetButtonConteiner>
                ),
            }}
        />
    );
};

export class ListUsersPresenter extends AbstractList<IListTypeUsersProps, IListState> {
    public static defaultProps = {
        requestOptions: {
            rid: '',
            search: '',
            catFilter: '',
            excludeGroup: -1,
            gid: -1,
            extended: true,
            count: 20,
            skipCount: 0,
        },
    };

    public type = 'users';

    constructor(props: IListTypeUsersProps) {
        super(props);
        this.prepareData = this.prepareData.bind(this);
        this.getData = this.getData.bind(this);
    }

    public search = (text: string) => {
        const { toggle } = this.props;
        this.searchString = text;
        toggle({
            id: this.listId,
            data: {
                hideData: text.length !== 0,
                clearData: true,
                isFinished: false,
            },
        });
        setTimeout(() => this.getData(), 10);
    };

    public getData() {
        const { requestOptions, context, edit } = this.props;
        const { clearData, isLoading } = this.props;
        const { clearDataFromList, getUsers, toggle } = this.props;
        const id = this.listId;
        if (edit || isLoading || !this.mounted) return;
        const opts = Object.assign({}, requestOptions);

        Object.keys(context).forEach((cKey: string) => {
            if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
            if (cKey === 'tId') opts['showcontext'] = 'bytag';
        });

        if (this.searchString) opts.search = this.searchString;
        if (clearData) {
            clearDataFromList({ id, data: null });
        }
        toggle({
            id,
            data: {
                isLoading: true,
                isFinished: false,
            },
        });
        getUsers.call(this, id, opts);
    }

    public componentDidUpdate(pp: IListTypeUsersProps) {
        const { context: c, toggle, isLoading } = this.props;
        const { context: pc } = pp;
        if (Object.keys(avalibleFilters).filter((fk) => (c[fk] !== pc[fk] ? true : false)).length) {
            toggle({
                id: this.listId,
                data: {
                    hideData: true,
                    clearData: true,
                    isFinished: false,
                },
            });
            setTimeout(() => this.getData(), 10);
        }
    }

    public prepareData(el: any) {
        el.view_data = {
            avatar: (
                <Button theme="unstyled" type="rlink" href={'/user/' + el.id}>
                    <Avatar imgUrl={el.imgUrl} imgId={el.imgId} size={56} name={el.displayName} />
                </Button>
            ),
            info: [
                [
                    <Button
                        className="UserName"
                        key={0}
                        noBorder
                        noPadding
                        noBackground
                        type="rlink"
                        href={'/user/' + el.id}
                        children={el.displayName}
                    />,
                    el.position,
                    el.division,
                    el.fields && (
                        <FieldsRender
                            categotyRender={(cat: string, fields: any, FieldRender: any) =>
                                fields.map((v: any) => <FieldRender field={v} />)
                            }
                            fieldRender={(val: any) => {
                                const { field, value, tags, files, fieldId } = val;
                                if (!field) return null;
                                if (!value) return null;
                                if (field.fieldType === 'Files') return null;
                                if (field.fieldType === 'Tags') {
                                    if (tags.length === 0) return null;
                                    return (
                                        <div>
                                            <b>
                                                <Translate
                                                    i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                    defaults={field.name}
                                                />
                                                :{' '}
                                            </b>
                                            {tags.map((tag: any, i: number) => {
                                                const tagLink = field.additionalParam1
                                                    ? (field.additionalParam1 as string).replace('{id}', tag.id)
                                                    : `/tag/${tag.id}`;
                                                return (
                                                    <>
                                                        <Button
                                                            theme={'unstyled'}
                                                            key={tag.id}
                                                            type="rlink"
                                                            href={tagLink}
                                                        >
                                                            {tag.displayName}
                                                        </Button>
                                                        {i < tags.length - 1 && <span>, </span>}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    );
                                }
                                if (field.fieldType === 'Interests') {
                                    if (tags.length === 0) return null;
                                    return (
                                        <div>
                                            <b>
                                                <Translate
                                                    i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                    defaults={field.name}
                                                />
                                                :{' '}
                                            </b>
                                            {tags.map((tag: any, i: number) => {
                                                const tagLink = field.additionalParam1
                                                    ? (field.additionalParam1 as string).replace('{id}', tag.id)
                                                    : `/tag/${tag.id}/people?collectionType=interests`;
                                                return (
                                                    <>
                                                        <Button
                                                            theme={'unstyled'}
                                                            key={tag.id}
                                                            type="rlink"
                                                            href={tagLink}
                                                        >
                                                            {tag.displayName}
                                                        </Button>
                                                        {i < tags.length - 1 && <span>, </span>}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    );
                                }
                                if (field.fieldType === 'String' && field.mask === 'email') {
                                    return (
                                        <div>
                                            <b>
                                                <Translate
                                                    i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                    defaults={field.name}
                                                />
                                                :
                                            </b>
                                            <Button theme={'unstyled'} {...utils.generateLinkData(value)}>
                                                {value}
                                            </Button>
                                        </div>
                                    );
                                }
                                if (field.fieldType === 'String' && field.mask === 'telefon') {
                                    if (!value || value === '+') return null;
                                    return (
                                        <div>
                                            <b>
                                                <Translate
                                                    i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                    defaults={field.name}
                                                />
                                                :
                                            </b>{' '}
                                            {value}
                                        </div>
                                    );
                                }
                                if (field.fieldType === 'Textarea') {
                                    return (
                                        <div>
                                            <b>
                                                <Translate
                                                    i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                    defaults={field.name}
                                                />
                                                :
                                            </b>{' '}
                                            <MDRender type={'inline'} source={value} />
                                        </div>
                                    );
                                }
                                if (field.fieldType === 'DateTime') {
                                    if (!isValidDate(moment(value).toDate())) return null;
                                    return (
                                        <div>
                                            <b>
                                                <Translate
                                                    i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                    defaults={field.name}
                                                />
                                                :
                                            </b>{' '}
                                            {moment(value).format('L LT')}
                                        </div>
                                    );
                                }
                                if (field.fieldType === 'Date') {
                                    if (!isValidDate(moment(value).toDate())) return null;
                                    return (
                                        <div>
                                            <b>
                                                <Translate
                                                    i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                    defaults={field.name}
                                                />
                                                :
                                            </b>{' '}
                                            {moment(value).format('L')}
                                        </div>
                                    );
                                }
                                return (
                                    <div>
                                        <b>
                                            <Translate
                                                i18nKey={`pryaniky.user.fields.${fieldId}`}
                                                defaults={field.name}
                                            />
                                            :{' '}
                                        </b>
                                        {value}
                                    </div>
                                );
                            }}
                            fields={el.fields.filter((f: any) => f.field.showInUserList && f.value !== '')}
                        />
                    ),
                ],
                [
                    el.eMail
                        ? [
                              <Button
                                  key={1}
                                  noBorder
                                  noPadding
                                  className={'List-Item-DataPart_email'}
                                  noBackground
                                  type="link"
                                  href={'mailto:' + el.eMail}
                                  children={el.eMail}
                              />,
                          ]
                        : null,
                    el.phone
                        ? [
                              <Button
                                  key={1}
                                  noBorder
                                  noPadding
                                  noBackground
                                  type="link"
                                  href={'tel:' + el.phone}
                                  children={el.phone}
                              />,
                          ]
                        : null,
                ],
                [
                    [
                        <div className={'List-Item-QRCode'}>
                            <QRCode uid={el.id} userName={el.displayName} />
                        </div>,
                    ],
                ],
            ],
        };
    }

    public renderChildren = () => {
        const { data, isFinished, isLoading, hideData } = this.props;
        return (
            <React.Fragment>
                <Header
                    className={'rounded-top'}
                    type={'common'}
                    search={{
                        placeholder: i18n.t('pryaniky.list.users.search'),
                        onChange: this.search,
                    }}
                />
                <ListLite
                    className={'rounded-bottom'}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    loadMore={this.getData}
                    noSkeletons={isFinished}
                >
                    {data.map((el) => (
                        <Item lId={this.listId} iId={el} type={'common'} key={el} data_key={el} />
                    ))}
                </ListLite>
            </React.Fragment>
        );
    };
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
    mapStateToProps,
    mapDispatchToProps(mapActionsToProps)
)(N_ListUsersPresenter);
