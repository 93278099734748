import { useWikilistItemBaseImageByType } from "blocks/WikiListNew/Items/Items..hooks";
import { WikiCardAvatar } from "blocks/WikiListNew/Items/style";
import { Translate } from "localization";
import { Box } from "muicomponents/src";
import { FilePreviewActionsIconButton } from "muicomponents/src/FileViewerNew/Item/FileViewerItem.styled";
import { FileCopy, FileOpen } from "muicomponents/src/Icons";
import { Link } from "muicomponents/src/Link";
import { Tooltip } from "muicomponents/src/Tooltip";
import React, { FC, useCallback } from "react";
import { generateLinkData, PageTypes } from "utils/src";
import { GUID_EMPTY } from "utils/src/constants.prn";
import { initWeekBlockSettingsAdd, initWeekBlockSettingsEdit } from "./Settings";
import { StyledWikiImageCardButton } from "./Settings/WeekBlockSettings.styled";
import { FilePreview } from '../../Files/Preview/FilePreview';
import { WeekBlockProps } from "./WeekBlock.index";
import { WeekBlockCard, WeekBlockCardAvatar } from "./WeekBlock.styled";

initWeekBlockSettingsAdd();
initWeekBlockSettingsEdit();

export const WeekBlock: FC<WeekBlockProps> = ({
    item
}) => {

    const noImg = item?.titleImageId === GUID_EMPTY || item?.titleImageId === null;

    const pageBaseImage = useWikilistItemBaseImageByType(item?.type || PageTypes.imageGallery);
    
    const copyFileLink = useCallback((link: string) => {
        const linkData = generateLinkData(link);
        let linkToClipboard = link;
        if (linkData.type === 'rlink') {
            linkToClipboard = window.location.origin + linkToClipboard;
        }
        navigator.clipboard.writeText(linkToClipboard);
    }, []);

    const showImagesPreview = useCallback(
        () => {
            if(!item || !item.file) return;
            // const initFileIndex = galleryItems.findIndex((el) => el.id === item.id);
            FilePreview({
                files: [item.file],
                filesNewsIds: {[item.file.id]: item.newsId},
                initFileIndex: 0,
                getActionForItem: (image, actions) => {
                    const pageActions = item.actions?.map((el) => el.id);
                    const downloadIsAllow = pageActions
                        ? pageActions.includes('download') || pageActions.includes('file.download')
                        : true;
                    if (!downloadIsAllow) {
                        return actions.filter((el) => el !== 'download');
                    }
                    return actions;
                },
                additionalRightControls: () => {
                    if(!item) return {};
                    const outLink =
                        (item.url.indexOf('http') === 0 || item.url.indexOf('//') === 0) &&
                        !item.url.includes(window.location.hostname);
                    let href = outLink || item.url[0] === '/' ? item.url : '/' + item.url;
                    if (!href) return {};
                    return (
                        <>
                            <Tooltip title={<Translate i18nKey="pryaniky.file.copyLink" />} disableInteractive>
                                <FilePreviewActionsIconButton
                                    className={'CopyLink'}
                                    onClick={() => copyFileLink(href!)}
                                >
                                    <FileCopy />
                                </FilePreviewActionsIconButton>
                            </Tooltip>
                            <Tooltip title={<Translate i18nKey="openFilePage" />} disableInteractive>
                                <FilePreviewActionsIconButton className={'OpenFilePage'} component={Link} {...{ href }}>
                                    <FileOpen />
                                </FilePreviewActionsIconButton>
                            </Tooltip>
                        </>
                    );
                },
            });
        },
        [item]
    );

    return (
        <WeekBlockCard component={Box} onClick={showImagesPreview}>
            {
                item && 
                <WeekBlockCardAvatar
                    src={noImg ? pageBaseImage : item.titleImageUrl}
                />
            }
        </WeekBlockCard>
    )
};