import { tT } from 'LMSModule/utils/i18n.adaptor';
import { getModeratedAnswersCount, getTestCount, getWaitModerationCount } from 'LMSModule/utils/result.utils';
import { CircularProgress } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Avatar, Button } from 'uielements/src';
import { mainUrls } from 'utils/src';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { Chart } from '../../components/Chart/Chart';
import { SummaryTable } from '../../components/SummaryTable/SummaryTable';
import { List as AnswerList } from '../AnswerList/AnswerList';
import { Sessions } from './Filters/Sessions/Sessions';
import { Units } from './Filters/Units/Units';
import { cnStudentResults, mapDispatchToProps, mapStateToProps } from './StudentResults.index';
import './StudentResults.scss';
import { Item } from './UnitsList/Item/Item';

const UserAnswers: FC<any> = (props) => {
    return <AnswerList {...props} />;
};
export const ConnectedUserAnswers = connect(
    (state: any, props: any) => {
        return {
            units: state.LMSTO.moderation.units,
            questions: state.LMSTO.moderation.questions,
            answers: state.LMSTO.moderation.answers,
            users: state.LMSTO.users,
            answersIds: state.LMSTO.moderation.answersIds,
            filters: state.LMSTO.moderation.filters,
        };
    },
    (dispatch: any) => bindActionCreators({}, dispatch)
)(UserAnswers);

export const StudentResultsPresenter: FC<any> = ({
    className,
    cid,
    uid,
    sid,
    unit,
    units,
    location,
    studentSessions,
    results = {},
    resultsIds = [],
    user = {},
}) => {
    if (!user.id) {
        return <CircularProgress />;
    }

    const TABS = [
        { value: 'results', content: tT('student_results.tabs.results.1') },
        { value: 'answers', content: tT('student_results.tabs.answers.1') },
    ];

    const testsCount = getTestCount(results.session.unitResults);

    const waitModerationCount = getWaitModerationCount(results.session.unitResults);

    const moderatedAnswersCount = getModeratedAnswersCount(results.session.unitResults);

    const rightPercent: number = results.session.rightAnswerPercentage;
    const wrongPercent: number = 100 - results.session.rightAnswerPercentage;

    const firstUnitTest = results.session.unitResults.find((val: any) => val.unitType === 'test');
    let currentUnitId: any = null;
    if (!unit && !firstUnitTest) currentUnitId = null;
    else currentUnitId = unit || firstUnitTest.unitId;

    return (
        <div className={cnStudentResults({}, [className])}>
            <div className={cnStudentResults('Aside')}>
                {<Sessions active={sid} cid={cid} uid={uid} />}
                {
                    /*currentUnitId &&*/ <Units
                        active={currentUnitId}
                        cid={cid}
                        sid={sid}
                        userId={user.id}
                        units={units}
                        results={results.session.unitResults}
                    />
                }
            </div>
            <div className={cnStudentResults('Section')}>
                <div className={cnStudentResults('Block', [cnStudentResults('Session')])}>
                    <div className={cnStudentResults('Breadcrumbs')}>
                        <Button theme={'unstyled'} type={'rlink'} href={`?tab=users`}>
                            {tT('student_results.students.1')}
                        </Button>{' '}
                        / {user.displayName}
                    </div>

                    <div className={cnStudentResults('Row')}>
                        <div className={cnStudentResults('Col')}>
                            <div className={cnStudentResults('Row')}>
                                <div className={cnStudentResults('Col', { noFlex: true })}>
                                    <Avatar
                                        className={cnStudentResults('Avatar')}
                                        name={user.displayName}
                                        imgUrl={user.imgUrl}
                                        size={100}
                                    />
                                </div>
                                <div className={cnStudentResults('Col')}>
                                    <div>
                                        <UserMention id={user.id}>
                                            <Link
                                                className={cnStudentResults('UserMention', [
                                                    classNamesDesign.primaryColor3.text,
                                                ])}
                                                href={appGeneratePath(mainUrls.user.id, { id: user.id })}
                                            >
                                                {user.displayName}
                                            </Link>
                                        </UserMention>
                                    </div>
                                    <div>{user.position}</div>
                                    <div>{user.division}</div>
                                </div>
                            </div>
                        </div>

                        <div className={cnStudentResults('Col')}>
                            <div className={cnStudentResults('Row')}>
                                <div className={cnStudentResults('Col', { noFlex: true })}>
                                    <Chart
                                        className={cnStudentResults('Chart')}
                                        waitModerationCount={waitModerationCount}
                                        moderatedAnswersCount={moderatedAnswersCount}
                                        rightPercent={rightPercent}
                                        wrongPercent={wrongPercent}
                                    />
                                </div>
                                <div className={cnStudentResults('Col')}>
                                    <SummaryTable
                                        className={cnStudentResults('SummaryTable')}
                                        waitModerationCount={waitModerationCount}
                                        moderatedAnswersCount={moderatedAnswersCount}
                                        rightPercent={rightPercent}
                                        wrongPercent={wrongPercent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {currentUnitId && (
                    <div className={cnStudentResults('Block')}>
                        <div className={cnStudentResults('Row')}>
                            <div className={cnStudentResults('Col')}>
                                <Item
                                    key={currentUnitId + cid + sid}
                                    unit={results.session.unitResults.find((val: any) => val.unitId === currentUnitId)}
                                    sid={sid}
                                    cid={cid}
                                    userId={user.id}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export const StudentResults = connect(mapStateToProps, mapDispatchToProps)(withRouter(StudentResultsPresenter));
