import { cn } from '@bem-react/classname';
import { SOCOrgchartUnitsTreeManager } from 'blocks/Filters/_type/Filters_type_socogchartunits/Filters_type_socogchartunits.hooks';
import { userFilters } from 'blocks/Filters/_type/Filters_type_users.constants';
import { convertFullUserToShort } from 'blocks/InfoBlock/UserNew/Avatar/ThankForm/ThankForm';
import { Translate } from 'localization';
import { flattenDeep, uniq } from 'lodash';
import { Box, Chip, CircularProgress, IconButton, styled } from 'muicomponents/src';
import { AskingHand, Bookmark, BookmarkBorder, CheckCircleOutline, CancelOutlined } from 'muicomponents/src/Icons';
import { IItemProps } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';
import { baseModels } from 'News/creator/Creator/Creator.hooks';
import { NewsCreatorButton } from 'News/creator/Creator/CreatorButton/CreatorButton';
import React, { ComponentProps, FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { API, checkResponseStatus, UserListItemActionsButtonIds } from 'utils/src';
import { avalibleFilters, TItemType } from './List_type_users.index';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { ActionButton } from 'muicomponents/src/ItemsListDialog/ActionButton/ActionButton';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

const UserAddittionalContentBox = styled(Box)({
    flex: 1,
    display: 'flex',
}) as typeof Box;

const cnUserAddittionalContentAction = cn('UserAddittionalContentAction');

export const UserAddittionalContent: FC<IItemProps<TItemType>> = ({ actions, item }) => {
    const creatorOptions = useMemo(() => {
        return {
            models: {
                thanks: {
                    ...baseModels.thanks,
                    users: [item].map((v: any) => ({ ...convertFullUserToShort(v), type: 'user' })),
                },
            },
        };
    }, [item]);

    const [subscribeLoading, setSubscribeLoading] = useState(false);
    const subscribe = useCallback(
        async function (newValue: boolean) {
            setSubscribeLoading(true);
            const v = newValue;
            const response = await API.users.subscribe(item.id, v).r;
            if (checkResponseStatus(response, true)) {
                actions.updateItem(item, {
                    ...item,
                    actionButtons: item.actionButtons?.map((el) => {
                        if (el.id === UserListItemActionsButtonIds.subscribe)
                            return {
                                ...el,
                                data: {
                                    ...el.data,
                                    isSubscribed: v,
                                },
                            };
                        return el;
                    }),
                });
            }
            setSubscribeLoading(false);
        },
        [item]
    );

    const [approvePhotoLoading, setApprovePhotoLoading] = useState(false);
    const approvePhoto = useCallback(
        async function (newValue: boolean) {
            setApprovePhotoLoading(true);
            const v = newValue;
            const response = await API.users.approvePhoto(item.id, v, '').r;
            if (checkResponseStatus(response, true)) {
                actions.removeItems([item]);
                setApprovePhotoLoading(false);
            }
        },
        [item]
    );

    return (
        <UserAddittionalContentBox>
            {item.actionButtons?.map((el) => {
                switch (el.id) {
                    case UserListItemActionsButtonIds.qr:
                        return null;
                    case UserListItemActionsButtonIds.approvePhoto:
                        return (
                            <Tooltip title={<Translate i18nKey="pryaniky.list.users.actions.approvePhoto" />}>
                                <IconButton
                                    key={el.id}
                                    onClick={() => approvePhoto(true)}
                                    className={cnUserAddittionalContentAction({ action: el.id })}
                                >
                                    {approvePhotoLoading ? <CircularProgress size={24} /> : <CheckCircleOutline />}
                                </IconButton>
                            </Tooltip>
                        );
                    case UserListItemActionsButtonIds.disapprovePhoto:
                        return (
                            <Tooltip title={<Translate i18nKey="pryaniky.list.users.actions.disapprovePhoto" />}>
                                <IconButton
                                    key={el.id}
                                    onClick={() => approvePhoto(false)} //TODO: диалог с комментом
                                    className={cnUserAddittionalContentAction({ action: el.id })}
                                >
                                    {approvePhotoLoading ? '' : <CancelOutlined />}
                                </IconButton>
                            </Tooltip>
                        );
                    case UserListItemActionsButtonIds.thanks:
                        return (
                            <Tooltip title={<Translate i18nKey="pryaniky.list.users.actions.thanks" />}>
                                <NewsCreatorButton
                                    key={el.id}
                                    newsType="thanks"
                                    iconButton
                                    creatorOptions={creatorOptions}
                                    className={cnUserAddittionalContentAction({ action: el.id })}
                                >
                                    <AskingHand />
                                </NewsCreatorButton>
                            </Tooltip>
                        );
                    case UserListItemActionsButtonIds.subscribe:
                        return (
                            <Tooltip
                                title={
                                    <Translate
                                        i18nKey={
                                            el.data.isSubscribed
                                                ? 'pryaniky.list.users.actions.unsubscribe'
                                                : 'pryaniky.list.users.actions.subscribe'
                                        }
                                    />
                                }
                            >
                                <IconButton
                                    key={el.id}
                                    onClick={() => subscribe(!el.data.isSubscribed)}
                                    className={cnUserAddittionalContentAction({ action: el.id })}
                                >
                                    {subscribeLoading ? (
                                        <CircularProgress size={24} />
                                    ) : !el.data.isSubscribed ? (
                                        <BookmarkBorder />
                                    ) : (
                                        <Bookmark />
                                    )}
                                </IconButton>
                            </Tooltip>
                        );
                    default:
                        return null;
                }
            })}
        </UserAddittionalContentBox>
    );
};

export const allUserFiltersKeys = uniq([
    ...flattenDeep(userFilters.map((el) => el.map((el2) => [el2.type, el2.type.split('/')]))),
    ...Object.typedKeys(avalibleFilters),
]);

const UnitsWithNoFilterChipsBox = styled(Box)({
    display: 'flex',
    gap: 8,
    gridGap: 8,
    maxWidth: '100%'
}) as typeof Box;

const UnitsWithNoFilterChipsMainBox = styled(Box)({
    flex: 1,
    maxWidth: '100%'
}) as typeof Box;

const ActionButtonStyle = styled(ActionButton)({
    padding: '4px 16px',
    lineHeight: '22px',
}) as typeof ActionButton;

const UnitsWithNoFilterChip: FC<ComponentProps<typeof Chip> & { unitId: string }> = ({ unitId, ...props }) => {
    const [displayName, setData] = useState(Translate.t({ i18nKey: 'unit' }));

    useLayoutEffect(() => {
        (async function () {
            setData((await SOCOrgchartUnitsTreeManager.inst.getTreeItemByItemId(unitId))?.displayName || '');
        })();
    }, []);

    return <Chip {...props} size="small" label={displayName} />;
};

export const UserSettedAdditionalFilters: FC<{
    units?: string[];
    changeUnits: (units: string[]) => void;
    dropAllFilters?: () => void;
    showAdditionalFilters?: boolean;
}> = ({ units, changeUnits, dropAllFilters, showAdditionalFilters }) => {
    const unitsRef = useRef(units);
    unitsRef.current = units;

    const removeItem = useCallback((unitId: string) => {
        changeUnits(unitsRef.current?.filter((el) => el !== unitId) || []);
    }, []);

    if (!units?.length && !dropAllFilters) return null;

    return (
        <UnitsWithNoFilterChipsBox>
            {showAdditionalFilters && (
                <UnitsWithNoFilterChipsMainBox>
                    {!!units?.length &&
                        units.map((unitId) => {
                            return (
                                <UnitsWithNoFilterChip
                                    key={unitId}
                                    size="small"
                                    unitId={unitId}
                                    onDelete={() => removeItem(unitId)}
                                />
                            );
                        })}
                </UnitsWithNoFilterChipsMainBox>
            )}
            {
                dropAllFilters && 
                <ActionButtonStyle
                    disabled={!showAdditionalFilters}
                    onClick={dropAllFilters}
                    startIcon={<CleaningServicesIcon />}
                >
                    <Translate i18nKey="reset" />
                </ActionButtonStyle>
            }
        </UnitsWithNoFilterChipsBox>
    );
};
