import React from 'react';
import './Rating-Item.scss';
import { Avatar } from 'uielements/src/Avatar/Avatar';
// import { cnRating } from '../Rating.index';
import { IRatingUserInfo } from 'utils/src/requests/models/api.ratings';
import { ProgressBar } from 'uielements/src/ProgressBar/ProgressBar';
import { Button } from 'uielements/src/Button/Button';
import { cn } from '@bem-react/classname';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

export const cnRating = cn('RatingTableItem');


interface IExtRatingUserInfo extends IRatingUserInfo {
  idx: number;
  color: string;
  logo?: string;
  listPosition?: number;
}

export interface IItemProps {
  tag?: React.ComponentType<any> | string;
  className?: string;
  data: IExtRatingUserInfo;
  color: string;
  maxValue: number;
  rating: any;
  setMePostition?: (r: any) => void
}

export const TableItem: React.FC<IItemProps> = ({ data, tag: TagName = 'div', color, maxValue, rating, className, setMePostition = (r: any) => { } }) => {
  const [carMoved, setCarMoved] = React.useState(false);
  React.useEffect(() => void setTimeout(() => setCarMoved(true), 1000), []);
  // data.value = '9999999/99999999';
  let { ratingUrlType } = rating;
  if (!ratingUrlType) {
    if (rating.ratingType === 'Group') {
      ratingUrlType = 'group'
    }
  }
  return (
    <tr className={cnRating('Item', { leveled: data.level ? true : false }, [className])} >
      <td>
        <span ref={(r) => setMePostition(r)} className={cnRating('ItemPosition')}>{data.listPosition/*data.idx >= 0 && data.idx + 1*/}</span>
      </td>
      <td>
        {/* <Avatar
          name={data.displayName}
          imgId={data.imgId}
          imgUrl={data.imgUrl}
          size={56}
          className={cnRating('UserAvatar')}
        /> */}
      </td>
      <td>
        {(data.id && ratingUrlType === 'user')
          ? <UserMention id={data.id}>
            <Link className={cnRating('UserName', [ classNamesDesign.primaryColor3.text ])} href={appGeneratePath(mainUrls.user.id, { id: data.id })}>
              {data.displayName}
            </Link>
          </UserMention>
          : <Button type="rlink" href={`/${ratingUrlType}/${data.id}`} theme="unstyled" className={cnRating('UserName')}>
            {data.displayName}
          </Button>}
      </td>
      <td style={{ width: '75%' }}>
        <div className={cnRating('ProgressBox')}>
          <span className={cnRating('ItemValue')} style={{ color }}>
            {/* {`${formatNumber(data.curValue)} / ${formatNumber(maxValue)}`} */}
            {data.value}
          </span>
          <ProgressBar
            percent={carMoved ? parseInt(data.percantage, 10) : 0}
            className={cnRating('Progress')}
            color={color}
            barIcon={rating.markerImgUrl && (
              <div
                style={{
                  top: rating.markerTop - 6,
                  right: rating.markerRight - 6,
                  // left: `calc(${(carMoved ? data.percantage : '100%')} + ${rating.markerRight}px )`,
                  // transform: carMoved ? `translate(-${data.percantage}, -15%)` : `translate(0, -15%)`,
                }}
                className={cnRating('ProgressSlider')}>
                <img src={rating.markerImgUrl} />
              </div>
            )}>
          </ProgressBar>

        </div>
      </td>
    </tr>
  );
};
