import React, { FC, useState, useMemo, useRef, useEffect } from 'react';
import { Box, Button, Typography } from 'muicomponents/src';
import { generatePath, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { Translate } from 'localization';
import {
    ArrowBlock,
    BlackTypography,
    GrayTypography,
    NameTypography,
    PaperWrap,
    SeparatedBox,
    ShowMoreButton,
    ShowMoreElipsesBox,
    ShowMoreTextTypography,
    StatusTextBox,
    StatusTypography,
} from './styled';
import DataBlockSkeleton from './Skeleton/DataBlockSkeleton';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { setStatus, setUserStatus } from 'utils/src/CommonRedux/users/actions';
import { StatusPopover } from './StatusPopover/StatusPopover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { cnInfoBlock } from '../../InfoBlock.index';
import { FieldRenderProfileView } from './FieldRenderProfileView';
import { Link } from 'muicomponents/src/Link/Link';
import urls from 'routes/urls';
import { SOCUserOrgChart } from 'utils/src/BaseTypes/units.types';
import { PartOrgChartAccordion } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import { UserOrgchartBreadcrumbs } from 'muicomponents/src/Breadcrumbs';
import { cn } from '@bem-react/classname';

const cnUserDataBlock = cn('UserDataBlock');

const getLineRectsArr = (range: Range) => {
    const rects = Array.from(range.getClientRects()); // Получаем прямоугольники каждой строки

    // Удаляем дубликаты (особенно важно для Safari)
    const uniqueRects = [];
    const seenTops = new Set();
    for (const rect of rects) {
        if (!seenTops.has(rect.top)) {
            uniqueRects.push(rect);
            seenTops.add(rect.top);
        }
    }
    return uniqueRects;
};

function getLineStrArr(element: Node) {
    if (element.nodeType !== Node.TEXT_NODE) return [];
    const range = document.createRange();
    range.selectNodeContents(element);
    range.setStart(element, 0);
    range.setEnd(element, 0);

    const lines: string[] = [];
    let prevText = '';
    let currentLineIndex = 0;

    for (let indexChar = 1; indexChar <= (element.textContent?.length || 0); indexChar++) {
        range.setEnd(element, indexChar);

        if (getLineRectsArr(range).length > 1) {
            lines[currentLineIndex] = prevText;
            range.setStart(element, indexChar - 1);
            currentLineIndex++;
        }
        prevText = range.toString();
        if (indexChar === element.textContent?.length) {
            lines[currentLineIndex] = range.toString();
        }
    }
    return lines;
}

export const Data: FC = () => {
    const dispatch = useDispatch();
    const { id }: { id: string } = useParams();
    const userId = id;
    const user = useSelector(getUserById(userId, true));
    const authUser = useSelector(getAuthUser);
    const isAuthUserAdmin = authUser.baseData.isAdmin;
    const isUserPageOwner = userId === authUser.baseData.id;

    const [isInfoBlockCollapsed, setIsInfoBlockCollapsed] = useState(false);
    const Arrow = ({ up }: { up: boolean }) => {
        return (
            <ArrowBlock onClick={() => setIsInfoBlockCollapsed(!isInfoBlockCollapsed)}>
                <Button
                    className={cnUserDataBlock('AdditionalFields-More')}
                    sx={{
                        textTransform: 'inherit',
                        '&:hover': {
                            backgroundColor: 'inherit',
                        },
                    }}
                    variant={'text'}
                    color="primary"
                >
                    {up ? (
                        <Box display="flex" alignItems="center" sx={{ marginLeft: '-14px' }}>
                            <ExpandLessIcon
                                className={cnInfoBlock('IconLess CustomStyle primaryColor3-text')}
                                color="primary"
                                sx={{ marginRight: '4px', width: '34px', height: '34px' }}
                            />
                            <Typography color="primary">
                                {Translate.t({ i18nKey: 'pryaniky.profile.hide.else' })}
                            </Typography>
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center" sx={{ marginLeft: '-14px' }}>
                            <ExpandMoreIcon
                                className={cnInfoBlock('IconMore CustomStyle primaryColor3-text')}
                                sx={{ marginRight: '4px', width: '34px', height: '34px' }}
                            />
                            <Typography color="primary">{Translate.t({ i18nKey: 'pryaniky.profile.else' })}</Typography>
                        </Box>
                    )}
                </Button>
            </ArrowBlock>
        );
    };

    const statusBlockRef = useRef<HTMLElement>(null);
    const [openAllStatus, setOpenAllStatus] = useState<boolean>(false);
    const [shotStatusText, setShotStatusText] = useState<string | null>(null);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const popoverId = open ? 'simple-popover' : undefined;
    const handleStatusClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleStatusClose = () => {
        setAnchorEl(null);
    };
    const saveStatus = (text: string) => {
        dispatch(setUserStatus(userId, text));
        handleStatusClose();
    };
    const onChangeStatus = (e: any) => {
        dispatch(setStatus(userId, e.target.value));
    };

    const mainUserOrgChart = useMemo(() => {
        return user?.baseData.userOrgChart?.[0] as SOCUserOrgChart | undefined;
    }, [user?.baseData.userOrgChart]);

    const partUserOrgChart = useMemo(() => {
        return (user?.baseData.userOrgChart?.slice(1) || []) as SOCUserOrgChart[];
    }, [user?.baseData.userOrgChart]);

    useEffect(() => {
        const checkNeedHideStatusButton = () => {
            if (statusBlockRef.current !== null) {
                statusBlockRef.current.textContent = user.baseData.statusText;
                const textLinesArr = getLineStrArr(statusBlockRef.current.firstChild || statusBlockRef.current);
                if (textLinesArr.length > 2) {
                    const twoLines = textLinesArr[0] + textLinesArr[1];
                    const shotText = twoLines.substring(0, Math.max(twoLines.length - 5, 0));
                    setShotStatusText(shotText);
                    if (!openAllStatus) statusBlockRef.current.textContent = shotText;
                } else {
                    setShotStatusText(null);
                }
            }
        };
        checkNeedHideStatusButton();
        window.addEventListener('resize', checkNeedHideStatusButton);
        return () => {
            window.removeEventListener('resize', checkNeedHideStatusButton);
        };
    }, [user]);

    if (!user) return <DataBlockSkeleton />;

    const showCollapseble = !user.profileData
        ? false
        : user.profileData.userHeadFieldsOriginal
              .filter((v: any) => !(v.field.fieldType === 'Files'))
              .filter((v: any) => !(v.field.fieldType === 'AdditionalUserId' && !isAuthUserAdmin))
              .filter((v: any) => !(v.field.showInHeadType === 'ShowInHeadFields'))
              .some((c: any) => !!c.value);

    return (
        <PaperWrap className={cnUserDataBlock()}>
            <NameTypography
                variant="h6"
                color="ActiveCaption"
                sx={(user.baseData.statusText || isUserPageOwner) && { mb: 0 }}
                className={cnUserDataBlock('Name')}
            >
                {user.baseData.displayName}
            </NameTypography>
            <StatusTypography variant="body1" className={cnUserDataBlock('Status')}>
                <StatusTextBox ref={statusBlockRef}>
                    {shotStatusText && !openAllStatus ? shotStatusText : user.baseData.statusText}
                </StatusTextBox>
                {shotStatusText && (
                    <ShowMoreButton
                        variant="text"
                        sx={{
                            height: statusBlockRef.current
                                ? window.getComputedStyle(statusBlockRef.current).lineHeight
                                : 'auto',
                            ...(!openAllStatus
                                ? {
                                      padding: 0,
                                      minWidth: 'unset',
                                      position: 'relative',
                                  }
                                : {}),
                        }}
                        onClick={() => {
                            setOpenAllStatus((prev) => !prev);
                        }}
                    >
                        {openAllStatus ? (
                            <Translate i18nKey={'pryaniky.profile.hide.else'} />
                        ) : (
                            <>
                                <ShowMoreElipsesBox>...</ShowMoreElipsesBox>
                                <ShowMoreTextTypography>
                                    <Translate i18nKey={'pryaniky.profile.else'} />
                                </ShowMoreTextTypography>
                            </>
                        )}
                    </ShowMoreButton>
                )}
            </StatusTypography>
            {isUserPageOwner && (
                <>
                    <Button
                        id={popoverId}
                        size="small"
                        variant="text"
                        sx={{ mb: 1, p: 0 }}
                        onClick={(e) => isUserPageOwner && handleStatusClick(e)}
                        className={cnUserDataBlock('ChangeStatusButton')}
                    >
                        <Typography textTransform={'none'} variant="body1" color="primary" textAlign="left">
                            {Translate.t({ i18nKey: 'pryaniky.profile.addStatus' })}
                        </Typography>
                    </Button>

                    <StatusPopover
                        popoverId={popoverId}
                        anchorEl={anchorEl}
                        open={open}
                        handleStatusClose={handleStatusClose}
                        saveStatus={saveStatus}
                        statusText={user.baseData.statusText}
                        onChangeStatus={onChangeStatus}
                    />
                </>
            )}
            {!!mainUserOrgChart ? (
                <>
                    {!!mainUserOrgChart.position && (
                        <SeparatedBox className={cnUserDataBlock('Position')}>
                            <GrayTypography variant="body1" color="GrayText">
                                <Translate i18nKey={'pryaniky.profile.position'} />:
                            </GrayTypography>
                            <BlackTypography variant="body1">{mainUserOrgChart.position.displayName}</BlackTypography>
                        </SeparatedBox>
                    )}
                    {!!mainUserOrgChart.unitHierarchy.length && (
                        <SeparatedBox className={cnUserDataBlock('UnitHierarchy')}>
                            <GrayTypography variant="body1" color="GrayText">
                                <Translate i18nKey={'pryaniky.profile.department'} />:
                            </GrayTypography>
                            <UserOrgchartBreadcrumbs>
                                {mainUserOrgChart.unitHierarchy.map((el) => (
                                    <Link href={generatePath(urls.users) + `?units=${el.id}`}>{el.displayName}</Link>
                                ))}
                            </UserOrgchartBreadcrumbs>
                        </SeparatedBox>
                    )}
                </>
            ) : (
                <>
                    {!!user.baseData.positionTags?.length && (
                        <SeparatedBox className={cnUserDataBlock('PositionTags')}>
                            <GrayTypography variant="body1" color="GrayText">
                                <Translate i18nKey={'pryaniky.profile.position'} />:
                            </GrayTypography>
                            <BlackTypography variant="body1">
                                {user.baseData.positionTags.map((v: any) => v.displayName).join(', ')}
                            </BlackTypography>
                        </SeparatedBox>
                    )}

                    {!!user.baseData.divisionTags?.length && (
                        <SeparatedBox className={cnUserDataBlock('DivisionTags')}>
                            <GrayTypography variant="body1" color="GrayText">
                                <Translate i18nKey={'pryaniky.profile.department'} />:
                            </GrayTypography>
                            <BlackTypography variant="body1">
                                {user.baseData.divisionTags.map((v: any) => v.displayName).join(', ')}
                            </BlackTypography>
                        </SeparatedBox>
                    )}
                </>
            )}
            {!!partUserOrgChart.length && (
                <SeparatedBox>
                    <PartOrgChartAccordion
                        className={cnUserDataBlock('PartUserOrgChart')}
                        title={
                            <Typography color={'primary'} variant="body1">
                                <Translate i18nKey={'pryaniky.users.orgChart.partTime'} />
                            </Typography>
                        }
                        sx={{
                            [`&:before`]: {
                                content: 'none',
                            },
                        }}
                    >
                        {partUserOrgChart.map((chart) => {
                            return !!chart.position || !!chart.unitHierarchy.length ? (
                                <SeparatedBox>
                                    {!!chart.position && (
                                        <SeparatedBox>
                                            <GrayTypography variant="body1" color="GrayText">
                                                <Translate i18nKey={'pryaniky.profile.position'} />:
                                            </GrayTypography>
                                            <BlackTypography variant="body1">
                                                {chart.position.displayName}
                                            </BlackTypography>
                                        </SeparatedBox>
                                    )}
                                    {!!chart.unitHierarchy.length && (
                                        <SeparatedBox>
                                            <GrayTypography variant="body1" color="GrayText">
                                                <Translate i18nKey={'pryaniky.profile.department'} />:
                                            </GrayTypography>
                                            <UserOrgchartBreadcrumbs className={'User'}>
                                                {chart.unitHierarchy.map((el) => (
                                                    <Link href={generatePath(urls.users) + `?units=${el.id}`}>
                                                        {el.displayName}
                                                    </Link>
                                                ))}
                                            </UserOrgchartBreadcrumbs>
                                        </SeparatedBox>
                                    )}
                                </SeparatedBox>
                            ) : null;
                        })}
                    </PartOrgChartAccordion>
                </SeparatedBox>
            )}
            <SeparatedBox>
                <FieldRenderProfileView
                    isAuthUserAdmin={isAuthUserAdmin}
                    fields={user.profileData.userHeadFieldsOriginal.filter(
                        (el: any) => el.field.showInHeadType === 'ShowInHeadFields'
                    )}
                />
            </SeparatedBox>
            {showCollapseble && (
                <SeparatedBox>
                    {!isInfoBlockCollapsed ? (
                        <Arrow up={isInfoBlockCollapsed} />
                    ) : (
                        <>
                            <FieldRenderProfileView
                                isAuthUserAdmin={isAuthUserAdmin}
                                fields={user.profileData.userHeadFieldsOriginal.filter(
                                    (el: any) => el.field.showInHeadType !== 'ShowInHeadFields'
                                )}
                            />

                            <Arrow up={isInfoBlockCollapsed} />
                        </>
                    )}
                </SeparatedBox>
            )}
        </PaperWrap>
    );
};
