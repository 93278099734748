import React, { FC, useEffect, useRef, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LMS
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import TestSummary from '../../components/TestSummary'
import ResultQuestionItem from '../../components/ResultQuestionItem'
import ScormAPI2004 from 'LMSModule/utils/scormAPI/scormAPI2004/scormAPI2004'
import ScormAPI12 from 'LMSModule/utils/scormAPI/scormAPI12/scormAPI12'
import BaseAPI from 'LMSModule/utils/scormAPI/baseAPI/baseAPI'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { Button } from 'uielements/src'

type IPresenterProps = LMS.ScormResult.IPresenterProps
type IOwnProps = LMS.ScormResult.IOwnProps
type IDispatchProps = LMS.ScormResult.IDispatchProps
type IStateProps = LMS.ScormResult.IStateProps
type IState = LMS.ScormResult.IState

type ValueType = string | number | boolean | null | undefined;
const setScormData = (
    scormCMI: { [key: string]: ValueType },
    apiRef: React.MutableRefObject<BaseAPI | null>,
    scormFileVersion: string
) => {
    if (scormFileVersion === 'scorm12') {
        apiRef.current = new ScormAPI12()
        Object.keys(scormCMI).forEach(key => {
            (apiRef.current as ScormAPI12).setCMIValue(key, scormCMI[key])
        })
    } else {
        apiRef.current = new ScormAPI2004()
        Object.keys(scormCMI).forEach(key => {
            (apiRef.current as ScormAPI2004).setCMIValue(key, scormCMI[key])
        })
    }
}

const getQuestions = (apiRef: React.MutableRefObject<BaseAPI | null>, scormFileVersion: string) => {
    if (scormFileVersion === 'scorm12') return (apiRef.current as ScormAPI12)?.cmi.interactions.childArray || []
    else return (apiRef.current as ScormAPI2004)?.cmi.interactions.childArray || []
}

const getResutValues = (scormCMI: { [key: string]: ValueType }, scormFileVersion: string) => {
    let rawValue = scormCMI['cmi.score.raw']
    let maxValue = scormCMI['cmi.score.max']
    if (scormFileVersion = 'scorm12') {
        rawValue = scormCMI['cmi.core.score.raw'] || rawValue
        maxValue = scormCMI['cmi.core.score.max'] || maxValue
    }
    const rightPercent = ((rawValue as any) || 0) - 0
    const max = ((maxValue as any) || 0) - 0

    const wrongPercent = max - rightPercent
    return {
        rightPercent: rightPercent / (max * 0.01),
        max,
        wrongPercent
    }
}

export const Presenter: FC<IPresenterProps> = ({
    className,
    scormCMI,
    fullscreen,
    unitContent,
    showScorm
}) => {
    // const ref = useRef<HTMLIFrameElement>(null)
    const apiRef = useRef<BaseAPI | null>(null)

    // useEffect(() => {
    setScormData(scormCMI, apiRef, unitContent.scormFileVersion)
    // }, [scormCMI])

    const questions = getQuestions(apiRef, unitContent.scormFileVersion)
    const { rightPercent, wrongPercent } = getResutValues(scormCMI, unitContent.scormFileVersion)

    return <div className={cnClassName({ fullscreen }, [className])}>

        {showScorm && <div className={cnClassName('NotData')}>
            <Button
                className={cnClassName('Btn', { type: 'scorm' })}
                main
                onClick={showScorm}>{tS('view.scorm.1')}</Button>
        </div>}
        {/* {window.PRN_SERVICE.dev_features ?
            <table className={cnClassName('Table')}>
                {Object.keys(scormCMI).map(key => <tr>
                    <td className={cnClassName('Key')}>{key}</td>
                    <td className={cnClassName('Value')}><div className={cnClassName('Content')}>{scormCMI[key]}</div></td>
                </tr>)}
            </table> :
            <h3>Курс завершен</h3>} */}

        {/* <h3 className={cnClassName('Title')}>Курс завершен</h3> */}
        {rightPercent === 0 && wrongPercent === 0 ?
            <h3 className={cnClassName('NotData')}>{tS('notResults.1')}</h3> :
            <TestSummary
                className={cnClassName('Summary')}
                rightPercent={Math.round(rightPercent)}
                wrongPercent={Math.round(wrongPercent)}
            />
        }

        <div className={cnClassName('Questions')}>
            {questions.map((value: any) => <ResultQuestionItem
                text={value.description}
                answersTexts={value.type === "fill-in" ? value.learner_response : ""}
                isRight={value.result === "correct"}
                isInfo={value.result === "neutral"}
            />)}
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
