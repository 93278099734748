import { connect } from 'react-redux';
import * as React from 'react';
import {
    mapStateToProps,
    IFiltersStateProps,
    mapDispatchToProps,
    IFiltersDispatchProps,
    cnFilters,
    IFiltersProps,
    IFiltersState,
    IFiltersOwnProps,
} from './Filters.index';
import './Filters.scss';
import { Selectize } from 'uielements/src';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import { IStateType as IState } from 'redux/store';
import { withRouter } from 'react-router';
import queryString from 'query-string';

export class FiltersPresenter extends React.Component<IFiltersProps, IFiltersState> {
    // public utils: any = utils;
    public defaulValues: Record<string, string> = {};
    public data: any[];
    public el: HTMLElement | null;
    public startOnDidMount: any[] = [];

    constructor(props: IFiltersProps) {
        super(props);
        // this.functionBind = this.functionBind.bind(this);
        // this.functionBind();
        this.state = {};
        this.startOnDidMount = [];
    }

    public componentDidMount() {
        this.startOnDidMount.forEach((f) => {
            f();
        });
    }

    public render() {
        const customClassNames = '';
        const { tag: TagName = 'div', children, className = '', data, context, ...props } = this.props;
        // const context = this.getContext();
        if (!data) return null;
        return (
            <TagName {...props} className={cnFilters({}, [customClassNames, className])}>
                {data &&
                    data.map((filter, i) => {
                        let { elements } = filter;
                        // elements = elements;
                        // elements = [];
                        // if(context && typeof(elements) === 'string') {
                        //   elements = context[elements];
                        // }
                        let filterChildren: any[] = [];
                        // filterChildren = filterChildren;
                        // if(context) {
                        //   if(filter.props && filter.props.children) {
                        //     filterChildren = filter.props.children.map((child: {[s: string]: any}, key: number) => {
                        //       if(child.type === 'button') {
                        //         const onCLick = () => {child.props.onClick(filter.name, this.contextVariable, filter.elements)};
                        //         if(filter.props.loadOndidMount) this.startOnDidMount.push(onCLick);
                        //         return <Button key={key} {...child.props} onClick={onCLick} children={context.loading === filter.name ? 'loading...' : child.props.children} />
                        //       }
                        //       return null
                        //     })
                        //   }
                        // }
                        return (
                            <Selectize
                                key={i}
                                onClick={this.handleChange}
                                active={
                                    elements.reduce(
                                        (acc: string, cur: any) =>
                                            context[cur.type] && context[cur.type] === cur.value ? cur.id : acc,
                                        ''
                                    )
                                    // context && context.filter ? context.filter.id : undefined
                                }
                                name={filter.name}
                                elements={elements || []}
                                children={filterChildren}
                            />
                        );
                    })}
                {children}
            </TagName>
        );
    }

    // private t(request: string, upper?: boolean, dataInText?: string) {
    //   return i18n.t(request, upper, dataInText).toString();
    // }

    // private functionBind() {}

    // public getContext: () => {[s: string]: any} = () => this.props.context[this.contextVariable[0]];

    public get contextVariables() {
        return this.props.relations || ['common'];
    }

    public getActive(idx?: number) {
        const { context } = this.props;
        if (!this.data) return undefined;
        if (idx !== undefined && this.data[0] && Array.isArray(this.data[0]))
            return this.data.reduce(
                (acc, cf) => [
                    ...acc,
                    cf.reduce((acc: string, cur: any, idx: number, arr: any[]) => {
                        if (context[cur.type] && context[cur.type] === cur.value) {
                            return cur.id;
                        }
                        if (idx === arr.length - 1 && !acc && this.defaulValues[cur.type] !== undefined) {
                            return this.defaulValues[cur.type];
                        }
                        return acc;
                        // context[cur.type] && context[cur.type] === cur.value ? cur.id : acc
                    }, undefined),
                ],
                []
            )[idx];
        return this.data.reduce((acc: string, cur: any, idx, arr) => {
            if (context[cur.type] && context[cur.type] === cur.value) {
                return cur.id;
            }
            if (idx === arr.length - 1 && !acc && this.defaulValues[cur.type] !== undefined) {
                return this.defaulValues[cur.type];
            }
            return acc;
        }, undefined);
    }

    public handleChange = (selected: ISelectizeItem) => {
        const { context } = this.props;
        // const { filter } = this.props.context;
        const same = context[selected.type] && context[selected.type] === selected.value;
        // additiona data to set filter
        const types = selected.type.split('/');
        const values = (selected.value || '').toString().split('/');
        const isMulti = types.length > 1;
        const value: { [key: string]: any } = isMulti
            ? types.reduce(
                  (a: any, c: any, idx: number) => ({
                      ...a,
                      [c]: !same ? values[idx] || this.defaulValues[c] || undefined : undefined,
                  }),
                  {
                      [selected.type]: !same
                          ? selected.value
                          : this.defaulValues[selected.type] !== undefined
                          ? this.defaulValues[selected.type]
                          : undefined,
                      ...(selected.clear || []).reduce(
                          (acc: any, key: string) => ({ ...acc, [key]: undefined }),
                          {} as Record<string, any>
                      ),
                  } as Record<string, string>
              )
            : {
                  [selected.type]: !same
                      ? selected.value
                      : this.defaulValues[selected.type] !== undefined
                      ? this.defaulValues[selected.type]
                      : undefined,
                  ...(selected.clear || []).reduce(
                      (acc: any, key: string) => ({ ...acc, [key]: undefined }),
                      {} as Record<string, any>
                  ),
              };
        this.props.history.push({
            search: `?${queryString.stringify(value)}`,
        });
        this.props.changeContextv3(
            this.contextVariables.reduce((acc: any, conID) => {
                acc[conID] = value;
                return acc;
            }, {} as { [s: string]: any })
        );
    };
}

export const Filters = withRouter(
    connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersOwnProps, IState>(
        mapStateToProps,
        mapDispatchToProps
    )(FiltersPresenter)
);
