import { Box, styled } from 'muicomponents/src';
import { ActionButton } from 'muicomponents/src/ItemsListDialog/ActionButton/ActionButton';

export const FiltersConteiner = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flexGrow: 1,
    width: '100%',
    ['& > *']: {
        width: '100%',
        flexGrow: 1,
        minWidth: '200px',
    },
}) as typeof Box;

export const MainConteiner = styled(Box)({
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
}) as typeof Box;

export const ResetBtn = styled(ActionButton)({
    minWidth: '40px',
    flexShrink: 0,
}) as typeof ActionButton;
