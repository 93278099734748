import React from 'react';
import { DialogBody } from 'muicomponents/src/DialogParts';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Typography } from 'muicomponents/src';
import { Translate } from 'localization';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link';
import { appGeneratePath } from 'utils/src/utils.path';
import { copyTextToClip, mainUrls } from 'utils';
import { TextItem } from 'muicomponents/src/TextItem/TextItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IBodyProps } from 'utils/src/DialogCreator';
import {
    cnOpenUsersList,
    ContactInfoBox,
    ContentBox,
    CopyIconButton,
    ItemBox,
    MainInfoBox,
} from './OpenUsersList.styled';

const OpenUsersList = ({ items, handleClose }: IBodyProps & { items: any[] }) => {
    return (
        <DialogBody
            className={cnOpenUsersList('')}
            onClose={handleClose}
            closeText={<Translate i18nKey="close" />}
            contentProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: 'fit-content',
                },
            }}
            header={<Translate i18nKey={`pryaniky.task.field.users`} />}
        >
            <ContentBox>
                {items.map((user) => {
                    return (
                        <ItemBox>
                            <Avatar src={user.imgUrl} />
                            <MainInfoBox>
                                <Link
                                    to={appGeneratePath(mainUrls.user.id, {
                                        id: user?.id,
                                    })}
                                >
                                    {user?.displayName}
                                </Link>
                                {user?.position && (
                                    <Typography color={'text.secondary'} variant="caption">
                                        {user?.position}
                                    </Typography>
                                )}
                            </MainInfoBox>
                            <ContactInfoBox>
                                {user.eMail && (
                                    <TextItem title={Translate.t({ i18nKey: 'pryaniky.profile.email' })} splitter="">
                                        <Link href={'mailto:' + user.eMail}>{user.eMail}</Link>
                                        <CopyIconButton size={'small'} onClick={() => copyTextToClip(user.eMail)}>
                                            <ContentCopyIcon sx={{ fontSize: '8px' }} />
                                        </CopyIconButton>
                                    </TextItem>
                                )}

                                {user.phone && (
                                    <TextItem title={Translate.t({ i18nKey: 'pryaniky.profile.phone' })}>
                                        <Link href={'tel:' + user.phone} children={user.phone} />
                                        <CopyIconButton size={'small'} onClick={() => copyTextToClip(user.phone)}>
                                            <ContentCopyIcon sx={{ fontSize: '8px' }} />
                                        </CopyIconButton>
                                    </TextItem>
                                )}
                            </ContactInfoBox>
                        </ItemBox>
                    );
                })}
            </ContentBox>
        </DialogBody>
    );
};

export const openUsersList = createModal(OpenUsersList, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
        },
    },
    TransitionComponent,
    scroll: 'body',
});
