import React, { FC, useEffect, useLayoutEffect } from "react";
import { useUpdatableRef } from "utils/src/hooks";
import { SelectedContextProps } from "./SelectedContext.index";
import { SelectedContextSettingsType } from "./Settings/SelectedContextSettings.index";

const contextSettingsKeysDiff: {
    [key in keyof NonNullable<SelectedContextProps['context']>]-?: keyof NonNullable<SelectedContextProps['settings']>
} = {
    newsTypes: 'postType',
    oneNewsType: 'postType',
    workflowId: 'postType'
};

export const SelectedContext: FC<SelectedContextProps> = ({
    context,
    settings,
    updateContext,
    children = null
}) => {

    const contextRef = useUpdatableRef(context || {});

    useLayoutEffect(() => {
        const differentKeys = Object.typedKeys(contextSettingsKeysDiff).reduce((a, key) => {
            if(
                contextRef.current?.[key] !== settings?.[contextSettingsKeysDiff[key]]?.id
            ) {
                return [...a, key];
            }
            return a;
        }, [] as any as (keyof (typeof contextRef['current']))[]);
        if(!differentKeys.length) return;
        const newContext: SelectedContextProps['context'] = {};
        differentKeys.forEach((key) => {
            newContext[key] = settings?.[contextSettingsKeysDiff[key]]?.id;
        });
        updateContext?.(newContext);
    }, [settings]);

    useEffect(() => {
        return () => {
            const values = Object.keys(contextSettingsKeysDiff).reduce((a, key) => {
                return {
                    ...a,
                    [key]: undefined
                }
            }, {} as NonNullable<SelectedContextProps['context']>);
            updateContext?.(values);
        };
    }, []);

    return <>{children}</>;
};