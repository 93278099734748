import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

export const cnSettingsActueleArticles = cn('ActueleArticles');

export namespace SettingsWikiActueleArticlesWidgetWidget {
    export interface Settings {
        hideIfNoData: boolean;
        hideName: boolean;
        hideDescription: boolean;
        collection: { [key: string]: any } | null;
        rubric: { [key: string]: any }[] | null;
        name: string;
        sort: string;
        /**
         * loaded count of items
         */
        countShowItems?: number;
    }
    export interface Props extends IClassNameProps {
        settings: Settings;
        isEdit: boolean;
    }
}
