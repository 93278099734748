import moment from 'moment';
import { FieldsEditor } from 'muicomponents/src/FieldRender';
import { IField } from 'muicomponents/src/FieldRender/interfaces';
import { changeField, getFieldsByProperty } from 'muicomponents/src/FieldRender/utils';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { useDidUpdateEffect, useLazyQueryEx } from 'utils/src/hooks';
import { getCalendarEventsRequestV4 } from 'utils/src/requests/requests.calendar';
import { useWorkflowsEdit } from './Workflows.hooks';
import './Workflows.scss';

const prepareI18nKey = (key: string) => `pryaniky.news.create.ideas.${key}`;

const changeAllFieldByType = (
    fields: IField.AdditionalField[],
    type: string,
    mapper: (field: IField.Field) => IField.Field
) => {
    let afv = [...fields];
    const filtren = getFieldsByProperty(afv, {
        property: 'type',
        value: type,
    });
    filtren.forEach((v) => {
        afv = changeField(afv!, mapper(v));
    });
    return afv;
};

const NewsTypeWorkflowsPresenter: FC<{}> = ({}) => {
    const { creatorId } = useContext(NewsCreateContext);

    const { data, errors, clearEditor, postType, changeCreator, changeNews, formSettings } =
        useWorkflowsEdit(creatorId);

    const { additionalFields } = data;
    // console.log("formSettings", formSettings);
    const [fieldsErrors, setFieldsErrors] = useState<any>();

    ///////
    const { result, send } = useLazyQueryEx(getCalendarEventsRequestV4);
    const afv = useRef(additionalFields?.additionalFieldsValues);
    afv.current = additionalFields?.additionalFieldsValues;

    useEffect(() => {
        if (postType?.renderParams?.calendar?.id && afv.current) {
            send({
                start: '1970-01-01T00:00:00.000Z',
                end: '2090-01-01T00:00:00.000Z',
                cIds: [postType.renderParams?.calendar?.id],
            });
            afv.current = changeAllFieldByType(afv.current, 'DateTimeRange', (v) => ({
                ...v,
                settings: {
                    ...v.settings,
                    minDateTime: moment(),
                    calendar: postType?.renderParams?.calendar,
                },
            }));
            changeNews({
                additionalFields: {
                    ...(additionalFields as any),
                    additionalFieldsValues: afv.current as any,
                },
            });
        }
    }, [postType?.renderParams?.calendar?.id]);

    useEffect(() => {
        afv.current = changeAllFieldByType(afv.current!, 'DateTimeRange', (v) => ({
            ...v,
            settings: {
                ...v.settings,
                shouldDisableTime: result?.events.map((v) => ({
                    start: new Date(v.start),
                    end: new Date(v.end),
                })),
            },
        }));
        changeNews({
            additionalFields: {
                ...(additionalFields as any),
                additionalFieldsValues: afv.current as any,
            },
        });
    }, [result]);
    ///////////

    useDidUpdateEffect(() => {
        console.log('useDidUpdateEffect', fieldsErrors);
        changeCreator({
            errors: {
                ...errors,
                ...fieldsErrors,
            },
        });
    }, [fieldsErrors]);

    if (!additionalFields) {
        console.error(`workflow "additionalFields" is undefined`);
        return null;
    }
    // console.log(
    //   'afv.current', afv.current
    // )
    return (
        <>
            <FieldsEditor
                fields={afv.current || []}
                onChange={(fields, newErrors) => {
                    changeNews({
                        additionalFields: {
                            ...additionalFields,
                            additionalFieldsValues: fields,
                        },
                    });
                    setFieldsErrors(newErrors);
                }}
                globalTagsCollection={'workflows'}
                errors={errors}
            />
        </>
    );
};

export const NewsTypeWorkflows = NewsTypeWorkflowsPresenter;
// export const NewsTypeWorkflows = connect(
//     mapNewsTypeWorkflowsStateToProps,
//     mapNewsTypeWorkflowsActionsToProps
// )(NewsTypeWorkflowsPresenter);

export const initNewsWorkflowsCreator = () =>
    ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeWorkflows />, 'workflow');
