import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CakeIcon from '@mui/icons-material/Cake';
import { Moment } from 'moment';
import { Box } from 'muicomponents/src';
import { styled } from 'muicomponents/src/mui/system';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import urls from 'routes/urls';
import { SCalendar, TValue } from 'utils/src';
import { appGeneratePath } from 'utils/src/utils.path';
import { useClendarEvents } from './CalendarMini.hooks';
import { SettingsCalendarMiniWidget, cnCalendarMini } from './CalendarMini.index';
import './CalendarMini.scss';
import { StyledBox } from './CalendarMini.styled';
import { initWidgetAddition, initWidgetSettings } from './CalendarMiniSettingsDialog/CalendarMiniSettingsDialog';

initWidgetAddition();
initWidgetSettings();

const Root = styled('div')({
    display: 'block',
    position: 'relative',
});

const Dots = styled('div')({
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
});

const Dot = styled('div')({
    backgroundColor: 'black',
    width: 7,
    borderRadius: '50%',
    boxSizing: 'border-box',
    border: '1px solid #d3d9e0',
    margin: 1,
    '&:before': {
        content: '""',
        float: 'left',
        paddingTop: '100%',
        display: 'block',
    },
});

const Item = styled(Box)({
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: 4,
}) as typeof Box;

const DayEvents: FC<{
    date: string;
    calendarsParams?: { cIds?: SCalendar['id'][] };
    events?: TValue<ReturnType<typeof useClendarEvents>['events']>;
}> = ({ date, calendarsParams, events = [], children }) => {
    const history = useHistory();

    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
        <Tooltip
            open={tooltipOpen}
            onOpen={() => events.length && setTooltipOpen(true)}
            onClose={() => setTooltipOpen(false)}
            arrow
            title={events.map((event) => {
                return (
                    <Item
                        key={event.id}
                        sx={{
                            bgcolor: event.color,
                        }}
                    >
                        {event.componentRenderName === 'birthday' && (
                            <CakeIcon sx={{ color: `color-mix(in srgb, ${event.color} 25%, white) !important` }} />
                        )}
                        {event.displayName}
                    </Item>
                );
            })}
        >
            <Root
                className={cnCalendarMini('Day')}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const prepairedDate = new Date(date).toISOString();
                    history.push(
                        appGeneratePath(
                            urls.calendar,
                            {},
                            { defaultDate: prepairedDate, defaultView: 'day', ...calendarsParams }
                        )
                    );
                }}
            >
                {children}
                <Dots className={cnCalendarMini('Dots')}>
                    {events.map((event) => {
                        return (
                            <Dot
                                style={{ backgroundColor: event.color || undefined }}
                                className={cnCalendarMini('Dot')}
                            />
                        );
                    })}
                </Dots>
            </Root>
        </Tooltip>
    );
};

export const CalendarMini: FC<SettingsCalendarMiniWidget.Props> = ({ widgetContext, settings }) => {
    const date = useMemo(() => new Date(), []);

    const { events, setDates } = useClendarEvents(
        date,
        widgetContext.gId,
        settings?.calendar?.map((el) => el.id)
    );

    const calendarsDayAdditionalParameters = useMemo(() => {
        if (!settings?.calendar || !settings?.calendar.length || settings?.calendar.length > 1) return {};
        return { cIds: settings?.calendar?.map((el) => el.id) };
    }, [settings?.calendar]);

    return (
        <StyledBox>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    className={cnCalendarMini()}
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={() => {}}
                    onMonthChange={(date) => {
                        if (!date) return;
                        const dt = date as any as Moment;
                        setDates({ start: dt.startOf('month').toISOString(), end: dt.endOf('month').toISOString() });
                    }}
                    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                        const date = (day as any as Moment)?.format('yyyy-MM-DD[T00:00:00Z]');
                        const evts = events[date];
                        return isInCurrentMonth ? (
                            <DayEvents date={date} events={evts} calendarsParams={calendarsDayAdditionalParameters}>
                                {dayComponent}
                            </DayEvents>
                        ) : (
                            dayComponent
                        );
                    }}
                    disableToolbar={true}
                />
            </MuiPickersUtilsProvider>
        </StyledBox>
    );
};
