import React from 'react';
import './Widget_type_weekblock.scss';
import WidgetPresenter from '../../Widget';
import { cnWidgetMui, IWidgetProps } from '../../Widget.index';
import { widgets } from '../../../../i.widgets';
import { WeekBlock } from 'blocks/WeekBlock';
import { getTypeForWidgetComponent, WidgetsTypes } from 'utils/src/widgets/types';

const type = getTypeForWidgetComponent(WidgetsTypes.weekblock);

export default class WidgetTypeWeekblockPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;

    const item = (this.props.widget.data || {})[this.props.widget.settings?.selectedItem]
    return (
      <widgets.components.common {...this.props} className={cnWidgetMui({ type })}>
        <WeekBlock item={item} />
      </widgets.components.common>
    );
  }
}
