import * as React from 'react';
import { IEditorTypeNewsProps } from './news.index';
import { CreativetasksendNews, defaultCreativetasksendNews } from '../../../Creativetasksend.type'
import { Editor } from '../../Editor'
import { BaseValidator, baseValidate } from '../../../../Base.validate'
import * as utils from 'utils/src/utils';
import { EditorState } from 'draft-js';

export const EditorTypeNews: React.FC<IEditorTypeNewsProps> = ({ onChange, data }) => {
    let localData = data as any;
    if (data.newstype === -1) {
        localData = { ...utils.cloneObject(defaultCreativetasksendNews), text: EditorState.createEmpty() };
    }

    const onChangeText = (text: string) => {
        localData.text = text; 
        const bv = new BaseValidator<any>()
        bv.setRules(baseValidate)
        bv.addData(localData)
        onChange(localData, bv.validation().length === 0);
    }
    return <Editor
        onChangeText={onChangeText}
        data={localData} />
}
