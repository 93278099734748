import { Box } from '@material-ui/core';
import { styled } from 'muicomponents/src';

import { Edit as EditIcon } from 'muicomponents/src/Icons';

export const LoaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 150,
    height: '100%',
    zIndex: 5,
}) as typeof Box;

export const ReplyTitleBox = styled(Box)({
}) as typeof Box;

export const ReplyTimeTextBox = styled(Box)({
    display: 'flex',
    gap: 4,
}) as typeof Box;

export const ReplyEditIcon = styled(EditIcon)({
    fontSize: '1.15rem',
}) as typeof Box;
