import { Translate } from 'localization';
import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import React, { ComponentProps, FC, useContext, useRef, useState } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import './Ideas.scss';

import { PrepareErrorText } from 'News/utils/utils.validate';
import { useDidUpdateEffect } from 'utils/src/hooks';

import { clone, differenceWith, isEqual } from 'lodash';
import { FieldsEditor } from 'muicomponents/src/FieldRender';
import { TextField as TextFieldC } from 'muicomponents/src/TextField';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { memoizedComponent } from 'News/creator/utils.sender';
import { baseIdeas } from './Ideas.constants';
import { useIdeasEdit } from './Ideas.hooks';
import { NIdeas } from './Ideas.interface';
import { converAdditionalValuesToAdditionalValuesV1, converAdditionalValuesV1ToAdditionalValues } from './Ideas.sides';

const prepareI18nKey = (key: string) => `pryaniky.news.create.ideas.${key}`;

const useV1Fields = (values: NIdeas.Create['idea']['additionalFieldsValues'], reconvert: any) => {
    const [additionalFields, setAdditionalFields] = useState({
        fields: converAdditionalValuesV1ToAdditionalValues(clone(values || [])),
        errors: {},
    });

    useDidUpdateEffect(() => {
        setAdditionalFields({
            fields: converAdditionalValuesV1ToAdditionalValues(clone(values || [])),
            errors: {},
        });
    }, [reconvert]);

    const onChange: ComponentProps<typeof FieldsEditor>['onChange'] = (fields, errors) => {
        setAdditionalFields({ fields, errors });
    };

    return {
        additionalFieldsState: additionalFields,
        onChange,
    };
};

const TextField = memoizedComponent(TextFieldC);
const DraftInput = memoizedComponent(DraftInputC);

const NewsTypeIdeasPresenter: FC<{}> = ({}) => {
    const { creatorId } = useContext(NewsCreateContext);

    const { data, renderParams, errors, clearEditor, sended, changeCreator, changeNews } = useIdeasEdit(creatorId);

    let { text: currentNewsText, draftRawState, header, idea = baseIdeas.idea } = data;

    if (!idea) {
        idea = baseIdeas.idea;
    }

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const { additionalFieldsValues } = idea;

    const { state, draftState, setDraftState } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if (!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw,
        });
    }, [state]);

    const { additionalFieldsState, onChange } = useV1Fields(additionalFieldsValues, sended);

    const { fields, errors: fieldsErrors } = additionalFieldsState;

    useDidUpdateEffect(() => {
        const fieldsV1 = converAdditionalValuesToAdditionalValuesV1(fields);
        const editedFields = additionalFieldsValues
            ?.map((cat, idx) => {
                const cat2 = fieldsV1[idx];
                const v = differenceWith(cat2.v, cat.v, isEqual);
                return v;
            })
            .reduce((a, c) => [...a, ...c], []);
        if (!editedFields || !editedFields.length) return;
        changeNews({
            idea: {
                ...idea,
                additionalFieldsValues:
                    additionalFieldsValues?.map((cat) => {
                        return {
                            ...cat,
                            v: cat.v.map((field) => {
                                if (editedFields.map((el) => el.fieldId).includes(field.fieldId)) {
                                    const finded = editedFields.find((el) => el.fieldId === field.fieldId) || field;
                                    return clone(finded);
                                }
                                return field;
                            }),
                        };
                    }) || [],
            },
        });
        changeCreator({
            errors: {
                ...errors,
                ...fieldsErrors,
            },
        });
    }, [fields, fieldsErrors]);

    const draftCommonProps = useDraftEditorStyle();
    return (
        <>
            <TextField
                fullWidth
                label={<Translate i18nKey={prepareI18nKey('header')} />}
                value={header}
                onChange={(e) => changeNews({ header: e.target.value })}
                error={Boolean(errors.header)}
                helperText={
                    // TODO check types in BaseNews.text
                    Boolean(errors.header) && PrepareErrorText(errors.header)
                }
                autoComplete="off"
            />
            <DraftInput
                {...draftCommonProps}
                label={<Translate i18nKey={prepareI18nKey('text')} />}
                disableToolbar={!renderParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={Boolean(errors.text)}
                helperText={
                    // TODO check types in BaseNews.text
                    Boolean(errors.text) && PrepareErrorText(errors.text as any)
                }
                clearValue={clearEditor}
            />
            <FieldsEditor
                autoComplete={'off'}
                fields={fields}
                onChange={(fields, newErrors) => {
                    onChange(fields, newErrors);
                }}
                globalTagsCollection={'ideas'}
                errors={errors}
            />
        </>
    );
};

export const NewsTypeIdeas = NewsTypeIdeasPresenter;

export const initNewsIdeasCreator = () =>
    ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeIdeas />, 'ideas');
