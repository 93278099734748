import { connect } from 'react-redux';
import * as React from 'react';
import { i18n, Translate } from 'localization';
import { Page } from '../../Page/Page';
import {
    mapStateToProps,
    IPagesNewStateProps,
    cnPagesNew,
    IPagesNewProps,
    IPagesNewState,
    PagesNewActionsToProps,
} from './PagesNew.index';
import './PagesNew.scss';
import { IAdditionalMenuItem } from 'utils/src/CommonRedux/base/reducer';
import { Icon } from 'uielements/src';
import { DialogWikiEdit } from '../../Dialogs/WikiCreateNew/WikiEdit/WikiEditNew';
import { PageHistory } from './../PageHistory/PageHistory';
import { PagesMenuMui } from '../PagesMenuMui/PagesMenuMui';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs/Breadcrumbs';
import { Button as MuiButton } from 'muicomponents/src/Button/Button';
import { Box, Typography } from 'muicomponents/src';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router';
import * as utils from 'utils/src/utils';
import { AdditionalBoxeis } from './styled';
import { ChevronRight, VisibilityOff, Archive } from 'muicomponents/src/Icons';
import { createPortal } from 'react-dom';
import Alert from '@mui/material/Alert';
import { IRequest } from 'utils/src/requests/service';
import { checkResponseStatus, API, getErrorText } from 'utils/src/utils';
import { toast } from 'react-toastify';
import { pageNames } from 'redux/pageNames';
import { useEnvironmentContex } from 'utils/src/EnvironmentContex';
import { formatDateSTD } from 'utils/src/utils.dates';
import { IStateType } from 'redux/store';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { PageBreadcrumbs } from '../PageBreadcrumbs/PageBreadcrumbs';

import { useSelector } from 'react-redux';

const top = 71;
let request: IRequest;

let breadcrumbsArr: string[] = [];
let breadcrumbsObjArr: { [key: string]: any }[] = [];
export const PagesNewPresenter: React.FC<IPagesNewProps> = ({
    getLoadedPage,
    changeVm,
    editShow,
    authUser,
    isAdmin,
    tag: TagName = 'div',
    className = '',
    children,
    match,
    updateActivePage,
}) => {
    let el: Element | Text | null;
    const history = useHistory();
    const environment = useEnvironmentContex();

    const [dt, setDT] = React.useState<any>();
    const [edit, setEdit] = React.useState<any>(history.location.search.startsWith('?version'));
    const editPage = useSelector((state: IStateType) => state.store.edit);

    const [hasRights, setHasRights] = React.useState<boolean>(false);
    const [isDraftState, setIsDraftState] = React.useState<boolean>(false);
    /**
     *
     * получает права, возвращает boolean
     * @returns
     */
    const getRights = () =>
        utils.API.system.sagas
            .getCheckRights('PagesV2')
            .then((value) => {
                if (value.error_code === 0) {
                    setHasRights(value.data);
                }
            })
            .catch(() => {
                console.error('error');
            });

    const publishPage = (id: string, flag: boolean) => {
        request = API.wikilist.setPagePublish(id, flag);
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                toast.success(<Translate i18nKey={'pryaniky.wikilist.sucsessfuly.published'} />);
                setIsDraftState(false);
                updateActivePage({ isDraft: !flag });
            } else {
                toast.error(getErrorText(d));
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    };

    const isDraftOrNot = React.useMemo(() => {
        return setIsDraftState(dt?.isDraft);
    }, [dt]);

    const initPageEdit = () => {
        setDT(getLoadedPage(`${pageNames.pages}/${match.params.id}`));
        getRights();
    };
    const customClassNames = '';

    const editToggle = (ev: React.SyntheticEvent<HTMLButtonElement>) =>
        changeVm('', { edit: setEdit(!edit), editType: '', saving: ev.currentTarget.name === 'save' });

    const virsionsCreationDate = dt?.versions?.find((el: any) => history.location.search.includes(el.id)) || undefined;

    const pageMenu = React.useMemo(() => {
        if (!dt || environment.isWebView) return null;
        const root = document.getElementById('LayoutRightMenu');
        return createPortal(
            <PagesMenuMui
                className={cnPagesNew('SettingsMenu', { id: match.params.id })}
                isWkilistModerator={hasRights || isAdmin}
                stayOpened={false}
                pageInfo={dt}
                isEditable={isAdmin || dt?.isEditable}
            />,
            root!
        );
    }, [dt, hasRights, environment.isWebView]);

    // коммент для мерджа:забираем новые хлебные крошки
    const whatBreadCrumbsToShow = React.useMemo(() => {
        return <PageBreadcrumbs dt={dt} isDraftState={isDraftState} matchId={match.params.id} />;
    }, [dt, isDraftState]);

    const AlertComponent = React.useMemo(() => {
        const root = document.getElementById('LayoutAlert');
        if (isDraftState || dt?.isArchived) {
            return createPortal(
                <Alert
                    className={cnPagesNew(isDraftState ? 'Alert isDraft' : 'Alert isArchived')}
                    icon={isDraftState ? <VisibilityOff fontSize="inherit" /> : <Archive fontSize="inherit" />}
                    severity="warning"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: top,
                        zIndex: 110,
                        '.MuiAlert-message': {
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            overflow: 'unset',
                            rowGap: '8px',
                            columnGap: '28px',
                        },
                    }}
                >
                    <Typography variant="body2">
                        {isDraftState ? (
                            <Translate i18nKey="pryaniky.wikilist.alert.isDraft" />
                        ) : (
                            <Translate i18nKey="pryaniky.wikilist.alert.Archive" />
                        )}
                    </Typography>
                    {!editPage && isDraftState && (
                        <MuiButton
                            sx={{
                                padding: '4px 10px',
                                fontSize: '13px',
                                minWidth: 'min-content',
                            }}
                            variant={'outlined'}
                            onClick={() => publishPage(dt?.id, dt?.isDraft)}
                        >
                            <Translate i18nKey="pryaniky.wikilist.alert.publish" />
                        </MuiButton>
                    )}
                    {editPage && <Translate i18nKey="pryaniky.wikilist.alert.publish.help" />}
                </Alert>,
                root!
            );
        }
    }, [dt, isDraftState, editPage]);

    return (
        <TagName ref={(el: any) => (el = el)} className={cnPagesNew({}, [customClassNames, className])}>
            {AlertComponent}

            {history.location.search.startsWith('?version') && virsionsCreationDate && (
                <AdditionalBoxeis className={cnPagesNew('Breadcrumbs', { id: match.params.id })}>
                    {edit ? (
                        <>
                            <Typography>
                                <Translate
                                    i18nKey={'pryaniky.list.wiki.version'}
                                    values={{ date: formatDateSTD(virsionsCreationDate.createdAt, true) }}
                                />
                            </Typography>
                            <Box>
                                <MuiButton
                                    sx={{ marginRight: '16px' }}
                                    variant="outlined"
                                    // sx={{ textTransform: "capitalize", fontSize: "16px" }}
                                    href={dt.url}
                                >
                                    <Translate i18nKey={'pryaniky.list.wiki.back.to.actual.version'} />
                                </MuiButton>
                                <MuiButton
                                    variant="contained"
                                    name="save"
                                    onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => editToggle(e)}
                                >
                                    <Translate i18nKey={'pryaniky.list.wiki.back.to.this.version'} />
                                </MuiButton>
                            </Box>
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Typography>
                                <Translate
                                    i18nKey={'pryaniky.list.wiki.version.saved'}
                                    values={{ date: formatDateSTD(virsionsCreationDate.createdAt, true) }}
                                />
                            </Typography>
                            <Box>
                                <MuiButton
                                    sx={{ fontSize: '1rem', textTransform: 'lowercase' }}
                                    variant="text"
                                    href={dt.url}
                                >
                                    <Translate i18nKey={'pryaniky.list.wiki.back.to.original.version'} />
                                </MuiButton>
                            </Box>
                        </Box>
                    )}
                </AdditionalBoxeis>
            )}

            {whatBreadCrumbsToShow}

            <Page
                className={cnPagesNew({ id: match.params.id })}
                page={{
                    name: pageNames.pages + '.' + match.params.id,
                    title: authUser.baseData.id === match.params.id ? i18n.t('me') : i18n.t('colleague'),
                    params: { id: match.params.id, authUser: authUser, pageId: match.params.id },
                }}
                cbAfterGetData={initPageEdit}
            />
            {children}

            {pageMenu}
        </TagName>
    );
};
export const PagesNew = withRouter(connect(mapStateToProps, PagesNewActionsToProps)(PagesNewPresenter));
