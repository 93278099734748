import { connect } from 'react-redux';
import React, { FC, Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import * as utils from 'utils/src/utils';
import { Translate } from 'localization';
import { cnPage, IPageProps, mapStateToProps, mapActionsToPropsPage, PageSignals } from './Page.index';
import './Page.scss';
import { widgets } from "i.widgets";
import { mapDispatchToProps } from '../../redux/connector';
import { WidgetTypeSkeleton } from 'blocks/Widget/_type/Widget_type_skeleton';
import { Prompt } from 'react-router';
import { Beforeunload } from 'react-beforeunload';
import { Widget } from 'Widgets_v2/Widget/Widget';
import { DialogHistoryComment } from 'blocks/Dialogs/HistoryComment/HistoryComment'
import { AppFooter } from 'blocks/AppFooter';
import { prepareMobilePageName } from 'redux/actions/Widgets';
import { EnvironmentContexWrapper } from 'utils/src/EnvironmentContex';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { getPageBaseType } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { PageTypes } from 'utils/src';
import { useSignal } from 'utils/src/signals';

const getLayout = () => document.getElementById('Layout');

const PagePr: React.FC<IPageProps> = ({
  page,
  tag: TagName = 'div',
  className,
  children,
  structure,
  layout,
  edit,
  getStructure,
  setStructure,
  backup,
  saving,
  cbAfterGetData,
  toggle
}) => {

  const getStructureRef = useRef(getStructure);
  getStructureRef.current = getStructure;

  const setStructureRef = useRef(setStructure);
  setStructureRef.current = setStructure;

  const backupRef = useRef(backup);
  backupRef.current = backup;

  const pageRef = useRef(page);
  pageRef.current = page;

  const structureRef = useRef(structure);
  structureRef.current = structure;

  const savingRef = useRef(saving);
  savingRef.current = saving;

  const cbAfterGetDataRef = useRef(cbAfterGetData);
  cbAfterGetDataRef.current = cbAfterGetData;

  const footer = useMemo(() => {
    if (!structure || structure.isHideFooter) return null;
    return <AppFooter />;
  }, [structure]);

  const Component = useMemo<typeof widgets.components.layout>(() => {
    if (layout?.version === '2') return Widget as any;
    if (layout?.type === widgets.types.grid) return widgets.components.grid;
    return widgets.components.layout
  }, [layout]);

  const getPage = useCallback((params: any, cb?: any, ignoreGetCache?: boolean) => {
    const pageName = prepareMobilePageName(pageRef.current.name);
    getStructureRef.current?.(
      pageName,
      {
        ...params,
        ...pageRef.current.params
      },
      cb,
      ignoreGetCache
    );
  }, []);

  const editPage = useCallback(() => {
    toggle('edit', true);
  }, []);

  useSignal(PageSignals.edit, editPage, [page.name]);

  const savePage = useCallback(() => {
    const pageBaseType = getPageBaseType(structureRef.current.type);
    if (pageBaseType === PageTypes.page || pageBaseType === PageTypes.file) {
      DialogHistoryComment({
        data: { comment: "" }
      })
      .then((value) => {
        setStructureRef.current({ ...structureRef.current, comment: value.comment });
        toggle('edit', false);
      })
      .catch((e) => {
        if(typeof e === 'object' && e.action === 1) {
          console.log('CANCEL');
        }
      })
    } else {
      setStructureRef.current(structureRef.current);
      toggle('edit', false);
    }
  }, []);

  useSignal(PageSignals.save, savePage, [page.name]);

  const cancelEdit = useCallback(() => {
    backupRef.current?.('restore');
    toggle('edit', false);
  }, []);

  useSignal(PageSignals.cancel, cancelEdit, [page.name]);

  /**
   * запрашиваем страницу при первом рендере и изменении имени
   */
  useLayoutEffect(() => {
    getPage(
      {},
      () => {
        if (edit) backup?.('make');
        cbAfterGetDataRef.current?.();
      }
    );
  }, [page.name]);

  /**
   * при изменении статуса редактирования страницы перезагружаем страницу с флагом edit = true
   */
  useDidUpdateEffect(() => {
    if(edit) {
      getPage(
        {
          edit
        },
        () => {
          if (edit) backup?.('make');
          cbAfterGetDataRef.current?.();
        },
        true
      );
    }
  }, [edit]);

  /**
   * проверяем изменение layout для установки правильного класса 'FullWidth'
   */
  useEffect(() => {
    if(!layout) return;
    if (
      // если первая структура это сетка то растягиваем на всю ширину
      (layout.type === widgets.types.grid) ||
      // если первая структура это колонки, но при этом в настройках есть галка fullWidth то растягиваем на всю ширину
      (
        (
          layout.type === widgets.types.layout ||
          layout.type === widgets.types.layout + '/horizontal'
        )
        && layout.settings?.fullWidth)
    ) {
      const elem = Array.from(getLayout()?.children || []).find((el) => el.classList.contains('Layout-Inner'));
      elem?.classList.add('FullWidth');
    } else {
      const elem = Array.from(getLayout()?.children || []).find((el) => el.classList.contains('Layout-Inner'));
      elem?.classList.remove('FullWidth');
    }
  }, [layout]);

  /**
   * will unmount
   */
  useLayoutEffect(() => {
    return () => {
      if (!layout) return;
      if (layout.type !== widgets.types.grid) {
        const elem = Array.from(getLayout()?.children || []).find((el) => el.classList.contains('Layout-Inner'));
        elem?.classList.remove('FullWidth');
      }
    }
  }, []);

  const type = prepareMobilePageName(utils.capitalize(page.name)).replace(/\./gi, '-');

  const MobileWrapper = useCallback<FC>((props) => {
    if(!type.startsWith(utils.mobileBuilderPrefix)) return <Fragment {...props} />;
    return <EnvironmentContexWrapper {...props} isMobileOS={true}/>
  }, [type]);

  return (
    <MobileWrapper>
      <TagName className={cnPage({ type }, [className])}>
        {edit && <Beforeunload onBeforeunload={() => Translate.t({ i18nKey: 'pryaniky.beforeunload.text' })} />}
        <Prompt
          when={edit}
          message={Translate.t({ i18nKey: 'pryaniky.beforeunload.text' })}
        />
        {
          layout ?
            <Component key={layout.id} id={layout.id} data-id={layout.id} pageData={page.data || {}} type={layout.type.split('/')[1]} />
            :
            <WidgetTypeSkeleton />
        }
        {
          typeof children === 'function' ?
            children() :
            children
        }
        {footer}
      </TagName>
    </MobileWrapper>
  );
};

export const Page = connect(
  mapStateToProps,
  mapActionsToPropsPage
)(PagePr);