import { Box, Button, Paper } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { styled } from 'muicomponents/src/mui/system';
import { ListItemDescription } from '../ItemList/ItemList.styled';
import { Link } from 'muicomponents/src/Link/Link';

export const GridItemTitle = styled(Link)({
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
    textDecoration: 'none',
    fontSize: 16,
}) as typeof Link;

export const GridItemBox = styled(Paper)<{ countColumns: number }>(({ countColumns }) => ({
    backgroundColor: '#fff',
    width: `calc( (100% - ${24 * (countColumns - 1)}px) / ${countColumns} )`,
    position: 'relative',
}));

export const GridItemButton = styled(Button)({
    padding: '0',
    '&:hover': {
        backgroundColor: '#F5F5F5',
    },
    width: '100%',
    height: '190px',
    background: '#F5F5F5',
}) as typeof Button;

export const GridItemAvatar = styled(Avatar)({
    height: '128px',
    width: '128px',
}) as typeof Avatar;

export const GridItemContent = styled(Box)({
    padding: '12px 16px 20px',
    color: 'rgba(0, 0, 0, 0.6)',
}) as typeof Box;

export const GridItemUserCount = styled(Box)(({ borderColor }) => ({
    borderRadius: '40px',
    position: 'absolute',
    bottom: '0',
    right: '0',
    border: `1px solid #0066CC`,
    display: 'flex',
    alignItems: 'center',
    background: '#F5F5F5',
    padding: '2px 6px',
    columnGap: '4px',
}));

export const GridItemDescription = styled(ListItemDescription)({
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
    fontSize: 14,
    lineHeight: '18px',
}) as typeof ListItemDescription;
