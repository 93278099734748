import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from './index';

interface IUsers {
    users: string;
    filters: string;
}

export const users = (): IWidget<IColumn[]> => {
    const ids = guids<IUsers>(['users', 'filters']);
    return generateWidget({
        type: widgets.types.layout,
        data: [
            generateColumn({
                items: [
                    generateWidget({
                        id: ids.users,
                        type: widgets.types.list + '/users',
                    }),
                ],
                styles: {
                    width: '72%',
                },
            }),
            generateColumn({
                items: [
                    generateWidget({
                        id: ids.filters,
                        type: widgets.types.filters + '/users',
                    }),
                ],
                styles: {
                    width: '28%',
                },
                mobileHidden: true,
            }),
        ],
    });
};
