import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { defaultTimelineFilter as filterAll, myTimelineFilter } from 'utils/src/constants.prn';
import { ITimlineFilterIncommingData } from 'utils/src/requests/models/admin.baseAdmin';
import * as utils from 'utils/src/utils';
import { useContextFiltersChange } from '../Filters.hooks';
import { IFiltersProps } from '../Filters.index';

export interface IFilterItem {
    displayName: string;
    enable: boolean;
    id: string;
    moduleEnable: boolean;
    position: number;
}

export interface IItemsViewDat {
    isAdmin: any;
    isSubnetworks: boolean | null;
    enableAutoRegistration: boolean;
}

export interface ITimlineFilterData {
    id: string;
    type: string;
    title: string;
    value: string;
    adminOnly: boolean;
    multiSelectable: boolean;
    isActive: boolean;
}
// подготовленный объект только категорий. он не учавствует в изменении контекста на пряму, но активирует работу чекбоксов.
export const onlyCat = {
    id: 'categories',
    type: 'newsTypes',
    title: 'pryaniky.filter.feed.categories',
    value: 'categories',
    adminOnly: false,
    multiSelectable: false,
    isActive: false,
};

export const useTimelineFilter = ({
    context,
    // props,
    changeContextv3,
    relations,
}: {
    context: IFiltersProps['context'];
    relations: IFiltersProps['relations'];
    changeContextv3: IFiltersProps['changeContextv3'];
    // props: Pick<IFiltersProps, 'relations' | 'changeContextv3'>
}) => {
    const currentUser = useSelector(getCurrentUser);

    const defaultFilter = currentUser?.profileData?.showMyNewsByDefault ? myTimelineFilter : filterAll;

    // state part для множественного фильтра
    const [timlineData, setTimlineData] = useState<ITimlineFilterData[]>([]);

    // state Part для обычного одиночного фильтра публикаций
    const [defaultTimelineData, setDefaultTimelineData] = useState<ITimlineFilterData[]>([]);

    // массив куда приходят одиночные категории() id: 'categories' запускает чекбоксы множественного фильтра
    const [others, setOthers] = useState<ITimlineFilterData[]>([]);

    // QUESTION типизировать возможно вобще убрать
    // массив с выбраными чекбоксами множественных типов публикаци, отправится в контест, когда будет нажата кнопка применить
    const [categoriesArr, setCategoriesArr] = useState<ITimlineFilterData[]>([]);

    // QUESTION обудить актыальность этого стэйта, можнт будет правильнее использовать useRef как компоненетную переменную
    // флаг дающий разрешение отправку в контекст множественных фильтров.
    // const [letUseMultiValues, setLetUseMultiValues] = useState<boolean>(false);

    // скрывет\показывает все типы публикаций
    const [typesListShown, setTypesListShown] = useState<boolean>(false);

    useEffect(() => {
        getAvaliablePostTypes();
    }, []);

    const location = useLocation();
    const onChange = useContextFiltersChange<typeof context>(relations);

    useEffect(() => {
        if (!context.filter) return;

        const newTimelineData = timlineData.map((item) => {
            const savedEl = context.filter?.find((el: any) => el.id === item.id);
            return savedEl ? { ...item, isActive: savedEl.isActive } : { ...item, isActive: false };
        });

        const isSameFilters = timlineData.every((item, index) => item.isActive === newTimelineData[index].isActive);

        if (isSameFilters) return;

        setTimlineData(newTimelineData);
        setOthers(timlineData.findIndex((el) => el.id === context.filter?.[0]?.id) !== -1 ? [onlyCat] : []);
    }, [context.filter]);

    useEffect(() => {
        setDefaultTimelineData((prev) => (prev.find((item) => item.id === onlyCat.id) ? prev : [...prev, onlyCat]));

        const filters = localStorage.getItem('timlineFilters');
        const parsedFilters = filters && filters !== 'undefined' ? JSON.parse(filters) : {};
        const savedFilters = parsedFilters[location.pathname];

        if (savedFilters && savedFilters !== 'undefined') {
            if (timlineData.findIndex((el: any) => el.id === savedFilters[0]?.id) !== -1) {
                // if (timlineData.map(i=>i.id).includes(parsed[0].id)) {
                setOthers([onlyCat]);
                savedFilters.map((savedEl: any) =>
                    setTimlineData((prevState) =>
                        prevState.map((item: any) =>
                            item.id === savedEl?.id ? { ...item, isActive: savedEl?.isActive } : item
                        )
                    )
                );
            } else {
                change(savedFilters);
            }
        }
    }, [defaultTimelineData, location.pathname]);

    const letUseMultiValues = useRef<boolean>(false);

    useEffect(() => {
        const filters = localStorage.getItem('timlineFilters');
        let parsedFilters = filters && filters !== 'undefined' ? JSON.parse(filters) : {};

        if (Array.isArray(parsedFilters)) {
            parsedFilters = {};
        }

        if (context.filter) {
            parsedFilters[location.pathname] = context.filter;
            const activeTypes = JSON.stringify(parsedFilters);
            localStorage.setItem('timlineFilters', activeTypes);
        }
    }, [context.filter, location.pathname]);

    // функция удаления чипа с выбраным типом публикции
    const handleDelete = (id: string) => {
        // если приходит запрос на удаление одиночного вильтра то мы просто заменяем его на все
        if (defaultTimelineData.map((el) => el.id).includes(id)) {
            change([defaultFilter]);
            setCategoriesArr([]);
        } else {
            // QUESTION тут сначала фильтруется массив context?.filter. Потом проверяется длинна не отфильтрованного массива, а не измененного context?.filter, надо проверить правильность работы
            // в любом другом случае мы в начале фильтруем контекст убирая из него выбранный для удаления массив
            const arr = context?.filter?.filter((el: any) => el.id !== id);
            // и проверяем укак будет изменен контекст, если в контексте еще остались элементы из множественных типов (то засовываем в него отфильтрованный массив)
            // если не осталось(то изменяем контекст на all)
            context?.filter?.length > 1 ? change(arr) : change([defaultFilter]);

            // снимаем чекбоксы при удаление челез чипы.
            setTimlineData((prevState) =>
                prevState.map((item: any) => (item.id === id ? { ...item, isActive: false } : item))
            );
        }
    };

    //  useEffect создающий массив с чекнутыми множественными типами публикаций этот масив мы зальем в контекст при нажатии на кнопку "применить".
    useEffect(() => {
        setCategoriesArr(timlineData.filter((el) => el.isActive));
    }, [timlineData]);

    // функция кнопки применить, которая будет менять контекст в функции change когда setLetUseMultiValues станет true.
    const useMultyValues = () => {
        letUseMultiValues.current = true;
        change(categoriesArr);
    };

    const getAvaliablePostTypes = () =>
        utils.API.baseAdmin.getEvaliblePostTypesForFilter(context.groupId).r.then((d) => {
            if (utils.checkResponseStatus(d)) {
                // QUESTION проверить типы
                let dataRespons = d.data?.data ? d.data.data : d.data;
                const respData: ITimlineFilterData[] = dataRespons.map((el: ITimlineFilterIncommingData) => ({
                    id: el.id,
                    type: 'newsTypes',
                    title: el.displayName,
                    value: el.id,
                    adminOnly: el.id === 'planned' ? true : false,
                    multiSelectable: el.multiSelectable,
                    isActive: false,
                }));

                setTimlineData(respData.filter((el: ITimlineFilterData) => el.multiSelectable === true));
                setDefaultTimelineData(respData.filter((el: ITimlineFilterData) => el.multiSelectable === false));
            }
        });

    const change = (selectedEl: ITimlineFilterData[]) => {
        if (!selectedEl || selectedEl?.length === 0) return;
        // изменяем одиночный тип с "Только категории" на другой выбранный (чтобы функция отработала по всем необходимым условиям)
        if (defaultTimelineData.map((el) => el.id).includes(selectedEl?.[0]?.id)) {
            setOthers(selectedEl);
            // снимаем чекбоксы
            setTimlineData((prevState) =>
                prevState.map((item) => (item.isActive ? { ...item, isActive: false } : item))
            );
        }
        // это проверка для типа публикации "Только категории". когда она включается чекбоксы становятся активны, и можно начинать выбирать мультифильтры.
        //  при выбори этой кнопки не меняется контекст если letUseMultiValues false.
        if (selectedEl?.[0]?.id === 'categories' && !letUseMultiValues.current) {
            setOthers(selectedEl);
        } else {
            letUseMultiValues.current = false;
            const type = selectedEl.reduce((acc: any, curr: any) => {
                return curr.type;
            }, '');
            onChange({
                filter: selectedEl,
                [type]: selectedEl.map((el: ITimlineFilterData) => el.value).join(','),
            });
        }
    };

    return {
        typesListShown,
        setTypesListShown,
        handleDelete,
        defaultTimelineData,
        change,
        others,
        timlineData,
        setTimlineData,
        setOthers,
        categoriesArr,
        useMultyValues,
        defaultFilter,
    };
};
