import React, { FC, memo, useState } from "react";

import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { ItemsListBody } from "muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody";
import { UsersListItem } from "muicomponents/src/ItemsListDialog/UserListItem/UserListItem";
import { Box, Typography } from "muicomponents/src";
import { SplitButton } from "muicomponents/src/SplitButton/SplitButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { i18n, Translate } from "localization";
import { Paper, Stack } from "@mui/material";
import { editUnitModal } from "../UnitEdit/UnitEdit";
import * as utils from "utils.project/utils.project";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getOrgchartDataFromServer,
  deleteStructureDataOrgchart,
  orgchartToggleDraggable,
  orgchartDragPosition,
  changeStructureDataOrgchart,
  saveStructureDataOrgchart,
  addSubUnit,
  orgchartSaveUsersInUnitAfterDrag,
  orgchartSaveAfterUnitPositionDrag,
  setOrgchartDraggingUnit,
  setOrgchartDraggingUnitToPosition,
  editSubUnit,
} from "../../../redux/sagas/orgchart/actions";
import { useDispatch, useSelector } from "react-redux";
import { IBaseDataType } from "muicomponents/src/ItemsListDialog/ItemsListDialog.index";
import { TListItemComponentProps } from "muicomponents/src/ItemsListDialog/BaseListItem/BaseListItem";
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import {
  cnTreeGroup,
  cnTreePosition,
  cnTreeRootPosition,
  cnTreeView,
} from "./TreeView.index";
import { useQueryEx } from "utils/src/hooks";
import {
  getOrgchartChildren,
  getOrgchartDirectpath,
  getOrgchartRoot,
} from "utils/src/requests/requests.orgchart";
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs/Breadcrumbs';
import { ChevronRight } from "muicomponents/src/Icons";

const AccordionSummaryShort = styled((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  padding: "0",
  justifyContent: "flex-start",
  "& .MuiAccordionSummary-content": {
    flexGrow: 0,
  },
  // '& .MuiAccordionSummary-content.Mui-expanded': {
  //     margin: '12px 0',
  // },
  // '& .MuiAccordionSummary-content': {
  //     margin: '14px 0',
  // },
}));

const AccordionDetailsShort = styled((props: AccordionSummaryProps) => (
  <AccordionDetails {...props} />
))(({ theme }) => ({
  padding: "8px 0 16px",
  // justifyContent: "flex-start",
  // "& .MuiAccordionSummary-content": {
  //   flexGrow: 0,
  // },
  // '& .MuiAccordionSummary-content.Mui-expanded': {
  //     margin: '12px 0',
  // },
  // '& .MuiAccordionSummary-content': {
  //     margin: '14px 0',
  // },
}));

const PositionsAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  // borderRadius: `8px`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const GroupAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  display: "flex",
}));

const FlatAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: `8px`,
  // '&:not(:last-child)': {
  //     borderBottom: 0,
  // },
  "&::before": {
    display: "none",
  },
}));

const getRootUnit = (units: any) => {
  const unitsArray = Object.values(units);
  const root = unitsArray.find((v: any) => !v.parent);
  return root;
};

const getRootUnits = (units: any, parentId?: string) => {
  const unitsArray = Object.values(units);
  const root = parentId
    ? unitsArray.filter((v: any) => v.id === parentId)
    : unitsArray.filter((v: any) => !v.parent);
  return root;
};

const unitsToTree = (unit: any, array: any[]): any => {
  return {
    ...unit,
    child: array
      .filter((v: any) => unit.id === v.parent)
      .map((v: any) => unitsToTree(v, array)),
  };
};

export function VacancyListItem<DT extends IBaseDataType = any>({
  item,
  unit,
  ...props
}: React.PropsWithChildren<TListItemComponentProps<DT> & { unit: any }>) {
  // console.log('VacancyListItem item', item)
  return (
    <UsersListItem
      {...props}
      item={{
        ...item,
        displayName: i18n.t("pryaniky.orgchart.tree.item.vacancy"), //'Открытая вакансия',
        position: item.description,
        division: unit.displayName,
      }}
      disableQR
    />
  );
}

const GroupActions: FC<{ data: any }> = memo(({ data }) => {
  const isAdmin = useSelector(getCurrentUserIsAdmin);
  const dispatch = useDispatch();

  let actions = [
    {
      content: i18n.t("pryaniky.orgchart.service.actions.AddHead"),
      id: "AddHead",
    },
    {
      content: i18n.t("pryaniky.orgchart.service.actions.AddSub"),
      id: "AddSub",
    },
    {
      content: i18n.t("pryaniky.orgchart.service.actions.AddHeadVacancy"),
      id: "AddHeadVacancy",
    },
    {
      content: i18n.t("pryaniky.orgchart.service.actions.AddSubVacancy"),
      id: "AddSubVacancy",
    },
    {
      content: i18n.t("pryaniky.orgchart.service.actions.AddSubUnit"),
      id: "AddSubUnit",
    },
    {
      content: i18n.t("pryaniky.orgchart.service.actions.deleteUnit"),
      id: "deleteUnit",
    },
    { content: i18n.t("pryaniky.orgchart.service.actions.edit"), id: "edit" },
  ];

  const clickAction = (action: string) => {
    if (action === "edit") {
      dispatch(editSubUnit({ id: data.id }));
      editUnitModal({
        parentUnitId: data.parent,
        editUnit: true,
        addNewUnitFromHeader: true,
      }).then((value: boolean) => {
        dispatch(saveStructureDataOrgchart({ data, thisIsMerge: value }));
      });
    }
    if (action === "deleteUnit") {
      utils.confirm({
        text: i18n.t("pryaniky.orgchart.confirm.delete.title.name"),
        onConfirm: () => dispatch(deleteStructureDataOrgchart(data.id)),
      });
    }
    if (action === "AddHead") {
      dispatch(editSubUnit({ id: data.id }));
      editUnitModal({
        addHeadOpen: true,
        parentUnitId: data.parent,
      }).then(() => {
        dispatch(saveStructureDataOrgchart({ data, thisIsMerge: false }));
      });
    }
    if (action === "AddSub") {
      dispatch(editSubUnit({ id: data.id }));
      editUnitModal({
        addSubOpen: true,
        parentUnitId: data.parent,
      }).then(() => {
        dispatch(saveStructureDataOrgchart({ data, thisIsMerge: false }));
      });
    }
    if (action === "AddHeadVacancy") {
      dispatch(editSubUnit({ id: data.id }));
      editUnitModal({
        addHeadOpen: true,
        addVacancy: true,
        parentUnitId: data.parent,
      }).then(() => {
        dispatch(saveStructureDataOrgchart({ data, thisIsMerge: false }));
      });
    }
    if (action === "AddSubVacancy") {
      dispatch(editSubUnit({ id: data.id }));
      editUnitModal({
        addSubOpen: true,
        addVacancy: true,
        parentUnitId: data.parent,
      }).then(() => {
        dispatch(saveStructureDataOrgchart({ data, thisIsMerge: false }));
      });
    }
    if (action === "AddSubUnit") {
      dispatch(addSubUnit({ parent: data.id }));
      editUnitModal({
        addNewUnit: true,
      }).then(() => {
        dispatch(saveStructureDataOrgchart({ data, thisIsMerge: false }));
      });
    } else {
      console.log(action);
    }
  };

  if (!isAdmin) return null;

  return (
    <SplitButton
      variant="text"
      icon={<MoreVertOutlinedIcon />}
      size={"small"}
      handleMenuItemClick={(e, item) => {
        e.preventDefault();
        e.stopPropagation();
        clickAction(item.id);
      }}
      options={actions}
    />
  );
});

const UserList: FC<{ positions: any; rootPositionIds: string[]; unit: any }> =
  memo(({ positions, rootPositionIds, unit }) => {
    // console.log('UserList unit', unit)
    const items = positions
      .filter((v: any) => rootPositionIds.includes(v.id))
      .sort((a: any, b: any) =>
        a.employee?.displayName?.localeCompare(b?.employee?.displayName, "ru", {
          sensitivity: "base",
        })
      );
    return (
      <ItemsList<any, { values: any[] }>
        method={async function (skipCount, { search, count, tabsValue }) {

          return await items;
        }}
        countPerPage={items.length + 1}
        // countPerPage={0}
        HeaderSecondaryComponent={() => null}
        ItemComponent={({ item, ...props }) => {
          if (item.isVacancyOpen) {
            if (!item.vacancy) {
              console.warn("vacancy is null:", item);
              return null;
            }
            return (
              <VacancyListItem {...props} item={item.vacancy} unit={unit} />
            );
          }
          if (!item.employee) {
            console.warn("employee is null:", item);
            return null;
          }
          return (
            <UsersListItem
              {...props}
              item={{ ...item.employee, url: `/user/${item.employee.id}` }}
            />
          );
        }}
        BodyComponent={({ children }) => (
          <Box sx={{ maxWidth: "800px" }}>{children}</Box>
        )}
      />
    );
  });

const GroupDetailsRender: FC<{ unit: any; positions: any }> = memo(
  ({ unit, positions }) => {
    const { result, isLoading } = useQueryEx(() =>
      getOrgchartChildren(unit.id)
    );
    // console.log("result", isLoading, result);
    // if (!result) return <></>;
    return (
      <AccordionDetails className={cnTreeGroup("Details")}>
        {!!unit.rootPositionIds?.length && (
          <PositionsAccordion
            className={cnTreeRootPosition()}
            disableGutters
            elevation={0}
            defaultExpanded
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummaryShort
              expandIcon={<ExpandMoreIcon />}
              className={cnTreeRootPosition("Summary")}
            >
              <Typography variant="h6" className={cnTreeRootPosition("Title")}>
                {i18n.t("pryaniky.orgchart.tree.accordion.rootPosition")}
                {/*Руководители*/}
              </Typography>
            </AccordionSummaryShort>
            <AccordionDetailsShort className={cnTreeRootPosition("Details")}>
              <UserList
                positions={positions}
                rootPositionIds={unit.rootPositionIds}
                unit={unit}
              />
            </AccordionDetailsShort>
          </PositionsAccordion>
        )}

        <PositionsAccordion
          className={cnTreePosition()}
          disableGutters
          defaultExpanded
          elevation={0}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummaryShort
            expandIcon={<ExpandMoreIcon />}
            className={cnTreePosition("Summary")}
          >
            <Typography variant="h6" className={cnTreePosition("Title")}>
              {i18n.t("pryaniky.orgchart.tree.accordion.position")}
              {/*Сотрудики*/}
            </Typography>
          </AccordionSummaryShort>
          <AccordionDetailsShort className={cnTreePosition("Details")}>
            {!!unit.positionIds?.length && (
              <UserList
                positions={positions}
                rootPositionIds={unit.positionIds}
                unit={unit}
              />
            )}

            {!!result && (
              <Stack
                className={cnTreePosition("Stack")}
                direction={"column"}
                spacing={"12px"}
                sx={{ marginLeft: "32px" }}
              >
                {(result as any)?.units/*.filter((v: any) => v.id !== unit.id)*/.map((item: any) => (
                  <GroupRender
                    unit={item}
                    positions={(result as any).positions}
                  />
                ))}
              </Stack>
            )}
          </AccordionDetailsShort>
        </PositionsAccordion>
      </AccordionDetails>
    );
  }
);

const GroupRender: FC<{ unit: any; positions: any; isRoot?: boolean }> = memo(
  ({ unit, positions, isRoot }) => {
    return (
      <FlatAccordion
        className={cnTreeGroup()}
        elevation={0}
        defaultExpanded={isRoot}
        TransitionProps={{ unmountOnExit: true }}
      >
        <GroupAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={cnTreeGroup("Summary")}
        >
          <Stack justifyContent="space-between" flexGrow={1} direction="row">
            <Typography variant="h5" className={cnTreeGroup("Title")}>
              {unit.displayName}
            </Typography>
            <GroupActions data={unit} />
          </Stack>
        </GroupAccordionSummary>

        <GroupDetailsRender positions={positions} unit={unit} />
      </FlatAccordion>
    );
  }
);

const getParentList = (connections: any[] = [], pp?: string) => {
  const parArr: any = []
  const fc = (p?: string) => {
    const pt = connections.find((v: any) => v.childId === p)
    if (!pt) return;
    parArr.push(pt?.parentId)
    fc(pt?.parentId)
  }
  fc(pp)
  return parArr;
}

export const TreeView: FC<{
  prentModel?: any;
  setParentModel?: (model: any) => any
}> = memo(({ prentModel, setParentModel = () => { } }) => {
  const { result, isLoading } = useQueryEx(
    async function () {
      return await (prentModel?.id
        ? getOrgchartDirectpath(prentModel.id)//getOrgchartChildren(prentModel.id, false, true)
        : getOrgchartRoot());
    },
    [prentModel?.id]
  );
  // console.log("root result", isLoading, result);
  if (isLoading)
    return (
      <Box
        sx={{
          margin: "24px",
          display: "flex",
          justifyContent: "center",
          ml: 0,
          mr: 0,
        }}
        className={cnTreeView()}
      >
        <CircularProgress />
      </Box>
    );
  if (!result)
    return (
      <Box
        sx={{
          margin: "24px",
          display: "flex",
          justifyContent: "center",
          ml: 0,
          mr: 0,
        }}
        className={cnTreeView()}
      >
        <CircularProgress />
      </Box>
    );

  const { units, positions, connections } = result as any;
  // console.log("prentModel", prentModel);
  // const unitsTree = unitsToTree(getRootUnit(units), Object.values(units))

  const parArr = getParentList(connections, prentModel?.id)
  // const rootUnits = getRootUnits(units, prentModel?.id);
  // console.log('rootUnits', rootUnits)
  return (
    <Box sx={{ margin: "24px", ml: 0, mr: 0 }} className={cnTreeView()}>
      {!!parArr?.length && <Paper sx={{ marginBottom: "24px", padding: '8px' }} className={cnTreeView("Item")}>
        <Breadcrumbs
          // maxItems={5}
          separator={<ChevronRight fontSize="small" />}
          aria-label="breadcrumb">
          {[...parArr.reverse().map((v: string) => units.find((u: any) => u.id === v)), prentModel].map((item: any) => <Button
            key={item.id}
            sx={{ textTransform: "inherit", fontSize: "16px" }}
            onClick={() => setParentModel(item)}
            disabled={item.id === prentModel.id}
          >
            {item.displayName}
          </Button>)}
        </Breadcrumbs>
      </Paper>}

      {units.filter((u: any) => !parArr.includes(u.id)).map((v: any) => {
        // const unitsTree = unitsToTree(v, units);
        return (
          <Box sx={{ marginBottom: "24px" }} className={cnTreeView("Item")}>
            <GroupRender unit={v} positions={positions} isRoot />
          </Box>
        );
      })}
    </Box>
  );
});
