import actions from 'redux/actionsTypes/AppSettings'

const defaultAppSettings: any = {
    companyPostTypes: [],
    allowPostType: {
        dash: [],
        groups: {},
        workflows: {}
    },
    error: null,
    allowToGroupTransfer: false,
}

export function appSettingsReducer(state = defaultAppSettings, action: any) {
    switch (action.type) {
        case actions.ADD_COMPANY_POST_TYPES: {
            return {
                ...state,
                companyPostTypes: action.payload.postTypes
            }
        }

        case actions.SET_COMPANY_POST_ERROR: {
            return {
                ...state,
                error: action.payload
            }
        }

        case actions.ADD_ALLOW_POST_TYPES: {
            const { postTypes, groupPKID = -1, workflowId, newsType, all } = action.payload
            const keys = {
                default: 'dash',
                group: groupPKID,
                workflow: workflowId
            }
            if(newsType) {
                keys.default = keys.default + '&' + newsType;
                keys.group = keys.group + '&' + newsType;
                keys.workflow = keys.workflow + '&' + newsType;
            }
            const data = groupPKID !== -1 ?
                {
                    groups: {
                        ...state.allowPostType.groups,
                        [keys.group]: postTypes
                    }
                }
                :
                workflowId ?
                {
                    workflows: {
                        ...state.allowPostType.workflows,
                        [keys.workflow]: postTypes
                    }
                }
                :
                {
                    [keys.default]: postTypes
                }
            return {
                ...state,
                error: null,
                allowPostType: {
                    ...state.allowPostType,
                    ...data
                }
            }
        }

        case actions.SET_ALLOW_GROUP_TRANSFER: {
            return {
                ...state,
                allowToGroupTransfer: action.payload
            }
        }

        default: return state
    }
}

