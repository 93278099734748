import { connect } from 'react-redux';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnReply, IReplyProps, IReplyState, mapDispatchToProps, mapStateToProps } from './Reply.index';
import './Reply.scss';
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
import { Edit } from './Edit/Reply-Edit';
import { Actions } from '../NewsView/Actions/Actions'
import { Like } from '../NewsView/Actions/Like/Like'
import { Reply as ReplyAction } from '../NewsView/Actions/Reply/Reply'
import { Reply as ReplyRender } from '../NewsTypes/Reply/Reply'
import { ReplyType } from '../NewsTypes/Reply/Reply.type'
import { Avatar } from 'uielements/src/Avatar/Avatar'
// import { ServiceActions } from '../NewsView/ServiceActions/ServiceActions'
import { ServiceActionsReply } from '../NewsView/ServiceActions/Actions'
import { withNewsContext } from '../NewsView/NewsView.context'
// import { NewsLikers } from 'blocks/Dialogs/News/Likers/NewsLikers';
import { withComponentEnjector, IComponentAcceptor } from 'utils/src/ComponentInjector'
import Rating from 'uielements/src/MaterialElements/RatingWithValue'
import { formatDateSTD } from 'utils/src/utils.dates';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { classNamesDesign } from 'utils/src/constants.classes';
import { Link } from 'muicomponents/src/Link';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

export class ReplyPresenter extends React.Component<IReplyProps & IComponentAcceptor, IReplyState> {

  constructor(props: IReplyProps & IComponentAcceptor) {
    super(props);
    this.state = {
      edit: false,
      likedView: false
    };
  }

  public render() {
    const {
      newsID,
      className,
      additionalReplyActions,
      comment,
      allowReply,
      avatarSize = 40,
    } = this.props;
    const { edit, likedView } = this.state;
    const { contentRating } = comment as any

    const dateStr = comment.changeDate.toString() !== comment.date.toString() ? 'отредактировано ' + formatDateSTD(comment.changeDate.toString(), true) : formatDateSTD(comment.date.toString(), true);
    const ActionComponent: any = this.props.getComponent(newsID) || (() => <></>)
    return (
      <div
        id={comment.id}
        data-comment-id={comment.id}
        className={cnReply({}, [className])}>

        <div className={cnReply('Aside')}>
          {comment.user.isDeleted ? <Avatar
            name={comment.user.displayName}
            size={avatarSize} /> :
            <Avatar
              imgUrl={comment.user.imgUrl || comment.user.userPhotoUrl}
              imgId={comment.user.imgId}
              name={comment.user.displayName}
              size={avatarSize} />}
        </div>

        <div className={cnReply('Section')}>
          <div className={cnReply('Header')}>
            <div className={cnReply('Title')}>
              {
                comment.user.isDeleted
                  ? <span>{comment.user.displayName}</span>
                  : <UserMention id={comment.user.id}>
                    <Link className={`UserName ${classNamesDesign.primaryColor3.text}`} href={appGeneratePath(mainUrls.user.id, { id: comment.user.id })}>
                      {comment.user.displayName}
                    </Link>
                  </UserMention>
              }
              {' ' + dateStr}
            </div>
            <div className={cnReply('Service')}>
              {/* <ServiceActions actions={comment.actions} onClick={this.replyAction} id={newsID} /> */}
            </div>
          </div>
          <div className={cnReply('Content')}>

            {edit ? <Edit newsId={newsID} comment={comment} disableEdit={this.disableEdit} />
              : <>
                {(contentRating || -1) > -1 && <div className={cnReply('RatingBox')}>
                  <Rating
                    readOnly
                    value={contentRating}
                  />
                </div>}
                <ReplyRender id={comment.id} header={comment.header} text={comment.text} type={comment.type} />
              </>}

            {
              !edit && <React.Fragment>
                <Preview newsid={comment.id} files={comment.attachments ? comment.attachments : []} />

                <Attachments files={comment.attachments ? comment.attachments : []} onDelete={() => { }} state='view' />
              </React.Fragment>
            }
          </div>
          <div className={cnReply('Footer')}>
            <Actions className={cnReply('Actions')}>
              {allowReply && <ReplyAction onClick={this.actionAnswer} />}

              <Like
                newsId={newsID}
                replyId={comment.id}
                buttonProps={{ 'data-liked': comment.liked }}
                likeCount={comment.likeCount}
                liked={comment.liked}
                onClick={this.actionLike} />

              <ActionComponent replyId={comment.id} replyAuthor={comment.user} />
              {/* {additionalReplyActions && additionalReplyActions(comment.id, comment.user)} */}
            </Actions>
          </div>
        </div>
      </div>
    )
  }

  private actionLikedByOn = () => this.setState({ likedView: true });
  private actionLikedByOff = () => this.setState({ likedView: false });

  private actionAnswer = () => {
    const { displayName, id, userPhotoUrl, imgUrl } = this.props.comment.user;
    this.props.insertMention({
      newsId: this.props.newsID,
      mention: {
        name: displayName,
        link: '/user/' + id,
        avatar: imgUrl || userPhotoUrl,
      }
    })
  }

  private actionLike = (likeCount: number, liked: boolean) => {
    this.props.sendLikeReply({
      likeCount: likeCount,
      liked: liked,
      newsId: this.props.newsID,
      replyId: this.props.comment.id
    })
  }

  private replyAction = (action: string) => {
    const SAW = new ServiceActionsReply({
      enableEdit: this.enableEdit,
      id: this.props.comment.id,
      actions: this.props.comment.actions,
      newsID: this.props.newsID,
      removeReply: this.removeReply,
      type: this.props.comment.type
    })
    SAW.callAction(action);
  }

  private disableEdit = () => {
    this.setState({ edit: false });
  }

  private enableEdit = () => {
    this.setState({ edit: true });
  }

  private removeReply = (id: string) => {
    this.props.deleteReply({ newsId: this.props.newsID, replyId: this.props.comment.id })
  };
}

export const Reply = connect(
  mapStateToProps,
  mapDispatchToProps
)(withComponentEnjector(ReplyPresenter, ['news_additional_reply_action']))

export const replyWithNewsContext = withNewsContext(Reply)
//   compose(
//     withBemMod(cnReply(), {})
//   )(
//     ReplyPresenter
//   )
// )
