import React, { FC, useEffect } from 'react';
import { Box, Paper, Typography, styled } from 'muicomponents/src';
import { clearShopCatProducts } from 'redux/shop/actions';
import InfinityList from 'muicomponents/src/InfinityList/InfinityList';
import { useDispatch, useSelector } from 'react-redux';
import { getShopCategoryProducts, getShopCategoryStatus } from 'redux/shop/selectors';
import { ListContainer } from '../ShopList/ListContainer';
import { ShopItem } from '../Item/Shop-Item';
import { IListRequestOptions } from 'utils/src/requests/models/api.base';
import { getShopProducts } from 'redux/shop/actions';
import { cn } from '@bem-react/classname';
import { Translate } from 'localization';

export const cnClassName = cn('MUIShopCurrentCategory');

interface ICurrentCategoryProps {
    categories: any;
    id: number;
    myThanksCount: number;
}

const MainBox = styled(Box)({
    margin: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    gap: '24px',
}) as typeof Box;

const TitleWrap = styled(Paper)({
    padding: '24px',
}) as typeof Paper;

export const CurrentCategory: FC<ICurrentCategoryProps> = ({ categories, id }) => {
    const products = useSelector(getShopCategoryProducts).data;
    const { isLoading, isFinished } = useSelector(getShopCategoryStatus);
    const dispatch = useDispatch();

    const catIds = categories.map((cat: any) => cat.id);

    const requestOptions: IListRequestOptions = {
        skipCount: 0,
        count: 5,
        search: '',
        CatFilterIds: catIds,
        CharFilters: [],
        MyFavorite: false,
        OrderByRating: '',
        OrderByPopular: '',
        OrderByCreateAt: 'desc',
        OrderByPrice: '',
    };

    useEffect(() => {
        dispatch(clearShopCatProducts());
        dispatch(getShopProducts(requestOptions));
    }, [categories]);

    if (!products) return null;

    return (
        <MainBox className={cnClassName('CategoryComponent')}>
            {Array.isArray(categories) && categories.length > 0 && (
                <TitleWrap>
                    <Typography variant="h6">
                        {categories.length === 1
                            ? Translate.t({ i18nKey: 'pryaniky.shop.category.titleForSingle' })
                            : Translate.t({ i18nKey: 'pryaniky.shop.category.titleForMany' })}
                        {categories.map((category, index) => {
                            return category.name + (index !== categories.length - 1 ? ', ' : ':');
                        })}
                    </Typography>
                </TitleWrap>
            )}
            <InfinityList
                isFinished
                isLoading={isLoading}
                itemsCount={products.length}
                ContentComponent={ListContainer}
            >
                {products.map((product, i) => {
                    if (product.id === id || i === 4) return null;
                    else return <ShopItem key={product.id} data={product} />;
                })}
            </InfinityList>
        </MainBox>
    );
};
