import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import { getAppSettings, getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as AT from '../../../actions/types';
import * as SEL from '../../selectors'
import repostModal from 'blocks/Dialogs/News/RepostForm/RepostForm'

const handleContextActionRepost = function* handleContextActionRepost({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'repost') {
        try {
            const news = yield* select(SEL.getNewsById(id))
            const newsId = news.newstype === 31 ? news.parentGuid : id

            if(!newsId) return ;

            const result = yield* call(repostModal, {
                newsId
            })

        } catch (error) {
            console.error('dismis', error)
        }
    }
    else  if (action.includes('repost.')) {
            try {
                const [_, param] = action.split('.')
                const as = yield* select(getAppSettings)
                const news = yield* select(SEL.getNewsById(id))
                const newsId = news.newstype === 31 ? news.parentGuid : id
    
                if(!newsId) return ;
                var group = as.globalParams['gs_newsaction_repost.' + param] ?? null;
                if (group)
                    group = JSON.parse(group);
                const result = yield* call(repostModal, {
                    newsId,
                    group
                })
                
    
            } catch (error) {
    
            }
        }
    yield;

    return 0;
}

export default handleContextActionRepost