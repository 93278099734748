import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { PropsWithChildren } from 'react';
import { IComponentAcceptor } from 'utils/src/ComponentInjector';
import { TCreatorOptions } from './Creator.hooks'
export const cnNewsCreator = cn('NewsCreator');

export namespace NNewsCreator {

    export interface Props extends PropsWithChildren<IClassNameProps>, OwnProps, IComponentAcceptor {

    };

    export interface OwnProps {
        creatorId?: string;
        groupId?: number | string;
        workflowId?: string;
        newsType?: string;
        all?: boolean;
        creatorOptions?: TCreatorOptions;
    };
};