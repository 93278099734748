import { put, takeLeading, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';
import React from 'react';
import { Translate } from 'localization';
import { checkResponseStatus } from 'utils/src/utils'
import widgetsActions from '../../actionsTypes/Widgets';

import { toast } from 'react-toastify';
import { getCurrentPage, saveWidget } from 'redux/actions/Widgets';
import { CircularProgress } from 'muicomponents/src';
import { IStateType } from 'redux/store';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { TOAST_AUTO_CLOSE } from 'utils/src/constants.prn';
import { setPageWidget } from 'utils/src/requests/requests.widgets';
import { ResponseError } from 'utils/src';

function* saveWidgetSaga(action: ReturnType<typeof saveWidget>) {
    const toastId = toast.warn(<><Translate i18nKey='pryaniky.page.save.inprocess' />&nbsp;<CircularProgress size={16} /></>, { autoClose: false });
    try {
        const page = yield* select(getCurrentPage);
        const widget = yield* select((state: IStateType) => state.widgets.widgets[action.payload.widgetId]);
        if(!page || !widget) return;
        switch (widget.type) {
            case WidgetsTypes.wiki:
                const response = yield* call(setPageWidget, page.url, widget);
                if(checkResponseStatus(response)) {
                    toast.update(toastId, { type: toast.TYPE.SUCCESS, autoClose: TOAST_AUTO_CLOSE, render: <Translate i18nKey='pryaniky.page.save.success' /> })
                    action.payload.callback?.(true);
                } else {
                    throw new ResponseError('widget not saved');
                }
                break;
            default:
                break;
        }
    } catch (error) {
        if(error instanceof ResponseError) {
            toast.update(toastId, { type: toast.TYPE.ERROR, autoClose: TOAST_AUTO_CLOSE, render: <Translate i18nKey='pryaniky.page.save.error' /> });
        }
        action.payload.callback?.(false);
    }
}

export const widgetsSaga = function*() {
    yield takeLatest(widgetsActions.SAVE_WIDGET, saveWidgetSaga);
};