import React, { ComponentProps, useCallback, useMemo, useRef } from 'react';

import { useReffedState } from 'utils/src/hooks';
import { tasksListClasses, TasksListProps } from './TasksList.index';
import { Translate } from 'localization';
import {
    checkResponseStatus,
    TaskFilterStatus,
    TaskModel,
    TaskModelAction,
    TaskModelStatus,
    useMyTasksCountManagerUpdate,
} from 'utils/src';
import { Button } from 'muicomponents/src';
import { getTasks, updateTaskField } from 'utils/src/requests/requests.tasks';
import { UpdateTask } from '../Dialog/UpdateTask';
import { TableList } from 'blocks/TableList/List';
import { IListItemProps } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';

import { removeTask } from 'utils/src/requests/requests.tasks';
import { toast } from 'react-toastify';
import { taskModelSimpleToTaskModel, taskModelToTaskModelSimple } from 'utils/src/BaseConverters/tasks.converters';
import { confirm } from 'utils.project/utils.project';
import { ResponseError } from 'utils/src';
import { MoreVertOutlined } from 'muicomponents/src/Icons';

const createItemProps = ({ item, actions }: IListItemProps<TaskModel>, context: any) => {
    const [showActions, setShowActions, showActionsRef] = useReffedState(false);
    const blockHide = useRef(false);

    const onMouseEnter = () => {
        setShowActions(true);
    };
    const onMouseMove = () => {
        if (showActionsRef.current) return;
        setShowActions(true);
    };
    const onMouseLeave = () => {
        if (blockHide.current) return;
        setShowActions(false);
    };
    const updateCount = useMyTasksCountManagerUpdate(context.userId);
    const updateCountRef = useRef(updateCount);
    updateCountRef.current = updateCount;

    const onActionClick = useCallback<NonNullable<ComponentProps<typeof SplitButton>['handleMenuItemClick']>>(
        async function (e, selected) {
            blockHide.current = false;
            const key = selected.id as any as keyof typeof TaskModelAction;
            let actionIsSuccess = false;
            switch (TaskModelAction[key]) {
                case TaskModelAction.complete:
                    try {
                        let value = TaskModelStatus.inProgress;
                        if (TaskModelAction[key] === TaskModelAction.complete) {
                            value = TaskModelStatus.completed;
                        }
                        const r1 = await updateTaskField({
                            id: item.id,
                            model: {
                                fieldName: 'status',
                                value,
                            },
                        });
                        if (checkResponseStatus(r1)) {
                            updateCountRef.current(-1);
                            actions.updateItem(item, {
                                ...item,
                                status: value,
                            });
                            toast.success(<Translate i18nKey="pryaniky.task.action.result.success" />);
                        } else {
                            toast.error(<Translate i18nKey="pryaniky.error.default" />);
                            throw new ResponseError('Update task error');
                        }
                    } catch (error) {
                        if (error instanceof ResponseError) {
                            console.error(error);
                        } else {
                            throw error;
                        }
                    }
                    break;
                case TaskModelAction.edit:
                    const newData = await UpdateTask({
                        userId: context.userId,
                        initData: taskModelToTaskModelSimple(item),
                    });
                    if (newData) {
                        const model = taskModelSimpleToTaskModel(newData, item);
                        actions.updateItem(item, {
                            ...item,
                            ...model,
                        });
                    }
                    break;
                case TaskModelAction.remove:
                    confirm({
                        text: (
                            <Translate
                                i18nKey={'asking.removeWith'}
                                values={{
                                    text: Translate.t({ i18nKey: 'pryaniky.tasks.name.create' }).toLowerCase(),
                                }}
                            />
                        ),
                        onConfirm: async function () {
                            try {
                                const r1 = await removeTask({
                                    taskId: item.id,
                                    userId: context.userId,
                                });
                                if (checkResponseStatus(r1)) {
                                    if (
                                        item.status === TaskModelStatus.notStarted ||
                                        item.status === TaskModelStatus.inProgress
                                    ) {
                                        updateCountRef.current(-1);
                                    }
                                    actions.removeItems([item]);
                                    toast.success(<Translate i18nKey="pryaniky.task.action.result.remove.success" />);
                                } else {
                                    toast.error(<Translate i18nKey="pryaniky.error.default" />);
                                    throw new ResponseError('Remove task error');
                                }
                            } catch (error) {
                                if (error instanceof ResponseError) {
                                    console.error(error);
                                } else {
                                    throw error;
                                }
                            }
                        },
                    });
                    break;
            }
        },
        [item, context.userId]
    );

    const itemActions = useMemo(() => {
        let actions = Object.typedKeys(TaskModelAction).filter((el) => isNaN(Number(el)) && el !== 'setInProgress');
        switch (item.status) {
            case TaskModelStatus.completed:
                actions = actions.filter((el) => el === 'remove');
            case TaskModelStatus.inProgress:
                actions = actions.filter((el) => el !== 'setInProgress');
                break;
        }
        if (!item.isStatusChangable) {
            actions = actions.filter((el) => el !== 'setInProgress' && el !== 'complete');
        }
        if (!item.isEditable) {
            actions = actions.filter((el) => el !== 'edit');
        }
        if (!item.isDeletable) {
            actions = actions.filter((el) => el !== 'remove');
        }
        return actions;
    }, [item.status, item.isDeletable, item.isEditable, item.isStatusChangable]);

    return {
        itemActionsButton: (
            <>
                {showActions ? (
                    <SplitButton
                        variant="text"
                        icon={<MoreVertOutlined />}
                        size={'small'}
                        handleMenuItemClick={onActionClick}
                        options={itemActions.map((key) => {
                            return {
                                id: key,
                                content: <Translate i18nKey={`pryaniky.task.action.${key}`} />,
                            };
                        })}
                        onOpen={(value) => {
                            blockHide.current = value;
                        }}
                    />
                ) : null}
            </>
        ),
        wrapProps: {
            onMouseEnter,
            onMouseMove,
            onMouseLeave,
        },
    };
};

export const TasksList = ({ context }: TasksListProps) => {
    const createActionsEl = (actions: any, context: any) => {
        const updateCount = useMyTasksCountManagerUpdate(context.userId);
        const updateCountRef = useRef(updateCount);
        updateCountRef.current = updateCount;

        const addNewTask = useCallback<(newTaks: TaskModel) => void>(
            (newTaks) => {
                actions.prependItems([newTaks]);
            },
            [actions.prependItems]
        );

        return (
            <Button
                className={tasksListClasses.headerButton}
                variant="contained"
                onClick={() => {
                    UpdateTask({
                        userId: context.userId,
                    })
                        .then((newData) => {
                            addNewTask(taskModelSimpleToTaskModel(newData));
                            updateCountRef.current?.(1);
                        })
                        .catch(console.warn);
                }}
            >
                <Translate
                    i18nKey={'createWith'}
                    values={{
                        text: Translate.t({ i18nKey: 'pryaniky.tasks.name.create' }).toLowerCase(),
                    }}
                />
            </Button>
        );
    };

    return (
        <TableList<TaskFilterStatus>
            context={context}
            filterOptions={Object.typedKeys(TaskFilterStatus).map((key) => {
                return {
                    title: <Translate i18nKey={`pryaniky.tasks.filter.${key}`} />,
                    value: TaskFilterStatus[key],
                };
            })}
            defaultFilter={TaskFilterStatus.active}
            defaultValue={''}
            isExpireDays
            getData={getTasks as any as ComponentProps<typeof TableList>['getData']}
            createActionsEl={createActionsEl}
            createItemProps={createItemProps}
        />
    );
};
