import React, { FC, useMemo, useState } from 'react';
import { cnBirthdays, mapStateToProps } from './Birthdays.index';
// import './Birthdays.scss';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import { i18n, Translate } from 'localization';
import { Box } from 'muicomponents/src/Box/Box';
import { useContainerMediaQuery } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher';
import { DatePicker } from 'muicomponents/src/DatePicker/DatePicker';
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog';
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import { Link } from 'muicomponents/src/Link/Link';
import { MiniPagination } from 'muicomponents/src/MiniPagination/MiniPagination';
import { Select } from 'muicomponents/src/Select/Select';
import { baseModels } from 'News/creator/Creator/Creator.hooks';
import { NewsCreatorButton } from 'News/creator/Creator/CreatorButton/CreatorButton';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { CustomSettings } from 'utils/src';
import { useCurrentUserBase } from 'utils/src/CommonRedux/users/hooks';
import { useScrollListState } from 'utils/src/hooks';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IUserBirthday } from 'utils/src/requests/models/api.users';
import { API, capitalize } from 'utils/src/utils';

const customBirthdayLink = CustomSettings.birthdaysLink();

// async function birthdaysListPromise(month: string, options: { search?: string, count: number, skipCount: number }) {
//   const response = await API.users.birthdays(month, options)
//   return await response.r
// }

const TODAY_ISO_DATE = new Date().toISOString();
const TODAY_MONTH = (new Date().getMonth() + 1).toString();

const useBirthdayParams = () => {
  const { variant: _variant = 'month', date: _date } =
    useParams<{ variant?: 'month' | 'day' | 'today'; date?: string }>();
  let variant = _variant;
  let date = _date!;

  if ('today' === variant) {
    variant = 'day';
    date = TODAY_ISO_DATE;
  } else if ('month' === variant) {
    date = !date ? TODAY_MONTH : date;
  } else if ('day' === variant) {
    date = !date ? TODAY_ISO_DATE : date;
  } else {
    const toInt = parseInt(_variant);
    variant = 'month';
    date = isNaN(toInt) ? TODAY_MONTH : _variant;
  }
  return {
    variant,
    date,
  };
};

/**
 * выбор даты в шапке списка
 * @param param0
 * @returns
 */
const MonthSelector: FC<{}> = ({ }) => {
  const dispatch = useDispatch();
  const { date, variant } = useBirthdayParams();

  const monthOptions = i18n
    .t('months')
    .split(',')
    .map((month, i) => ({ title: capitalize(month), value: (i + 1).toString() }));
  return (
    <Box sx={{ display: 'flex' }}>
      <Select
        sx={{ width: '150px', marginLeft: '8px', marginRight: '8px' }}
        onChange={(event) => {
          event.target.value;
          dispatch(push(`/birthdays/${event.target.value}`));
        }}
        size="small"
        value={variant}
        options={[
          {
            title: <Translate i18nKey={'pryaniky.birthdays.variant.day'} />, //'День',
            value: 'day',
          },
          {
            title: <Translate i18nKey={'pryaniky.birthdays.variant.month'} />, //'Месяц',
            value: 'month',
          },
        ]}
      />
      <MiniPagination
        sx={{ /*width: '350px',*/ gap: '8px', marginLeft: '12px' }}
        next={() => {
          if (variant === 'day') {
            const parsedDate = date === 'today' ? new Date() : new Date(date);
            parsedDate.setDate(parsedDate.getDate() + 1);
            const value = format(new Date(parsedDate), 'yyyy-MM-dd');
            // const value = parsedDate.toISOString()
            dispatch(push(`/birthdays/day/${value}`));
          } else {
            const parsedDate = date === 'today' ? new Date().getMonth() + 1 : parseInt(date);
            const value = parsedDate === 12 ? monthOptions[0] : monthOptions[parsedDate];
            dispatch(push(`/birthdays/month/${value.value}`));
          }
        }}
        previous={() => {
          if (variant === 'day') {
            const parsedDate = date === 'today' ? new Date() : new Date(date);
            parsedDate.setDate(parsedDate.getDate() - 1);
            const value = format(new Date(parsedDate), 'yyyy-MM-dd');
            // const value = parsedDate.toISOString()
            dispatch(push(`/birthdays/day/${value}`));
          } else {
            const parsedDate = date === 'today' ? new Date().getMonth() + 1 : parseInt(date);
            const value = parsedDate === 1 ? monthOptions[11] : monthOptions[parsedDate - 2];
            dispatch(push(`/birthdays/month/${value.value}`));
          }
        }}
        value={
          <>
            {variant === 'day' ? (
              <DatePicker
                InputProps={{
                  size: 'small',
                  sx: { width: '150px' },
                }}
                InputAdornmentProps={{ sx: { marginLeft: 0 } }}
                type="date"
                onChange={(_, val) => {
                  if (val) {
                    dispatch(push(`/birthdays/day/${format(new Date(val), 'yyyy-MM-dd')}`));
                  }

                  // dispatch(push(`/birthdays/day/${val?.toISOString()}`))
                }}
                value={date === 'today' ? new Date() : new Date(date)}
              />
            ) : (
              <Select
                sx={{ width: '150px' }}
                onChange={(event) => {
                  event.target.value;
                  dispatch(push(`/birthdays/month/${event.target.value}`));
                }}
                size="small"
                value={date === 'today' ? TODAY_MONTH : date}
                options={monthOptions}
              />
            )}
          </>
        }
      />
    </Box>
  );
};

/**
 * дата в начале элесентов списка
 * @param param0
 * @returns
 */
const DateIcon = ({ month, day }: { month: string; day: number }) => {
  const point500 = useContainerMediaQuery({ minWidth: 500 });
  return (
    <Box sx={{ textAlign: 'center', marginInline: point500 ? '8px' : '4px', marginTop: point500 ? '0px' : '4px' }}>
      <Box sx={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '16px' }}>{day}</Box>
      <Box>{month}</Box>
    </Box>
  );
};

export const BirthdaysPresenter: FC<any> = ({ className, language, currencyName, ...props }) => {
  return (
    <div className={cnBirthdays({}, [className, 'Page'])}>
      <ItemsList<IUserBirthday & { url: string }>
        BodyComponent={ItemsListBody}
        listStateHook={({ defaultSelected }, middlewareParams) => {
          const { search, debouncedSearch, changeSearch, tabsValue, onChangeTab } = useDefaultStaticStates(
            middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id
          ); // состояние для поиска и табов

          const { date, variant } = useBirthdayParams();
          const [totalUserCount, setTotalUserCount] = useState(0);

          const currentUser = useCurrentUserBase();

          // получаем список пользователей и достаём запиханное туда колличество пользователей
          const state = useScrollListState(
            async function (skipCount, count) {
              const result = (await API.users.birthdaysByDate(
                variant,
                date === TODAY_ISO_DATE ? 'today' : date,
                {
                  count,
                  skipCount,
                  search: debouncedSearch,
                }
              )) as any as { data: IUserBirthday[]; length: number } & IBasicResponse;
              if (result.error_code !== 0) throw result;

              setTotalUserCount((result as any)?.totalUserCount);
              return result.data.map((item) => ({ ...item, url: `/user/${item.id}` }));
            },
            [debouncedSearch, date],
            {
              selectFilter: (value) => value.id !== currentUser.id,
            }
          );

          const creatorOptions = useMemo(() => {
            return {
              models: {
                thanks: {
                  ...baseModels.thanks,
                  thanksCount: 1,
                  users: state.select.selected.map((v: any) => ({ ...v, type: 'user' })),
                },
              },
              formSettings: {
                thanks: {
                  hideUsersSelect: true,
                  subHeaderContent: (
                    <Translate
                      i18nKey={'pryaniky.birthdays.form.subHeader'}
                      values={{ count: state.select.selected.length }}
                      count={state.select.selected.length}
                    />
                  ),
                },
              },
            };
          }, [state.select.selected]);

          return {
            middlewareParams: {
              ...middlewareParams,
              listHeaderProps: {
                ...middlewareParams.listHeaderProps,
                SearchActions: <MonthSelector />,
                actionButtonNode: (
                  <NewsCreatorButton
                    newsType="thanks"
                    size="small"
                    all
                    disabled={state.select.selected.length === 0}
                    creatorOptions={creatorOptions}
                    component={customBirthdayLink ? Link : ''}
                    href={customBirthdayLink}
                  >
                    <Translate
                      i18nKey={'pryaniky.birthdays.thanksSelected'}
                      values={{ text: currencyName }}
                    />
                  </NewsCreatorButton>
                ),
                summaryText: i18n.t('pryaniky.list.usersCount', { count: totalUserCount }),
              },
              itemMiddleware: (props) => {
                const date = new Date(props.item.birthday);
                const day = date.getDate();
                const month = date
                  .toLocaleDateString(language || 'ru', { day: 'numeric', month: 'long' })
                  .replace(/\d+/, '')
                  .trim();

                const creatorOptions = useMemo(() => {
                  return {
                    models: {
                      thanks: {
                        ...baseModels.thanks,
                        thanksCount: 1,
                        users: [props.item].map((v: any) => ({ ...v, type: 'user' })),
                      },
                    },
                  };
                }, [props.item]);

                return {
                  ...props,
                  listItemIcon: <DateIcon day={day} month={month} />,
                  showActionAlways: true,
                  select: {
                    ...props.select,
                    disableSelect: props.item.id === currentUser.id,
                  },
                  splitButtonProps: {
                    children: (
                      <NewsCreatorButton
                        newsType="thanks"
                        all
                        disabled={props.item.id === currentUser.id}
                        creatorOptions={creatorOptions}
                        component={customBirthdayLink ? Link : ''}
                        href={customBirthdayLink}
                      >
                        <Translate
                          i18nKey={'pryaniky.birthdays.give'}
                          values={{ text: currencyName }}
                        />
                      </NewsCreatorButton>
                    ),
                  },
                };
              },
            },
            state,
            defaultSelected,
            search,
            tabsValue,
            changeSearch,
            onChangeTab,
            middleware: defaultMiddleware,
          };
        }}
        selectable
        ItemComponent={UsersListItem}
      />
    </div>
  );
};

export const Birthdays = connect(mapStateToProps, null)(BirthdaysPresenter);
