import React from 'react';
import { cn } from '@bem-react/classname';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import './Admin.scss';
import { tC } from 'LMSModule/utils/i18n.adaptor'
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

const cnCourseAdmin = cn('CourseAdmin');

interface IAdminProps {
  mod?: string;
  admin: any;//ICourseAdmin;
  className?: string
}

export const Admin: React.FC<IAdminProps> = ({ admin, className }) => {
  return (
    <div className={cnCourseAdmin({}, [className])}>
      <Avatar className={cnCourseAdmin('Avatar')} size={56} imgUrl={admin.userPhotoUrl} />
      <div className={cnCourseAdmin('Info')}>
        <UserMention id={admin.id}>
          <Link className={cnCourseAdmin(`Name`, [classNamesDesign.primaryColor3.text])} href={appGeneratePath(mainUrls.user.id, { id: admin.id })}>
            {admin.displayName}
          </Link>
        </UserMention>
        <p className={cnCourseAdmin('Description')}>{ tC('author.1') }</p>
      </div>
    </div>
  );
};
