import { useContextFiltersChange } from "blocks/Filters/Filters.hooks";
import { cnFilters } from "blocks/Filters/Filters.index";
import React, { ComponentProps, createContext, FC, forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { SOCOrgchartUnitsTreeManager, useSOCOrgchartUnitsTreeManager } from "./Filters_type_socogchartunits.hooks";
import { FiltersSOCOrgchartUnitsProps } from "./Filters_type_socogchartunits.index";
import { TreeView } from 'muicomponents/src/Tree';
import { ApplyButton, SOCOrgchartUnitsBox, SOCOrgchartUnitsLabelBox, SOCOrgchartUnitsLabelTextBox, StyledCheckbox, StyledFormControlLabel, StyledTreeItem } from "./Filters_type_socogchartunits.styled";
import { Add, Remove, IndeterminateCheckBox, LibraryAddCheck } from 'muicomponents/src/Icons';
import { Tooltip } from "muicomponents/src/Tooltip";
import { Button, Checkbox, Divider } from "muicomponents/src";
import { i18n, Translate } from 'localization';
import { LinearProgress } from "muicomponents/src/LinearProgress";
import { useDidUpdateEffect, useReffedState, useSearchParams } from "utils/src/hooks";
import { difference, flattenDeep, intersection } from 'lodash';
import { WidgetsTypes } from "utils/src/widgets/types";
import { getReqursiveSOCOrgchartUnitsChildrensIds } from "./Filters_type_socogchartunits.utils";

const FilterContext = createContext<ReturnType<typeof useSOCOrgchartUnitsTreeManager>['items'][number]['id'][]>([]);

const FilterItem: FC<ComponentProps<typeof SOCOrgchartUnitsLabelBox> & {
    el: ReturnType<typeof useSOCOrgchartUnitsTreeManager>['items'][number];
    selectUnit: (unitId: ReturnType<typeof useSOCOrgchartUnitsTreeManager>['items'][number]['id'], selectOnlyPassed?: boolean) => void;
    CheckboxProps?: ComponentProps<typeof Checkbox>;
    // parents: string[];
}> = ({
    el,
    selectUnit,
    CheckboxProps,
    // parents,
    ...props
}) => {

    const selectedIds = useContext(FilterContext);

    const childrensTreeIds = useMemo(() => {
        return getReqursiveSOCOrgchartUnitsChildrensIds(el.childrens || []);
    }, [el.childrens]);

    const childrenIntersection = useMemo(() => {
        const intersectionsCount = intersection(selectedIds, childrensTreeIds).length;
        return {
            /**
             * has intersaction
             */
            value: !!intersectionsCount,
            /**
             * full intersection
             */
            full: !!childrensTreeIds.length && intersectionsCount === childrensTreeIds.length
        }
    }, [selectedIds, childrensTreeIds]); 

    const halfChecked = useMemo(() => {
        return !selectedIds.includes(el.id) && childrenIntersection.value
    }, [selectedIds, childrenIntersection, el.id]);

    const fullGroupSelected = useMemo(() => {
        return selectedIds.includes(el.id) && childrenIntersection.full
    }, [selectedIds, childrenIntersection, el.id]);

    const checked = useMemo(() => {
        return selectedIds.includes(el.id);
    }, [selectedIds, el.id]);

    return (
            <StyledTreeItem
                nodeId={el.id}
                label={
                    <Tooltip
                        title={el.displayName}
                        disableInteractive
                    >
                        <SOCOrgchartUnitsLabelBox
                            {...props}
                            component={'span'}
                        >
                            <StyledCheckbox
                                size="small"
                                {...CheckboxProps}
                                checkedIcon={
                                    halfChecked 
                                    ? <IndeterminateCheckBox />
                                    : fullGroupSelected
                                    ? <LibraryAddCheck />
                                    : undefined
                                }
                                checked={checked || halfChecked}
                                disableRipple
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectUnit(el.id, fullGroupSelected);
                                }}
                            />
                            <SOCOrgchartUnitsLabelTextBox>
                                {el.displayName}
                            </SOCOrgchartUnitsLabelTextBox>
                        </SOCOrgchartUnitsLabelBox>
                    </Tooltip>
                }
                ContentProps={{
                    style: {
                        boxSizing: 'border-box'
                    }
                }}
            >
                {el.childrens?.map(it => {
                    return (
                        <FilterItem
                            key={it.id}
                            el={it}
                            selectUnit={selectUnit}
                            // parents={[ ...parents, el.id ]}
                        />
                    )
                })}
            </StyledTreeItem>
    );
};

const FiltersSOCOrgchartUnitsPr: FC<FiltersSOCOrgchartUnitsProps> = ({
    tag: Tag = 'div',
    context,
    relations,
    ...props
}) => {

    const {
        items,
        loading
    } = useSOCOrgchartUnitsTreeManager();
    
    const [ { units: searchUnits, ...searchParams }, updateSearchParams ] = useSearchParams<{units: string}>();

    const [localState, setLocalState, localStateRef] = useReffedState<NonNullable<typeof context.units>>(context.units || []);

    const [localChanged, setLocalChanged] = useState(false);

    useLayoutEffect(() => {
        if(!!difference(localStateRef.current, context.units || []).length || !!difference(context.units || [], localStateRef.current).length) {
            setLocalState(context.units || []);
        }
    }, [context.units]);

    // const changed = useMemo(() => {
    //     return !!difference(localState, context.units || []).length || !!difference(context.units || [], localState).length;
    // }, [context.units, localState]);

    const onChange = useContextFiltersChange<typeof context>(relations);

    const selectUnit = useCallback(async function(unitId: typeof items[number]['id'], selectOnlyPassed?: boolean) {
        const value = await SOCOrgchartUnitsTreeManager.inst.getTreeItemIdWithChildrenIdsByItemId(unitId);
        let newValue: typeof value = [];
        if(selectOnlyPassed) {
            const onlyChilds = value.filter(el => el !== unitId);
            newValue = localStateRef.current.filter(el => onlyChilds.length ? !onlyChilds.includes(el) : true)
        } else if (localStateRef.current.includes(unitId)) {
            newValue = localStateRef.current.filter(el => value.length ? !value.includes(el) : true)
        } else {
            newValue = [...localStateRef.current, ...value]
        }
        setLocalState(newValue);
        setLocalChanged(true);
    }, []);

    const selectAll = useCallback(() => {
        setLocalState([]);
        setLocalChanged(true);
    }, []);

    const applySelectedUnits = useCallback(() => {
        if(searchUnits) {
            updateSearchParams({
                ...searchParams
            });
        }
        onChange({
            units: localStateRef.current
        });
        setLocalChanged(false);
    }, [searchUnits]);

    return (
        <FilterContext.Provider value={localState}>
            <Tag {...props} className={cnFilters()}>
                <SOCOrgchartUnitsBox>
                    <div>
                        <Translate i18nKey={'organizations and units'} />
                    </div>
                    <StyledFormControlLabel
                        checked={!localState.length}
                        onClick={selectAll}
                        control={<Checkbox size={'small'} />}
                        label={<Translate i18nKey={'select all'} />}
                    />
                    <Divider />
                    {
                        loading
                        ? <LinearProgress />
                        : <TreeView
                            defaultCollapseIcon={<Remove />}
                            defaultExpandIcon={<Add />}
                        >
                            {items.map(el => {
                                return (
                                    <FilterItem
                                        key={el.id}
                                        el={el}
                                        selectUnit={selectUnit}
                                        // parents={[]}
                                    />
                                )
                            })}
                        </TreeView>
                    }
                    <ApplyButton
                        disabled={!localChanged}
                        variant={'contained'}
                        size={'small'}
                        onClick={applySelectedUnits}
                    >
                        <Translate i18nKey={'apply'} />
                    </ApplyButton>
                </SOCOrgchartUnitsBox>
            </Tag>
        </FilterContext.Provider>
    );
};

export const FiltersSOCOrgchartUnits = FiltersSOCOrgchartUnitsPr;