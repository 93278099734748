import {
    takeEvery,
    put,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import {
    API, checkResponseStatus, nameCount,
} from 'utils/src/utils'

import {
    loadCompanyPostTypesActionType,
    addCompanyPostTypes,
    loadAllowPostTypesActionType,
    addAllowPostTypes,
    setAllowGroupsTransfer,
    loadCompanyPostTypes,
    getAllowGroupsTransfer,
    setAllowPostError
} from 'redux/actions/AppSettings'

import actions from 'redux/actionsTypes/AppSettings';

import { CacheHelper } from 'utils/src/CacheHelper'
import { IResponseWithData } from 'utils/src/requests/models/api.base';
// import { thanksValidate, thanksEditValidate } from 'blocks/NewsTypes/Thanks/Thanks.validate';
// import { thanksValidate as thanksReplyValidate } from 'blocks/NewsTypes/ThanksReply/ThanksReply.validate';
// import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import LCActions from 'utils/src/CommonRedux/LifeCycol/actionsTypes'
// import { initFirstCompleted } from 'utils/src/CommonRedux/LifeCycol/actions'

/**
 * получает с сервера список всех доступных в компании типов публикаций
 * @param action 
 */
const handleLoadCompanyPostTypes = function* handleLoadCompanyPostTypes(action: loadCompanyPostTypesActionType) {
    try {
        // ключ для кеша
        const cacheKey = `settings/postTypes?all=true`
        try {
            // запрашиваем из кеша
            const fromCache = yield* call(CacheHelper.get, 'settings', cacheKey)
            // если есть в кеше, то кидаем в стор
            if (fromCache) yield put(addCompanyPostTypes(fromCache.postTypes))
        } catch (error) {
            console.warn('postTypes read cache', error)
        }
        // оброщаемся к серверу
        const request = yield* call(API.news.postTypes, { all: true })

        // @ts-ignore 
        const result = yield request.r
        // const authUser = yield* select(getAuthUser);
        // const maxThanksArguments = yield* select((state: any) => state.store.appSettings.maxThanksArgumentsCount);
        if (result.error_code === 0) {
            // отправляем в стор свежие данные с сервера
            yield put(addCompanyPostTypes(result.data.map((value: any) =>
                ({ ...value, 
                    componentRenderName: !value.componentRenderName ? value.id : value.componentRenderName,
                    displayName: !value.displayName ? value.id : value.displayName,
                }))))
            // обновляем кеш 

            return yield* call(CacheHelper.set, 'settings', cacheKey, { postTypes: result.data })
        }
        yield;
        return 0;
    } catch (error) {
        console.warn('handleLoadCompanyPostTypes', error)
    }
}


const handleLoadAllowPostTypes = function* handleLoadAllowPostTypes(action: loadAllowPostTypesActionType) {
    try {
        const { groupPKID, workflowId, newsType, all } = action.payload
        // ключ для кеша
        const cacheKey = `settings/postTypes?gId=${groupPKID}&workflowId=${workflowId}&type=${newsType}&all=${all}`

        try {
            // запрашиваем из кеша
            const fromCache = yield* call(CacheHelper.get, 'posttypes', cacheKey)
            // если есть в кеше, то кидаем в стор
            if (fromCache) yield put(addAllowPostTypes(fromCache.postTypes, groupPKID, workflowId, newsType, all))
        } catch (error) {
            console.warn('handleLoadAllowPostTypes postTypes read cache', error)
        }
        // оброщаемся к серверу
        const request = yield* call(API.news.postTypes, { gId: groupPKID, workflowId, type: newsType, all })

        // @ts-ignore
        const result = yield request.r
        if (result.error_code === 0) {
            // отправляем в стор свежие данные с сервера
            yield put(addAllowPostTypes(result.data.map((value: any) =>
                ({ ...value, 
                    componentRenderName: !value.componentRenderName ? value.id : value.componentRenderName,
                    displayName: !value.displayName ? value.id : value.displayName,
                })), groupPKID, workflowId, newsType, all))
            // обновляем кеш
            return yield* call(CacheHelper.set, 'posttypes', cacheKey, { postTypes: result.data })
        } else {
            yield put(setAllowPostError(result, groupPKID, workflowId, newsType, all))
        }

        yield;
        return 0;
    } catch (error) {
        console.warn('handleLoadAllowPostTypes', error)
    }
}


function* getAllowToGroupTransfer() {
    try {
        const dt: IResponseWithData<boolean> = yield* call(API.system.sagas.getAllowToTransferRequest);
        if (checkResponseStatus(dt)) {
            yield put(setAllowGroupsTransfer(dt.data))
            return;
        } else {
            console.error('invaliad response from get access to group transfer, response:', dt);
        }
        return 0;
    } catch (error) {
        console.error('get access to group transfer error', error);
    }
}


function* init() {
    try {
        yield put(loadCompanyPostTypes())
        yield put(getAllowGroupsTransfer())
        return 0;
    } catch (error) {
        console.error('get access to group transfer error', error);
    }
}


/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.LOAD_COMPANY_POST_TYPES, handleLoadCompanyPostTypes)
    yield takeLatest(actions.LOAD_ALLOW_POST_TYPES, handleLoadAllowPostTypes)
    yield takeLeading(actions.GET_ALLOW_GROUP_TRANSFER, getAllowToGroupTransfer)

    // очевидно, нужна проверка, нужно ли это делать... Но пока не знаю условия. Возможно, это воощбе не на ините должно висеть
    yield takeLeading(LCActions.INIT_FIRST_COMPLETED, init)
};

/**
 * export root saga
 */
export default root;