import { Badge } from 'blocks/Badge/Badge';
import { Birthdays } from 'blocks/Birthdays/Birthdays';
import { Dash } from 'blocks/Dash/Dash';
import { Group } from 'blocks/Group/Group';
import { Groups } from 'blocks/Groups/Groups';
import { Ideas } from 'blocks/Ideas/Ideas';
import { Invite } from 'blocks/Invite/Invite';
import { Messages } from 'blocks/Messages/Messages';
import { MountainRating } from 'blocks/MountainRating/MountainRating';
import { News } from 'blocks/News/News';
import { NotificationsSettings } from 'blocks/NotificationsSettings/NotificationsSettings';
import { Orders } from 'blocks/Orders/Orders';
import { Competitions } from 'blocks/Pages/Competitions/Competitions';
import { PagesNew } from 'blocks/Pages/PagesNew/PagesNew';
import { Rating } from 'blocks/Rating/Rating';
import { Shop } from 'blocks/Shop/Shop';
import { Tag } from 'blocks/Tag/Tag';
import { User } from 'blocks/User/User';
import { Users } from 'blocks/Users/Users';
import { Widgets } from 'blocks/Widgets/Widgets';
import { Redirect, RouteProps } from 'react-router';
import urls, { exactPathnames } from './urls';

import { Course } from '../LMSModule/LMS/Course/Course';
import { Courses } from '../LMSModule/LMS/List/Courses';
import { Results } from '../LMSModule/LMS/Results/Results';
import { Course as LmsTest } from '../LMSModule/LMS/Single/Course';
import TrackListUser from '../LMSModule/LMS/TrackList';
import TrackView from '../LMSModule/LMS/TrackView';
import { CourseCreate } from '../LMSModule/LMSTeacherOffice/CourseCreate/CourseCreate';
import { CourseView } from '../LMSModule/LMSTeacherOffice/CourseView/CourseView';
import { LMSTeacherOffice } from '../LMSModule/LMSTeacherOffice/LMSTeacherOffice/LMSTeacherOffice';
import ManageTrack from '../LMSModule/LMSTeacherOffice/ManageTrack';
import LmsStatView from '../LMSModule/LMSTeacherOffice/StatisticsFull/StatView';
import LmsFullStatUser from '../LMSModule/LMSTeacherOffice/StatisticsFull/Users/User';
import TrackList from '../LMSModule/LMSTeacherOffice/TracksList';

import { Error500 } from 'blocks/500/500';
import { CreativeTasks } from 'blocks/CreativeTasks/CreativeTasks';
import { Dashboard } from 'blocks/Dashboard/Dashboard';
import { Events } from 'blocks/Events/Events';
import { Integrate } from 'blocks/Integrate/Integrate';
import { NotFound } from 'blocks/NotFound/NotFound';
import { Notices } from 'blocks/Notices/Notices';
import { Thanks } from 'blocks/Thanks/Thanks';
import { OrgChartTree } from 'blocks/Orgchart/Orgchart';
import { Page } from 'blocks/Page/Page';
import { AlbumView } from 'blocks/PhotoalbumContainer/AlbumView';
import { PhotoalbumContainer } from 'blocks/PhotoalbumContainer/PhotoalbumContainer';
import { Polls } from 'blocks/Polls/Polls';
import { questsmoderate } from 'blocks/QuestsModerate/QuestsModerate';
import { QuestsPage } from 'blocks/QuestsPage/QuestsPage';
import { Quiz } from 'blocks/Quiz/Quiz';
import { SearchMui } from 'blocks/SearchMui/SearchMui';
import { SecretSantaWidgetPage } from 'blocks/SecretSantaPage/SecretSantaWidgetPage';
import { StatisticsPage } from 'blocks/StatisticsPage/StatisticsPage';
import { WDialog } from 'blocks/WDialog/WDialog';
import { wikilist } from 'blocks/WIKIList/WIKIList';
import { Workflows } from 'blocks/Workflows/Workflows';
import { Welcome } from '../blocks/Welcome/Welcome';
// import { Survey } from 'blocks/Survey/Survey';
import { DevelopPage } from 'blocks/_Develop';
import { AchievementsPage } from 'blocks/Achievements';
import { BadgesPage } from 'blocks/BadgesPage/BadgesPage';
import { DeviceInfo } from 'blocks/DeviceInfo';
import { ExternalRedirect } from 'blocks/ExternalRedirect';
import { ProfileEdit } from 'blocks/InfoBlock/UserNew/_edit/ProfileEdit';
import { ProductCardMui } from 'blocks/ShopMui/ProductCardMui/ProductCardMui';
import { Survey } from 'blocks/SurveyMui/Survey';
import { Tasks } from 'blocks/Tasks/Tasks';
import { UserNew } from 'blocks/UserMui5/User';
import { VacanciesPage } from 'blocks/Vacancies/Pages/VacanciesPage';
import { VacancyPage } from 'blocks/Vacancies/Pages/VacancyPage';
import { pageNames } from 'redux/pageNames';
import { TValue } from 'utils/src';

interface IPageItem {
    c: React.ComponentType<any>;
    props?: Record<string, any>;
}

interface IRoute {
    path: TValue<typeof urls>;
    props?: RouteProps;
    item: IPageItem;
    accessOnlyAdmin?: boolean;
    pageName?: string;
}

export const routes: IRoute[] = [
    {
        path: urls.deviceInfo,
        item: {
            c: DeviceInfo,
        },
    },
    {
        path: urls._widgetresponsive,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames._widgetresponsive,
                },
            },
        },
    },
    {
        path: urls._widgetdebug,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames._widgetdebug,
                },
            },
        },
    },
    {
        path: urls._develop,
        item: {
            c: DevelopPage,
        },
    },
    {
        path: urls._appsDebug,
        item: {
            c: Page,
            props: {
                className: 'appsDebug',
                page: {
                    name: pageNames._appsDebug,
                },
            },
        },
    },
    {
        path: urls.dash,
        item: {
            c: Dash,
        },
    },
    {
        path: urls.users,
        item: {
            c: Users,
        },
    },
    {
        path: urls.user,
        item: {
            c: User,
        },
    },
    {
        path: urls.profileEdit,
        item: {
            c: ProfileEdit,
        },
    },
    {
        path: urls.userNew,
        item: {
            c: UserNew,
        },
    },
    {
        path: urls.invite,
        item: {
            c: Invite,
        },
    },
    {
        path: urls.groups,
        item: {
            c: Groups,
        },
    },
    {
        path: urls.group,
        item: {
            c: Group,
        },
    },
    {
        path: urls.messages,
        item: {
            c: Messages,
        },
    },
    {
        path: urls.news,
        item: {
            c: News,
        },
    },
    {
        path: urls.tag,
        item: {
            c: Tag,
        },
    },
    {
        path: urls.badge,
        item: {
            c: Badge,
        },
    },
    {
        path: urls.notificationsSettings,
        item: {
            c: NotificationsSettings,
        },
    },
    {
        path: urls.pages,
        item: {
            c: PagesNew,
        },
    },
    {
        path: urls.rating,
        item: {
            c: Rating,
        },
    },
    {
        path: urls.mountainRating,
        item: {
            c: MountainRating,
        },
    },
    {
        path: urls.shop,
        item: {
            c: Shop,
        },
    },
    {
        path: urls.orders,
        item: {
            c: Orders,
        },
    },
    {
        path: urls.competitions,
        item: {
            c: Competitions,
        },
    },
    {
        path: urls.ideas,
        item: {
            c: Ideas,
        },
    },
    {
        path: urls.birthdays,
        item: {
            c: Birthdays,
        },
    },
    {
        path: urls.birthdaysNoMonth,
        item: {
            c: Redirect,
            props: {
                to: `${urls.birthdaysNoMonth}/${new Date().getMonth() + 1}`,
            },
        },
    },
    {
        path: urls.lms,
        item: {
            c: Courses,
        },
    },
    {
        path: urls.lmsTest,
        item: {
            c: LmsTest,
        },
    },
    {
        path: urls.lmsResult,
        item: {
            c: Results,
        },
    },
    {
        path: urls.lmsCourse,
        item: {
            c: Course,
        },
    },
    {
        path: urls.lmsTeacherCourse,
        item: {
            c: CourseView,
        },
    },
    {
        path: urls.lmsCourseCreate,
        item: {
            c: CourseCreate,
        },
    },
    {
        path: urls.lmsTeacherOffice,
        item: {
            c: LMSTeacherOffice,
        },
    },
    {
        path: urls.lmsStatistics,
        item: {
            c: LmsStatView,
        },
        accessOnlyAdmin: true,
    },
    {
        path: urls.lmsStatisticsUser,
        item: {
            c: LmsFullStatUser,
        },
        accessOnlyAdmin: true,
    },
    {
        path: urls.tracksManage,
        item: {
            c: TrackList,
        },
    },
    {
        path: urls.trackManage,
        item: {
            c: ManageTrack,
        },
    },
    {
        path: urls.trackView,
        item: {
            c: TrackView,
        },
    },
    {
        path: urls.learningTracks,
        item: {
            c: TrackListUser,
        },
    },
    {
        path: urls.photoalbum,
        item: {
            c: PhotoalbumContainer,
        },
    },
    {
        path: urls.album,
        item: {
            c: AlbumView,
        },
    },
    {
        path: urls.widgets,
        item: {
            c: Widgets,
        },
    },
    {
        path: urls.integrate,
        item: {
            c: Integrate,
        },
    },
    {
        path: urls.wdialogs,
        item: {
            c: WDialog,
        },
    },
    {
        path: urls.questsmoderate,
        item: {
            c: questsmoderate,
        },
    },
    {
        path: urls.events,
        item: {
            c: Events,
        },
    },
    {
        path: urls.polls,
        item: {
            c: Polls,
        },
    },
    {
        path: urls.creativetasks,
        item: {
            c: CreativeTasks,
        },
    },
    {
        path: urls.calendar,
        item: {
            c: Page,
            props: {
                className: 'CalendarPage',
                page: {
                    name: pageNames.calendar,
                },
            },
        },
    },
    {
        path: urls.settingsDesign,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames.settingsDesign,
                },
            },
        },
    },
    {
        path: urls.notices,
        item: {
            c: Notices,
        },
    },
    {
        path: urls.thanks,
        item: {
            c: Thanks,
        },
    },
    {
        path: urls.wikilist,
        item: {
            c: wikilist,
        },
    },
    {
        path: urls.board,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames.board,
                },
            },
        },
    },
    {
        path: urls.search,
        item: {
            c: SearchMui,
        },
    },
    {
        path: urls.quiz,
        item: {
            c: Quiz,
        },
    },
    {
        path: urls.shopProduct,
        item: {
            c: ProductCardMui,
        },
    },
    {
        path: urls.notifiSettings,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames.notifiSettings,
                },
            },
        },
    },
    {
        path: urls.notfound,
        item: {
            c: NotFound,
        },
    },
    {
        path: urls.error500,
        item: {
            c: Error500,
        },
    },
    {
        path: urls.welcome,
        item: {
            c: Welcome,
        },
    },
    {
        path: urls.quests,
        item: {
            c: QuestsPage,
        },
    },
    {
        path: urls.workflows,
        item: {
            c: Workflows,
        },
    },
    {
        path: urls.orgchart,
        item: {
            c: OrgChartTree,
        },
    },
    {
        path: urls.dashboard,
        item: {
            c: Dashboard,
        },
    },
    {
        path: urls.secretsanta,
        item: {
            c: SecretSantaWidgetPage,
        },
    },
    {
        path: urls.statistics,
        item: {
            c: StatisticsPage,
        },
    },
    {
        path: urls.survey,
        item: {
            c: Survey,
        },
    },
    {
        path: urls.badges,
        item: {
            c: BadgesPage,
        },
    },
    {
        path: urls.vacancies,
        item: {
            c: VacanciesPage,
        },
    },
    {
        path: urls.vacancy,
        item: {
            c: VacancyPage,
        },
    },
    {
        path: urls.achievements,
        item: {
            c: AchievementsPage,
        },
    },
    {
        path: urls.widgets_v2,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames.widgets_v2,
                },
            },
        },
    },
    {
        path: urls.widgets_horizontal_v2,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames.widgets_horizontal_v2,
                },
            },
        },
    },
    {
        path: urls.external,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames.external,
                },
            },
        },
    },
    {
        path: urls.tasks,
        item: {
            c: Tasks,
        },
    },
    {
        path: urls.currentApplications,
        item: {
            c: Page,
            props: {
                page: {
                    name: pageNames.currentApplications,
                },
            },
        },
    },

    {
        path: urls.externalRedirect,
        item: {
            c: ExternalRedirect,
        },
    },
].map((el: IRoute) => {
    if (!el.props) el.props = {};
    if (typeof el.path === 'string') {
        el.props.exact = exactPathnames.includes(el.path);
    }
    return el;
});

export default routes;
