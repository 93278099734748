import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { IDispatchProps } from 'redux/connector';
import { WithWidgetProps } from 'Widgets_v2/types';

export interface IWikiProps extends IClassNameProps, IWikiStateProps, IDispatchProps, WithWidgetProps {
  tag?: 'div';
  data?: any;
  widget: any;
  editToggle?: boolean;
  saveData(d: any): void;
  updateWidget: (d: any) => void;
  saveWidgetData: (cd?: (result?: boolean) => void) => void;
}

export interface IWikiState {
  text?: string;
  edit: boolean;
}

export interface IWikiStateProps {
  edit: boolean;
  isAdmin: boolean;
  maxFileSize?: number;
  textEditorColors?: string[];
}

export const mapStateToProps = (state: any) => {
  return ({
  edit: state.store.edit,
  isAdmin: getAuthUser(state).baseData.isAdmin,
  maxFileSize: state.store.appSettings.maxFileSizeLimit,
  textEditorColors: state.store?.appDesign?.colors?.textEditorColors as string[] | undefined
})}

export const cnWiki = cn('Wiki');