import { Box, Divider, Typography } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link/Link';
import { styled } from 'muicomponents/src/mui/system';
import {
    PageCollectionSuggester as PageCollectionSuggesterMui,
    BaseSuggester as BaseSuggesterMui,
} from 'muicomponents/src/Suggester';
import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import CardMedia from '@mui/material/CardMedia';

export const EditWrapBox = styled(Box)({
    padding: '24px',
}) as typeof Box;

export const SkeletonMainWrapBox = styled(Box)({
    flex: 1,
}) as typeof Box;
export const SkeletonWrapBox = styled(Box)({
    '&:before': {
        content: '""',
        paddingTop: '62.5%',
        float: 'left',
        width: '100%',
    },
}) as typeof Box;
export const MainSkeletonStyle = styled(Skeleton)({
    m: 0,
    p: 0,
    mr: 3,
}) as typeof Skeleton;
export const SkeletonStyle = styled(Skeleton)({
    flex: 1,
    mr: 1,
    width: '100%',
}) as typeof Skeleton;

const defaultSuggesterStyle = {
    '& .MuiInputBase-root': {
        paddingRight: '39px !important',
    },
};
export const WidgetArticlesBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    background: '#FFFFFF',
    boxShadow: ' 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    gap: '16px',
    padding: '24px',
    boxSizing: 'border-box',
});
export const AdditionalButtonsWrapBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});
export const ListContentBox = styled(Box)({
    display: 'flex',
    gap: '16px',
}) as typeof Box;

export const DividerStyled = styled(Divider)({
    height: '30px',
    margin: '0 8px',
}) as typeof Divider;

export const UppercaseLink = styled(Link)({
    textTransform: 'uppercase',
}) as typeof Link;

export const CardStyled = styled(Card)({
    position: 'relative',
    flex: '1',
    display: 'flex',
    boxShadow: 'none',
    background: 'rgba(245, 245, 245, 1)',
    boxSizing: 'border-box',
});

export const CardContentStyled = styled(CardContent)({
    padding: '8px 16px',
    boxSizing: 'border-box',
    minHeight: 32,
    '&:last-child': {
        paddingBottom: '16px',
    },
});

export const WidgetDialogBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
}) as typeof Box;

export const CardMediaWrapBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '24px',
    height: '350px',
    maxHeight: '350px',
    overflowX: 'hidden',
    overflowY: 'auto',
}) as typeof Box;

export const CardMediaStyle = styled(CardMedia)({
    width: 'auto',
    height: '270px',
    marginBottom: '24px',
}) as typeof CardMedia;

export const HeaderWrapBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
}) as typeof Box;

export const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
}) as typeof Box;

export const HeaderButtonsWrapBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
}) as typeof Box;

export const HeaderButtonsBox = styled(Box)({
    display: 'flex',
    minWidth: '90px',
}) as typeof Box;

export const ActionBox = styled(Box)({
    display: 'flex',
    height: '24px',
    bottom: '0',
    position: 'relative',
    padding: '0px 16px 16px 16px',
    justifyContent: 'space-between',
}) as typeof Box;

export const ActionContetBox = styled(Box)({
    padding: '4px 0',
    display: 'flex',
    alignItems: 'center',
}) as typeof Box;

export const TypographyArticles = styled(Typography)({
    fontWeight: '700',
}) as typeof Typography;

export const TypographyDateArticles = styled(Typography)({
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.38)',
}) as typeof Typography;

export const LinkStyled = styled(Link)({
    position: 'relative',
    flex: '1 1',
    display: 'flex',
    textDecoration: 'none',
}) as typeof Link;

export const BaseSuggester = styled(BaseSuggesterMui)({
    ...defaultSuggesterStyle,
}) as typeof BaseSuggesterMui;

export const PageCollection = styled(PageCollectionSuggesterMui)({
    ...defaultSuggesterStyle,
}) as typeof PageCollectionSuggesterMui;
