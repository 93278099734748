import { cnWidgetMui } from 'blocks/Widget/Widget.index';
import { Translate } from 'localization';
import { Divider, UsersSuggester } from 'muicomponents/src';
import { DateRangePicker } from 'muicomponents/src/DatePicker';
import React, { ComponentProps, FC, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SSelectableObject } from 'utils/src';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';
import { UsersEventesHead } from './Head/UsersEventesHead';
import { UsersEventesHeadCheckboxes } from './HeadCheckboxes/UsersEventesHeadCheckboxes';
import { initUsersEventsTableAddSettings, initUsersEventsTableSettings } from './Settings/UsersEventsTableSettings';
import { UserEventsTable } from './Table/UsersEventsTable';
import { UsersEventsContextProvider } from './UsersEvents.context';
import { cnUsersEvents } from './UsersEvents.index';
import { HeadBottomBox, HeadBox, TableBox } from './UsersEvents.styled';
import { getDefaultFilters } from './UsersEvents.utils';

initUsersEventsTableAddSettings();
initUsersEventsTableSettings();

export const UsersEvents: FC<ComponentProps<typeof UsersEventsContextProvider> & {}> = ({
    calendars,
    settings,
    userId,
    cIds,
}) => {
    const defaultFilters = useMemo(() => {
        return getDefaultFilters(settings);
    }, [settings]);
    const defaultFiltersRef = useRef(defaultFilters);
    defaultFiltersRef.current = defaultFilters;

    const currentUser = useSelector(getCurrentUser);
    const showUserSuggester = useMemo(() => {
        const fields: any = currentUser?.profileData?.userInfoFields?.entities?.fields ?? {};
        const founded = Object.values(fields).find(
            (field: any) => field?.field.fieldType === 'SubstituteForUser'
        ) as any;
        return founded?.users?.length || false;
    }, [currentUser?.profileData?.userInfoFields]);

    const [user, setUser] = useState<SSelectableObject | null>(defaultFilters.user);
    const [dates, setDates] = useState<ComponentProps<typeof DateRangePicker>['value']>(defaultFilters.dates);
    const [showOthers, setShowOthers] = useState(false);

    const prepairedUserId = useMemo(() => {
        return user?.id || userId;
    }, [userId, user?.id]);

    const [activeBlocks, setActiveBlocks] = useState(defaultFilters.activeBlocks);

    const onDrop = useCallback(() => {
        setUser(defaultFiltersRef.current.user);
        setDates(defaultFiltersRef.current.dates);
        setActiveBlocks(defaultFiltersRef.current.activeBlocks);
    }, []);
    // activeBloks: prepairedSettings.headBlocks.map((_, idx) => idx)

    return (
        <UsersEventsContextProvider
            userId={prepairedUserId}
            activeBlocks={activeBlocks}
            cIds={cIds}
            calendars={calendars}
            settings={settings}
            showOthers={showOthers}
        >
            <HeadBox className={cnWidgetMui({}, [cnUsersEvents('Header')])}>
                <UsersEventesHead dates={dates} />
                <Divider orientation="horizontal" flexItem />
                <HeadBottomBox>
                    <DateRangePicker value={dates} onChange={(value) => setDates(value)} />
                    {showUserSuggester && (
                        <UsersSuggester
                            fullWidth
                            value={user}
                            onChange={(e, value) => {
                                if (!Array.isArray(value)) {
                                    setUser(value);
                                }
                            }}
                            requestAdditionalParams={{
                                module: 'substituteByMeUsers',
                            }}
                            TextFieldProps={{
                                label: <Translate i18nKey={'pryaniky.usersevents.header.substitute'} />,
                                sx: {
                                    maxWidth: '320px',
                                },
                            }}
                        />
                    )}
                </HeadBottomBox>
                <UsersEventesHeadCheckboxes
                    changeOthers={setShowOthers}
                    onChange={setActiveBlocks}
                    onDrop={onDrop}
                />
            </HeadBox>
            <TableBox className={cnWidgetMui({}, [cnUsersEvents('Table')])}>
                <UserEventsTable dateEnd={dates[1] as string} dateStart={dates[0] as string} />
            </TableBox>
        </UsersEventsContextProvider>
    );
};
