import { Box, Typography, styled, Paper, Button } from 'muicomponents/src';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs';

export const ArrowBlock = styled(Box)({
    display: 'flex',
    marginBottom: '-14px',
}) as typeof Box;

export const SeparatedBox = styled(Box)(({ theme }) => ({
    marginBottom: '16px',
})) as typeof Box;

export const GrayTypography = styled(Typography)({
    fontSize: '16px',
}) as typeof Typography;

export const BlackTypography = styled(Typography)({
    fontSize: '16px',
    '& a': {
        fontWeight: '400 !important',
        fontSize: '16px !important',
    },
}) as typeof Typography;

export const StatusTypography = styled(Typography)({
    position: 'relative',
    width: 'fit-content',
}) as typeof Typography;

export const StatusTextBox = styled(Box)({
    display: 'inline',
    whiteSpace: 'pre-wrap',
}) as typeof Box;

export const ShowMoreButton = styled(Button)({
    textTransform: 'unset',
    display: 'inline-flex',
    font: 'inherit',
    background: 'transparent',
    ['&.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover']: {
        background: 'transparent',
    },
}) as typeof Button;

export const ShowMoreElipsesBox = styled(Box)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '-30px',
    width: '30px',
    textAlign: 'end',
    background: 'linear-gradient(to right, transparent 0, white 100%)',
}) as typeof Box;

export const ShowMoreTextTypography = styled(Typography)({
    textTransform: 'lowercase',
}) as typeof Typography;

export const NameTypography = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: '500 !important',
    // marginBottom: theme.spacing(2),
})) as typeof Typography;

export const PaperWrap = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
})) as typeof Paper;
