import { IconButton } from "@mui/material";
import { WikiImageCardButton } from "blocks/WikiListNew/Items/style";
import { Box, styled } from "muicomponents/src";

export const SettingsListBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    gridGap: 16,
    padding: 16,
    [' > *']: {
        flexBasis: 'calc(50% - 8px)',
        minWidth: 0
    }
}) as typeof Box;


export const StyledWikiImageCardButton = styled(WikiImageCardButton)({
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'flex-start',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    overflow: 'hidden'
}) as typeof WikiImageCardButton;

export const SelectedRemoveButton = styled(IconButton)({
    position: 'absolute',
    top: 0,
    right: 0
}) as typeof IconButton;