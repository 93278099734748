import React, { ComponentProps, useState } from 'react';
import { CurrentApplicationsFilterStatus, CurrentApplicationsModelStatus } from 'utils';
import { getApplications } from 'utils/src/requests/requests.applications';
import { CurrentApplicationsListProps } from './CurrentApplicationsList.index';
import { Translate } from 'localization';
import { DateRangePicker } from 'muicomponents/src/DatePicker/DatePicker';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { HistoryToggleOff, CheckCircle, Info, Pending } from 'muicomponents/src/Icons';
import { DatesBox, ResetButton } from './CurrentApplicationsList.styled';
import { useReffedState } from 'utils/src/hooks';
import { TableList } from 'blocks/TableList/List';

const getDateTime = (val: Date | null | string) => {
    if (!val) return 0;
    return (typeof val === 'string' ? new Date(val) : val).getTime();
};

export const CurrentApplicationsList = ({ context }: CurrentApplicationsListProps) => {
    const nowDate = new Date();
    nowDate.setHours(0, 0, 0, 0);
    const futureDate = new Date(nowDate);
    futureDate.setHours(0, 0, 0, 0);
    futureDate.setDate(nowDate.getDate() + 30);

    const [rangeDatesState, setRangeDatesState, rangeDatesStateRef] = useReffedState<{
        start: Date | null | string;
        end: Date | null | string;
    }>({
        start: nowDate,
        end: futureDate,
    });

    const [activeFilter, setActiveFilter] = useState<CurrentApplicationsFilterStatus>(
        CurrentApplicationsFilterStatus.initiatedByMe
    );

    const getData: typeof getApplications = (props) => {
        const { userId, filter, ...params } = props;
        const newFieldsRequest: any = {};

        switch (filter) {
            case CurrentApplicationsFilterStatus.initiatedByMe:
                newFieldsRequest.creatorUser = userId;
                newFieldsRequest.status =
                    CurrentApplicationsModelStatus.UnderApproval + ',' + CurrentApplicationsModelStatus.agreed;

                break;
            case CurrentApplicationsFilterStatus.agreedByMe:
                newFieldsRequest.users = userId;
                newFieldsRequest.status = CurrentApplicationsModelStatus.agreed;

                break;
            case CurrentApplicationsFilterStatus.archive:
                newFieldsRequest.creatorUser = userId;
                newFieldsRequest.status =
                    CurrentApplicationsModelStatus.implemented +
                    ',' +
                    CurrentApplicationsModelStatus.cancelled +
                    ',' +
                    CurrentApplicationsModelStatus.completed;

                break;
            case CurrentApplicationsFilterStatus.underApproval:
                newFieldsRequest.users = userId;
                newFieldsRequest.status = CurrentApplicationsModelStatus.UnderApproval;
                break;
            default:
                break;
        }

        newFieldsRequest.startDate =
            rangeDatesStateRef.current.start instanceof Date
                ? rangeDatesStateRef.current.start.toISOString()
                : rangeDatesState.start;
        newFieldsRequest.endDate =
            rangeDatesStateRef.current.end instanceof Date
                ? rangeDatesStateRef.current.end.toISOString()
                : rangeDatesState.end;

        return getApplications({ userId, ...params, ...newFieldsRequest });
    };

    return (
        <TableList<CurrentApplicationsFilterStatus>
            context={context}
            filterOptions={Object.typedKeys(CurrentApplicationsFilterStatus).map((key) => {
                return {
                    title: <Translate i18nKey={`pryaniky.currentApplications.filter.${key}`} />,
                    value: CurrentApplicationsFilterStatus[key],
                };
            })}
            selectedFilter={activeFilter}
            onFilterChange={setActiveFilter}
            defaultFilter={CurrentApplicationsFilterStatus.initiatedByMe}
            defaultValue={''}
            getData={getData as any as ComponentProps<typeof TableList>['getData']}
            isWithActionButton={false}
            strForLocalization={'currentApplications'}
            createItemProps={() => ({
                createStatusIcon: (status) => {
                    switch (status) {
                        case CurrentApplicationsModelStatus.completed:
                            return <CheckCircle color={'success'} />;
                        case CurrentApplicationsModelStatus.deferred:
                            return <HistoryToggleOff color={'warning'} />;
                        case CurrentApplicationsModelStatus.inProgress:
                            return <Pending color={'info'} />;
                        case CurrentApplicationsModelStatus.notStarted:
                            return <HistoryToggleOff color={'warning'} />;
                        default:
                            return <Info color="error" />;
                    }
                },
            })}
            bottomContent={
                <DatesBox>
                    <DateRangePicker
                        value={[rangeDatesState.start, rangeDatesState.end]}
                        onChange={([start, end]) =>
                            setRangeDatesState({
                                start,
                                end,
                            })
                        }
                    />
                    <ResetButton
                        disabled={
                            activeFilter === CurrentApplicationsFilterStatus.initiatedByMe &&
                            getDateTime(rangeDatesState.start) === nowDate.getTime() &&
                            getDateTime(rangeDatesState.end) === futureDate.getTime()
                        }
                        onClick={() => {
                            setActiveFilter(CurrentApplicationsFilterStatus.initiatedByMe);
                            setRangeDatesState({
                                start: nowDate,
                                end: futureDate,
                            });
                        }}
                        startIcon={<CleaningServicesIcon />}
                    >
                        {Translate.t({ i18nKey: 'reset' })}
                    </ResetButton>
                </DatesBox>
            }
        />
    );
};
