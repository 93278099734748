import { IClassNameProps } from '@bem-react/core';
import actions from 'redux/actionsTypes/Widgets';
import { Dispatch } from 'redux';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { RouteComponentProps } from 'react-router';
import { ComponentProps } from 'react';
import { Box } from 'muicomponents/src';

export type TFilterOptsData = {
    id: any;
    type: any;
    title: string;
    value: any;
    [field: string]: any;
};
export type TFilterData = { icon?: React.ReactNode; opts: TFilterOptsData[] };

export interface IFiltersProps extends IFiltersOwnProps, IClassNameProps, IFiltersStateProps, IFiltersDispatchProps {}

export interface IFiltersRouteProps {}
export interface IFilterContext {
    filter?: Record<string, any>;
    [s: string]: any;
}
export interface IFiltersOwnProps {
    context: IFilterContext;
    relations: string[];
    setCountFilterItems?: (count: number) => void;
    countFilterItems?: number;
}

export interface ICommonFiltersProps extends IFiltersProps, RouteComponentProps<{}> {
    additionalFields?: string[];
    getDataFromContext?: (type: string) => any;
    withResetBtn?: boolean;
    getCategories?: (setData: React.Dispatch<React.SetStateAction<TFilterData[]>>, authUser?: any) => void;
    typeStr: string;
    titleStr: string;
    filterData: TFilterData[];
    headerData: Record<string, string>;
    defaulValues: { [k: string]: string };
    defaulIds: { [k: string]: string };
    className?: string;
    wrapProps?: ComponentProps<typeof Box>;
}

export const mapStateToProps = (state: IState) => ({
    authUser: getAuthUser(state),
    itemsView: {
        isAdmin: getAuthUser(state).baseData.isAdmin,
        isSubnetworks: state.store.appSettings && Boolean(state.store.appSettings.parentNetwork),
        enableAutoRegistration: (state.store.appSettings &&
            (state.store.appSettings.enableAutoRegistration ||
                state.store.appSettings.enableAutoRegistrationWithTrustedDomains)) as boolean,
    },
    edit: state.store.edit,
    enabledModules: state.store.appSettings ? state.store.appSettings.enabledModules : {},
});

export type IFiltersStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeContext: (paths: string[], innerPath: string, value: any) =>
        dispatch({ type: 'CHANGE_CONTEXT', payload: { paths, innerPath, value } }),
    changeContextv3: (payload: Array<{ [s: string]: any }>) => dispatch({ type: actions.UPDATE_CONTEXTS, payload }),
});

export type IFiltersDispatchProps = ReturnType<typeof mapDispatchToProps>;
