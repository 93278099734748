import { EditorState } from 'draft-js';
import { changeReplyForm, sendReply, toggleThank } from 'News/redux/actions';
import { getReplyFormById } from 'News/redux/saga/selectors';
import {} from 'News/types/reply';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { Rule } from '../NewsTypes/Base.validate';
import { createDefaultReply } from '../NewsTypes/Reply/Reply.type';
export interface IReplyEditorPropsType extends IDispatchNews, ReturnType<typeof mapStateToProps> {
    isThanks: boolean;
    users: any;
    newsId: string;
    newsAuthor: any;
    newComment?: any;
    avatarSize?: number;
    withRating?: boolean;
}

export interface IReplyEditorStateType {
    validFile: boolean;
    isValid: boolean;
    validationErrors: Rule[];
    postErrorText: string;
    postErrorCode: number;
}
export const mapStateToProps = (state: any, props: any) => {
    let commentModel = getReplyFormById(props.newsId)(state) || {
        ...createDefaultReply(),
        user: getAuthUser(state).baseData,
        contentRating: props.withRating ? 0 : null,
        ratingEnabled: props.withRating,
    };
    if (commentModel.user.id === '') commentModel = { ...commentModel, user: getAuthUser(state).baseData };
    if (!commentModel.users) commentModel = { ...commentModel, users: props.users };
    return {
        userData: getAuthUser(state),
        commentModel,
        maxFileSize: state.store.appSettings.maxFileSizeLimit,
        denyMIME: state.store.appSettings.denyMIME,
    };
};

export interface IDispatchNews {
    changeNewCommnet?: any;
    sendReply: typeof sendReply;
    changeReplyForm: typeof changeReplyForm;
    toggleThank: typeof toggleThank;
}

export const mapDispatchToProps = {
    sendReply,
    changeReplyForm,
    toggleThank,
};
