import { Dictionary } from "lodash";
import moment from "moment";
import React, { ComponentProps, createContext, FC, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { SCalendar } from "utils/src";
import { getCurrentUserId } from "utils/src/CommonRedux/base/selectors";
import { baseUsersEventsSettings } from "./Settings/UsersEventsTableSettings.constants";
import { UsersEventsSettings } from "./Settings/UsersEventsTableSettings.index";
import { MonthHeader } from "./Table/MonthHeader";

type UsersEventsContextType = {
    showOthers: boolean;
    userId: string;
    cIds: string[];
    calendars: Dictionary<SCalendar>;
    settings: UsersEventsSettings;
    activeBlocks: UsersEventsSettings['headBlocks'][number]['id'][];
}

const UsersEventsContext = createContext<UsersEventsContextType>({
    showOthers: false,
    userId: '',
    cIds: [],
    calendars: {},
    settings: baseUsersEventsSettings,
    activeBlocks: []
});

export const getMonthKey = (date: moment.Moment | Date) => {
    return moment(date).format('MM-YYYY');
};

export const useUsersEventsContext = () => {
    return useContext(UsersEventsContext);
};

export const UsersEventsContextProvider: FC<Partial<UsersEventsContextType & {
    settings: UsersEventsSettings;
}>> = ({
    showOthers,
    calendars,
    settings,
    userId,
    cIds,
    activeBlocks = [],
    children
}) => {
    
    const authUserId = useSelector(getCurrentUserId);
    
    const contextValue = useMemo<UsersEventsContextType>(() => {
        const prepairedSettings = settings || baseUsersEventsSettings;
        const cIds = prepairedSettings.headBlocks.filter((block) => !!activeBlocks.length ? activeBlocks.includes(block.id) : true).map(block => block.calendars).flat();
        return {
            showOthers: showOthers || false,
            userId: userId ?? authUserId,
            cIds: cIds || [
                // '87f3c338-a03f-4c2e-b8d8-9eed799645d9',
                // '181c86c0-2855-41cf-a905-8af31d8a6699'
            ],
            calendars: calendars || {},
            settings: prepairedSettings,
            activeBlocks
        };
    }, [userId, showOthers, cIds, authUserId, calendars, settings, activeBlocks]);

    return (
        <UsersEventsContext.Provider value={contextValue}>
            {children}
        </UsersEventsContext.Provider>
    )
};