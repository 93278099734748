import { Translate } from 'localization';
import {
  Box,
  DelayPublicationPicker,
  Divider,
  FileDropZone as FileDropZoneComp,
  FilesUploader as FilesUploaderComp,
  PostToGroup,
} from 'muicomponents/src';
import { DraftContextProps, DraftContextProvider } from 'muicomponents/src/DraftComponents/Editor/Editor';
import { ErrorOutline } from 'muicomponents/src/Icons/Icons';
import { PrepareErrorText } from 'News/utils/utils.validate';
import React, { ComponentProps, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAppSettings } from 'utils/src/CommonRedux/base/selectors';
import { useDialogContext } from 'utils/src/DialogCreator';
import { useEnvironmentContex } from 'utils/src/EnvironmentContex';
import { ItemType, mapFromAttachmentsList, useFileUploader } from 'utils/src/useFileUploader';
import { NewsCreateContext } from '../Creator/Creator.constants';
import { memoizedComponent } from '../utils.sender';
import { useNewsSender } from './Sender.hooks';
import { cnNewsSender, NNewsSender } from './Sender.index';
import './Sender.scss';
import { StlyedPostNewsButton, StyledSenderBox } from './Sender.styled';

const FilesUploader = memoizedComponent(FilesUploaderComp);

const FileDropZone = memoizedComponent(FileDropZoneComp);

const ErrorPresenter: FC<{}> = ({ }) => {
    return (
        <div className={cnNewsSender('Error')}>
            <div className={'Icon'}>
                <ErrorOutline />
            </div>
            <div>
                <h4>
                    <Translate i18nKey={'pryaniky.news.create.error.title'} />
                </h4>
                {/* <p>
                    <Translate i18nKey={'pryaniky.news.create.error.description'} />
                </p> */}
            </div>
        </div>
    );
};

export const NewsSenderActions: FC<NNewsSender.Props> = ({ creatorOptions, disableSend }) => {
    const { isMobileOS } = useEnvironmentContex();

    const { creatorId, groupId } = useContext(NewsCreateContext);

    const {
        group,
        publishAt,
        attachments,
        newstype,
        isValid,
        errors,
        isAdmin,
        newsCreatorChange,
        newsCreatorNewsChange,
        postNews,
        newsSending,
        validateNews,
    } = useNewsSender(creatorId);

    const senderBoxSx = useMemo<ComponentProps<typeof StyledSenderBox>['sx']>(
        () => ({
            flexDirection: isMobileOS ? 'column' : undefined,
        }),
        [isMobileOS]
    );

    const selectorsSx = useMemo<ComponentProps<typeof DelayPublicationPicker>['ButtonProps']>(
        () => ({
            sx: {
                padding: '8px 22px',
                justifyContent: 'center',
            },
        }),
        []
    );

    const publishAtChange = useCallback<ComponentProps<typeof DelayPublicationPicker>['onChange']>((iso) => {
        newsCreatorNewsChange({
            publishAt: iso || '',
        });
    }, []);

    const propsChange = useCallback<ComponentProps<typeof PostToGroup>['onChange']>((group) => {
        newsCreatorNewsChange({
            group,
        });
    }, []);

    const hideGroupSelector = useMemo(() => {
        return groupId !== -1 || creatorOptions?.formSettings?.root?.hideGroupSelector;
    }, [groupId, creatorOptions?.formSettings?.root?.hideGroupSelector]);

    const isSendAllowed = disableSend || !!errors.text;

    return (
        <StyledSenderBox className={cnNewsSender('Actions')} sx={senderBoxSx}>
            {isAdmin && (
                <DelayPublicationPicker
                    value={publishAt || null}
                    onChange={publishAtChange}
                    ButtonProps={selectorsSx}
                />
            )}
            {!hideGroupSelector && (
                <PostToGroup
                    typeNews={newstype?.toString()}
                    value={group}
                    onChange={propsChange}
                    ButtonProps={selectorsSx}
                />
            )}
            <StlyedPostNewsButton
                id={'SENDNEWSBUTTON'}
                variant="contained"
                size="large"
                loading={newsSending}
                disabled={disableSend}
                onClick={postNews}
            >
                <Translate i18nKey="publicate" />
            </StlyedPostNewsButton>
        </StyledSenderBox>
    );
};

/**
 * component for fill base news data like post to group, post in setted time
 * @param param0
 * @returns
 */
const NewsSenderPresenter: FC<NNewsSender.Props> = ({
    // sendNews,
    creatorOptions,
    className,
    children,
}) => {
    const { creatorId } = useContext(NewsCreateContext);

    const {
        group,
        publishAt,
        attachments,
        newstype,
        isValid,
        errors,
        isAdmin,
        isModerator,
        newsCreatorChange,
        newsCreatorNewsChange,
        postNews,
        validateNews,
        hideSender,
    } = useNewsSender(creatorId);

    const { maxFileSizeLimit } = useSelector<any, { maxFileSizeLimit: number }>(getAppSettings);

    const [boxElement, setBoxElement] = useState<HTMLElement | null>(null);

    const [draftWork, setDraftWork] = useState(false);
    const [draftFocus, setDraftFocus] = useState(false);
    const draftContext = useMemo<DraftContextProps>(
        () => ({
            locker: setDraftWork,
            maxFileSizeLimit: (isAdmin || isModerator ? undefined : maxFileSizeLimit),
            onFocus: () => setDraftFocus(true),
            onBlur: () => setDraftFocus(false),
        }),
        [setDraftWork]
    );
    const errorHandler = (item: ItemType, error?: any) => {
        if (error && error.error_code !== 0) {
            switch (error.error_code) {
                case 4050:
                    toast.error(
                        <Translate
                            i18nKey={'pryaniky.file.size.error'}
                            values={{
                                fileName: item.name,
                                maxSize: maxFileSizeLimit,
                            }}
                        />)
                    break;
                case 4052:
                    toast.error(error?.error_text);
                    break;
                default:
                    if (error.error_text)
                        toast.error(error.error_text);
                    break;
            }
        }
    }
    const {
        isLoading,
        isDeleting,
        progress,
        startUpload,
        files,
        onFileChange,
        dndRef,
        dragEntered,
        dndTriggerRef,
        removeFile,
        totalSize,
        inputAccept,
        uploadError,
        setFiles,
        getAttachmentsList,
    } = useFileUploader({
        // allowTypes: [],
        // TODO check types
        disablePaste: draftFocus,
        defaultAttachments: (attachments || []) as any,
        uploadOnAdd: true,
        pasteElement: boxElement,
        maxFileSize: (isAdmin || isModerator) ? undefined : maxFileSizeLimit || undefined,
        callbacks: {
            // loadFinish(item, response) {
            //     if (response?.error_code) {
            //         switch (response?.error_code) {
            //             case 4050:
            //                 toast.error(
            //                     <Translate
            //                         i18nKey={'pryaniky.file.size.error'}
            //                         values={{
            //                             fileName: item.name,
            //                             maxSize: maxFileSizeLimit,
            //                         }}
            //                     />
            //                 );
            //                 break;
            //             case 4052:
            //                 toast.error(response?.error_text);
            //                 break;
            //             default:
            //                 if (response?.error_text) toast.error(response?.error_text);
            //                 break;
            //         }
            //     }
            // },
            loadError: () => {
                toast.error(<>Unknown error</>)
            },
            loadFinish: errorHandler,
            checkError: errorHandler,
        },
    });

    // useDidUpdateEffect(() => {
    //     switch(uploadError?.error?.error_code) {
    //         case 4050:
    //             toast.error(<Translate i18nKey={'pryaniky.file.size.error'} values={{
    //                 fileName: uploadError.file?.name,
    //                 maxSize: maxFileSizeLimit
    //             }} />);
    //             break;
    //         case 4052:
    //                 toast.error(uploadError?.error?.error_text);
    //                 break;
    //         default:
    //             if(uploadError?.error?.error_text)
    //                 toast.error(uploadError?.error?.error_text);
    //             break;
    //     }
    // }, [uploadError]);

    useEffect(() => {
        // if(files.reduce((rez, el) => !rez ? rez : (!el.response?.data[0] ? false : true), true)) {
        // }
    }, [files]);

    useEffect(() => {
        // TODO check types
        if (!attachments || !attachments.length) setFiles(mapFromAttachmentsList((attachments || []) as any));
    }, [attachments]);

    useEffect(() => {
        newsCreatorChange({ validFile: !isLoading });
        if (!isLoading && !isDeleting) {
            // TODO check types
            newsCreatorNewsChange({ attachments: getAttachmentsList() as any });
        }
    }, [isLoading, isDeleting]);

    const { isDialog } = useDialogContext(['isDialog']);

    const FilesUploaderProps = useMemo<ComponentProps<typeof FilesUploader>>(
        () => ({
            selectButtonProps: {
                inputId: 'createNewsUploadFile',
                variant: 'outlined',
                children: <Translate i18nKey={'pryaniky.news.create.selectFile'} />,
            },
            removeFile: (item) => {
                // if (item.response?.data?.[0].id) API.files.remove(item.response?.data?.[0].id);
                removeFile(item);
                // const newAttachments = getAttachmentsList().filter((attach) => attach?.name !== item.name);
                // newsCreatorNewsChange({ attachments: newAttachments as any });
            },
        }),
        []
    );

    return (
        <DraftContextProvider value={draftContext}>
            <Box ref={setBoxElement} className={cnNewsSender({}, [className])}>
                {!isValid && <ErrorPresenter />}
                {children}
                {!hideSender && (
                    <>
                        <Divider />
                        <Box ref={dndTriggerRef} sx={{ position: 'relative' }}>
                            <FilesUploader
                                {...FilesUploaderProps}
                                files={files}
                                onFileChange={onFileChange}
                                inputAccept={inputAccept}
                                error={Boolean(errors.attachments)}
                                helperText={
                                    // TODO check types in BaseNews.attachments
                                    Boolean(errors.attachments) && PrepareErrorText(errors.attachments as any)
                                }
                            />
                            <FileDropZone ref={dndRef} show={dragEntered} />
                        </Box>
                        <Divider />
                        {!isDialog && <NewsSenderActions creatorOptions={creatorOptions} disableSend={draftWork} />}
                    </>
                )}
            </Box>
        </DraftContextProvider>
    );
};

export const NewsSender = NewsSenderPresenter;
// export const NewsSender = connect(
//     mapNewsSenderStateToProps,
//     mapNewsSenderActionsToProps
// )(NewsSenderPresenter)
