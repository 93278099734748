import { cn } from '@bem-react/classname';
import { Page } from 'blocks/Page/Page';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { pageNames } from 'redux/pageNames';
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';

const cnTasks = cn('Tasks');

export const Tasks: FC = () => {
    const tasksModuleIsEnabled = useSelector(moduleIsEnabled('tasks'));

    if (!tasksModuleIsEnabled) {
        return <Redirect to={'/404?path=' + window.location.pathname} />;
    }

    return <Page className={cnTasks()} page={{ name: pageNames.tasks }} />;
};
