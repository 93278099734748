import React, { FC } from 'react';
import { Translate } from 'localization';
import { NBadgesItem } from '../Badges.index';
import { ListItemBox, ListItemDescription } from './ItemList.styled';
import { Avatar } from 'muicomponents/src/Avatar';
import { Box, Typography } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link/Link';
import { useSelector } from 'react-redux';
import { IStateType } from 'redux/store';
import { BadgeItemValue } from '../Badges.styled';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn'
import { GridItemTitle, GridItemDescription } from '../ItemGrid/ItemGrid.styled'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';

export const ItemList: FC<NBadgesItem.Props> = ({ className, data, ...props }) => {

    const currencyName = useSelector((state: IStateType) => state.store.appSettings!!.currencyNameGenitivePlural as string);

    return (
        <ListItemBox {...props} className={className}>
            <Link to={`/badge/${data.badgeUid}`}>
                <Avatar
                    src={data.iconUrl || BaseRequests.getUrl(defaultBadgeIconUrl)}
                    sx={{ width: 64, height: 64 }}
                />
            </Link>

            <Box sx={{ color: '#00000099' }}>
                <GridItemTitle href={`/badge/${data.badgeUid}`} >
                    <Typography variant='body2'>
                        <Tooltip title={data?.name?.length > 75 ? data.name : ""}>
                            <span>
                                {data.name}
                            </span></Tooltip>
                    </Typography>
                </GridItemTitle>
                <GridItemDescription>
                    <Tooltip title={data?.description?.length > 160 ? data.description : ""}>
                        <span>{data.description}</span>
                    </Tooltip>
                </GridItemDescription>
                {Boolean(data.totalCount) && (
                    <Typography fontSize={14}>
                        <Translate i18nKey={'pryaniky.badge.item.owners'} />:
                        <BadgeItemValue>{' '}{data.totalCount}</BadgeItemValue>
                    </Typography>
                )}
                {data?.issueCurrency[0]?.key && <Typography fontSize={14}>
                    <Translate i18nKey={'pryaniky.badge.item.earned'} />{' '}
                    <BadgeItemValue>
                        {data?.issueCurrency[0]?.key} {currencyName}
                    </BadgeItemValue>
                </Typography>
                }
            </Box>
        </ListItemBox>
    )
};