import React from 'react';
import { IThanksProps, IThanksState, IThanksStateToProps, IThanksOwnProps, IThanksActionsToProps, ThanksActions, mapStateToProps } from './Thanks.index';
import { Page } from 'blocks/Page/Page';
import { connect } from 'react-redux';
import { IStateType as IState } from 'redux/store';
import { pageNames } from 'redux/pageNames';

class ThanksPresenter extends React.Component<IThanksProps, IThanksState> {

  public componentDidMount() {
    // this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {
    //     newsType: 'Thanks',
    //     postOff: true,
    //   },
    // })
  }

  public componentWillUnmount() {
    // this.props.updateContext('common', {
    //   newsType: undefined,
    //   postOff: false,
    // })
  }

  public componentDidUpdate(pp: IThanksProps) {}

  /**
   * render
   */
  public render() {
    return (
      <Page page={{
        name: pageNames.thanks
      }} />
    )
  }
}

export const Thanks = connect < IThanksStateToProps, IThanksActionsToProps, IThanksOwnProps, IState>(
  mapStateToProps,
  ThanksActions
)(ThanksPresenter)