import React, { FC, useState, createContext, useContext, useMemo } from 'react';
import * as utils from 'utils/src/utils';
import { Box, Button, Chip, Typography } from 'muicomponents/src';
import { generatePath, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { i18n, Translate } from 'localization';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import MDRender from 'uielements/src/CommonmarkRender';
import moment from 'moment';
import { ArrowBlock, BlackTypography, GrayTypography, NameTypography, PaperWrap, SeparatedBox } from './styled';
import DataBlockSkeleton from './Skeleton/DataBlockSkeleton';
import { Link } from 'muicomponents/src/Link/Link';
import { FileItem } from 'muicomponents/src/FileItem/FileItem';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { setStatus, setUserStatus } from 'utils/src/CommonRedux/users/actions';
import { StatusPopover } from './StatusPopover/StatusPopover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { cnInfoBlock } from '../../InfoBlock.index';
import { Divider } from 'muicomponents/src/Divider/Divider';
import { UserChip } from 'muicomponents/src/Chip/type/UserChip';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { formatDateSTD, isValidDate } from 'utils/src/utils.dates';
import CheckIcon from '@mui/icons-material/Check';
import { mainUrls } from 'utils/src';
import queryString from 'query-string';

const defaultCatRender = (cat: string, values: any, FieldRender: React.ComponentType<any>) => {
    return (
        <Box>
            <Box>{cat}</Box>
            <Box>
                {values.map((v: any) => (
                    <FieldRender field={v} />
                ))}
            </Box>
        </Box>
    );
};
const defaultFieldRender = (field: any) => {
    return <></>;
};

const defaultContext: any = {
    categotyRender: defaultCatRender,
    fieldRender: defaultFieldRender,
};

export const Context = createContext<any>(defaultContext);

export const FieldRender: FC<any> = ({ field }) => {
    const { fieldRender } = useContext(Context);
    return <>{fieldRender(field)}</>;
};

export const CatRender: FC<any> = ({ cat, values }) => {
    const { categotyRender } = useContext(Context);
    return <>{categotyRender(cat, values, FieldRender)}</>;
};

export const FieldsRender: FC<any> = ({
    fields = [],
    categotyRender = defaultCatRender,
    fieldRender = defaultFieldRender,
}) => {
    const fieldsCategoryes = useMemo(
        () =>
            (fields as any[]).reduce<any>((a: any, c: any) => {
                const cat = c.field.category;
                a[cat] = [...(a[cat] || []), c];
                return a;
            }, {}),
        [fields]
    );

    const categoryesKeys = Object.keys(fieldsCategoryes)
        .sort()
        .filter((cat: string) => cat !== 'default.fields');

    const context = useMemo<any>(() => {
        return {
            categotyRender,
            fieldRender,
        };
    }, [categotyRender, fieldRender]);

    return (
        <>
            <Context.Provider value={context}>
                {categoryesKeys.map((cat: string) => (
                    <CatRender key={cat} cat={cat} values={fields.filter((v: any) => v.field.category === cat)} />
                ))}
            </Context.Provider>
        </>
    );
};

export const FieldRenderProfileView: FC<{
    fields: any;
    isAuthUserAdmin: boolean;
}> = ({ fields, isAuthUserAdmin }) => {
    return (
        <FieldsRender
            categotyRender={(cat: string, fields: any, FieldRender: any) => (
                <>
                    {cat && (
                        <SeparatedBox>
                            <NameTypography variant="h6" color="ActiveCaption">
                                {cat}
                            </NameTypography>
                        </SeparatedBox>
                    )}
                    {fields.map((v: any) => (
                        <FieldRender field={v} />
                    ))}
                </>
            )}
            fieldRender={(val: any) => {
                const { field, value, tags, fieldId, users, files } = val;
                if (!field) return null;
                // if (field.fieldType === 'Files') {
                //     return null;
                // }

                if (field.fieldType === 'Tags') {
                    if (tags.length === 0) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <Box>
                                {tags.map((tag: any) => {
                                    const tagLink = field.additionalParam1
                                        ? (field.additionalParam1 as string).replace('{id}', tag.id)
                                        : generatePath(mainUrls.users) + `?${queryString.stringify({ tId: tag.id })}`;
                                    return (
                                        <Link key={tag.id} href={field.url || tagLink}>
                                            <Typography variant="body1" component={'span'}>
                                                {tag.displayName}
                                            </Typography>
                                        </Link>
                                    );
                                })}
                            </Box>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'Users' || field.fieldType === 'SubstituteForUser') {
                    if (users.length === 0) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <Box>
                                {users.map((user: any) => {
                                    const userLink = field.additionalParam1
                                        ? (field.additionalParam1 as string).replace('{id}', user.id)
                                        : `/user/${user.id}`;
                                    return (
                                        <Link key={user.id} href={userLink}>
                                            <UserChip userData={user} sx={{ mr: 1, mt: 1, cursor: 'pointer' }} />
                                        </Link>
                                    );
                                })}
                            </Box>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'Interests') {
                    if (tags.length === 0) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <Box>
                                {tags.map((tag: any, i: number) => {
                                    const tagLink = !field.linkedCollection
                                        ? (field.additionalParam1 as string).replace('{id}', tag.id)
                                        : `/tag/${tag.id}/people?collectionType=${field.linkedCollection.type}`;
                                    return (
                                        <>
                                            <Link key={tag.id} href={tagLink} target={'_blank'}>
                                                <Chip
                                                    label={tag.displayName}
                                                    sx={{ mr: 1, mt: 1, cursor: 'pointer' }}
                                                />
                                            </Link>
                                        </>
                                    );
                                })}
                            </Box>
                        </SeparatedBox>
                    );
                }

                if (field.fieldType === 'String' && field.mask === 'email') {
                    if (!value) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <BlackTypography>
                                <Link {...utils.generateLinkData(`mailto:${value}`)}>{value}</Link>
                            </BlackTypography>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'String' && (field.mask === 'telefon' || field.mask === 'phone')) {
                    if (!value || value === '+') return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <BlackTypography>
                                <Link href={`tel:${value.replace(/[()-\s]/g, '')}`}>{value}</Link>
                            </BlackTypography>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'String') {
                    if (!value) return null;
                    const withLinks = utils.createMdLinks(value);
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <BlackTypography>
                                {field.url ? (
                                    <Link target="_blank" href={field.url}>
                                        <MDRender source={withLinks} />
                                    </Link>
                                ) : (
                                    <MDRender source={withLinks} />
                                )}
                            </BlackTypography>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'Bool') {
                    if (!value || value === 'false') return null;
                    return (
                        <SeparatedBox sx={{ display: 'flex' }} key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography sx={{ marginLeft: '5px' }} color="GrayText">
                                <CheckIcon />
                            </GrayTypography>

                            <GrayTypography>
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'Textarea') {
                    if (!value) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <BlackTypography>
                                <MDRender source={value} />
                            </BlackTypography>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'DateTime') {
                    if (!value) return null;
                    if (!isValidDate(moment(value).toDate())) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <BlackTypography>{moment(value).format('L LT')}</BlackTypography>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'Date') {
                    if (!value) return null;
                    if (!isValidDate(moment(value).toDate())) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                            </GrayTypography>
                            <BlackTypography>{moment(value).format('L')}</BlackTypography>
                        </SeparatedBox>
                    );
                }
                if (field.fieldType === 'AdditionalUserId' && !isAuthUserAdmin) {
                    return null;
                }
                if (field.fieldType === 'Files') {
                    if (files.length === 0) return null;
                    return (
                        <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                            <GrayTypography color="GrayText">
                                <Translate i18nKey={`pryaniky.profile.files`} defaults={field.name} />
                            </GrayTypography>
                            {files.map((file: any) => {
                                const fileType = file.name?.split('.');

                                return (
                                    <Box sx={{ padding: '4px 0 0 0' }}>
                                        <FileItem
                                            key={file.id}
                                            type={fileType[fileType.length - 1]}
                                            // type={file.thumbUrl}
                                            link={file.originalUrl}
                                            //доработка для тикета https://tracker.yandex.ru/COMMON-10687
                                            profileView={true}
                                            children={
                                                <>
                                                    <BlackTypography fontWeight="400">{file.name}</BlackTypography>
                                                    <GrayTypography
                                                        sx={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.38)' }}
                                                        fontWeight="400"
                                                    >
                                                        {formatDateSTD(file.date, false, false)}
                                                    </GrayTypography>
                                                </>
                                            }
                                        />
                                        {/* <Divider sx={{ padding: "4px 0 0 0" }} /> */}
                                    </Box>
                                );
                            })}
                        </SeparatedBox>
                    );
                }
                if (!value) return null;

                return (
                    <SeparatedBox key={fieldId} className={`UserField-${fieldId}`}>
                        <GrayTypography color="GrayText">
                            <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                        </GrayTypography>
                        <BlackTypography>{value}</BlackTypography>
                    </SeparatedBox>
                );
            }}
            fields={fields}
        />
    );
};
