import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CommonFilters } from '../../CommonFilters';
import { IFiltersProps, mapStateToProps, mapDispatchToProps } from '../../CommonFilters.index';
import { defaulIds, defaulValues, filterData, headerData } from './UsersFilters.constants';
import { cn } from '@bem-react/classname';

const cnUsersFilters = cn('UsersFilters');

const UsersFiltersPresented = (props: IFiltersProps) => {
    const { authUser, context, enabledModules, relations, countFilterItems, setCountFilterItems, ...otherProps } =
        props;

    const getDataFromContext = useCallback(
        (type: string) => {
            if (type === 'roles') {
                return context[type] || authUser.baseData.roles || [];
            }
            if (type === 'modules') {
                return context[type] || enabledModules || {};
            }
            if (type === 'networks') {
                return authUser.baseData.networks;
            }
            return context[type];
        },
        [context, authUser, enabledModules]
    );

    return (
        <CommonFilters
            {...props}
            typeStr="usersCatFilter/usersRole/userBlock"
            titleStr="pryaniky.filter.users"
            getDataFromContext={getDataFromContext}
            filterData={filterData}
            headerData={headerData}
            defaulValues={defaulValues}
            defaulIds={defaulIds}
            additionalFields={['showProps', 'clear']}
            relations={(relations || []).slice(0, 1)}
            setCountFilterItems={setCountFilterItems}
            withResetBtn={countFilterItems === 1}
            className={cnUsersFilters('')}
        />
    );
};

export const UsersFilters = connect(mapStateToProps, mapDispatchToProps)(UsersFiltersPresented);
