import { cn } from '@bem-react/classname';
import { ListItemButton } from 'muicomponents/src/ListItem';
import { ComponentProps, FC } from 'react';
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { IListItemProps } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';
import { TaskModel } from 'utils';
import { useTableListContext } from '../TableList.context';

export type TableListItemProps = {
    isWithActionButton?: boolean;
    strForLocalization?: string;
    createStatusIcon?: (v: string) => React.ReactNode;
    wrapProps?: Partial<ComponentProps<typeof ListItemButton>>;
    itemActionsButton?: React.ReactNode;
};

export const cnTableListItem = cn('TableListItem');
export const cnTableListItemCell = cn('TableListItemCell');
