import * as React from 'react';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { cnHeader, IHeaderProps } from './Reply-Header.index';
import './Reply-Header.scss';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';

export class HeaderPresenter extends React.PureComponent<IHeaderProps> {
  public el: HTMLElement | null;

  public render() {
    const { className = '', data } = this.props;
    return (
      <div ref={el => this.el = el} className={cnHeader({}, [className])}>
        <Avatar
          imgUrl={data.user.imgId !== '00000000-0000-0000-0000-000000000000' ? (data.user.imgUrl || data.user.userPhotoUrl) : undefined}
          name={data.user.displayName}
          size={40} />
        <div className={cnHeader('Data')}>
          <div className={cnHeader('Data-User')}>
            <UserMention id={data.user.id}>
              <Link className={cnHeader('UserName', [ classNamesDesign.primaryColor3.text ])} href={appGeneratePath(mainUrls.user.id, { id: data.user.id })}>
                {data.user.displayName}
              </Link>
            </UserMention>
          </div>
          <div className={cnHeader('Data-Date')}>
            {this.commentDate(data.date)}
          </div>
        </div>
      </div>
    )
  }
  private commentDate = (UTCDate: string) => new Date(UTCDate).toLocaleDateString('ru', {hour: 'numeric', minute: 'numeric'}).split(', ').join(' ' + this.props.data.timeSplitter + ' ');

}


export const Header = HeaderPresenter