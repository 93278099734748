import { IClassNameProps } from "@bem-react/core";
import { IStateType as IState } from 'redux/store';
import { setContext, updateContext } from 'redux/actions/Widgets';
import { bindActionCreators } from 'redux';
import { Dispatch } from 'redux';

export interface IThanksProps extends IClassNameProps, IThanksOwnProps, IThanksStateToProps, IThanksActionsToProps { }

export interface IThanksOwnProps { }

export interface IThanksState {}

export type IThanksStateToProps = ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: IState, props: IThanksOwnProps) => ({})

export type IThanksActionsToProps = ReturnType<typeof ThanksActions>

export const ThanksActions = (dispatch: Dispatch) => bindActionCreators({ 
  setContext, 
  updateContext 
}, dispatch);