import { Box, styled } from 'muicomponents/src';
import React, { ComponentProps, FC } from 'react';
import { InfinityList } from 'muicomponents/src/InfinityList';
import { useTableListContext } from '../TableList.context';
import { TaskModelColumn } from 'utils/src';
import { Translate } from 'localization';
import { cnTableListItemCell } from '../Item/TableListItem.index';
import { ItemActionCell, ItemGridContent } from '../TableList.styled';
import { HeaderListCell, StyledItemBox } from './TableListComponent.styled';

const StyledBox = styled(Box)({}) as typeof Box;

const HeaderGridItem = styled('th')({});

const GridCellContent = styled('div')({});

export const TableListComponent: FC<
    ComponentProps<typeof InfinityList> & { isWithActionButton?: boolean; strForLocalization?: string }
> = ({ isWithActionButton = true, strForLocalization = 'task', children, ...props }) => {
    const context = useTableListContext();

    return (
        <InfinityList {...props} ContentComponent={StyledBox}>
            <StyledItemBox disableRipple component={Box}>
                {context?.columns
                    ?.filter((el: TaskModelColumn) => el.isVisible)
                    .map((col: TaskModelColumn, idx) => {
                        return (
                            <HeaderListCell key={idx} className={cnTableListItemCell('Header', [col.name])}>
                                <ItemGridContent>
                                    <Translate
                                        i18nKey={`pryaniky.${strForLocalization}.field.${col.name}`}
                                        defaults={Translate.t({
                                            i18nKey: `pryaniky.${strForLocalization}.field.custom.${col.name}`,
                                        })}
                                    />
                                </ItemGridContent>
                            </HeaderListCell>
                        );
                    })}
                {isWithActionButton && <ItemActionCell className={cnTableListItemCell('Actions')} component="span" />}
            </StyledItemBox>
            {children}
        </InfinityList>
    );
};
