import { BaseNews } from './BaseType'
import * as React from 'react';
import { Button } from 'uielements/src';
import { i18n, Translate } from 'localization';
/**
 * test of user on exists in array
 * 
 * @param arrayOfUsers - array of users objects
 * @param userId - ID of user
 * @returns boolean
 */
export const userInArray = (arrayOfUsers: any[], userId: string) => !!arrayOfUsers.find((u: any) => u.id === userId);


/**
 * toggle(add or remove) user from array
 * 
 * @param arrayOfUsers - array of users objects
 * @param user - user object
 * @returns - new array of users objects
 */
export const toggleUserInArray = (arrayOfUsers: any[], user: any) =>
    userInArray(arrayOfUsers, user.id) ? removeUserFromArray(arrayOfUsers, user) : addUserToArray(arrayOfUsers, user);


/**
 * add user from array
 * 
 * @param arrayOfUsers - array of users objects
 * @param user - user object
 * @returns - new array of users objects
 */
export const addUserToArray = (arrayOfUsers: any[], user: any) => userInArray(arrayOfUsers, user.id) ? [...arrayOfUsers] : [user, ...arrayOfUsers];


/**
 * remove user from array
 * 
 * @param arrayOfUsers - array of users objects
 * @param user - user object
 * @returns - new array of users objects
 */
export const removeUserFromArray = (arrayOfUsers: any[], user: any) => arrayOfUsers.filter((u: any) => u.id !== user.id)


const crreateMdLink = (title: string, url: string) => `[${title}](${url})`

const createNewsLink = (id: string, isExternalSite?: boolean | "", linkToNetwork?: string) => {
    let link = `/news/${id}`
    link = isExternalSite ? (linkToNetwork + link) : link

    return link
}

export const getFemin = (sex: string) => sex === 'f' ? ' femin' : ''

/**
 * @deprecated
 * @param param0 
 * @param isExternalSite 
 * @param linkToNetwork 
 */
export const createNewsTitle = ({ newstype, user: { sex }, header, id, componentRenderName }: any, isExternalSite?: boolean | "", linkToNetwork?: string) => {
    const newsLink = createNewsLink(id, isExternalSite, linkToNetwork)
    if (componentRenderName === 'workflow')
        return `${i18n.t(`published${getFemin(sex)}`)} ${crreateMdLink(`${i18n.t('pryaniky.post.published.workflows')} ${header}`, newsLink)}`;

    switch (newstype) {
        case 0: {
            return `${crreateMdLink(`${i18n.t(`got${getFemin(sex)}`)} ${i18n.t('pryaniky.post.badge')}`, newsLink)} ${header}`;
            // if (sex === 'f') return 'получила бейдж ' + header;
            // else return 'получил бейдж ' + header;
        }
        case 4: {
            return `${crreateMdLink(i18n.t(`thanked${getFemin(sex)}`), newsLink)} ${header}`;
            // if (sex === 'f') return 'поблагодарила ' + header;
            // else return 'поблагодарил ' + header;
        }
        case 5: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.news'), newsLink)}`;
        }
        case 6: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${i18n.t('pryaniky.post.competition')} ${header}`;
            // if (sex === 'f') return 'опубликовала конкурс ' + header;
            // else return 'опубликовал конкурс ' + header;
        }
        case 7: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.poll'), newsLink)}`;
            // if (sex === 'f') return `опубликовала ${crreateMdLink('опрос', newsLink)}`
            // else return `опубликовал ${crreateMdLink('опрос', newsLink)}`;
        }
        case 10: {
            return `${i18n.t(`got${getFemin(sex)}`)} ${header}`;
            // if (sex === 'f') return 'получила ' + header;
            // else return 'получил ' + header;
        }
        case 13: {
            return `${i18n.t(`completed${getFemin(sex)}`)} ${i18n.t('pryaniky.post.competition')} ${header}`;
            // if (sex === 'f') return 'завершила конкурс ' + header;
            // else return 'завершил конкурс ' + header;
        }
        case 17:
            return `${i18n.t(`welcome`)}`;
        // return 'добро пожаловать';
        case 19: {
            return `${i18n.t(`shared${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.shared.achievment'), newsLink)}`;
            // if (sex === 'f') return `поделилась ${crreateMdLink('достижением', newsLink)}`;// + header;
            // else return `поделился достижением ${crreateMdLink('достижением', newsLink)}`;// + header;
        }
        case 21: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${i18n.t('pryaniky.post.published.ideas')} ${header}`;
            // if (sex === 'f') return 'опубликовала идею ' + header;
            // else return 'опубликовал идею ' + header;
        }
        case 22: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${i18n.t('pryaniky.post.published.announcement')} ${header}`;
            // if (sex === 'f') return 'опубликовала объявление ' + header;
            // else return 'опубликовал объявление ' + header;
        }
        case 23: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${i18n.t('pryaniky.post.published.event')} ${header}`;
            // if (sex === 'f') return 'опубликовала событие ' + header;
            // else return 'опубликовал событие ' + header;
        }
        case 26:
            return `${i18n.t(`published${getFemin(sex)}`)} ${i18n.t('pryaniky.post.published.ask')} ${header}`;
        // return 'ask';
        case 25: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${i18n.t('pryaniky.post.ideas.result')} ${header}`;
            // if (sex === 'f') return `опубликовала результаты идеи ${header}`;
            // else return `опубликовал результаты идеи ${header}`;
        }
        case 16:
            return `${i18n.t(`published${getFemin(sex)}`)} ${i18n.t('pryaniky.post.order')}`;
        // return 'order';
        case 31: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.repost'), newsLink)}`;
            // if (sex === 'f') return `опубликовала ${crreateMdLink('репост', newsLink)}`;
            // else return `опубликовала ${crreateMdLink('репост', newsLink)}`;
        }
        case 33: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.quiz.result'), newsLink)}`;
            // if (sex === 'f') return `опубликовала ${crreateMdLink('результат викторины', newsLink)}`;
            // else return `опубликовал ${crreateMdLink('результат викторины', newsLink)}`;
        }
        case 11: {
            return `${i18n.t(`finished${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.survey.result'), newsLink)}`;
            // if (sex === 'f') return `опубликовала ${crreateMdLink('результат викторины', newsLink)}`;
            // else return `опубликовал ${crreateMdLink('результат викторины', newsLink)}`;
        }
        case 40: {
            return `${i18n.t(`published${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.longread'), newsLink)}`;
        }
        case 8:
            return `${i18n.t(`published${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.published.tasks'), newsLink)}`;
        // return 'tasks';
        case 39:
            return `${i18n.t(`completed2${getFemin(sex)}`)} ${crreateMdLink(i18n.t('pryaniky.post.queststeps'), newsLink)}`;
        case 42: {
            return `${crreateMdLink(`${i18n.t(`issue${getFemin(sex)}`)} ${i18n.t('pryaniky.post.badge.type42')}`, newsLink)} ${header}`;
        }
        case 43:
            return `${header}`;
        case -1:
            return 'removed';
        default: return ''
    }
}