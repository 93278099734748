import * as React from 'react';
import { v1 as uuid } from 'uuid';
// import {IWidgetData} from './models/api.widgets';
import { connect } from 'react-redux';
import { mapDispatchToProps } from './redux/connector';
import { makeMapStateToProps, mapActionsToProps } from './blocks/Widget/Widget.index';
import { i18n, Translate } from 'localization';
import ErrorBoundary from 'utils/src/ErrorBoundary';
import { IAttachement } from 'utils/src/requests/models/api.system';
import { Widget as Widgetv2 } from 'Widgets_v2/Widget/Widget';
import { TColumn, TWidget } from 'utils/src';
import { WidgetsTypes } from 'utils/src/widgets/types';

const connectWidget = (path: string) => {
    const Component = require(path + '.tsx');
    const ComponentIndex = require(path + '.index.tsx');
    return connect(
        ComponentIndex.mapStateToProps || makeMapStateToProps,
        mapActionsToProps
        // mapDispatchToProps({ ...mapActionsToProps })
    )(Component.default);
};

export interface IColumnProps {
    accessPolicy?: TColumn['accessPolicy'] | null;
    items?: IWidget[] | string[];
    title?: string | null;
    name?: string | null;
    styles?: React.CSSProperties;
    mobileHidden?: boolean;
    isHidden?: boolean;
    icon?: string | null;
    iconFile?: IAttachement | null;
    [s: string]: any;
}

export interface IColumn extends IColumnProps {
    icon: string | null;
    iconFile: IAttachement | null;
    id: string;
    title: string | null;
    name: string | null;
    items: IWidget[] | string[];
    isHidden: boolean;
}

type widgetsVersions = '1' | '2';

export interface IWidgetProps {
    /**
     *
     */
    accessPolicy?: TWidget<any>['accessPolicy'] | null;
    isHidden?: boolean;
    type?: string;
    title?: string;
    description?: string;
    iconUrl?: string | null;
    relations?: string[];
    files?: any[];
    version?: widgetsVersions;
    // 'data-id': string;
    [s: string]: any;
}

// export interface IWidgetProps extends Partial<IWidget> {}

export interface IWidget<DataT = null | any, SettingsT = any> extends IWidgetProps {
    /**
     *
     */
    accessPolicy?: TWidget<any, DataT, SettingsT>['accessPolicy'] | null;
    id: string;
    type: typeof widgets.types[keyof typeof widgets.types];
    title: string;
    description: string;
    data: DataT | null;
    relations: string[];
    refresh?: boolean;
    authorId?: string;
    isHidden?: boolean;
    allowChangeSettings?: boolean;
    errorLoadWidgetText: string | null;
    errorLoading: boolean;
    files: any[];
    settings: { [s: string]: any } | null;
    version: widgetsVersions;
}

export const generateColumn = (columnProps: IColumnProps) =>
    Object.assign(
        {
            title: null,
            name: null,
            icon: null,
            items: [],
            id: uuid(),
            isHidden: false,
            iconFile: null,
        },
        columnProps
    );

export function generateWidget<DataT = null>(widgetProps: IWidgetProps): IWidget<DataT> {
    const widget: IWidget<DataT> = Object.assign(
        {
            id: uuid(),
            type: 'pryaniky/emptyWidget',
            title: 'GeneratedWidget',
            description: 'frontEndGeneratedWidget',
            iconUrl: null,
            data: null,
            isEditable: true,
            relations: [],
            settings: null,
            errorLoading: false,
            errorLoadWidgetText: null,
            files: null,
        },
        widgetProps
    );
    switch (widget.type) {
        case WidgetsTypes.selectedContext:
            widget.version = '2';
            break;
    }
    return widget;
}

export function prepareWidget(widget: IWidget) {
    let wType = widget.type.split('/').slice(0, 2).join('/');
    let widgetVersion = widget.version;
    if (wType === 'pryaniky/external') wType = widgets.types.iframe;
    const type = Object.keys(widgets.types).reduce((acc, t) => (widgets.types[t] === wType ? t : acc), 'common');
    let Widget = widgets.components[type];
    // to use widgets v2 in v1
    if (widgetVersion === '2') {
        Widget = Widgetv2;
    }
    return (
        <ErrorBoundary key={`EB-${widget.id}`}>
            <Widget key={widget.id} data-id={widget.id} id={widget.id} type={type} />
        </ErrorBoundary>
    );
}

export function prepareWidgetProps(props: any) {
    return Object.assign({}, props, {
        type: undefined,
        children: undefined,
        tag: undefined,
    });
}

interface IWidgetsComponents {
    common: React.ComponentType<any>;
    layout: React.ComponentType<IWidgetProps>;
    list: React.ComponentType<IWidgetProps>;
    filters: React.ComponentType<IWidgetProps>;
    info: React.ComponentType<IWidgetProps>;
    tabs: React.ComponentType<IWidgetProps>;
    users: React.ComponentType<IWidgetProps>;
    groups: React.ComponentType<IWidgetProps>;
    groupsNew: React.ComponentType<IWidgetProps>;
    virtcurrency: React.ComponentType<IWidgetProps>;
    ratings: React.ComponentType<IWidgetProps>;
    wiki: React.ComponentType<IWidgetProps>;
    file: React.ComponentType<IWidgetProps>;
    timeline: React.ComponentType<IWidgetProps>;
    timelineCreator: React.ComponentType<IWidgetProps>;
    quests: React.ComponentType<IWidgetProps>;
    shop: React.ComponentType<IWidgetProps>;
    markdown: React.ComponentType<IWidgetProps>;
    mobileApp: React.ComponentType<IWidgetProps>;
    questsmoderate: React.ComponentType<IWidgetProps>;
    wikilist: React.ComponentType<IWidgetProps>;
    secretSantaPage: React.ComponentType<IWidgetProps>;
    topbutton: React.ComponentType<IWidgetProps>;
    quiz: React.ComponentType<IWidgetProps>;
    skeleton: React.ComponentType<IWidgetProps>;
    journal: React.ComponentType<IWidgetProps>;
    notifiSettings: React.ComponentType<IWidgetProps>;
    currencyTimeline: React.ComponentType<IWidgetProps>;
    virtualUser: React.ComponentType<IWidgetProps>;
    tagsCloud: React.ComponentType<IWidgetProps>;
    presents: React.ComponentType<IWidgetProps>;
    grid: React.ComponentType<IWidgetProps>;
    statistic: React.ComponentType<IWidgetProps>;
    statisticFilters: React.ComponentType<IWidgetProps>;
    mood: React.ComponentType<IWidgetProps>;
    banner: React.ComponentType<IWidgetProps>;
    secretSanta: React.ComponentType<IWidgetProps>;
    secretSantaWidgetList: React.ComponentType<IWidgetProps>;
    survey: React.ComponentType<IWidgetProps>;
    referral: React.ComponentType<IWidgetProps>;
    blocks: React.ComponentType<IWidgetProps>;
    badgesList: React.ComponentType<IWidgetProps>;
    badgesNew: React.ComponentType<IWidgetProps>;
    infoData: React.ComponentType<IWidgetProps>;
    adventCalendar: React.ComponentType<IWidgetProps>;
    card: React.ComponentType<IWidgetProps>;
    hierarchyNew: React.ComponentType<IWidgetProps>;
    userBadges: React.ComponentType<IWidgetProps>;
    lastUserPost: React.ComponentType<IWidgetProps>;
    userLmsStatNew: React.ComponentType<IWidgetProps>;
    currencyTimeLineNew: React.ComponentType<IWidgetProps>;
    ratingsNew: React.ComponentType<IWidgetProps>;
    userRatings: React.ComponentType<IWidgetProps>;
    userGroupsNew: React.ComponentType<IWidgetProps>;
    avatarBlock: React.ComponentType<IWidgetProps>;
    tagBlock: React.ComponentType<IWidgetProps>;
    shopMui: React.ComponentType<IWidgetProps>;
    ratingBadge: React.ComponentType<IWidgetProps>;
    wikiArticles: React.ComponentType<IWidgetProps>;
    wikiActueleArticles: React.ComponentType<IWidgetProps>;
    QuickLinks: React.ComponentType<IWidgetProps>;
    pageCommentsWidget: React.ComponentType<IWidgetProps>;
    remindFillProfile: React.ComponentType<IWidgetProps>;
    myTasks: React.ComponentType<IWidgetProps>;
    commonmark: React.ComponentType<IWidgetProps>;
    eventslist: React.ComponentType<IWidgetProps>;
    usersevents: React.ComponentType<IWidgetProps>;
    wikiblock: React.ComponentType<IWidgetProps>;
    currentApplications: React.ComponentType<IWidgetProps>;
    [s: string]: any;
}

const widgetsComponentsPath: any = {
    common: './blocks/Widget/Widget',
    layout: './blocks/Widget/_type/Widget_type_layout',
    list: './blocks/Widget/_type/Widget_type_list',
    filters: './blocks/Widget/_type/Widget_type_filters',
    info: './blocks/Widget/_type/Widget_type_info',
    tabs: './blocks/Widget/_type/Widget_type_tabs',
    users: './blocks/Widget/_type/Widget_type_users',
    groups: './blocks/Widget/_type/Widget_type_groups',
    virtcurrency: './blocks/Widget/_type/Widget_type_virtcurrency',
    ratings: './blocks/Widget/_type/Widget_type_ratings',
    wiki: './blocks/Widget/_type/Widget_type_wiki',
    file: './blocks/Widget/_type/Widget_type_file',
    editorjs: './blocks/Widget/_type/Widget_type_editorjs',
    timeline: './blocks/Widget/_type/Widget_type_timeline',
    timelineCreator: './blocks/Widget/_type/Widget_type_timelineCreator',
    quests: './blocks/Widget/_type/Widget_type_quests',
    shop: './blocks/Widget/_type/Widget_type_shop',
    birthdays: './blocks/Widget/_type/Widget_type_birthdays',
    markdown: './blocks/Widget/_type/Widget_type_markdown',
    textblock: './blocks/Widget/_type/Widget_type_markdown',
    notes: './blocks/Widget/_type/Widget_type_wiki',
    html: './blocks/Widget/_type/Widget_type_html',
    news: './blocks/Widget/_type/Widget_type_news',
    mobileApp: './blocks/Widget/_type/Widget_type_mobileApp',
    album: './blocks/Widget/_type/Widget_type_album',
    userRating: './blocks/Widget/_type/Widget_type_userRating',
    userGroups: './blocks/Widget/_type/Widget_type_userGroups',
    userAbout: './blocks/Widget/_type/Widget_type_userAbout',
    files: './blocks/Widget/_type/Widget_type_files',
    lms: './blocks/Widget/_type/Widget_type_lms',
    coursesMy: './blocks/Widget/_type/Widget_type_courses_my',
    mindMap: './blocks/Widget/_type/Widget_type_mindmap',
    code: './blocks/Widget/_type/Widget_type_code',
    render: './blocks/Widget/_type/Widget_type_render',
    calendar: './blocks/Widget/_type/Widget_type_calendar',
    calendarWidget: './blocks/Widget/_type/Widget_type_calendar_widget',
    AlertWidget: './blocks/Widget/_type/Widget_type_AlertWidget',
    FloatMessageWidget: './blocks/Widget/_type/Widget_type_FloatMessageWidget',
    UsersCardWidget: './blocks/Widget/_type/Widget_type_UsersCardWidget',
    ContentCardWidget: './blocks/Widget/_type/Widget_type_ContentCardWidget',
    breadcrumbs: './blocks/Widget/_type/Widget_type_breadcrumbs',
    LoadAchievements: './blocks/Widget/_type/Widget_type_loadAchievements',
    context: './blocks/Widget/_type/Widget_type_context',
    sliderlink: './blocks/Widget/_type/Widget_type_sliderLink',
    ratingmini: './blocks/Widget/_type/Widget_type_ratingmini',
    topbutton: './blocks/Widget/_type/Widget_type_topbutton',
    charts: './blocks/Widget/_type/Widget_type_chart',
    reactions: './blocks/Widget/_type/Widget_type_reactions',
    quiz: './blocks/Widget/_type/Widget_type_quiz',
    skeleton: './blocks/Widget/_type/Widget_type_skeleton',
    journal: './blocks/Widget/_type/Widget_type_journal',
    usetLmsStat: './blocks/Widget/_type/Widget_type_userLmsStat',
    skills: './blocks/Widget/_type/Widget_type_Skills',
    kpi: './blocks/Widget/_type/Widget_type_kpi',
    notifiSettings: './blocks/Widget/_type/Widget_type_notifiSettings',
    tabsControl: './blocks/Widget/_type/Widget_type_tabsControl',
    license: './blocks/Widget/_type/Widget_type_license',
    currencyTimeline: './blocks/Widget/_type/Widget_type_currencyTimeline',
    virtualUser: './blocks/Widget/_type/Widget_type_virtualUser',
    tagsCloud: './blocks/Widget/_type/Widget_type_tagsCloud',
    iframe: './blocks/Widget/_type/Widget_type_iframe',
    presents: './blocks/Widget/_type/Widget_type_presents',
    grid: './blocks/Widget/_type/Widget_type_grid',
    statistic: './blocks/Widget/_type/Widget_type_statistic',
    statisticFilters: './blocks/Widget/_type/Widget_type_statistic_filters',
    mood: './blocks/Widget/_type/Widget_type_mood',
    banner: './blocks/Widget/_type/Widget_type_banner',
    secretwidget: './blocks/Widget/_type/Widget_type_secretwidget',
    userfields: './blocks/Widget/_type/Widget_type_userfields',
    secretSanta: './blocks/Widget/_type/Widget_type_secret_santa',
    secretSantaWidgetList: './blocks/Widget/_type/Widget_type_secret_santa_list_widget',
    survey: './blocks/Widget/_type/Widget_type_survey',
    secretSantaPage: './blocks/Widget/_type/Widget_type_secret_santa_page',
    referral: './blocks/Widget/_type/Widget_type_referral',
    blocks: './blocks/Widget/_type/Widget_type_blocks',
    badgesList: './blocks/Widget/_type/Widget_type_badgesList',
    calendarmini: './blocks/Widget/_type/Widget_type_calendarmini',
    adventCalendar: './blocks/Widget/_type/Widget_type_adventCalendar',
    card: './blocks/Widget/_type/Widget_type_card',
    vacancy: './blocks/Widget/_type/Widget_type_vacancy',
    vacancies: './blocks/Widget/_type/Widget_type_vacancies',
    infoData: './blocks/Widget/_type/Widget_type_infoData',
    badgesNew: './blocks/Widget/_type/Widget_type_badgesNew', // для  редизайна страницы пользователя (usernew)
    groupsNew: './blocks/Widget/_type/Widget_type_groupsNew', // для  редизайна страницы пользователя (usernew)
    hierarchyNew: './blocks/Widget/_type/Widget_type_hierarchyNew', // для  редизайна страницы пользователя (usernew)
    lastUserPost: './blocks/Widget/_type/Widget_type_lastUserPost', // для  редизайна страницы пользователя (usernew)
    userLmsStatNew: './blocks/Widget/_type/Widget_type_userLmsStatNew', // для  редизайна страницы пользователя (usernew)
    userBadges: './blocks/Widget/_type/Widget_type_userBadges', // вкладка Бейджи для  редизайна страницы пользователя (usernew)
    currencyTimeLineNew: './blocks/Widget/_type/Widget_type_currencyTimeLineNew', // вкладка currencyTimeline для  редизайна страницы пользователя (usernew)
    ratingsNew: './blocks/Widget/_type/Widget_type_ratingsMui', // виджет для вкладки Обомне для  редизайна страницы пользователя (usernew)
    userRatings: './blocks/Widget/_type/Widget_type_userRatings', // виджет для вкладки ratings для  редизайна страницы пользователя (usernew)
    userGroupsNew: './blocks/Widget/_type/Widget_type_userGroupsNew', // виджет для вкладки userGroupsNew для  редизайна страницы пользователя (usernew)
    avatarBlock: './blocks/Widget/_type/Widget_type_avatarBlock', // для  редизайна страницы пользователя (usernew)
    tagBlock: './blocks/Widget/_type/Widget_type_tagBlock', // для страницы отельного тега
    shopMui: './blocks/Widget/_type/Widget_type_shopMui', // для  редизайна страницы пользователя (usernew)
    skillsNew: './blocks/Widget/_type/Widget_type_SkillsNew', // для  редизайна страницы пользователя (usernew)
    ratingBadge: './blocks/Widget/_type/Widget_Type_RatingBadges/Widget_Type_RatingBadges', // для  редизайна страницы пользователя (usernew)
    wikiArticles: './blocks/Widget/_type/Widget_Type_Wiki_Articles/Widget_Type_Wiki_Articles', // для  редизайна страницы пользователя (usernew)
    remindFillProfile: './blocks/Widget/_type/Widget_type_RemindFillProfile/Widget_type_RemindFillProfile', // виджет напомнинание о заполнении профиля
    wikiActueleArticles: './blocks/Widget/_type/Widget_Type_Wiki_Actuele_Articles/Widget_Type_Wiki_Actuele_Articles', // для  редизайна страницы пользователя (usernew)
    QuickLinks: './blocks/Widget/_type/Widget_type_QuickLinks/Widget_type_QuickLinks', // быстрые ссылки
    pageCommentsWidget: './blocks/Widget/_type/Widget_type_PageComment/Widget_type_PageComment', //
    myTasks: './blocks/Widget/_type/Widget_type_MyTasks/Widget_type_MyTasks', //
    commonmark: './blocks/Widget/_type/Widget_type_commonmark/Widget_type_commonmark', //
    eventslist: './blocks/Widget/_type/Widget_type_eventslist/Widget_type_eventslist',
    usersevents: './blocks/Widget/_type/Widget_type_usersevents/Widget_type_usersevents',
    weekblock: './blocks/Widget/_type/Widget_type_weekblock/Widget_type_weekblock',
};

/**
 * @deprecated
 * use WidgetsTypes from 'utils/src/widegts/types'
 */
export class WidgetTypes {
    public readonly badges: string = '';
    public readonly badgesNew: string = '';
    public readonly filters: string = '';
    public readonly groups: string = '';
    public readonly groupsNew: string = '';
    public readonly layout: string = '';
    public readonly info: string = '';
    public readonly list: string = '';
    public readonly news: string = '';
    public readonly quests: string = '';
    public readonly ratings: string = '';
    public readonly shop: string = '';
    public readonly tabs: string = '';
    public readonly textblock: string = '';
    public readonly timeline: string = '';
    public readonly timelineCreator: string = '';
    public readonly users: string = '';
    public readonly after35: string = '';
    public readonly virtcurrency: string = '';
    public readonly wiki: string = '';
    public readonly file: string = '';
    public readonly editorjs: string = '';
    public readonly birthdays: string = '';
    public readonly markdown: string = '';
    public readonly notes: string = '';
    public readonly html: string = '';
    public readonly mobileApp: string = '';
    public readonly ideas: string = '';
    public readonly competitions: string = '';
    public readonly album: string = '';
    public readonly userRating: string = '';
    public readonly userGroups: string = '';
    public readonly userAbout: string = '';
    public readonly files: string = '';
    public readonly lms: string = '';
    public readonly mindMap: string = '';
    public readonly code: string = '';
    public readonly render: string = '';
    public readonly calendar: string = '';
    public readonly calendarWidget: string = '';
    public readonly AlertWidget: string = '';
    public readonly FloatMessageWidget: string = '';
    public readonly UsersCardWidget: string = '';
    public readonly ContentCardWidget: string = '';
    public readonly breadcrumbs: string = '';
    public readonly LoadAchievements: string = '';
    public readonly coursesMy: string = '';
    public readonly context: string = '';
    public readonly sliderlink: string = '';
    public readonly ratingmini: string = '';
    public readonly topbutton: string = '';
    public readonly charts: string = '';
    public readonly reactions: string = '';
    public readonly quiz: string = '';
    public readonly skeleton: string = '';
    public readonly journal: string = '';
    public readonly usetLmsStat: string = '';
    public readonly skills: string = '';
    public readonly skillsNew: string = '';
    public readonly kpi: string = '';
    public readonly notifiSettings: string = '';
    public readonly tabsControl: string = '';
    public readonly license: string = '';
    public readonly currencyTimeline: string = '';
    public readonly virtualUser: string = '';
    public readonly tagsCloud: string = '';
    public readonly iframe: string = '';
    public readonly presents: string = '';
    public readonly grid: string = '';
    public readonly statistic: string = '';
    public readonly statisticFilters: string = '';
    public readonly mood: string = '';
    public readonly banner: string = '';
    public readonly secretwidget: string = '';
    public readonly userfields: string = '';
    public readonly secretSanta: string = '';
    public readonly secretSantaWidgetList: string = '';
    public readonly survey: string = '';
    public readonly secretSantaPage: string = '';
    public readonly referral: string = '';
    public readonly blocks: string = '';
    public readonly badgesList: string = '';
    public readonly calendarmini: string = '';
    public readonly adventCalendar: string = '';
    public readonly card: string = '';
    public readonly vacancy: string = '';
    public readonly vacancies: string = '';
    public readonly hierarchyNew: string = '';
    public readonly lastUserPost: string = '';
    public readonly userBadges: string = '';
    public readonly userLmsStatNew: string = '';
    public readonly currencyTimeLineNew: string = '';
    public readonly ratingsNew: string = '';
    public readonly userRatings: string = '';
    public readonly userGroupsNew: string = '';
    public readonly avatarBlock: string = '';
    public readonly shopMui: string = '';
    public readonly ratingBadge: string = '';
    public readonly wikiArticles: string = '';
    public readonly wikiActueleArticles: string = '';
    public readonly QuickLinks: string = '';
    public readonly pageCommentsWidget: string = '';
    public readonly remindFillProfile: string = '';
    public readonly myTasks: string = '';
    public readonly commonmark: string = '';
    public readonly eventslist: string = '';
    public readonly usersevents: string = '';
    public readonly weekblock: string = '';
    public readonly currentApplications: string = '';

    /**
     * user text info data
     */
    public readonly infoData: string = '';
    public readonly tagBlock: string = '';
    // public readonly textblock: string = '';
    [s: string]: any;

    constructor() {
        for (const key of Object.keys(this)) this[key] = 'pryaniky/' + key;
    }
}

interface IWidgets {
    /**
     * @deprecated
     * use WidgetsTypes from 'utils/src/widegts/types'
     */
    types: WidgetTypes;
    components: IWidgetsComponents;
}

const widgetsComponents: IWidgetsComponents = createWC<IWidgetsComponents>();

function createWC<T>(): T {
    const r: any = {};
    for (const type in widgetsComponentsPath) r[type] = connectWidget(widgetsComponentsPath[type]);
    return r as T;
}

export const widgets: IWidgets = {
    types: new WidgetTypes(),
    components: widgetsComponents,
};

export const newWidgets = () => [
    generateWidget<any>({
        type: widgets.types.list + '/users',
        title: i18n.t('pryaniky.widgets.create.list.users.title'),
        schema: 'lists',
    }),
    generateWidget<any>({
        type: widgets.types.list + '/groups',
        title: i18n.t('pryaniky.widgets.create.list.groups.title'),
        schema: 'lists',
    }),
];
