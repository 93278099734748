import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllowPostTypes } from 'redux/actions/AppSettings';
import { getAllowPostError, getCurrentAllowPostTypes } from 'redux/sagas/AppSettings/selectors';
import { getAppSettingsValueByKeys } from 'utils/src/CommonRedux/base/selectors';
import { v1 as uuid } from 'uuid';
import { defaultGroup, emptyDraftRawState } from '../baseNews';
import { newsCreatorInit, newsCreatorItemChange } from '../redux/actions.newsCreator';
import { getNewsCreateItem, getNewsCreateItemFields } from '../redux/selectors.newsCreate';
import { NewsCreateItem } from '../redux/types.newsCreator';
import { baseAchievements } from '../types/Achievements';
import { baseAchievementsFor } from '../types/AchievementsFor';
import { baseBadges } from '../types/Badges';
import { baseCreativeTasks } from '../types/CreativeTasks';
import { baseEvents } from '../types/Events';
import { baseIdeas } from '../types/Ideas';
import { baseNews } from '../types/News';
import { baseNotices } from '../types/Notices';
import { basePolls } from '../types/Polls/Polls.constants';
import { baseThanks } from '../types/Thanks';
import { baseWorkflows } from '../types/Workflows';

export const baseModels = {
    news: baseNews,
    thanks: baseThanks,
    achievements: baseAchievements,
    'achievements.moderation': baseAchievementsFor,
    badges: baseBadges,
    notices: baseNotices,
    creativetasks: baseCreativeTasks,
    polls: basePolls,
    events: baseEvents,
    ideas: baseIdeas,
    workflow: baseWorkflows,
};

export const useInitNewsCreator = (id?: string) => {
    const creatorId = useMemo(() => id || uuid(), []);
    const creator = useSelector(getNewsCreateItem(creatorId));

    const dispatch = useDispatch();

    const initFunction = useCallback(() => {
        dispatch(newsCreatorInit(creatorId));
    }, [creatorId]);

    useEffect(() => {
        if (!id || !creator) initFunction();
    }, []);

    return {
        creatorId,
        initFunction,
    };
};

export type TCreatorOptions = {
    models?: Partial<typeof baseModels>;
    formSettings?: NewsCreateItem['formSettings'];
};

export const defaultCreatorOptions: TCreatorOptions = {
    models: {},
};
export const useNewsCreator = (
    creatorId: string,

    groupId?: number | string,

    workflowId?: string,

    options: TCreatorOptions = defaultCreatorOptions,

    newsType?: string,

    /**
     * pass all parameter to posttypes request to ignore enabled flag
     * use only for birthdays and thank on profile
     */
    all?: boolean
) => {
    const { models, formSettings } = options;
    // const creatorId = useMemo(() => id || uuid(), []);

    const dispatch = useDispatch();

    const postTypes = useSelector(getCurrentAllowPostTypes(groupId, workflowId, newsType, all));

    const postTypesErrorRedux = useSelector(getAllowPostError);
    const postTypesError =
        postTypesErrorRedux &&
        postTypesErrorRedux.groupId === groupId &&
        postTypesErrorRedux.workflowId === workflowId &&
        postTypesErrorRedux.newsType === newsType &&
        postTypesErrorRedux.all === all
            ? postTypesErrorRedux.error
            : false;

    const creatorFields = useSelector(
        getNewsCreateItemFields(['selectedNews', 'selectedComponentRenderName', 'news', 'clearEditor', 'sended'])(
            creatorId
        )
    );

    const selectPostType = useCallback(
        (id: typeof postTypes[number]['id'], force?: boolean) => {
            if (!force && creatorFields.selectedNews === id) return;
            const finded = postTypes?.find((el: any) => el.id === id);
            if (!finded) return;
            const baseModel = cloneDeep(
                { ...baseModels, ...models }[finded.componentRenderName as keyof typeof baseModels]
            );
            if (!baseModel) return;
            switch (baseModel.componentRenderName) {
                case 'polls': {
                    baseModel.expires = moment().add(7, 'days').toISOString();
                    baseModel.poll.expires = moment().add(7, 'days').toISOString();
                    break;
                }
                case 'events': {
                    baseModel.uevent = {
                        ...baseModel.uevent,
                        calendar: baseModel.uevent.calendar || finded.renderParams?.defaultCalendar,
                    };
                    break;
                }
                case 'ideas': {
                    baseModel.idea = {
                        additionalFieldsValues: cloneDeep(finded.ideaRenderParams?.additionalFieldsValues),
                        additionalFieldsValuesV2: [],
                    };
                    break;
                }
                case 'workflow': {
                    baseModel.id = finded.id;
                    let additionalFieldsValues = cloneDeep(finded.additionalFields || []).map(
                        (category: any, idx: number) => {
                            return {
                                ...category,
                                fields: category.fields.filter((el: any) => !el.defaultFieldName),
                            };
                        }
                    );

                    additionalFieldsValues = additionalFieldsValues.map((category: any) => {
                        return {
                            ...category,
                            fields: category.fields.map((field: any) => {
                                if (field.fieldType === 'DateTimeRange') {
                                    return {
                                        ...field,
                                        data: {
                                            startDate:
                                                formSettings?.additionalFields?.DateTimeRange?.defaultStartDateTime,
                                            endDate:
                                                formSettings?.additionalFields?.DateTimeRange?.defaultEndDateTime ||
                                                formSettings?.additionalFields?.DateTimeRange?.defaultStartDateTime,
                                        },
                                        settings: {
                                            ...field.settings,
                                            minDateTime: moment(new Date()),
                                            calendar: finded?.renderParams?.calendar,
                                        },
                                    };
                                }
                                return field;
                            }),
                        };
                    });

                    baseModel.additionalFields = {
                        additionalFieldsValues,
                        currentStatus: null,
                        formId: '00000000-0000-0000-0000-000000000000',
                        responsibleForStatus: [],
                        showChangeStatusButton: false,
                        comment: '',
                    };
                    break;
                }
            }
            dispatch(
                newsCreatorItemChange({
                    id: creatorId,
                    item: {
                        selectedNews: finded.id,
                        selectedComponentRenderName: finded.componentRenderName,
                        news: {
                            ...baseModel,
                            newstype: finded.newstype || finded.idNumber || 0,
                            // TODO check types
                            group: groupId && ({ ...defaultGroup, pkid: groupId } as any),
                            draftRawState: cloneDeep(emptyDraftRawState),
                        },
                        formSettings,
                        hideSender: false,
                        isValid: true,
                        clearEditor: false,
                        errors: {},
                    },
                })
            );
        },
        [groupId, postTypes, creatorFields.selectedNews, formSettings]
    );

    useEffect(() => {
        dispatch(
            newsCreatorItemChange({
                id: creatorId,
                item: {
                    formSettings,
                },
            })
        );
    }, [formSettings]);

    useEffect(() => {
        dispatch(loadAllowPostTypes(groupId, workflowId, newsType, all));
    }, [groupId, workflowId, newsType, all]);

    const newsCreatorChange = (item: Partial<ReturnType<ReturnType<typeof getNewsCreateItem>>>) => {
        dispatch(newsCreatorItemChange({ id: creatorId, item }));
    };

    const { showPostFormInDialog } = useSelector(getAppSettingsValueByKeys('showPostFormInDialog'));
    //const { showPostFormInDialog } = { showPostFormInDialog: true };

    return {
        creatorId,
        postTypes,
        postTypesError,
        showPostFormInDialog,
        ...creatorFields,
        selectPostType,
        newsCreatorChange,
    };
};
