import React from 'react';
import { IVoteFullView, cnVoteFullView, mapVoteFullViewStateToProps, mapVoteFullViewActionToProps } from './VoteFullView.index';
import './VoteFullView.scss';
import { createModal, TransitionComponent, TDialogProps } from 'utils/src/DialogCreator';
import {
  // DialogTitle,
  DialogActions,
  DialogContent,
  ErrorMsg,
  Spinner,
  Avatar
} from 'uielements/src';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { i18n, Translate } from 'localization';
import { connect } from 'react-redux';
import { ViewComponent/*, IAdditionalField, prepareFromServer, preparePhotoToVoteView, prepareToServer*/ } from 'uielements/src/FieldRender';
import NewsActions from '../../../../News/containers/NewsActions';
import { NewsContext, withNewsContext } from 'News/contexts/news';
import { usePreperedPhoto } from 'News/containers/Vote/hooks';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import NewsComments from 'News/containers/NewsComments';
import { classNamesDesign } from 'utils/src/constants.classes';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { Link } from 'muicomponents/src/Link';
// import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
// import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'

const VoteFullViewPresenter: React.FC<IVoteFullView.Props> = ({
  id,
  newsId,
  user,
  additionalFields,
  className,
  handleClose
}) => {

  const [imageField, fields] = usePreperedPhoto(additionalFields?.additionalFieldsValues)

  // const context = React.useMemo(() => {
  //   return {
  //     newsId,
  //   }
  // }, [newsId]);

  // const clearFields = additionalFields?.additionalFieldsValues?.reduce((acc: any, category: any) => [...acc, { ...category, fields: category.fields.filter((field: any) => !field.defaultFieldName) }], []);


  // const [imageField, fields] = React.useMemo(() => preparePhotoToVoteView(prepareFromServer(clearFields)), [clearFields]);

  return (
    <div className={cnVoteFullView({}, [className])}>
      <DialogTitle onClose={handleClose}>
        <Translate i18nKey={'pryaniky.post.workflows.voteview.dialog.title'} />
      </DialogTitle>
      <DialogContent>
        <div className={cnVoteFullView('User')}>
          {/* as any сделано для того чтоб ы при сборке не ругалось на never */}
          <Avatar 
          size={112} 
          className={cnVoteFullView('Avatar')} 
          imgId={imageField?.data?.id || user.imgId} 
          imgUrl={imageField?.data?.previewUrl || user.imgUrl} 
          name={user.displayName} />
          <div>
            <UserMention id={user.id}>
              <Link className={cnVoteFullView('UserName', [classNamesDesign.primaryColor3.text])} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>{user.displayName}</Link>
            </UserMention>
            <div>{user.position}</div>
            <div>{user.division}</div>
          </div>
        </div>
        <div className={cnVoteFullView('Fields')}>
          <ViewComponent className={cnVoteFullView('Fields')} fields={fields} />
        </div>
      </DialogContent>
      <DialogActions>
        {/* <NewsContext.Provider value={context as any}> */}
          <NewsActions />
          <NewsComments />
        {/* </NewsContext.Provider> */}
      </DialogActions>
    </div>
  )
}
export const ConnectedVoteFullView = withNewsContext(connect(
  mapVoteFullViewStateToProps,
  mapVoteFullViewActionToProps
)(VoteFullViewPresenter))

export const dialogParams: TDialogProps = {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
}
// export const voteFullView = createModal(ConnectedVoteFullView, {
//   maxWidth: 'sm',
//   fullWidth: true,
//   PaperProps: {
//     style: {
//       backgroundColor: 'transparent',
//       // overflowY: 'unset'
//     }
//   },
//   TransitionComponent,
//   scroll: 'body'
// });