/**
 * https://redux-saga.js.org/
 * 
 * this is root saga.
 * read more of root-saga pattern: https://redux-saga.js.org/docs/advanced/RootSaga.html
 * 
 * HOW TO USE:
 * import your file and add your root-sagas to array of arguments function all()
 * 
 * sagas folder structure:
 * - sagas
 * - - ...
 * - - moduleName1
 * - - - ...
 * - - - otherFile.ts
 * - - - selectors.ts
 * - - - saga1.ts
 * - - - saga2.ts
 * - - - index.ts
 * - - moduleName2 <- your module folder
 * - - - saga.ts <- your module saga(optional)
 * - - - index.ts <- root saga for your module
 * - - index.ts <- project root saga, you here
 */
import { all } from 'redux-saga/effects';
/**
 * imports modules root-sagas
 * declarate your import here
 */
import sagaLMSTO from '../../LMSModule/redux/sagas/LMSTO'
import sagaLMS from '../../LMSModule/redux/sagas/LMS'
import sagaLMS_COMMON from '../../LMSModule/redux/sagas/COMMON'
import sagaTracksManage from '../../LMSModule/redux/sagas/TracksManage'
import sagaTracksUse from '../../LMSModule/redux/sagas/TracksUse'
import sagaContext from './context'
import sagaGroups from './Groups'
import sagaDialogs from '../dialogs/saga'
import sagaAppSettings from './AppSettings'
import sagaShop from '../shop/saga'
import sagaJournal from './Journal/saga'
import sagaCalendar from '../calendar/saga'
import i18nSaga from 'utils/src/CommonRedux/i18n/saga';
import analyticSaga from './../analytic';
import sagaSkills from './Skills';
import notifiSettingsSaga from '../notifiSettings/saga';
import currencyTimelineSaga from '../currencyTimeline/saga';
// import { newsSaga } from './news';
import Hierarchy from './Hierarchy/saga';
import LifeCycol from 'utils/src/CommonRedux/LifeCycol/saga';
import sagaNews from '../../News/redux/saga';
import sagaKPI from '../../KPIModule/redux/saga';
import userSaga from 'utils/src/CommonRedux/users/saga'
import questsSaga from '../quests/saga';
import surveys from './Surveys/saga';
import sagaOrgchart from './orgchart/saga';
import sagaSecretSanta from './SecretSanta/saga';
// import { menuv2Saga } from '../../blocks/LeftMenu_v2/redux/saga';
import { menuSaga } from '../../blocks/LeftMenu/redux/saga';
import  Titles  from './Titles/saga';
import { widgetsSaga } from './Widgets/saga.Widgets';

export const sagas = [
    LifeCycol,
    sagaContext,
    analyticSaga,
    sagaLMSTO,
    sagaLMS,
    sagaLMS_COMMON,
    sagaGroups,
    sagaDialogs,
    sagaAppSettings,
    sagaShop,
    sagaJournal,
    sagaCalendar,
    i18nSaga,
    sagaSkills,
    notifiSettingsSaga,
    // newsSaga,
    Hierarchy,
    sagaTracksManage,
    sagaTracksUse,
    sagaNews,
    userSaga,
    currencyTimelineSaga,
    questsSaga,
    sagaKPI,
    sagaOrgchart,
    sagaSecretSanta,
    // menuv2Saga,
    menuSaga,
    Titles,
    widgetsSaga,
    surveys
]

/**
 * root saga of project
 * add your saga here
 */
const root = function* root() {
    yield all(sagas.map(e => e()));
};

/**
 * export root saga for connect to saga midleware
 */
export default root;