import React, { FC, memo, useMemo, useState } from 'react';
import { cnClassName, SkillsListItemInterface } from './SkillsListItem.interface';
// import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';
import { i18n } from 'localization';
import { AvatarGroup } from 'muicomponents/src/AvatarGroup/AvatarGroup';
import { Box } from 'muicomponents/src/Box/Box';
import { Button } from 'muicomponents/src/Button/Button';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { AvTimer, ThumbUp, ThumbUpOffAlt } from 'muicomponents/src/Icons';
import { Link } from 'muicomponents/src/Link/Link';
import { styled } from 'muicomponents/src/mui/system';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { useResizeHandler } from 'utils/src/hooks';
import { useSkillsItem } from '../../hooks';
import {
    SkillsInfoIconBox,
    SkillsItemAction,
    SkillsItemAlertContent,
    SkillsItemBox,
    SkillsItemTitle,
    SkillsItemTitleChip,
    SkillsItemUser,
} from './SkillsListItem.styled';
import { AvatarSize } from 'muicomponents/src/Avatar/Avatar';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

const Alert: FC<AlertProps> = (props) => <MuiAlert elevation={3} variant="standard" {...props} />;

export const SkillsItemAlert = styled(Alert)({
    alignItems: 'center',
    '.MuiAlert-message': {
        width: '100%',
    },
}) as typeof Alert;

type IPresenterProps = SkillsListItemInterface.IPresenterProps;

export const SkillsListItem: FC<IPresenterProps> = ({
    className,
    skill,
    index,
    confirmSkill,
    userid,
    deleteSkill,
    showControls,
}) => {
    const { del, actionCallback, onChangeConfirm, onCancleDelete, onDelete, setDel } = useSkillsItem(skill, userid, {
        confirmSkill,
        deleteSkill,
    });

    const [showActionButton, setShowBtn] = useState(false);

    const ref = useResizeHandler((el) => {
        if (el.clientWidth < 500) {
            setShowBtn(false);
        } else {
            setShowBtn(true);
        }
    });

    const confirmBtr = useMemo(
        () => (
            <IconButton onClick={onChangeConfirm}>
                {skill.confirmedByMe ? (
                    <ThumbUp className={cnClassName('IconUp CustomStyle primaryColor3-text')} />
                ) : (
                    <ThumbUpOffAlt className={cnClassName('IconDown CustomStyle primaryColor3-text')} />
                )}
            </IconButton>
        ),
        [skill.confirmedByMe, showActionButton, skill.tag.id]
    );

    return (
        <SkillsItemBox ref={ref as any} className={cnClassName({}, [className])}>
            {!del && (
                <>
                    {/* <Box className={cnClassName('Index')}>
                    {skill.onModeration ?
                        <Tooltip
                            className={'InfoIcon'}
                            title={i18n.t('pryaniky.skills.onModeration.help.1')}
                        >
                            <span><Icon icon={'eye-crossed'} /></span>

                        </Tooltip> :
                        <span>{index}.</span>}
                </Box> */}
                    <SkillsItemTitle className={cnClassName('Title')}>
                        <Link
                            href={`/tag/${skill.tag.id}/people?collectionType=skills`}
                            className={cnClassName('TagLink')}
                            title={skill.tag.displayName}
                        >
                            <SkillsItemTitleChip
                                key={skill.tag.id}
                                sx={{
                                    background: `${skill.onModeration ? 'rgba(0, 0, 0, 0.26)' : ''}`,
                                }}
                                className={cnClassName(
                                    `SkillChip ${!skill.onModeration && 'CustomStyle primaryColor3-bg'}`
                                )}
                                variant="filled"
                                label={skill.tag.displayName}
                            />
                        </Link>
                        {skill.onModeration && (
                            <Tooltip className={'InfoIcon'} title={i18n.t('pryaniky.skills.onModeration.help.1')}>
                                <SkillsInfoIconBox>
                                    <AvTimer sx={{ marginLeft: '5px', color: 'rgba(0, 0, 0, 0.54)' }} />
                                </SkillsInfoIconBox>
                            </Tooltip>
                        )}
                    </SkillsItemTitle>
                </>
            )}
            <SkillsItemUser className={cnClassName('Users')} del={del}>
                {del ? (
                    <Box className={cnClassName('AlertCell')}>
                        <SkillsItemAlert severity="warning" className={cnClassName('Alert')}>
                            <SkillsItemAlertContent
                                className={cnClassName('AlertContent', { small: !showActionButton })}
                            >
                                <Box className={cnClassName('AlertText')}>
                                    {i18n.t('pryaniky.skills.confirmDelete.1', { title: skill.tag.displayName })}
                                </Box>
                                <ButtonGroup variant="contained">
                                    <Button
                                        className={cnClassName('Btn')}
                                        size={'small'}
                                        variant={'outlined'}
                                        onClick={onDelete}
                                    >
                                        {i18n.t('pryaniky.skills.deleteBtn.on.1')}
                                    </Button>
                                    <Button
                                        className={cnClassName('Btn')}
                                        size={'small'}
                                        variant={'contained'}
                                        onClick={onCancleDelete}
                                    >
                                        {i18n.t('pryaniky.skills.deleteBtn.off.1')}
                                    </Button>
                                </ButtonGroup>
                            </SkillsItemAlertContent>
                        </SkillsItemAlert>
                    </Box>
                ) : skill?.confirmedBy?.length > 0 ? (
                    <>
                        <AvatarGroup
                            elements={skill.confirmedBy.map((item) => ({ ...item, link: appGeneratePath(mainUrls.user.id, { id: item.id })  }))}
                            ElementWrapper={({item, children}) => {
                                return (
                                    <UserMention
                                        id={item.id}
                                    >
                                        <span>
                                            {children}
                                        </span>
                                    </UserMention>
                                )
                            }}
                            spacing={-4}
                            size={AvatarSize.s}
                            onClickAction={() => {
                                actionCallback();
                            }}
                        />
                    </>
                ) : undefined}
            </SkillsItemUser>

            {skill?.confirmedBy && <SkillsItemAction className={cnClassName('Action')}>{confirmBtr}</SkillsItemAction>}
        </SkillsItemBox>
    );
};

export default memo(SkillsListItem);
