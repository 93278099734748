import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Calendar as BigCalendar } from "react-big-calendar";
import { connect, useSelector } from "react-redux";
import "moment/locale/ru";
import "./Calendar.scss";
import {
  cnCalendar,
  mapDispatchToProps,
  mapStateToProps,
  ICalendarProps,
  CalendarSearchParams,
} from "./Calendar.index";
import { EventsList } from "./EventsList/EventsList";
import { openEventsDialog } from "./ShowMoreEventsDialog/ShowMoreEventsDialog";
import { CustomToolbar } from "./CustomToolbar/CustomToolbar";
import { CustomEvent } from "./CustomEvent/CustomEvent";
import { i18n } from "localization";
import { useDidUpdateEffect, useSearchParams } from "utils/src/hooks";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Typography from "@mui/material/Typography";
import { IconButton } from "muicomponents/src/IconButton/IconButton";
import { useOpenEventCreator, isShowCreateButton, isToDay } from "./utils";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { NewsCreatorButton } from "News/creator/Creator/CreatorButton/CreatorButton";
import { baseModels } from "News/creator/Creator/Creator.hooks";
import { calendarNewsCreatorId } from "./Calendar.constants";
import {
  useCalendarDisallowParallelEvents,
  useCalenderNewsCreatorOptions,
  useCanCalendarCreateEvent,
} from "./Calendar.hooks";
import { CalendarContextProvider } from "./Calendar.context";
import { useLocation } from "react-router";
import { getCompanyPostTypes } from "redux/sagas/AppSettings/selectors";

const localizer = momentLocalizer(moment);

const CalendarPresenter: React.FC<ICalendarProps> = (props) => {
  const openCreator = useOpenEventCreator();

  const {
    context,
    calendarEvents,
    loading,
    getCalendarEvents,
    currentCalendarDate,
    calFilters,
    language,
  } = props;
  const [isFirstRequest, setIsFirstRequest] = useState<boolean>(true);

  const location = useLocation();
  const pathnameRef = useRef(location.pathname);
  const [searchParams, setSearchParams] =
    useSearchParams<CalendarSearchParams>();
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  let {
    defaultDate = searchParams.defaultDate,
    defaultView = searchParams.defaultView,
  } = context;

  useDidUpdateEffect(() => {
    props.updateParentContext?.({
      defaultDate: searchParams.defaultDate,
      defaultView: searchParams.defaultView,
    });
  }, [searchParams.defaultDate, searchParams.defaultView]);

  useDidUpdateEffect(() => {
    if (pathnameRef.current === location.pathname) {
      setSearchParams({
        ...searchParamsRef.current,
        cIds: context.cIds?.length === 1 ? context.cIds : [],
      });
    }
  }, [...(context?.cIds || [])]);

    useEffect(() => {
        return () => {
            props.updateParentContext?.({
                defaultDate: undefined,
                defaultView: undefined,
            });
        };
    }, []);
	

  useEffect(() => {
    setTimeout(() => {
      if (isFirstRequest) {
        setIsFirstRequest(false);
        getCalendarEvents({
          filter: context.filter?.value,
          cIds: context?.cIds,
          statusIds: context?.statusIds,
          currentCalendarDate,
        });
        return;
      }
      getCalendarEvents({
        filter: context.filter?.value,
        cIds: context?.cIds,
        statusIds: context?.statusIds,
        currentCalendarDate,
      });
    }, 100);
  }, [context.filter, context.cIds, currentCalendarDate.slice(0, 7)]);

  const canCreateEvent = useCanCalendarCreateEvent(context);

  CustomToolbar.defaultProps = { context };

  const blockContextValue = useMemo(() => {
    return {
      cIds: context.cIds,
      workflowId: context.workflowId
    };
  }, [context]);

  const disallowParallelEvents = useCalendarDisallowParallelEvents(context);

  return (
    <CalendarContextProvider value={blockContextValue}>
      {
        <Box className={cnCalendar()}>
          <Box
            sx={{ position: "relative", margin: "24px" }}
            className={cnCalendar("Main")}
          >
            <BigCalendar
              culture={language}
              tooltipAccessor={() => ""}
              popup={true}
              messages={{
                showMore: (total) => {
                  return i18n.t("pryaniky.calendar.moreEvents", { total });
                },
              }}
              defaultDate={
                (defaultDate &&
                  typeof defaultDate === "string" &&
                  new Date(defaultDate)) ||
                undefined
              }
              defaultView={defaultView as any}
              onShowMore={(events, date) =>
                openEventsDialog({ events, date })
                  .then(() => { })
                  .catch(() => { })
              }
              // step={15}
              // timeslots={4}
              popupOffset={10}
              localizer={localizer}
              events={calendarEvents}
              components={{
                toolbar: CustomToolbar,
                event: CustomEvent as any,
                timeSlotWrapper: ({ children, value, ...props }: any) => {
                  // if (value.getMinutes() !== 0) return children;
                  if (!!(children as any).props.children) return children;

                  const items = [
                    ...calendarEvents.map((ev) => ({
                      id: ev.id,
                      date: new Date(ev.start),
                    })),
                    { id: "current", date: new Date(value) },
                  ];
                  items.sort((a, b) => a.date.getTime() - b.date.getTime());

                  const index = items.findIndex((v) => v.id === "current");
                  
                  const currentIsUse = disallowParallelEvents
                    ? calendarEvents.some((item) => {
                      const beetwen = moment(value).isBetween(
                        moment(item.start),
                        moment(item.end),
                        null,
                        "[)"
                      );
                      return beetwen;
                    })
                    : false;

                  const showCreateButton = isShowCreateButton(value, true);

                  const creatorOptions = useCalenderNewsCreatorOptions(value, true);
                  const opt: typeof creatorOptions = {
                    ...creatorOptions,
                    options: {
                      ...creatorOptions.options,
                      formSettings: {
                        ...creatorOptions.options?.formSettings,
                        respFormat: 'calendar',
                        additionalFields: {
                          ...creatorOptions.options?.formSettings
                            ?.additionalFields,
                          DateTimeRange: {
                            ...creatorOptions.options?.formSettings
                              ?.additionalFields?.DateTimeRange,
                            defaultEndDateTime: items[index + 1]?.date,
                          },
                        },
                      },
                    },
                  };

                  return (
                    <Box
                      className={'rbc-time-slot'}
                      sx={{
                        display: "flex",
                        justifyContent: showCreateButton
                          ? "space-between"
                          : "flex-end",
                        "&:hover": {
                          "& .OpenCreatorBtn": {
                            opacity: 1,
                          },
                        },
                      }}
                    >
                      {canCreateEvent && showCreateButton && !currentIsUse && (
                        <NewsCreatorButton
                          newsType={opt.workflowId || "events"}
                          iconButton
                          color="primary"
                          size="small"
                          className={"OpenCreatorBtn"}
                          creatorOptions={opt.options}
                          creatorId={calendarNewsCreatorId}
                          groupId={context.gId}
                          workflowId={opt.workflowId || context.workflowId}
                          sx={{
                            position: "relative",
                            zIndex: 11,
                            opacity: 0,
                            padding: '1px',
                          }}
                        >
                          <AddCircleOutlineIcon sx={{ fontSize: '1rem' }} />
                        </NewsCreatorButton>
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: "auto",
                          marginTop: "4px",
                          marginRight: "2px",
                        }}
                      >
                        {children}
                      </Typography>
                    </Box>
                  );
                },
                month: {
                  dateHeader: ({ children, label, ...props }: any) => {
                    const showCreateButton = isShowCreateButton(props.date);
                    // console.log('props.date', props.date)
                    const opt = useCalenderNewsCreatorOptions(
                      props.date
                    );
                    const creatorOptions: typeof opt = {
                      ...opt, options: { ...opt?.options, formSettings: { ...opt?.options?.formSettings, respFormat: 'calendar' } }
                    }

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: showCreateButton
                            ? "space-between"
                            : "flex-end",
                          "&:hover": {
                            "& .OpenCreatorBtn": {
                              opacity: 1,
                            },
                          },
                        }}
                      >
                        {canCreateEvent && showCreateButton && (
                          <NewsCreatorButton
                            newsType={creatorOptions.workflowId || "events"}
                            iconButton
                            color="primary"
                            size="small"
                            className={"OpenCreatorBtn"}
                            creatorOptions={creatorOptions.options}
                            creatorId={calendarNewsCreatorId}
                            groupId={context.gId}
                            workflowId={
                              creatorOptions.workflowId || context.workflowId
                            }
                            sx={{
                              opacity: 0,
                            }}
                          >
                            <AddCircleOutlineIcon fontSize="small" />
                          </NewsCreatorButton>
                        )}
                        <Typography
                          variant="body2"
                          sx={{
                            marginLeft: "auto",
                            marginTop: "4px",
                            marginRight: "2px",
                          }}
                        >
                          {label}
                        </Typography>
                      </Box>
                    );
                  },
                  // event: () => <>event</>,
                  // header: () => <>header</>
                },
                dateCellWrapper: ({ children, value, ...props }: any) => {
                  return (
                    <Box
                      sx={{
                        flex: "1 0",
                        display: "flex",
                      }}
                      className={`rbc-day-bg${isToDay(value) ? " rbc-today" : ""
                        }`}
                    >
                      {/* <AddCircleOutlineIcon onClick={openCreator} /> */}
                    </Box>
                  );
                },
              }}
            />
            {loading && (
              <CircularProgress
                size={45}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      }
      <EventsList groupId={context.gId} workflowId={context.workflowId} />
    </CalendarContextProvider>
  );
};

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarPresenter);
