import React, { FC, memo, useMemo, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import { createNewsTitle } from 'blocks/NewsTypes/NewsUtils';
import MDRender, { NodeBulder } from 'uielements/src/CommonmarkRender'
import { HeaderLayout } from 'blocks/NewsTypes/common/HeaderLayout/HeaderLayout';
import { Button, Avatar, Icon } from 'uielements/src';
import { createNewsDate } from '../../utils/utils'
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { classNamesDesign } from 'utils/src/constants.classes';
import { UserMention } from 'muicomponents/src/UserMention/UserMention';
import { Link } from 'muicomponents/src/Link';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';

type IPresenterProps = News.MiniHeader.IPresenterProps
type IOwnProps = News.MiniHeader.IOwnProps
type IDispatchProps = News.MiniHeader.IDispatchProps
type IStateProps = News.MiniHeader.IStateProps
type IState = News.MiniHeader.IState

const noopFn = () => { }
const noopArray: any[] = []

const useHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            borderBottom: '1px solid #d3d9e0',
            alignItems: 'center',
            padding: '8px 24px',//'0',
            minHeight: '56px',
            boxSizing: 'border-box',
            justifyContent: 'flex-start'
            // backgroundColor: '#fff',
        }
    }),
);

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    id,
    newstype,
    componentRenderName,
    user,
    group,
    header,
    disableTextCut = false,
    date,
    isDraft = false,
    publishAt,
    changeDate,
    disableDate,
    onClick,
    authorOnly
}) => {
    const classes = useHeaderStyles()
    const newsTitleMd = createNewsTitle({ id, newstype, user, header, componentRenderName })
    const titleText = useMemo(() => {
        const nb = new NodeBulder(newsTitleMd)
        return user.displayName + ' ' + nb.getPlainText().join('')
    }, [newsTitleMd])

    const dateStr = createNewsDate({
        date,
        isDraft,
        changeDate,
        publishAt
    })

    return <ButtonBase {...{ component: 'div' }} classes={classes} onClick={onClick} className={cnClassName({}, [className])}>
        <HeaderLayout
            compact
            className={cnClassName('HeaderLayout')}
            type={'post'}
            left={
                user.isDeleted ?
                    <Avatar
                        responsive
                        name={user.displayName}
                        size={40} /> :
                    <Button theme='unstyled' className={cnClassName('UserImg')} type='rlink' href={'/user/' + user.id} >
                        <Avatar
                            responsive
                            imgUrl={user.imgUrl}
                            imgId={user.imgId}
                            name={user.displayName}
                            size={40} />
                    </Button>
            }
            bottom={disableDate ? (header.trim() === "" ? dateStr : null) : dateStr}
        >
            {user.isDeleted ?
                <span>{user.displayName}</span> :
                <UserMention id={user.id}>
                    <Link className={`UserName ${classNamesDesign.primaryColor3.text}`} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>
                        {user.displayName}
                    </Link>
                </UserMention>
            }

            {!authorOnly && <>
                {' '}

                <MDRender
                    title={titleText}
                    className={'Markdown'}
                    type={'inline'}
                    maxCharCount={35}
                    maxCharOffset={15}
                    textCut={!disableTextCut}
                    disableShowMoreBtn
                    source={newsTitleMd}
                />

                {group && group.pkid !== -1 ?
                    <span>
                        <Icon icon="caret-right" />
                        <Button type="rlink" className={'GrupLink'} href={'/group/' + group?.pkid} theme="unstyled">
                            {' ' + group.name}
                        </Button>
                    </span>
                    : undefined
                }
            </>}
        </HeaderLayout>
    </ButtonBase>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
