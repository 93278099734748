import {
    takeEvery,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';
import {
    call,
    put,
    select
} from 'utils/src/saga.effects'

import actions from './actionsType'

import {
    pathNews,
    upgradeNews
} from 'News/redux/actions'

import {
    getNewsParamsById
} from 'News/redux/saga/selectors'

import {
} from 'News/redux/saga'

import {

} from './selectors'

import {

} from './actions'

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

import * as AT from './actions.d'
import { removeUserFromArray, addUserToArray } from '../../NewsUtils';
import { omit } from 'lodash'
import { IdeaNews } from '../IdeaType'
import {
    API,
    getCookie
} from 'utils/src/utils'
import { denormalize, normalize, schema } from 'normalizr';
// import { rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
// import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
// import { convertToRaw } from 'draft-js';
import { withSagaIndicator, withProgressIndicator } from 'utils/src/CommonRedux/LoadIndicator'

const handleToggleParticipants = withProgressIndicator(function* ({ payload, type }: AT.AToggleParticipantsAction | AT.AToggleHeadsAction) {
    const { ideaId, set } = payload

    const field = type === actions.TOGGLE_HEAD ? 'ideaHeads' : 'ideaParticipants'

    const currentUser: any = yield* select(getCurrentUser)
    const { idea }: { idea: IdeaNews['idea'] } = yield* select(getNewsParamsById<IdeaNews, 'idea'>(ideaId, 'idea'))
    // TODO typing
    const result = yield* call(API.news.ideas.setParticipate, ideaId, type === actions.TOGGLE_HEAD ? 'head' : 'user', set) as any;
    const respons = yield result.r
    if (respons.error_code === 0) {
        yield* put(pathNews({
            id: ideaId,
            idea: {
                ...idea,
                [field]: set ? addUserToArray(idea[field] || [], currentUser.baseData) : removeUserFromArray(idea[field] || [], currentUser.baseData)
            }
        }))
    } else {
        throw new Error('error_toogle_participate')
    }

    yield;
    return 0;
})

const handleSetExperts = withProgressIndicator(function* ({ payload, type }: AT.ASetExpertsAction) {
    const { ideaId, experts, newExperts = [] } = payload
    const { idea }: { idea: IdeaNews['idea'] } = yield* select(getNewsParamsById<IdeaNews, 'idea'>(ideaId, 'idea'))
    const errors: any[] = []
    for (const element of experts) {
        const el = newExperts.findIndex(val => {
            return val.id === element.id;
        })
        if (el === -1) {
            // TODO typing
            const result = yield* call(API.news.ideas.setExpert, ideaId, false, element.id) as any;
            const response = yield result.r
            if (response?.error_code !== 0) {
                errors.push(element)
            }
        }
    }

    for (const element of newExperts) {
        const el = experts.findIndex(val => {
            return val.id === element.id;
        })
        if (el === -1) {
            // TODO typing
            const result = yield* call(API.news.ideas.setExpert, ideaId, true, element.id) as any;
            const response = yield result.r
            if (response?.error_code !== 0) {
                errors.push(element)
            }
        }
    }



    // просто обновляем экспертов если дело не связано с их полной зачисткой
    if (newExperts) {
        yield* put(pathNews({

            id: ideaId,
            idea: {
                ...idea,
                responsibleForStatusView: newExperts,

            }
        }))
    }


    if (errors.length > 0) {
        console.error(errors)
        throw new Error('error_handleSetExperts')
    }

    yield;
    return 0;
})


const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });
const handleSaveStatus = withProgressIndicator(function* ({ payload, type }: AT.ASaveStatusAction) {
    const { ideaId, comment, fields = [], statusID } = payload

    const { idea }: { idea: IdeaNews['idea'] } = yield* select(getNewsParamsById<IdeaNews, 'idea'>(ideaId, 'idea'))

    const denormalizedData = denormalize(fields.result, [categorys], fields.entities);
    const opt = { comment, fields: denormalizedData }

    // TODO typing
    const result = yield* call(API.news.ideas._setIdeaStatus, ideaId, statusID, opt) as any;
    const respons: any = yield result.r;
    if (respons.error_code === 0) {
        const data = (respons as any).data
        const commentsKeys: string[] = [];
        const commentsValues: { [s: string]: any } = {};

        data.comments?.forEach((el: any) => {
            commentsKeys.push(el.id);
            commentsValues[el.id] = el;
        });

        yield* put(upgradeNews({ values: { [data.id]: { ...data, comments: data.comments?.map((el: any) => el.id) } }, keys: [data.id], commentsKeys, commentsValues }))
    } else {
        throw new Error('error_handleSaveStatus')
    }
    yield;
    return 0;
})


/**
 * not used
 */
// const loadUsers = withProgressIndicator(function* ({ payload, type }: AT.ALoadUsersAction) {
//     const { ideaId, statusID } = payload

//     const currentUser: any = yield* select(getCurrentUser)
//     const { idea }: { idea: IdeaNews['idea'] } = yield* select(getNewsParamsById<IdeaNews, 'idea'>(ideaId, 'idea'))
//     const result: any = yield* call(API.news.ideas.getExpertiseResult, ideaId, statusID)
//     const respons = yield result.r
//     if (respons.error_code === 0) {
//         const expertArray = respons.data.expertises.map((ex: any) => {
//             return ex.user;
//         })
//         return expertArray
//     } else {
//         throw new Error('error_loadUsers')
//     }

//     yield;
//     return null;
// })



/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.TOGGLE_PARTICIPATS, handleToggleParticipants)
    yield takeLeading(actions.TOGGLE_HEAD, handleToggleParticipants)
    yield takeLeading(actions.SAVE_EXPERTS, handleSetExperts)
    yield takeLeading(actions.SAVE_STATUS, handleSaveStatus)
    // yield takeLeading(actions.LOAD_EXPERTISE_RESULT, loadUsers)
}
/**
 * export root saga
 */
export default root;