import {
    takeEvery,
    put,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import actions from './actionsType'

import {
    pathNews
} from 'News/redux/actions'

import {
    getNewsById
} from 'News/redux/saga/selectors'

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

import * as AT from './actions.d'

import {
    API,
    getCookie
} from 'utils/src/utils'





const handleChangeAnswer = function* handleChangeAnswer({ payload }: AT.AChaneAnswer) {
    try {
        const { id, answers, maxCount } = payload

        const news = yield* select(getNewsById(id)) as any

        yield put(pathNews({
            id,
            poll: {
                ...news.poll,
                answers,
                maxCount
            }
        }))

        const request = yield* call(API.news.addPollAnswer, {
            newsUid: id,
            data: answers
        })
        // @ts-ignore
        const result = yield request.r

    } catch (error) {
        console.warn(error)
    }
    yield;
    return 0;
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.CHANGE_ANSWER, handleChangeAnswer)
};

/**
 * export root saga
 */
export default root;